import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import NavLeft from './NavLeft';
import NavRight from './NavRight';
import Logo from '../../../assets/images/user/logo.png';
import { ConfigContext } from '../../../contexts/ConfigContext';
import * as actionType from '../../../store/actions';
import SnackbarRefresh from '../../../components/SnackBar/SnackbarRefresh';
import ReminderPopup from '../../../components/Apt/ReminderPopUp/ReminderPopup';
import { Grid } from '@mui/material';
import NavSearch from './NavLeft/NavSearch';
import { live } from '../../../config/server-config';
import { ButtonGroup, Dropdown, DropdownButton, ListGroup } from 'react-bootstrap';
import CustomDropdown from '../../../views/ui-elements/CustomDropdown/CustomDropdown';

const userInfo = JSON.parse(localStorage.getItem('user_info'));
const NavBar = () => {
  const location = useLocation();
  const [showRefresh, setShowRefresh] = useState(false);
  const configContext = useContext(ConfigContext);
  const { collapseMenu, headerBackColor, headerFixedLayout, layout, subLayout } = configContext.state;
  const { dispatch } = configContext;

  setTimeout(function () {
    setShowRefresh(true);
  }, 300000);
  let headerClass = ['navbar', 'pcoded-header   ', 'navbar-expand-lg', headerBackColor];
  if (headerFixedLayout && layout === 'vertical') {
    headerClass = [...headerClass, 'headerpos-fixed'];
  }
  let toggleClass = ['mobile-menu'];
  if (collapseMenu) {
    toggleClass = [...toggleClass, 'on'];
  }

  const navToggleHandler = () => {
    dispatch({ type: actionType.COLLAPSE_MENU });
  };

  // let moreClass = ['mob-toggler'];;
  // if (layout === 'horizontal') {
  //     moreClass = [...moreClass, 'd-none'];
  // }
  let collapseClass = ['collapse navbar-collapse'];
  // if (moreToggle) {
  //     //moreClass = [...moreClass, 'on'];
  //     collapseClass = [...collapseClass, 'd-block']
  // }

  let navBar = (
    <React.Fragment>
      {location.pathname === '/diary' && (
        <>
          {showRefresh && (
            <SnackbarRefresh
              snackbar={true}
              massage="You have not refreshed your diary screen for a long time "
              color="#203850"
              vertical="top"
              horizontal="center"
              onSnackBarClose={() => setShowRefresh(false)}
            />
          )}
        </>
      )}
      {
        userInfo?.roleName === 'Booking Team' && <ReminderPopup />
      }
      <div className="m-header">
        <Link to="#" className={toggleClass.join(' ')} id="mobile-collapse" onClick={navToggleHandler}>
          <span />
        </Link>
        <Link to="/" className="b-brand">
          <div>
            <img style={{ width: '50px', height: '50px' }} src={Logo} />
          </div>
          <span className="b-title">LDC</span>
        </Link>
        <Link to="#">
          <i className="feather icon-more-vertical" />
        </Link>
      </div>
      <div className=" align-items-center  w-100 d-flex justify-content-between">
        {/* <div></div> */}
        {/* <div> */}
        <NavSearch />
        <div className="text-danger " style={{ fontSize: '3rem', fontWeight: 'bold' }}>
          {live === false ? 'STAGING' : ''}
        </div>
        {/* </div> */}
        {/* <div> */}
        {/* <CustomDropdown/> */}

        <NavRight />
        {/* </div> */}
      </div>
      {/* <div className={collapseClass.join(' ')}> */}
      {/* <Grid container style={{ backgroundColor: 'white' }}>
        <Grid item md={10} sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}>
          <NavLeft />
        </Grid>
        <Grid item md={2} sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}>
          <NavRight />
        </Grid>
      </Grid> */}
    </React.Fragment>
  );

  if (layout === 'horizontal' && subLayout === 'horizontal-2') {
    navBar = <div className="container">{navBar}</div>;
  }

  return (
    <React.Fragment>
      <header className={headerClass.join(' ')}>{navBar}</header>
    </React.Fragment>
  );
};

export default NavBar;
