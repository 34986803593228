import React from "react";
import { Navbar } from "react-bootstrap";
 
import { useState } from "react";
 
import FormValidationExample from "../../forms/FormValidationExample";
import { ToastProvider } from "react-toast-notifications";

const apStyle = {
  textAlign: "center",
};

const Systems = () => {
  return (
    <>
      <Navbar />
      <div className="App" style={apStyle}>
        <header className="App-header">
          <ToastProvider
            placement="top-center"
            autoDismissTimeout={2000}
            autoDismiss={true}
          >
            <FormValidationExample />
          </ToastProvider>
        </header>
      </div>
    </>
  );
};

export default Systems;
