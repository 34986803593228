let liveUrl = 'https://api.sfd3973z443h.com/api/';
let stagingUrl = 'https://api-ldc.goldenbuzz.in/api/';

let liveWebsite = 'https://sfd3973z443h.com';
// let liveWebsite = 'https://vc.londondermatologyclinic.com';
let stagingWebsite = 'https://ldc.goldenbuzz.in';

let goToLive = '';
let goToStaging = '';
export const vcLiveURL = 'https://vc.londondermatologyclinic.com';

export let live = false; //true for live .... false for staging .... please ensure that it should be true when you upload it on live ok

if (live === true) {
  goToLive = liveUrl;
  goToStaging = liveWebsite;
} else {
  goToLive = stagingUrl;
  goToStaging = stagingWebsite;
}

export const serverConfig = {
  // appServerUrl: 'https://api.sfd3973z443h.com/api/ '
  // appServerUrl: 'https://api-ldc.goldenbuzz.in/api/'
  appServerUrl: goToLive,
};

export const webServer = {
  // appServerUrl: 'https://sfd3973z443h.com'
  // appServerUrl: 'https://ldc.goldenbuzz.in'
  appServerUrl: goToStaging,
};
//testing
