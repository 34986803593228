import { useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useDetectOutsideClick } from '../../../helper/useDetechOutsideClick';
import { chanelValidate, validateEndTime, validateStartTime } from '../../../helper/validation';
import CustomButtons from '../../../views/ui-elements/Buttons/CustomButtons';
import CustomInput from '../../../views/ui-elements/input/CustomInput';
import CustomSelect from '../../../views/ui-elements/input/CustomSelect';
import CustomTime from '../../../views/ui-elements/input/TimeInput';

const ChamberModal = (props) => {
  const {
    show,
    handleClose,
    chamber,
    clinicname,
    allStatus,
    chamberError,
    doctorList,
    allweek,
    setEditChamber,
    setEditChamberError,
    onChamberChange,
    onChamberSubmit,
    roomList
  } = props;

  const [startTimeOpen, setStartTimeOPen] = useState(false);
  const [endTimeOpen, setEndTimeOpen] = useState(false);
  const startTimeRef = useRef();
  const endTimeRef = useRef();

  const onStOutsideClick = () => {
    setStartTimeOPen(false);
  };
  const onEdOutsideClick = () => {
    setEndTimeOpen(false);
  };

  useDetectOutsideClick(startTimeRef, onStOutsideClick);
  useDetectOutsideClick(endTimeRef, onEdOutsideClick);

  const onEditStartTime = (e) => {
    setEditChamber({
      ...chamber,
      timeStart: e
    });

    const isValidate = validateStartTime(e, chamber.timeEnd);
    const isEndValidate = validateEndTime(chamber.timeEnd, e);

    if (isValidate) {
      setEditChamberError({
        ...chamberError,
        timeStart: 'Start Time should not be greater than  or equal to end time'
      });
    } else {
      setEditChamberError({
        ...chamberError,
        timeStart: chanelValidate('timeStart', e)
      });

      if (!isEndValidate && chamber.timeEnd !== '') {
        setEditChamberError({
          ...chamberError,
          timeEnd: '',
          timeStart: ''
        });
      }
    }

    setStartTimeOPen(false);
  };

  const onEditEndTime = (e) => {
    setEditChamber({
      ...chamber,
      timeEnd: e
    });
    const isValidate = validateEndTime(e, chamber.timeStart);
    const isStartValidate = validateStartTime(chamber.timeStart, e);

    if (isValidate) {
      setEditChamberError({
        ...chamberError,
        timeEnd: 'End Time should not be less than  or equal to start time'
      });
    } else {
      setEditChamberError({
        ...chamberError,
        timeEnd: chanelValidate('timeEnd', e)
      });
      if (!isStartValidate && chamber.timeStart !== '') {
        setEditChamberError({
          ...chamberError,
          timeStart: '',
          timeEnd: ''
        });
      }
    }
    setEndTimeOpen(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onChamberSubmit}>
            <Row style={{ margin: '0.5rem', backgroundColor: 'white', padding: '1rem' }}>
              {/* <Col md={6} className="clinicName">
                <Form.Group className="mb-3 ">
                  <Form.Label htmlFor="clinicName">Clinic Name</Form.Label>
                  <CustomSelect
                    name="clinicName"
                    value={chamber.clinicName}
                    defaultValue="Select"
                    onChange={onChamberChange}
                    selectData={clinicname}
                  />
                  <small className="text-danger">{chamberError.clinicName}</small>
                </Form.Group>
              </Col> */}
              <Col md={6} className="doctorId">
                <Form.Group className="mb-3 ">
                  <Form.Label htmlFor="doctorId">Doctor</Form.Label>
                  <CustomSelect
                    name="doctorId"
                    value={chamber.doctorId}
                    onChange={onChamberChange}
                    defaultValue="Select"
                    selectData={doctorList}
                  />
                  <small className="text-danger">{chamberError.doctorId}</small>
                </Form.Group>
              </Col>
              {/* <Col md={6} className="dayOfWeek">
                <Form.Group className="mb-3 ">
                  <Form.Label htmlFor="dayOfWeek">Day Of Week</Form.Label>
                  <CustomSelect
                    name="dayOfWeek"
                    value={chamber.dayOfWeek}
                    onChange={onChamberChange}
                    defaultValue="Select"
                    selectData={allweek}
                  />
                  <small className="text-danger">{chamberError.dayOfWeek}</small>
                </Form.Group>
              </Col> */}
              <Col md={6} ref={startTimeRef}>
                <Form.Group className="mb-3 ">
                  <Form.Label htmlFor="timeStart">Time Start</Form.Label>
                  <CustomTime
                    value={chamber.timeStart}
                    isDisabled={false}
                    isListOpen={startTimeOpen}
                    setIsListOpen={setStartTimeOPen}
                    onTime={onEditStartTime}
                    filedNotRed={true}
                    block="end"
                  />
                  <small className="text-danger">{chamberError.timeStart}</small>
                </Form.Group>
              </Col>

              <Col md={6} ref={endTimeRef}>
                <Form.Group className="mb-3 ">
                  <Form.Label htmlFor="timeEnd">Time End</Form.Label>
                  <CustomTime
                    value={chamber.timeEnd}
                    isDisabled={false}
                    isListOpen={endTimeOpen}
                    setIsListOpen={setEndTimeOpen}
                    onTime={onEditEndTime}
                    filedNotRed={true}
                    block="end"
                  />
                  <small className="text-danger">{chamberError.timeEnd}</small>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3 ">
                  <Form.Label htmlFor="chamberDate"> Date</Form.Label>
                  <CustomInput type="date" value={chamber.chamberDate} name="chamberDate" onChange={onChamberChange} />
                  <small className="text-danger">{chamberError.chamberDate}</small>
                </Form.Group>
              </Col>
              {/* <Col md={6} className="endDate">
                <Form.Group className="mb-3 ">
                  <Form.Label htmlFor="endDate">End Date</Form.Label>
                  <CustomInput type="date" value={chamber.endDate} name="endDate" onChange={onChamberChange} />
                  <small className="text-danger">{chamberError.endDate}</small>
                </Form.Group>
              </Col> */}
              <Col md={6} className="roomName">
                <Form.Group className="mb-3 ">
                  <Form.Label htmlFor="roomName">Room</Form.Label>

                  <CustomSelect
                    isDisabled={false}
                    name="roomName"
                    value={chamber.roomName}
                    defaultValue="Select"
                    onChange={onChamberChange}
                    selectData={roomList}
                  />
                  <small className="text-danger">{chamberError.roomName}</small>
                </Form.Group>
              </Col>

              {/* <Col md={6} className="room">
                <Form.Group className="mb-3 ">
                  <Form.Label htmlFor="room">Room</Form.Label>
                  <CustomSelect name="room" value="Room4" onChange={onChamberChange} defaultValue="Select" selectData={roomList} />

                  <small className="text-danger">{chamberError.roomId}</small>
                </Form.Group>
              </Col> */}
              <Col md={6} className="allStatus">
                <Form.Group className="mb-3 ">
                  <Form.Label htmlFor="allStatus">Status</Form.Label>
                  <CustomSelect
                    isDisabled={false}
                    name="active"
                    value={chamber.active}
                    defaultValue="Select"
                    onChange={onChamberChange}
                    selectData={allStatus}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <CustomButtons variant="primary" onClick={onChamberSubmit} name="Update" />
          <CustomButtons variant="danger" onClick={handleClose} name="Cancel" />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChamberModal;
