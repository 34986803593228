import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import './App.scss';
import { FirebaseProvider } from './contexts/FirebaseContext';
import { Navigation } from './Navigation';
import store from './store';
import { ConfigProvider } from './contexts/ConfigContext';
// import axios from 'axios';
// import { serverConfig } from './config/server-config';

// const appServerUrl = serverConfig.appServerUrl;

// axios.interceptors.request.use((config) => {
//   config.baseURL = appServerUrl;

//   const token = localStorage.getItem('_code');

//   if (token) {
//     config.headers = {
//       SecurityCode: token
//     };
//   }
//   return config;
// });
//git pull - rr
const App = () => {
  useEffect(() => {
    document.title = 'Login - LDC';
  });

  return (
    <React.Fragment>
      <ConfigProvider>
        <Provider store={store}>
          <ToastProvider
            placement='bottom-center'
            autoDismissTimeout={3000}
            autoDismiss={true}
          >
            <FirebaseProvider>
              <Navigation />
            </FirebaseProvider>
          </ToastProvider>
        </Provider>
      </ConfigProvider>
    </React.Fragment>
  );
};

export default App;
