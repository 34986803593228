import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { RiCloseFill } from 'react-icons/ri';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useEffect } from 'react';
import LdcApi from '../../../../../LdcApis';
import ConsentFormsNavbar from '../../../../../components/common/ConsentFormsNavbar';

const initialValues = {
  otp: ''
};
const validationSchema = yup.object().shape({
  otp: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits')
    .required('Field is required.')
});

const VascularSurgeryOTPVerification = () => {
  const [GetLoading, setGetLoading] = useState(false);
  const { addToast } = useToasts();
  const { id, appId } = useParams();
  const location = useLocation();

  const navigate = useNavigate();
  let forPatients = JSON.parse(localStorage.getItem('forPatients'));
  const [ph, setPh] = useState('0000');

  useEffect(() => {
    if (location.state == null) {
      navigate(`/VascularSurgeryOTP/${id}/appId/${appId}`);
    }
    setPh(location?.state?.ph);
    document.title = 'OTP verification- LDC';
  }, [location]);
  const nextBtn = (values) => {
    setGetLoading(true);
    LdcApi({
      method: 'GET',
      url: `HealthQuestionnaire/ValidateFormOTP?PatientId=${id}&AppointmentId=${appId}&OTP=${values.otp}`
    })
      .then((res) => {
        setGetLoading(false);
        if (res.data.status.isSuccess === true) {
          navigate(`/consent-forms/patieId/${id}/appointId/${appId}/VascularSurgeryMessage`, { state: res.data.data[0] });
        } else {
          addToast(`Invalid OTP`, {
            appearance: 'error'
          });
        }
      })
      .catch((error) => {
        setGetLoading(false);
      });
  };
  const resendOTP = () => {
    LdcApi({
      method: 'GET',
      url: `HealthQuestionnaire/ValidatePhoneNumber?PatientId=${id}&Mobile=${ph}`
    })
      .then((res) => {
        if (res.data.status.isSuccess == true) {
          // alert(res.data.data);
          addToast(` We have sent you a text message wth a six-digit verification code to the phone number ********${ph}`, {
            appearance: 'success'
          });
        }
      })
      .catch((error) => {
      });
  };
  return (
    <div>
      <ConsentFormsNavbar />
      <div className="pt-5">
        <Container className="mt-5">
          {' '}
          <div className="pt-5" style={{ maxWidth: '450px', display: 'flex', margin: 'auto' }}>
            <div className="mt-5 ">
              <h4 className="text-center">London Dermatology Clinic Secure Person Verification</h4>

              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={nextBtn}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <Form
                    className="mt-4"
                    style={{ maxWidth: '300px', display: 'flex', margin: 'auto', flexDirection: 'column' }}
                    onSubmit={handleSubmit}
                  >
                    {' '}
                    <p>
                      Let's make sure it's really you. We have sent you a text message wth a six-digit verification code to the phone number
                      ********{ph} (the last 4 digits of the phone number).
                    </p>
                    <div>
                      <Form.Group>
                        <b> Enter the 6-digit verification code</b>
                        <Form.Control
                          className="mt-1"
                          type="text"
                          name="otp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.otp}
                          error={errors.otp}
                        />
                        {touched.otp && errors.otp && <small className="text-danger error_field">{errors.otp}</small>}
                      </Form.Group>
                    </div>
                    <Button type="submit" className="d-block">
                      {GetLoading ? 'Loading...' : 'Next'}
                    </Button>
                  </Form>
                )}
              </Formik>
              <p className="text-center mt-3">
                Didn't receive the code?{' '}
                <span onClick={() => resendOTP()} style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}>
                  Resend SMS
                </span>
              </p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default VascularSurgeryOTPVerification;
