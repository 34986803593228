import React, { useRef, useState } from 'react';
import ConsentFormsNavbar from '../../../components/common/ConsentFormsNavbar';
import ProfileBar from './ProfileBar';
import { Button, Modal, Container, Card, Form, Row, Col, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import moment from 'moment';
import classNames from 'classnames';
import LdcApi from '../../../LdcApis';
import { useToasts } from 'react-toast-notifications';
import CanvasDraw from 'react-canvas-draw';
import { useEffect } from 'react';
import termsandcondition from './../../../../src/assets/PDF/Terms_&_Conditions_(LDC).pdf';

const VascularSurgery = () => {
    let appointmentData = JSON.parse(localStorage.getItem('appointmentData'));
    const [mouseDisable, setMouseDisable] = useState('auto');
    const [patientSignature, setPatientSignature] = useState('');
    const [brushColor, setBrushColor] = React.useState('#000');
    const [btnSubmit, setBtnSubmit] = useState(false);
    const [patientAuthorized, setPatientAuthorized] = useState(null);
    const navigate = useNavigate();
    const hideForms = () => {
        navigate('/confirmation');
    };
    const patientAuthorizedChanges = (bool) => {
        setPatientAuthorized(bool);
        setValidationError({ ...validationError, patientAuthorized: false });
    };

    const [formData, setFromData] = useState({
        todayDate: new Date(),
        appointmentDate: appointmentData?.date,
        patientName: `${appointmentData?.firstName} ${appointmentData?.lastName}`,
        pId: appointmentData.patientId,
        doctorName: appointmentData.doctorName,
        cordinatorName: "",
        SecondDate: new Date(),
        procedureTitle: "",
        procedureCost: "",
        procedureDate: "",
        anaesthesiaCost: "",
        arrivalTime: "",
        anticipatedTreatmentDur: ""
    });


    // useEffect(()=>{
    //     console.log(formData)
    // },[formData])

    const [validationError, setValidationError] = useState();


    const handelChange = (e) => {
        setFromData({ ...formData, [e.target.name]: e.target.value });
    };

    const { addToast } = useToasts();

    useEffect(() => {
        document.title = 'Vascular Veins Surgery Form - LDC';
        localStorage.setItem('confirmationCode', true);
    }, []);

    // signature  start************************************************
    const canvasRef = useRef(null);

    const defaultProps = {
        loadTimeOffset: 5,
        lazyRadius: 0,
        brushRadius: 2,
        catenaryColor: '#0a0302',
        gridColor: 'rgba(150,150,150,0.17)',
        hideGrid: true,
        canvasHeight: 300,
        disabled: false,
        imgSrc: '',
        saveData: '',
        immediateLoading: false,
        hideInterface: false
    };

    const getImgPatientSignature = () => canvasRef.current.canvasContainer.children[1].toDataURL();

    const patientSignatureCanvasChange = () => {
        const saveData = getImgPatientSignature();
        setPatientSignature(saveData);
        if (patientSignature == '') {
            setValidationError({ ...validationError, patientSignature: true });
        } else {
            setValidationError({ ...validationError, patientSignature: false });
        }
    };

    const patientSignatureProps = {
        ...defaultProps,
        onChange: patientSignatureCanvasChange,
        ref: canvasRef,
        className: classNames('canvasSignature'),
        brushColor,
        catenaryColor: brushColor
    };

    // signature  end************************************************

    const handleSubmit = (e) => {
        e.preventDefault();
        setBtnSubmit(true);
        let formValidationError = null;
        Object.entries(formData).forEach(([key, value]) => {
            if ((value !== 0 || value !== false) && !value) {
                formValidationError = {
                    ...formValidationError,
                    [key]: true
                }
            }
        })
        if (patientSignature === '') {
            formValidationError.patientSignature = true;
        }
        if (patientAuthorized === null) {
            formValidationError.patientAuthorized = true;
        }
        console.log(formValidationError)
        setValidationError(formValidationError);
        if (!formValidationError) {
            console.log(formData)
            // do some api call
            const newValues = {
                patientId: formData.pId,
                patientName: formData.patientName,
                patientSignature: patientSignature,
                consultationDate: moment(formData.appointmentDate).format(),
                todaysDate: moment(formData.todayDate).format(),
                consultantName: formData.doctorName,
                coordinatorName: formData.cordinatorName,
                patientAgreement: patientAuthorized,
                appointmentId: appointmentData?.id,
                procedureTitle: formData.procedureTitle,
                procedureCost: formData.procedureCost,
                procedureDate: formData.procedureDate,
                anaesthesiaType: formData.anaesthesiaCost,
                arrivalTime: formData.arrivalTime,
                anticipatedTreatmentDuration: formData.anticipatedTreatmentDur
            };
            LdcApi({
                method: 'POST',
                url: 'VascularVeins_SurgeryForm/AddVascularVeins_SurgeryForm',
                data: newValues
            }).then((res) => {
                if (res?.data?.status?.isSuccess === true) {
                    addToast(' Vascular veins surgery form  added successfully ', { appearance: 'success' });
                    setMouseDisable('auto');
                    setBtnSubmit(false);
                    canvasRef.current.clear();
                    navigate('/confirmation');
                    setFromData({
                        todayDate: new Date(),
                        appointmentDate: appointmentData?.date,
                        patientName: `${appointmentData?.firstName} ${appointmentData?.lastName}`,
                        pId: appointmentData.patientId,
                        doctorName: appointmentData.doctorName,
                        cordinatorName: "",
                        SecondDate: new Date(),
                        procedureTitle: "",
                        procedureCost: "",
                        procedureDate: "",
                        anaesthesiaCost: "",
                        arrivalTime: "",
                        anticipatedTreatmentDur: ""
                    });
                }
            })
                .catch((err) => {
                    setMouseDisable('auto');
                    setBtnSubmit(false);
                })
        } else {
            setMouseDisable('auto');
            setBtnSubmit(false);
        }
    };

    return (
        <div>
            <div className="navbar_header_container br_c">
                <ConsentFormsNavbar />
            </div>
            <ProfileBar />
            <Container style={{ pointerEvents: { mouseDisable } }}>
                <div className="d-flex my-4 justify-content-end">
                    <Button onClick={() => hideForms()} variant="outline-primary mb-3">
                        Go to diary
                    </Button>
                </div>
                <Card>
                    <p className="text-center form_heading  mb-5">Vascular Veins Surgery Form</p>

                    <Form onSubmit={(e) => handleSubmit(e)} className="px-3">
                        <Row className="px-4 py-2">
                            <Col className='d-flex justify-content-start align-item-center'>
                                <Form.Group className="m-0 p-0">
                                    <b className="mr-2  ">Today's Date: </b>
                                    <input
                                        className={`from_control  disabled  `}
                                        type="text"
                                        disabled
                                        // isInvalid={touched && !!error}
                                        // isValid={touched && !error}
                                        name="todayDate"
                                        // onChange={(e) => handelOnaChnage(e)}
                                        value={moment(formData.todayDate).format('DD/MM/YYYY')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='d-flex justify-content-start align-item-center'>
                                <Form.Group className="m-0 p-0">
                                    <b className="mr-2 ">Date of Consultation: </b>
                                    <input
                                        className={`from_control disabled `}
                                        type="text"
                                        disabled
                                        // isInvalid={touched && !!error}
                                        // isValid={touched && !error}
                                        name="appointmentDate"
                                        // onChange={(e) => handelOnaChnage(e)}
                                        value={moment(formData.appointmentDate).format('DD/MM/YYYY')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="px-4 py-2">
                            <Col className='d-flex justify-content-start align-item-center'>
                                <Form.Group className="m-0 p-0">
                                    <b className="mr-2  ">Patient Name: </b>
                                    <input
                                        className={`from_control  disabled  `}
                                        type="text"
                                        disabled
                                        // isInvalid={touched && !!error}
                                        // isValid={touched && !error}
                                        name="patientName"
                                        // onChange={(e) => handelOnaChnage(e)}
                                        value={formData.patientName}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='d-flex justify-content-start align-item-center'>
                                <Form.Group className="m-0 p-0">
                                    <b className="mr-2 ">Patient ID Number (PID): </b>
                                    <input
                                        className={`from_control disabled `}
                                        type="text"
                                        disabled
                                        // isInvalid={touched && !!error}
                                        // isValid={touched && !error}
                                        name="pId"
                                        // onChange={(e) => handelOnaChnage(e)}
                                        value={formData.pId}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="px-4 py-2">
                            <Col className='d-flex justify-content-start align-item-center'>
                                <Form.Group className="m-0 p-0">
                                    <b className="mr-2  ">Consultant Name: </b>
                                    <input
                                        className={`from_control  disabled  `}
                                        type="text"
                                        disabled
                                        // isInvalid={touched && !!error}
                                        // isValid={touched && !error}
                                        name="doctorName"
                                        // onChange={(e) => handelOnaChnage(e)}
                                        value={formData.doctorName}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='d-flex justify-content-start align-item-center'>
                                <Form.Group className="m-0 p-0">
                                    <div>
                                        <b className="mr-2">Coordinator Name: </b>
                                        <input
                                            className={`from_control disabled `}
                                            type="text"
                                            // disabled
                                            // isInvalid={touched && !!error}
                                            // isValid={touched && !error}
                                            name="cordinatorName"
                                            onChange={(e) => handelChange(e)}
                                            value={formData.cordinatorName}
                                        />
                                    </div>
                                    <div>
                                        <span className="text-danger">{validationError?.cordinatorName === true && 'Field is required.'}</span>
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="px-4 py-2">
                            <Col>
                                <div>
                                    <b>Procedure Details:</b>
                                    <ul>
                                        <li>
                                            <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                    <b>Procedure Title:</b>
                                                    <input
                                                        className={`from_control  disabled  `}
                                                        type="text"
                                                        // disabled
                                                        // isInvalid={touched && !!error}
                                                        // isValid={touched && !error}
                                                        name="procedureTitle"
                                                        onChange={(e) => handelChange(e)}
                                                        value={formData.procedureTitle}
                                                    />
                                                </div>
                                                <div>
                                                    <span className="text-danger">{validationError?.procedureTitle === true && 'Field is required.'}</span>
                                                </div>
                                            </Form.Group>
                                        </li>
                                        <li>
                                            <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                    <b>Procedure Cost:</b>
                                                    <input
                                                        className={`from_control  disabled  `}
                                                        type="text"
                                                        // disabled
                                                        // isInvalid={touched && !!error}
                                                        // isValid={touched && !error}
                                                        name="procedureCost"
                                                        onChange={(e) => handelChange(e)}
                                                        value={formData.procedureCost}
                                                    />
                                                </div>
                                                <div>
                                                    <span className="text-danger">{validationError?.procedureCost === true && 'Field is required.'}</span>
                                                </div>
                                            </Form.Group>
                                        </li>
                                        <li>
                                            <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                    <b>Anaesthesia Type:</b>
                                                    <input
                                                        className={`from_control  disabled  `}
                                                        type="text"
                                                        // disabled
                                                        // isInvalid={touched && !!error}
                                                        // isValid={touched && !error}
                                                        name="anaesthesiaCost"
                                                        onChange={(e) => handelChange(e)}
                                                        value={formData.anaesthesiaCost}
                                                    />
                                                </div>
                                                <div>
                                                    <span className="text-danger">{validationError?.anaesthesiaCost === true && 'Field is required.'}</span>
                                                </div>
                                            </Form.Group>
                                        </li>
                                        <li>
                                            <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                    <b>Procedure Date:</b>
                                                    <input
                                                        className={`from_control  disabled  `}
                                                        type="date"
                                                        format="DD-MM-YYYY"
                                                        // disabled
                                                        // isInvalid={touched && !!error}
                                                        // isValid={touched && !error}
                                                        name="procedureDate"
                                                        onChange={(e) => handelChange(e)}
                                                        value={formData.procedureDate}
                                                    />
                                                </div>
                                                <div>
                                                    <span className="text-danger">{validationError?.procedureDate === true && 'Field is required.'}</span>
                                                </div>
                                            </Form.Group>
                                        </li>
                                        <li>
                                            <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                    <b>Arrival Time:</b>
                                                    <input
                                                        className={`from_control  disabled  `}
                                                        type="time"
                                                        // disabled
                                                        // isInvalid={touched && !!error}
                                                        // isValid={touched && !error}
                                                        name="arrivalTime"
                                                        onChange={(e) => handelChange(e)}
                                                        value={formData.arrivalTime}
                                                    />
                                                </div>
                                                <div>
                                                    <span className="text-danger">{validationError?.arrivalTime === true && 'Field is required.'}</span>
                                                </div>
                                            </Form.Group>

                                        </li>
                                        <li>
                                            <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                                                    <b>Anticipated Treatment Duration:</b>
                                                    <input
                                                        className={`from_control  disabled  `}
                                                        type="text"
                                                        // disabled
                                                        // isInvalid={touched && !!error}
                                                        // isValid={touched && !error}
                                                        name="anticipatedTreatmentDur"
                                                        onChange={(e) => handelChange(e)}
                                                        value={formData.anticipatedTreatmentDur}
                                                    />

                                                </div>
                                                <div>
                                                    <span className="text-danger">{validationError?.anticipatedTreatmentDur === true && 'Field is required.'}</span>
                                                </div>
                                            </Form.Group>
                                        </li>
                                        <li><b>Location:</b> London Dermatology Clinic, 20 Eastcheap, London, EC3M 1EB</li>

                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row className="px-4 py-2">
                            <Col>
                                <div>
                                    <b>Financials:</b>
                                    <ol>
                                        <li><b>Consultation - Initial Consultation:</b> £95.00 (this includes a free ultrasound scan).</li>
                                        <li><b>Reservation fee - (securing a theatre slot):</b> For Laser EVLA patients a £500.00 deposit is required.
                                            For Sclerotherapy-only and Phlebectomy-only patients a deposit of 50% of the treatment fee is required.</li>
                                        <li><b>Residual balances:</b> These are paid in the clinic on the procedure day, before treatment commences.</li>
                                    </ol>
                                </div>
                            </Col>
                        </Row>
                        <Row className="px-4 py-2">
                            <Col>
                                <div>
                                    <b>Rescheduling and Cancellation Policy:</b>
                                    <p>
                                        The clinic will ensure that laser fibres, consumables, staffing and support stockings are available to all patients.
                                        This of course incurs costs for the clinic. Should a patient cancel or wish to reschedule before 14 days
                                        of the procedure no charges are applied. Patients wanting to cancel or reschedule their procedures within
                                        14 days of their planned procedure will incur a fee of the value of 50% of their deposit. Any cancellation
                                        or rescheduling within 7 days of their planned procedure will incur a fee of the value of 100% of their
                                        deposit.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="px-4 py-2">
                            <Col>
                                <div>
                                    <b>Patient Agreement:</b>
                                </div>
                            </Col>
                        </Row>
                        <Row className="px-4 py-2">
                            <Col>
                                <div>
                                    <b>
                                        {' '}
                                        I have read and understood the terms and conditions <a style={{ textDecoration: "underline", color: "blue" }} href={termsandcondition} target="_blank">(read here)</a>.{' '}
                                        <span className="text-danger">*</span>
                                    </b>
                                    <Row className="mt-2   p-0 " style={{ maxWidth: '1200px', justifyContent: 'center', margin: 'auto' }}>
                                        <Col sm={2} className="p-0 mx-4">
                                            <div
                                                className={` yes_no_btn ${patientAuthorized !== null ? (patientAuthorized ? 'yes_Btn' : 'back') : null}`}
                                                onClick={() => patientAuthorizedChanges(true)}
                                            >
                                                YES
                                            </div>
                                        </Col>
                                        <Col sm={2} className="p-0 mx-4">
                                            <div
                                                className={`   yes_no_btn ${patientAuthorized !== null ? (patientAuthorized ? 'back' : 'no_Btn') : null} `}
                                                onClick={() => patientAuthorizedChanges(false)}
                                            >
                                                NO
                                            </div>
                                        </Col>
                                    </Row>
                                    <span className="text-danger">{validationError?.patientAuthorized === true && 'Field is required.'}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="px-4 py-2">
                            <Col>
                                <div className="mt-3">
                                    <Form.Group className="m-0 p-0 d-flex align-items-center">
                                        <b className="mr-2  ">Date: </b>
                                        <input
                                            className={`from_control disabled `}
                                            disabled
                                            type="text"
                                            name="SecondDate"
                                            // placeholder="name"
                                            value={moment(formData.SecondDate).format('DD/MM/YYYY')}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row className="px-4 py-2">
                            <Col>
                                <div className="mt-4">
                                    <p>
                                        * I the patient or the authorised representative e.g. parent, sign below to give my formal informed consent to the
                                        proposed treatment/procedure. <span className="text-danger">*</span>
                                    </p>
                                    <Col sm={12} className="mt-2">
                                        <div style={{ overflow: 'hidden' }}>
                                            <CanvasDraw {...patientSignatureProps} />
                                            <span className="text-danger">{validationError?.patientSignature === true && 'Field is required.'}</span>
                                            <br />
                                            <Button
                                                classNames="mt-2"
                                                onClick={() => {
                                                    canvasRef.current.clear();
                                                    setPatientSignature('');
                                                }}
                                            >
                                                Clear
                                            </Button>
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                        </Row>

                        <div className="d-flex my-4    ">
                            <button type="submit" disabled={btnSubmit} className="btn px-4 btn-outline-primary">
                                {btnSubmit ? 'Loading....' : 'Save'}
                            </button>
                            {/* <CustomButtons type="submit " buttonDisabled={btnSubmit} variant="primary" className="outline- px-4" name="Save" /> */}
                        </div>
                    </Form>
                </Card>
            </Container>
        </div>
    );
};

export default VascularSurgery;
