import React, { useEffect, useRef } from 'react';
import ConsentFormsNavbar from '../../../../components/common/ConsentFormsNavbar';
import ProfileBar from '../ProfileBar';
import { Button, Modal, Container, Card, Form, Row, Col, Table } from 'react-bootstrap';

import { useNavigate, useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import classNames from 'classnames';

import moment from 'moment';
import { BsArrowLeft } from 'react-icons/bs';
import LdcApi from '../../../../LdcApis';
import termsandcondition from './../../../../../src/assets/PDF/Terms_&_Conditions_(LDC).pdf';

const EditVascularSurgerySchedule = () => {
  let patientDetailsData = JSON.parse(localStorage.getItem('patientDetailsData'));
  let userInfo = JSON.parse(localStorage.getItem('user_info'));

  const { id, viewId } = useParams();
  const [patientSignature, setPatientSignature] = useState('');
  const [patientAuthorized, setPatientAuthorized] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Vascular Veins Surgery Form - LDC';
  }, []);

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    LdcApi({
      method: 'GET',
      url: `VascularVeins_SurgeryForm/GetVascularVeins_SurgeryForm?Id=${viewId}`
    })
      .then((response) => {
        console.log(response?.data);
        setPatientAuthorized(response?.data.patientAgreement);
        setLoading(false);
        setRowData(response?.data);
      })
      .catch((error) => {
        setLoading(false);
        // navigate('/diary');
      });
  }, []);
  console.log(rowData)

  return (
    <div>
      <ProfileBar />
      <Container
      // style={{ pointerEvents: { mouseDisable } }}
      >
        {/* go to diary  */}
        {/* <div className="d-flex my-4 justify-content-end">
          <Button onClick={() => hideForms()} variant="outline-primary mb-3">
            Go to diary
          </Button>
        </div> */}
        <Card className="p-3">
          <p className="text-center form_heading  mb-5">Vascular Veins Surgery Form</p>
          <Form>
            <Row className="px-4 py-2">
              <Col className='d-flex justify-content-start align-item-center'>
                <Form.Group className="m-0 p-0">
                  <b className="mr-2  ">Today's Date: </b>
                  <input
                    className={`from_control  disabled  `}
                    type="text"
                    disabled
                    // isInvalid={touched && !!error}
                    // isValid={touched && !error}
                    name="todayDate"
                    // onChange={(e) => handelOnaChnage(e)}
                    value={moment(rowData.todaysDate).format('DD/MM/YYYY')}
                  />
                </Form.Group>
              </Col>
              <Col className='d-flex justify-content-start align-item-center'>
                <Form.Group className="m-0 p-0">
                  <b className="mr-2 ">Date of Consultation: </b>
                  <input
                    className={`from_control disabled `}
                    type="text"
                    disabled
                    // isInvalid={touched && !!error}
                    // isValid={touched && !error}
                    name="appointmentDate"
                    // onChange={(e) => handelOnaChnage(e)}
                    value={moment(rowData.consultationDate).format('DD/MM/YYYY')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="px-4 py-2">
              <Col className='d-flex justify-content-start align-item-center'>
                <Form.Group className="m-0 p-0">
                  <b className="mr-2  ">Patient Name: </b>
                  <input
                    className={`from_control  disabled  `}
                    type="text"
                    disabled
                    // isInvalid={touched && !!error}
                    // isValid={touched && !error}
                    name="patientName"
                    // onChange={(e) => handelOnaChnage(e)}
                    value={rowData.patientName}
                  />
                </Form.Group>
              </Col>
              <Col className='d-flex justify-content-start align-item-center'>
                <Form.Group className="m-0 p-0">
                  <b className="mr-2 ">Patient ID Number (PID): </b>
                  <input
                    className={`from_control disabled `}
                    type="text"
                    disabled
                    // isInvalid={touched && !!error}
                    // isValid={touched && !error}
                    name="pId"
                    // onChange={(e) => handelOnaChnage(e)}
                    value={rowData.patientId}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="px-4 py-2">
              <Col className='d-flex justify-content-start align-item-center'>
                <Form.Group className="m-0 p-0">
                  <b className="mr-2  ">Consultant Name: </b>
                  <input
                    className={`from_control  disabled  `}
                    type="text"
                    disabled
                    // isInvalid={touched && !!error}
                    // isValid={touched && !error}
                    name="doctorName"
                    // onChange={(e) => handelOnaChnage(e)}
                    value={rowData.consultantName}
                  />
                </Form.Group>
              </Col>
              <Col className='d-flex justify-content-start align-item-center'>
                <Form.Group className="m-0 p-0">
                  <b className="mr-2 ">Coordinator Name: </b>
                  <input
                    className={`from_control disabled `}
                    type="text"
                    disabled
                    // isInvalid={touched && !!error}
                    // isValid={touched && !error}
                    name="cordinatorName"
                    // onChange={(e) => handelChnage(e)}
                    value={rowData.coordinatorName}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="px-4 py-2">
              <Col>
                <div>
                  <b>Procedure Details:</b>
                  <ul>
                    <li>
                      <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                        <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                          <b>Procedure Title:</b>
                          <input
                            className={`from_control  disabled  `}
                            type="text"
                            disabled
                            // isInvalid={touched && !!error}
                            // isValid={touched && !error}
                            name="procedureTitle"
                            // onChange={(e) => handelChange(e)}
                            value={rowData.procedureTitle}
                          />
                        </div>
                        {/* <div>
                          <span className="text-danger">{validationError?.procedureTitle === true && 'Field is required.'}</span>
                        </div> */}
                      </Form.Group>
                    </li>
                    <li>
                      <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                        <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                          <b>Procedure Cost:</b>
                          <input
                            className={`from_control  disabled  `}
                            type="text"
                            disabled
                            // isInvalid={touched && !!error}
                            // isValid={touched && !error}
                            name="procedureCost"
                            // onChange={(e) => handelChange(e)}
                            value={rowData.procedureCost}
                          />
                        </div>
                        {/* <div>
                          <span className="text-danger">{validationError?.procedureCost === true && 'Field is required.'}</span>
                        </div> */}
                      </Form.Group>
                    </li>
                    <li>
                      <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                        <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                          <b>Anaesthesia Type:</b>
                          <input
                            className={`from_control  disabled  `}
                            type="text"
                            disabled
                            // isInvalid={touched && !!error}
                            // isValid={touched && !error}
                            name="anaesthesiaCost"
                            // onChange={(e) => handelChange(e)}
                            value={rowData.anaesthesiaType}
                          />
                        </div>
                        {/* <div>
                          <span className="text-danger">{validationError?.anaesthesiaCost === true && 'Field is required.'}</span>
                        </div> */}
                      </Form.Group>
                    </li>
                    <li>
                      <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                        <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                          <b>Procedure Date:</b>
                          <input
                            className={`from_control  disabled  `}
                            type="text"
                            disabled
                            // isInvalid={touched && !!error}
                            // isValid={touched && !error}
                            name="procedureDate"
                            // onChange={(e) => handelChange(e)}
                            value={moment(rowData.procedureDate).format("DD/MM/YYYY")}
                          />
                        </div>
                        {/* <div>
                          <span className="text-danger">{validationError?.procedureDate === true && 'Field is required.'}</span>
                        </div> */}
                      </Form.Group>
                    </li>
                    <li>
                      <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                        <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                          <b>Arrival Time:</b>
                          <input
                            className={`from_control  disabled  `}
                            type="time"
                            disabled
                            // isInvalid={touched && !!error}
                            // isValid={touched && !error}
                            name="arrivalTime"
                            // onChange={(e) => handelChange(e)}
                            value={rowData.arrivalTime}
                          />
                        </div>
                        {/* <div>
                          <span className="text-danger">{validationError?.arrivalTime === true && 'Field is required.'}</span>
                        </div> */}
                      </Form.Group>

                    </li>
                    <li>
                      <Form.Group className="m-0 p-0" style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                        <div style={{ gap: "20px", display: "flex", alignItems: 'center' }}>
                          <b>Anticipated Treatment Duration:</b>
                          <input
                            className={`from_control  disabled  `}
                            type="text"
                            disabled
                            // isInvalid={touched && !!error}
                            // isValid={touched && !error}
                            name="anticipatedTreatmentDur"
                            // onChange={(e) => handelChange(e)}
                            value={rowData.anticipatedTreatmentDuration}
                          />

                        </div>
                        {/* <div>
                          <span className="text-danger">{validationError?.anticipatedTreatmentDur === true && 'Field is required.'}</span>
                        </div> */}
                      </Form.Group>
                    </li>
                    <li><b>Location:</b> London Dermatology Clinic, 20 Eastcheap, London, EC3M 1EB</li>

                  </ul>
                </div>
              </Col>

            </Row>
            <Row className="px-4 py-2">
              <Col>
                <div>
                  <b>Financials:</b>
                  <ol>
                    <li><b>Consultation - Initial Consultation:</b> £95.00 (this includes a free ultrasound scan).</li>
                    <li><b>Reservation fee - (securing a theatre slot):</b> For Laser EVLA patients a £500.00 deposit is required.
                      For Sclerotherapy-only and Phlebectomy-only patients a deposit of 50% of the treatment fee is required.</li>
                    <li><b>Residual balances:</b> These are paid in the clinic on the procedure day, before treatment commences.</li>
                  </ol>
                </div>
              </Col>
            </Row>
            <Row className="px-4 py-2">
              <Col>
                <div>
                  <b>Rescheduling and Cancellation Policy:</b>
                  <p>
                    The clinic will ensure that laser fibres, consumables, staffing and support stockings are available to all patients.
                    This of course incurs costs for the clinic. Should a patient cancel or wish to reschedule before 14 days
                    of the procedure no charges are applied. Patients wanting to cancel or reschedule their procedures within
                    14 days of their planned procedure will incur a fee of the value of 50% of their deposit. Any cancellation
                    or rescheduling within 7 days of their planned procedure will incur a fee of the value of 100% of their
                    deposit.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="px-4 py-2">
              <Col>
                <div>
                  <b>Patient Agreement:</b>
                </div>
              </Col>
            </Row>
            <Row className="px-4 py-2">
              <Col>
                <div>
                  <b>
                    {' '}
                    I have read and understood the terms and conditions <a style={{ textDecoration: "underline", color: "blue" }} href={termsandcondition} target="_blank">(read here)</a>.{' '}
                    <span className="text-danger">*</span>
                  </b>
                  <Row className="mt-2   p-0 " style={{ maxWidth: '1200px', justifyContent: 'center', margin: 'auto' }}>
                    <Col sm={2} className="p-0 mx-4">
                      <div
                        className={` yes_no_btn ${patientAuthorized !== null ? (patientAuthorized ? 'yes_Btn' : 'back') : null}`}
                      >
                        YES
                      </div>
                    </Col>
                    <Col sm={2} className="p-0 mx-4">
                      <div
                        className={`   yes_no_btn ${patientAuthorized !== null ? (patientAuthorized ? 'back' : 'no_Btn') : null} `}
                      // onClick={() => patientAuthorizedChanges(false)}
                      >
                        NO
                      </div>
                    </Col>
                  </Row>
                  {/* <span className="text-danger">{validationError.patientAuthorized === true && 'Field is required.'}</span> */}
                </div>
              </Col>
            </Row>
            <Row className="px-4 py-2">
              <Col>
                <div className="mt-3">
                  <Form.Group className="m-0 p-0 d-flex align-items-center">
                    <b className="mr-2  ">Date: </b>
                    <input
                      className={`from_control disabled `}
                      disabled
                      type="text"
                      name="SecondDate"
                      // placeholder="name"
                      value={moment(rowData.createdOn).format('DD/MM/YYYY')}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <Row className="px-4 py-2">
              <Col>
                <div className="mt-4">
                  <p>
                    * I the patient or the authorised representative e.g. parent, sign below to give my formal informed consent to the
                    proposed treatment/procedure. <span className="text-danger">*</span>
                  </p>
                  <Col sm={12} className="mt-2">
                    <div style={{ overflow: 'hidden' }}>
                      <img src={rowData?.patientSignature} className="signature" />
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>

          </Form>
        </Card>
        {userInfo.roleID == 10 ? (
          <Button
            className="px-3"
            onClick={() => {
              localStorage.setItem('patientDetailsData', JSON.stringify({ ...patientDetailsData, tab: 0 }));

              navigate(`/profile/${id}`);
            }}
          >
            <BsArrowLeft /> Back
          </Button>
        ) : (
          <Button
            className="px-3"
            onClick={() => {
              localStorage.setItem('patientDetailsData', JSON.stringify({ ...patientDetailsData, tab: 7 }));

              navigate(`/profile/${id}`);
            }}
          >
            <BsArrowLeft /> Back
          </Button>
        )}
      </Container>
    </div>
  );
};

export default EditVascularSurgerySchedule;
