import { Snackbar } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './style.scss';
function MuiSnackBar(props) {
  const [ldcSnackbar, setLdcSnackbar] = useState({ open: false, type: '' });
  useEffect(() => {
    let timer = setTimeout(() => {
      setLdcSnackbar(props.ldcSnackBar);
    }, 0);
    return () => {
      clearTimeout(timer);
    };
  });
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={1500}
        open={ldcSnackbar.open}
        onClose={props.onClose}
        message={ldcSnackbar.mesage}
        className={ldcSnackbar.type}
      />
    </div>
  );
}

export default MuiSnackBar;
