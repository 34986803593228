import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AutoComplete, Input } from 'antd';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Patients from '../../../../../modules/Patients';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from 'axios';
const { Option } = AutoComplete;

const NavSearch = (props) => {
  const navigate = useNavigate();
  const [getAllPatients, setGetAllPatients] = useState([]);
  const [value, setValue] = useState(null);
  const { windowWidth } = props;
  const [isOpen, setIsOpen] = useState(windowWidth < 600);
  const [searchString, setSearchString] = useState(windowWidth < 600 ? '100px' : '');
  const [result, setResult] = useState([]);
  const [res, setRes] = useState([]);

  const [valueID, setValueID] = useState(null);
  const [resultID, setResultID] = useState([]);
  const [resID, setResID] = useState([]);
  const [valuePhnEmail,setValuePhnEmail]=useState("")
  const [resultPhnEmail,setResultPhnEmail]=useState([]);
  const cancelTokenSource = useRef()

  const Options = result.map((el) => {
    return {
      key: el.id,
      label: `${el.fullName} (${el.mobile}) :: ${el.id}`,
      value: `${el.id}`
    };
  });

  const OptionsID = resultID.map((el) => {
    return {
      key: el.id,
      label: `${el.fullName} (${el.mobile}) :: ${el.id}`,
      value: `${el.id}`
    };
  });

  const OptionsEmailOrPhn = resultPhnEmail.map((el)=>{
    return {
      key: el.id,
      label: `${el.email} (${el.mobile}) :: ${el.id}`,
      value: `${el.id}`
    };
  })

  const searchOnHandler = () => {
    if (windowWidth < 600) {
      document.querySelector('#navbar-right').classList.add('d-none');
    }
    setIsOpen(true);
    setSearchString('250px');
  };

  const searchOffHandler = () => {
    setIsOpen(false);
    setSearchString(0);
    setTimeout(() => {
      if (windowWidth < 600) {
        document.querySelector('#navbar-right').classList.remove('d-none');
      }
    }, 500);
  };

  let searchClass = ['main-search'];
  if (isOpen) {
    searchClass = [...searchClass, 'open'];
  }

  const inputhandle = () => {
    document.getElementById('search').value = '';
  };

  // const allPatients = () => {
  //   Patients.getAllPatients((response) => {
  //     if (response?.status === 'success') {
  //       setGetAllPatients(response?.data);
  //     }
  //   });
  // };

  useEffect(() => {
    // allPatients();
  }, []);

  const handleSearch = (value) => {
    value=value.replace(/[^a-zA-Z\s]/g, '');
    setValue(value);
    setRes([]);

    if (value.length === '') {
      setResult([]);
    }

    // allPatients();
    if (value !== "" && value.length >= 3) {
      Patients.getExistPatients(value, (response) => {
        if (response?.status === 'success') {
          if (response?.data.length > 0) {
            // setGetAllPatients(response?.data);
            setResult(response?.data);
          } else {
            setResult([]);
          }
        }
      });
    } else {
      setResult([]);
    }

    // if (!value || value.indexOf('@') >= 2) {
    //   // allPatients();
    //   setRes([]);
    // } else {
    //   setRes(getAllPatients);
    // }
  };

  const onSelect = (data) => {
    onSubmit(data);
    handleSearch('');
  };

  const onSubmit = (val) => {
    const selItem = result.find((item) => item?.id.toString() === val.toString());
    let PatientDataProfile = { ...selItem, tab: 0, patientId: selItem.id };

    localStorage.setItem('patientDetailsData', JSON.stringify(PatientDataProfile));
    navigate(`/profile/${selItem.id}`, { state: '' });
  };

  // Ujjwal

  const handleSearchID = (valueID) => {
    if(!isNaN(valueID) && valueID.length<=6){
      setValueID(valueID);
      setResID([]);
      // value.length === ''
      if (valueID.length === '') {
        setResultID([]);
      }
      // allPatients();
      if (valueID !== "" && valueID.length >= 3) {
        if (cancelTokenSource.current) {
          cancelTokenSource.current.cancel('Operation canceled due to new request.');
        }
        // Create a new CancelToken
        cancelTokenSource.current = axios.CancelToken.source();
        Patients.getExistPatientsID(valueID, cancelTokenSource.current.token, (response) => {
          if (response?.status === 'success') {
            if (response?.data.length > 0) {
              // setGetAllPatients(response?.data);
              setResultID(response?.data);
            } else {
              setResultID([]);
            }
          }
        });
      } else {
        setResultID([]);
      }
    }
  };

  const handleSearchByEmailOrPhone=(value)=>{
    value=value.trim();
    setValuePhnEmail(value);
    // setResultPhnEmail([]);
    if(value!=="" && value.length>=3){
      // alert("hello")
      Patients.getExistPatientsByEmailOrPhn(value,(response)=>{
        if (response?.status === 'success') {
          if (response?.data.length > 0) {
            setResultPhnEmail(response.data)
          }else{
            setResultPhnEmail([])
          }
        }
      })
    }else{
      setResultPhnEmail([]);
    }
  }

  const onSelectID = (data) => {
    onSubmitID(data);
    handleSearchID('');
  };
  const onSelectEmailOrPhn=(data)=>{
    onSubmitPhnOrEmail(data);
    handleSearchByEmailOrPhone('');
  }

  const onSubmitPhnOrEmail= (val)=>{
    const selectItem = resultPhnEmail.find((item)=>item?.id.toString() === val.toString());
    let PatientDataProfile = { ...selectItem, tab: 0, patientId: selectItem.id };
    localStorage.setItem('patientDetailsData', JSON.stringify(PatientDataProfile));
    navigate(`/profile/${selectItem.id}`, { state: '' })
  }
  const onSubmitID = (val) => {
    const selItemID = resultID.find((item) => item?.id.toString() === val.toString());
    // let PatientDataProfile = { ...resultID, tab: 0, patientId: selItemID.id };
    let PatientDataProfile = { ...selItemID, tab: 0, patientId: selItemID.id };
    localStorage.setItem('patientDetailsData', JSON.stringify(PatientDataProfile));
    // setResultID('')
    navigate(`/profile/${selItemID.id}`, { state: '' });
  };

  let pathname = window.location.pathname;

  return (
    <React.Fragment>
      <div id="main-search" className="main-search open d-flex ">
        <Row style={{ rowGap: "2px",flexWrap:"wrap",alignItems:"center",paddingLeft:"15px" }}>
          <Col lg={4} style={{padding:"0px"}}>
            {pathname !== '/question' ? (
              <AutoComplete
                position="center"
                style={{ width: '250px' }}
                id="searchBar"
                // onMouseDown={allPatients}
                value={value}
                onSearch={handleSearch}
                onSelect={onSelect}
                placeholder="Search Patient by Name"
                options={Options}
              // filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              />
            ) : null}
          </Col >
          <Col lg={4} style={{padding:"0px"}}>
            {pathname !== '/question' ? (
              <AutoComplete
                position="center"
                style={{ width: '250px' }}
                id="searchBar"
                value={valueID}
                onSearch={handleSearchID}
                onSelect={onSelectID}
                options={OptionsID}
                placeholder="Search Patient by ID"
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: '350px', maxWidth: '350px' }}
              // onMouseDown={allPatients}
              // filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              />
            ) : null}
          </Col>
          <Col lg={4} style={{padding:"0px"}}>
            <AutoComplete
              position="center"
              style={{ width: '300px' }}
              id="searchBar"
              value={valuePhnEmail}
              onSearch={handleSearchByEmailOrPhone}
              onSelect={onSelectEmailOrPhn}
              options={OptionsEmailOrPhn}
              placeholder="Search Patient by email or phone number"
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: '380px', maxWidth: '380px' }}
            // onMouseDown={allPatients}
            // filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            />
          </Col>
        </Row>
        {/* Ujjwal */}
        &nbsp;
        {/* {result.map((item, index) => (
              <Option key={index} value={item.fullName}>
                {item.fullName} ({item.mobile}) :: {item.id}
              </Option>
            ))}
          </AutoComplete> */}
        {/* <div className="input-group">
          <div>
            <input
              type="text"
              id="myinput"
              className="form-control"
              placeholder="Search Patient . . ."
              position="center"
              style={{ width: '300px' }}
              Options={options}
            />
          </div>
          <Link to="#" className="input-group-append search-close" onClick={searchOffHandler}>
            <i className="feather icon-x input-group-text" onClick={() => (document.getElementById('myinput').value = '')} />
          </Link>
          <span className="input-group-append search-btn btn btn-primary" onClick={searchOnHandler}>
            <i className="feather icon-search input-group-text" />
          </span>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default NavSearch;
