// CiclosporinSchedule
import React, { useEffect, useRef } from 'react';
import ProfileBar from '../ProfileBar';
import { Button, Modal, Container, Card, Form, Row, Col, Table } from 'react-bootstrap';

import { useNavigate, useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import classNames from 'classnames';
import moment from 'moment';
import LdcApi from '../../../../LdcApis';
import { BsArrowLeft } from 'react-icons/bs';

const EditCiclosporinSchedule = () => {
  let patientDetailsData = JSON.parse(localStorage.getItem('patientDetailsData'));
  let userInfo = JSON.parse(localStorage.getItem('user_info'));
  const { id, viewId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Ciclosporin Schedule Consent Consent Form - LDC';
  }, []);

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true);
    LdcApi({
      method: 'GET',
      url: `Ciclosporin/GetViewCiclosporin?Id=${viewId}`
    })
      .then((response) => {
        if (response.data.length > 0) {
          setLoading(false);
          setRowData(response?.data[0]);
        } else {
          // navigate('/diary');
        }
      })
      .catch((error) => {
        setLoading(false);
        // navigate('/diary');
      });
  }, []);

  return (
    <div>
      <ProfileBar />
      <Container
      // style={{ pointerEvents: { mouseDisable } }}
      >
        {/* go to diary  */}
        <div className="d-flex my-4 justify-content-end">
          <Button variant="outline-primary mb-3">Go to diary</Button>
        </div>
        <Card className="p-3">
          <p className="text-center form_heading  mb-5">Ciclosporin Schedule Consent Form</p>
          <div>
            <p>
              I understand that oral Ciclosporin is a medication that needs careful monitoring for safety purposes. I have had the risks,
              benefits and potential side effects linked to oral Ciclosporin explained to me, before I consented to treatment. I have also
              been given the opportunity to ask any questions prior to starting treatment. I agree to adhere to any schedule of care my
              clinic doctors advise is in my best interest. I have had the typical schedule of care below given to me. I will contact the
              clinic if I have a problem. Clinic doctors reserve the right to stop prescribing oral Ciclosporin for me at any stage in the
              course, if I am unable to adhere to any recommendations to my schedule of care.
            </p>
            <Form>
              <Row className="d-flex justify-content-center">
                <Col sm={12} className=" ">
                  <div className="mt-3">
                    <Form.Group className="m-0 p-0">
                      <b className="mr-2  ">Patient name: </b>
                      <input
                        className={`from_control     `}
                        type="text"
                        disabled
                        value={rowData.patientName}
                        // isInvalid={touched && !!error}
                        // isValid={touched && !error}
                        name="patientName"
                      // onChange={(e) => handelOnchnage(e)}
                      //  value={formData.appointmentDate}
                      />

                      {/* <span className="text-danger">{validationError.patientName === true && 'Field is required.'}</span> */}
                    </Form.Group>
                    <Form.Group className="m-0 p-0">
                      <b className="mr-2 ">Date: </b>
                      <input
                        className={`from_control     `}
                        value={moment(rowData.appointmentDate).format('DD/MM/YY')}
                        disabled
                        type="text"
                        name="patientDate"
                      // onChange={(e) => handelOnchnage(e)}
                      //  value={formData.appointmentTime}
                      />
                      {/* <span className="text-danger">{validationError.patientDate === true && 'Field is required.'}</span> */}
                    </Form.Group>
                  </div>
                  <div className="mt-4">
                    <b>
                      Signature<span className="text-danger">*</span>
                    </b>
                    <Col sm={12} className="mt-2">
                      <div className="view_sigPadContainer p-2 my-3 ">
                        <img src={rowData?.patientSignature} className="signature" />
                      </div>
                    </Col>
                  </div>
                  <Form.Group className="m-0 p-0">
                    <b className="mr-2 ">Doctor initiating: </b>
                    <input
                      className={`from_control     `}
                      type="text"
                      disabled
                      name="doctorInitiating"
                      value={rowData.doctorName}
                    //   onChange={(e) => handelOnchnage(e)}
                    //  value={formData.appointmentTime}
                    />
                    <br />
                    {/* <span className="text-danger">{validationError.doctorInitiating === true && 'Field is required.'}</span> */}
                  </Form.Group>
                  <div className="mt-5">
                    <b>Typical schedule care (can vary): </b>

                    <div className="mt-5">
                      <b className="" style={{ textDecoration: 'underline' }}>
                        Before starting treatment:
                      </b>
                      <ol>
                        <li>
                          Initial baseline blood test; FBC, U&Es, LFTs, fasting lipids, Hepatitis B + C serology
                          (*HIV test and Varicella Zoster Virus serology if indicated - separate charge applicable).
                        </li>
                        <li>
                          {' '}
                          Blood pressure.
                        </li>
                      </ol>
                    </div>
                    <div className="mt-5">
                      <b className="" style={{ textDecoration: 'underline' }}>
                        After starting treatment:
                      </b>
                      <ol className="">
                        <li>
                          Blood tests for FBC, U&Es, and blood pressure weekly for the first four weeks, then fortnightly for 2 months and monthly thereafter if stable.
                        </li>
                        <li>
                          Blood test - LFTs (liver function test) monthly.
                        </li>
                        <li>
                          Blood test - fasting lipids at 1 month and then yearly if remains on treatment.
                        </li>
                      </ol>
                    </div>
                    <p className="mt-2">
                      Initial bloods cost £250*. Each subsequent monitoring blood test costs £90*. The above schedule frequency may change if abnormal results are found or after a treatment dose change.
                    </p>

                    <b className="mt-3" style={{ textDecoration: 'underline' }}>
                      Follow-up Consultations
                    </b>
                    <p className="mt-2">
                      A review will be required by booking a follow-up consultation every 6 to 8 weeks and once the dose of the medication
                      and your skin condition is stable then it will be reduced to once every 4 months.
                    </p>
                    <p className="mt-2">Each follow-up consultation costs £175*.</p>
                  </div>

                  <b className="mt-5" style={{ textDecoration: 'underline' }}>
                    Side Effects
                  </b>
                  <p>The following is a list of some common side effects which can be expected.</p>
                  <ul>
                    <li> Increased susceptibility to infection</li>
                    <li> High blood pressure</li>
                    <li> Increased hair growth</li>
                    <li> Swollen or inflamed gums</li>
                    <li> Numbness or tingling of the hands or feet</li>
                    <li> Change in blood electrolytes (potassium and magnesium levels)</li>
                    <li>
                      Other common side effects are tremors, restlessness, stomach upset, nausea, cramps, diarrhea, headache, and changes in
                      blood sugar.
                    </li>
                  </ul>

                  <b className="mt-5" style={{ textDecoration: 'underline' }}>
                    N.B.
                  </b>
                  <ul>
                    <li> Blood tests are needed as oral Ciclosporin can adversely affect your kidney and liver.</li>
                    <li>
                      Consultations are an opportunity for your doctors to review your care and monitor your treatment safely. Reviews tend
                      to include your skin, any side effects you may be experiencing, answer questions you have and dosage changes.
                    </li>
                    <li> Consultations can be conducted online over a video call or a face to face clinic attendance. </li>
                    <li>
                      If you decide to have your blood tests done externally please be aware you will have to be responsible in organising
                      and getting the correct test results to us in a timely manner to avoid delays or disruptions.
                    </li>
                  </ul>
                  <span>*Price correct at the time of publication but subject to change without notice.</span>
                  {/* end  😊 */}
                </Col>
              </Row>
            </Form>
          </div>
        </Card>
        {userInfo.roleID == 10 ? (
          <Button
            className="px-3"
            onClick={() => {
              localStorage.setItem('patientDetailsData', JSON.stringify({ ...patientDetailsData, tab: 0 }));

              navigate(`/profile/${id}`);
            }}
          >
            <BsArrowLeft /> Back
          </Button>
        ) : (
          <Button
            className="px-3"
            onClick={() => {
              localStorage.setItem('patientDetailsData', JSON.stringify({ ...patientDetailsData, tab: 7 }));

              navigate(`/profile/${id}`);
            }}
          >
            <BsArrowLeft /> Back
          </Button>
        )}
      </Container>
    </div>
  );
};

export default EditCiclosporinSchedule;
