import LdcApi from '../../LdcApis';

class Service {
  getAllService = (callback) => {
    LdcApi({
      method: 'GET',
      url: 'ServiceMaster/GetServiceMaster'
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  getBlockAllService = (callback) => {
    LdcApi({
      method: 'GET',
      url: 'ServiceMaster/GetBlockOuts'
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  addService = (serviceData, callback) => {
    LdcApi({
      method: 'POST',
      url: 'ServiceMaster/AddServiceMaster',
      data: serviceData
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  validateUserDialog = (serviceErrData) => {
    if (serviceErrData.serviceName) {
      return false;
    } else if (serviceErrData.category) {
      return false;
    } else if (serviceErrData.code) {
      return false;
    } else if (serviceErrData.price) {
      return false;
    } else if (serviceErrData.duration) {
      return false;
    } else if (serviceErrData.active) {
      return false;
    } else return true;
  };
}

export default new Service();
