import React, { useContext, useEffect, useState } from 'react';
import { ListGroup, Dropdown, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ChatList from './ChatList';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import useAuth from '../../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../../../assets/images/user/avatar-3.jpg';
import avatar4 from '../../../../assets/images/user/avatar-4.jpg';
import LdcApi from '../../../../LdcApis';
import CustomDropdown from '../../../../views/ui-elements/CustomDropdown/CustomDropdown';

const NavRight = () => {
  const navigate = useNavigate();
  const configContext = useContext(ConfigContext);
  const { logout } = useAuth();
  const { rtlLayout } = configContext.state;
  const token = localStorage.getItem('_code');

  const { dispatch } = configContext;
  const [listOpen, setListOpen] = useState(false);
  const [userLogin, setUserLogin] = useState({});
  useEffect(() => {
    let userObj = JSON.parse(localStorage.getItem('user_info'));
    if (userObj) {
      setUserLogin(userObj);
    }
  }, []);

  const handleLogout = async () => {
    localStorage.clear();
    // const users = JSON.parse(localStorage.getItem('user'));
    await LdcApi({
      method: 'GET',
      url: `Account/SignOut?code=${token.replace(/"([^"]+(?="))"/g, '$1')}`,
    })
      .then((res) => {
        localStorage.removeItem('isLogin');
        localStorage.removeItem('_code');
        localStorage.removeItem('user');
        localStorage.clear();
      })
      .catch((error) => {});
    navigate('/');
  };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CustomDropdown />
        <ListGroup
          as='ul'
          bsPrefix=' '
          className='navbar-nav ml-aut'
          id='navbar-right'
        >
          {/* <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout}>
            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              <i className="feather icon-bell icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="notification notification-scroll">
              <div className="noti-head">
                <h6 className="d-inline-block m-b-0">Notifications</h6>
                <div className="float-right">
                  <Link to="#" className="m-r-10">
                    mark as read
                  </Link>
                  <Link to="#">clear all</Link>
                </div>
              </div>
              <PerfectScrollbar>
                <ListGroup as="ul" bsPrefix=" " variant="flush" className="noti-body">
                  <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                    <p className="m-b-0">NEW</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Media>
                      <img className="img-radius" src={avatar1} alt="Generic placeholder" />
                      <Media.Body>
                        <p>
                          <strong>John Doe</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span>
                        </p>
                        <p>New ticket Added</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                    <p className="m-b-0">EARLIER</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Media>
                      <img className="img-radius" src={avatar2} alt="Generic placeholder" />
                      <Media.Body>
                        <p>
                          <strong>Joseph William</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span>
                        </p>
                        <p>Purchase New Theme and make payment</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Media>
                      <img className="img-radius" src={avatar3} alt="Generic placeholder" />
                      <Media.Body>
                        <p>
                          <strong>Sara Soudein</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span>
                        </p>
                        <p>currently login</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Media>
                      <img className="img-radius" src={avatar4} alt="Generic placeholder" />
                      <Media.Body>
                        <p>
                          <strong>Suzen</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            yesterday
                          </span>
                        </p>
                        <p>Purchase New Theme and make payment</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item>
                </ListGroup>
              </PerfectScrollbar>
              <div className="noti-footer">
                <Link to="#">show all</Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item> */}
          {/* <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown>
            <Dropdown.Toggle as={Link} variant="link" to="#" className="displayChatbox" onClick={() => setListOpen(true)}>
              <i className="icon feather icon-mail" />
            </Dropdown.Toggle>
          </Dropdown>
        </ListGroup.Item> */}
          <ListGroup.Item as='li' bsPrefix=' '>
            <Dropdown alignRight={!rtlLayout} className='drp-user'>
              <Dropdown.Toggle
                as={Link}
                variant='link'
                to='#'
                id='dropdown-basic'
              >
                <i
                  style={{ color: '#04a9f5' }}
                  className='icon feather icon-user mr-1 '
                />
                <strong>{userLogin?.firstName}</strong>
              </Dropdown.Toggle>
              {/* <div className="pro-head">

                <span onClick={() => navigate('/')}>SignOut</span>
                <Link to="/" className="dud-logout" title="Logout">
                  <i className="feather icon-log-out" />
                </Link>


                <span onClick={() => navigate('/')}>SignOut</span>
                <Link to="/" className="dud-lock" title="Logout">
                  <i className="feather icon-lock" />
                </Link>
              </div> */}
              <Dropdown.Menu alignRight className='profile-notification'>
                <ListGroup
                  as='ul'
                  bsPrefix=' '
                  variant='flush'
                  className='pro-body'
                >
                  {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-settings" /> Settings
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-user" /> Profile
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-mail" /> My Messages
                  </Link>
                </ListGroup.Item> */}
                  <ListGroup.Item
                    as='li'
                    bsPrefix=' '
                    onClick={() =>
                      dispatch({
                        type: 'PASSWORD_CHANGE',
                        payload: true,
                      })
                    }
                  >
                    <div
                      style={{ cursor: 'pointer' }}
                      className='dropdown-item'
                    >
                      <i className='feather icon-lock' /> Change Password/PIN
                    </div>
                  </ListGroup.Item>

                  <Dropdown.Divider />
                  <ListGroup.Item as='li' bsPrefix=' '>
                    <div
                      onClick={handleLogout}
                      style={{ cursor: 'pointer' }}
                      // onClick={() => {

                      // }}
                      className='dropdown-item'
                    >
                      <i className='feather icon-log-out' /> Sign Out
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>
          </ListGroup.Item>
        </ListGroup>
        <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
      </div>
    </React.Fragment>
  );
};

export default NavRight;
