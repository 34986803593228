import React, { useEffect } from 'react';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Button } from 'react-bootstrap';
import { SnackbarContent } from '@mui/material';

function SnackbarRefresh({ snackbar, massage, color, onSnackBarClose }) {
  const action = (
    <React.Fragment>
      <Button
        style={{ border: '1px solid white', color: 'white', padding: '5px' }}
        size="small"
        onClick={() => window.location.reload(false)}
      >
        Refresh Now
      </Button>
      <IconButton size="small" aria-label="close" color="success" onClick={onSnackBarClose}>
        <CloseIcon style={{ color: 'white' }} fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      sx={{
        '& .SnackbarItem-variantSuccess': {
          backgroundColor: 'success'
        },
        '& .SnackbarItem-variantError': {
          backgroundColor: 'success'
        },
        '& .SnackbarItem-variantWarning': {
          backgroundColor: 'success'
        },
        '& .SnackbarItem-variantInfo': {
          backgroundColor: 'success'
        }
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={snackbar}
      onClose={onSnackBarClose}
    >
      <SnackbarContent
        style={{
          backgroundColor: '#3f4d67'
        }}
        action={action}
        message={<span id="client-snackbar">{massage}</span>}
      />
    </Snackbar>
  );
}

export default SnackbarRefresh;
