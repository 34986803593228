import React, { useEffect } from 'react';
import ConsentFormsNavbar from '../../components/common/ConsentFormsNavbar';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router';
import LdcApi from '../../LdcApis';
import { useState } from 'react';

const DoctorVideoConsultation = () => {
  const { id } = useParams();

  const [embedId, setEmbedId] = useState('');
  const [errMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = 'Video Consultation - LDC';
  }, []);
  useEffect(() => {
    setLoading(true);
    LdcApi({
      method: 'GET',
      url: `video/ValidateRoom?roomId=${id}`
    })
      .then((res) => {
        if (res.data.status.isSuccess == true) {
          setEmbedId(res.data.data.embed);
          setLoading(false);
        } else {
          setErrorMsg(res.data.status.errorMessage);
          setEmbedId(null);
          setLoading(false);
        }
      })
      .catch((err) => {
        setErrorMsg('Something is Wrong');
        setEmbedId(null);
        setLoading(false);
      });
  }, []);
  return (
    <div>
      {/* <ConsentFormsNavbar /> */}
      <Container className=" ">
        <div className="mt-1  d-flex justify-content-center">
          {loading === true ? (
            <>Loading...</>
          ) : (
            <>
              {' '}
              {embedId == null ? (
                <>
                  <p style={{ fontSize: '1.4rem' }} className="text-center">
                    {errMsg}
                  </p>
                </>
              ) : (
                <iframe
                  className="shadow"
                  // style={{ border: '1px solid #000' }}
                  src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${embedId}&room=${id}&iframe=true`}
                  // src="https://tokbox.com/embed/embed/ot-embed.js?embedId=f28cb8a1-00ad-4b6f-bc05-414a0db23d03&room=948iyut&iframe=true"
                  width="90%"
                  height="500"
                  scrolling="auto"
                  allow="microphone; camera"
                ></iframe>
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default DoctorVideoConsultation;
