import React, { useEffect, useMemo, useState } from 'react';
import ProfileBar from '../consentforms/ProfileBar';
import LdcApi from '../../../LdcApis';
import { useNavigate, useParams } from 'react-router';
import Question from '../../../modules/Question';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { Checkbox, Radio } from 'antd';
import { useToasts } from 'react-toast-notifications';

const ErrMsg = ({ show = false, msg = 'Field is required.' }) => (show ? <span className="text-danger">{msg}</span> : null);

const filterOutQuestion = {
  yes: [138],
  no: [139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150]
}
const TreatmentNoteEdit = ({ editTemplateData, showEditAccordian }) => {
  const data = useParams();

  const [editTemplateNoteData, setEditTemplateNoteData] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterQuestions, setFilterQuestions] = useState([]);
  const [bigError, setBigError] = useState(false);
  const [allQuestion, setAllQuestion] = useState({
    treatmentNoteAnswers: []
  });
  const [showOther, setShowOther] = useState({ open: false, details: null });
  const [disableBtn, setDisableBtn] = useState(false);
  const [pinData, setPinData] = useState({ pin: '' });
  const [showPinModal, setShowPinModal] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [selectField, setSelectField] = useState('yes');


  const navigate = useNavigate();

  const token = localStorage.getItem('_code');

  const { addToast } = useToasts();

  const options = (items, splitBy = '|', valueOnly) => {
    if (!items?.trim()) return [];

    if (valueOnly) return items.split(splitBy);

    return items.split(splitBy).map((item) => ({
      label: item,
      value: item
    }));
  };

  const handleClose = () => {
    setShowPinModal(false);
  };

  useEffect(() => {
    document.title = 'Treatmentnote Edit';
  }, []);

  useEffect(() => {
    LdcApi({
      method: 'GET',
      url: `TreatmentNotes/GetTreatmentNoteAnswerbyId?TreatmentNoteId=${data.treatid}`
    })
      .then((response) => {
        console.log(response.data)
        setEditTemplateNoteData(response.data);
        setAllQuestion({
          treatmentNoteAnswers: response.data.treatmentNoteAnswersByTreatmentNoteId.map((item) => {
            // console.log(item)
            if (item.questionId === 137) {
              setSelectField(item.answer === 'Yes' ? 'no' : 'yes');
            }
            return ({
              questionId: item.questionId,
              question: item.question,
              answer: item.answer,
              required:item.required
            })
          })
        });
      })
      .catch((error) => { });
  }, []);

  const mainOption = useMemo(
    () =>
      editTemplateNoteData?.treatmentNoteAnswersByTreatmentNoteId?.filter(
        (item) => item.answerValue === null && item.parentQuestionID === null
      ) || []
  );

  const handleQuestionChange = (type, value, question, questionId, answer) => {
    const index = allQuestion?.treatmentNoteAnswers?.findIndex((item) => item.questionId === questionId);
    const item =
      index > -1
        ? allQuestion?.treatmentNoteAnswers[index]
        : {
          questionId,
          question,
          value: ''
        };
    const newObj = { ...item };

    if (Array.isArray(value)) {
      newObj.answer = value.join(',');
    } else newObj.answer = value;

    setAllQuestion((prev) => {
      const temp = { ...prev };
      if (index < 0) {
        temp.treatmentNoteAnswers.push(newObj);
        return temp;
      }

      temp.treatmentNoteAnswers = prev.treatmentNoteAnswers.map((item, idx) => {
        if (idx === index) return newObj;
        return item;
      });
      return temp;
    });
  };

  const getChildQuestions = (questionId, type) => {
    let temp = [...(editTemplateNoteData?.treatmentNoteAnswersByTreatmentNoteId || [])];

    if (type === 'dropdown') {
      const question = allQuestion?.treatmentNoteAnswers.find((item) => item.questionId === questionId);
      if (question?.answer) {
        temp = temp.filter((e) => e.answerValue === question?.answer);
      } else {
        temp = [];
      }
    } else {
      temp = editTemplateNoteData?.treatmentNoteAnswersByTreatmentNoteId?.filter((e) => e.parentQuestionID === questionId) || [];
    }
    return temp.map((item, index) => {
      if (selectField === "") {
        // console.log([...filterOutQuestion.yes, ...filterOutQuestion.no],[...filterOutQuestion.yes, ...filterOutQuestion.no].includes(item.id),item)
        if ([...filterOutQuestion.yes, ...filterOutQuestion.no].includes(item.questionId)) {
          // console.log(item)
          return null;
        } else {
          // console.log(item)
          return renderItem(item, index, false)
        }
      } else {
        if (filterOutQuestion[selectField].includes(item.questionId)) {
          return null;
        } else {
          return renderItem(item, index, false)
        }
      }
    });
  };

  const onSubmit = (e) => {
    // setBigError(true);
    e.preventDefault();
    let obj=null;
    console.log(allQuestion.treatmentNoteAnswers)
    const req = allQuestion.treatmentNoteAnswers.filter((el, index) => el.required === true);
    console.log(req,allQuestion.treatmentNoteAnswers)

    const res = req.filter((el, i) => {
      const out=allQuestion.treatmentNoteAnswers.some((item, index) => {
        return el.questionId === item.questionId && item.answer!=="";
      });
      if(!out){
        obj={...obj,[el.questionId]:true}
      }
      console.log(out)
      return !out;
    });
    // console.log(req,res,obj)

    if (res.length === 0) {
      setBigError(null);

      setDisableBtn(true);
      if (pinData.pin !== '') {
        setShowPinModal(false);
        const userData = {
          ...allQuestion,
          patientID: editTemplateNoteData?.patientID,
          appointmentId: Number(data?.aId) == 0 ? null : Number(data?.aId),
          templateId: Number(data?.tempId),
          pin: pinData?.pin,
          id: Number(data?.treatid),
          prescriptionid: editTemplateNoteData.prescriptionid,
        };

        if (userData?.treatmentNoteAnswers.length) {
          Question?.AddTreatmentNotes(userData, (response) => {
            if (response?.data?.status?.isSuccess === true) {
              addToast('Notes saved successfully.', { appearance: 'success' });
              e.target.reset();
              // getQuestionTypeMaster();
              navigate(`/profile/${userData.patientID}`);
              setDisableBtn(false);
            } else if (response?.data?.status?.errorMessage === 'Plz Enter Gender') {
              addToast('Please enter gender , address , Date of Birth for this patient.', {
                appearance: 'error',
                autoDismissTimeout: 15000
              });
              setDisableBtn(false);
            } else if (response?.data?.status?.errorMessage === 'Plz Enter DateOfBirth') {
              addToast('Please enter Date of Birth for this patient.', {
                appearance: 'error',
                autoDismissTimeout: 15000
              });
              setDisableBtn(false);
            } else if (response?.data?.status?.errorMessage === 'Plz Enter Address') {
              addToast('Please enter the address for this patient.', {
                appearance: 'error',
                autoDismissTimeout: 15000
              });
              setDisableBtn(false);
            } else if (response?.data?.status?.errorMessage === 'plz enter valid pin') {
              addToast('Please enter valid pin', {
                appearance: 'error'
              });
              setShowPinModal(true);
              setDisableBtn(false);
            } else if (response?.data?.status?.errorMessage.match('You trying to access resource of prescriber')) {
              addToast(`You trying to access resource of prescriber that you don't have access.`, { appearance: 'error' });
              setDisableBtn(false);
            } else {
              addToast('Something is wrong While Adding Notes.', { appearance: 'error' });
              setDisableBtn(false);
            }
          });
        } else {
          setDisableBtn(false);
          window.scrollTo(0, 0);
          addToast('No entries have been filled', { appearance: 'error' });
        }
      } else {
        setDisableBtn(false);
        setShowPinModal(true);
      }
    } else {
      setBigError(obj)
      addToast('Fields are required.', { appearance: 'error' });
    }

    // if (bigError === false) {

    // } else {
    // }
  };
  const backgroundRadioChangeFun = (value, id) => {
    console.log(value)
    console.log(id);
    setSelectField(value === 'Yes' ? 'no' : 'yes')
  }
  const renderItem = (item, index, isMain = false) => (
    <div className={item.typesofInput === 'numerical' ? 'd-flex align-items-center mb-1 ' : ''} style={{ padding: '10px' }}>
      {item.typesofInput === null ? <h6 className="mt-1" style={{ color: 'black', fontSize: '14px', fontWeight: '900' }}>
        {item.question} {item.required && <span style={{ color: 'red' }}>*</span>}
      </h6> : <h6 className="mt-1" style={{ color: 'gray', fontSize: '13px', fontWeight: 'bold',paddingLeft:'20px'}}>
        {item.question} {item.required && <span style={{ color: 'red' }}>*</span>}
      </h6>}


      {item?.typesofInput === null && isMain ? getChildQuestions(item.questionId, item.typesofInput) : null}

      <div style={{paddingLeft:'20px'}}>
        {item?.typesofInput === 'textarea' ? (
          <>
            <textarea
              className={`form-control mt-3 ${bigError?.[item.questionId]===true && item.required ? 'req_Field' : ''}`}
              data-id={index}
              onChange={({ target: { value, type } }) => {
                handleQuestionChange('textArea', value, item.question, item.questionId, item.answer);
              }}
              rows="4"
              cols="50"
              defaultValue={options(item.answer, ',', true)}
            />
            <ErrMsg show={bigError?.[item.questionId]===true && item.required} />
          </>
        ) : null}

        {item.typesofInput === 'multi-select checkbox' ? (
          <div className="mt-1" style={{ display: 'flex' }}>
            <Checkbox.Group
              id="checkbox"
              defaultValue={options(item.answer, ',', true)}
              options={options(item.value)}
              onChange={(checkedValues) => {
                handleQuestionChange('multi-select checkbox', checkedValues, item.question, item.questionId, item.answer);
              }}
            />
            <ErrMsg show={bigError?.[item.questionId]===true && item.required} />
          </div>
        ) : null}

        {item.typesofInput === 'dropdown' ? (
          <>
            <select
              defaultValue={item.answer}
              name="select1"
              className={`form-control mt-3 ${bigError?.[item.questionId]===true && item.required ? 'req_Field' : ''}`}
              onChange={({ target: { value } }) => {
                handleQuestionChange('dropdown', value, item.question, item.questionId, item.answer);
              }}
            >
              <option>Select Option</option>
              {item.value.split('|').map((item) => {
                return <option value={item.answer}>{item}</option>;
              })}
            </select>
            <ErrMsg show={bigError?.[item.questionId]===true && item.required} />
            {isMain ? getChildQuestions(item.questionId, item.typesofInput) : null}
          </>
        ) : null}

        {item.typesofInput === 'textbox' ? (
          <>
            <input
              className={`form-control mt-3 ${bigError?.[item.questionId]===true && item.required ? 'req_Field' : ''}`}
              data-id={index}
              type="text"
              onChange={({ target: { value } }) => {
                handleQuestionChange('textbox', value, item.question, item.questionId, item.answer);
              }}
              defaultValue={options(item.answer, ',', true)}
              rows="4"
              cols="50"
            />
            <ErrMsg show={bigError?.[item.questionId]===true && item.required} />
          </>
        ) : null}

        {item.typesofInput === 'radio button' ? (
          <div className="mt-3">
            <Radio.Group
              onChange={(e) => {
                handleQuestionChange('radio button', e.target.value, item.question, item.questionId, item.answer);
                backgroundRadioChangeFun(e.target.value, item.id)
              }}
              defaultValue={item.answer}
            >
              <br />
              {item.value.split('|').map((row, index) => {
                return <Radio value={row}>{row}</Radio>;
              })}
            </Radio.Group>
            <br />
            <ErrMsg show={bigError?.[item.questionId]===true && item.required} />
          </div>
        ) : null}

        {item.typesofInput === 'numerical' ? (
          <>
            <input
              className={`form-control ${bigError?.[item.questionId]===true && item.required ? 'req_Field' : ''}`}
              style={{ width: '200px' }}
              onChange={({ target: { value } }) => {
                if (value.includes('-')) {
                  addToast('Negative number cant be accepted', { appearance: 'error' });
                } else {
                  handleQuestionChange('numerical', value, item.question, item.questionId, item.answer);
                }
              }}
              type="number"
              min="0"
              oninput="this.value = Math.abs(this.value)"
              defaultValue={options(item.answer, ',', true)}
            />
            <ErrMsg show={bigError?.[item.questionId]===true && item.required} />
          </>
        ) : null}
      </div>


    </div>
  );
  return (
    <div style={{ marginTop: '0.5rem' }} className="p-2">
      <ProfileBar />
      <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>{editTemplateNoteData?.templateName}</p>
      <Row>
        <Col md={12} className="p-2">
          <form onSubmit={onSubmit}>
            {mainOption.map((item, index) => renderItem(item, index, true))}
            <button type="submit" disabled={disableBtn} className="btn px-4 btn-outline-primary mt-4">
              {disableBtn ? 'Loading....' : 'Submit'}
            </button>
          </form>
        </Col>
      </Row>
      <Modal show={showPinModal} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Please enter your PIN:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit} autoComplete="off">
            <Row className="my-4">
              <Col lg={8}>
                <Form.Group>
                  <Form.Label>PIN</Form.Label> <span className="text-danger">*</span>
                  <Form.Control
                    autoComplete="off"
                    type="password"
                    name="pin"
                    placeholder="PIN"
                    value={pinData.pin}
                    required
                    autocomplete="new-password"
                    // onChange={(e) => {
                    //   setPinData({ pin: e.target.value });
                    //   if (e.target.value.length === 6 || e.target.value.length === 0) {
                    //     setPinError(false);
                    //   } else {
                    //     setPinError(true);
                    //   }
                    // }}
                    onChange={(e) => {
                      let pattern = /^\d+$/;
                      let result = e.target.value.match(pattern);
                      if (result == null) {
                        setPinError(true);
                        setPinData({ pin: e.target.value });
                      } else {
                        setPinError(false);
                        setPinData({ pin: e.target.value });
                        if (e.target.value.length === 6 || e.target.value.length === 0) {
                          setPinError(false);
                        } else {
                          setPinError(true);
                        }
                      }
                    }}
                  />
                  {pinError && <small className="text-danger error_field"> Must be exactly 6 characters.</small>}
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg={6}>
                <Button
                  type="submit"
                  disabled={pinError}
                  className="ne_btn w-100 ne_btn_primary d-flex justify-content-center align-items-center"
                >
                  {disableBtn ? 'Loading....' : 'Submit'}
                </Button>
              </Col>
            </Row>
          </Form>
          {/* )}
          </Formik> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TreatmentNoteEdit;
