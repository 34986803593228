import { AutoComplete } from 'antd';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Patients from '../../../modules/Patients';
import LdcApi from '../../../LdcApis';
import DeletePatient from './DeletePatient';
import { GoAlert } from 'react-icons/go';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useToasts } from 'react-toast-notifications';
import {BsArrowRight} from 'react-icons/bs'
const initialValues = {
  pin: ''
};
const validationSchema = yup.object().shape({
  pin: yup.string().max(6).min(6).required('PIN is required')
});

const DeleteMergePatient = () => {
  const { addToast } = useToasts();
  useEffect(() => {
    document.title = 'Delete Merge Patient - LDC';
  }, []);
  const [result, setResult] = useState([]);
  const [resultTow, setResultTow] = useState([]);
  const [show, setShow] = useState(false);
  //   const [res, setRes] = useState([]);
  const [patientOne, setPatientOne] = useState(0);
  const [patientTow, setPatientTow] = useState(0);
  const [numberOne, setNumberOne] = useState(0);
  const [numberTow, setNumberTow] = useState(0);
  const [margeLoading, setLoading] = useState(false);
  const [empty, setEmpty] = useState('');
  const [emptyTh, setEmptyTh] = useState('');
  const [value, setValue] = useState('');
  const [valueTwo, setValueTwo] = useState('');

  //pin

  const [pin, setPin] = useState('');
  const [pinError, setPinError] = useState(false);
  const [pinPopUpShow, setPinPopUpShow] = useState(false);

  const Options = result.map((el) => {
    return {
      key: el.id,
      id: el.id,
      label: `${el.fullName} (${el.mobile}) :: ${el.id}`,
      value: `${el.id}`
    };
  });

  const optionsTwo = resultTow.map((el) => {
    return {
      key: el.id,
      id: el.id,
      label: `${el.fullName} (${el.mobile}) :: ${el.id}`,
      value: `${el.id}`
    };
  });

  const handleSearch = (value) => {
    setValue(value);
    if (value.length === '') {
      setResult([]);
    }
    if (value.length>=3) {
      // allPatients();
      Patients.getExistPatients(value, (response) => {
        if (response?.status === 'success') {
          if (response?.data.length > 0) {
            setResult(response?.data);
          } else {
            setResult([]);
          }
        }
      });
    }else{
      setResult([]);
    }

  };

  const onSelect = async (data) => {
    const patientData = result.filter((item) => item?.id.toString() === data.toString());
    await setValue(patientData[0].fullName + " :: " + data);
    onSubmit(data);
    // handleSearch('')
  };

  const onSelectTwo = async (data) => {
    const patientData = resultTow.filter((item) => item?.id.toString() === data.toString());
    await setValueTwo(patientData[0].fullName + " :: " + data);
    onSubmitTow(data);
    // handleSearchTow('')
  };

  const handleSearchTow = (value) => {
    setValueTwo(value);
    if (value.length === '') {
      setResultTow([]);
    }
    if(value.length>=3){
      // allPatients();
      Patients.getExistPatients(value, (response) => {
        if (response?.status === 'success') {
          if (response?.data.length > 0) {
            setResultTow(response?.data);
          } else {
            setResultTow([]);
          }
        }
      });
    }else{
      setResultTow([]);
    }
    
  };
  const onSubmit = (val, asd) => {
    setNumberOne(val);
    const selItem = result.find((item) => item?.id.toString() === val.toString());
    setPatientOne(selItem.id);
  };

  const onSubmitTow = (val, asd) => {
    setNumberTow(val);
    const selItemdata = resultTow.find((item) => item?.id.toString() === val.toString());
    setPatientTow(selItemdata.id);
  };

  const margePatient = () => {
    if (patientOne == 0 && patientTow == 0) {
      setShow(false);
    } else if (patientTow === patientOne) {
      setShow(false);
      addToast('Same patients cannot be Merged.', { appearance: 'warning' });
    } else {
      setShow(true);
    }
  };
  const handleClose = () => {
    setShow(false);
    setPinPopUpShow(false);
  };

  const submitMarge = (values, { resetForm }) => {
    setPinPopUpShow(false);

    setLoading(true);
    LdcApi({
      method: 'GET',
      url: `Patients/Merge_Patients?fromPatientID=${patientOne}&toPatientID=${patientTow}&pin=${values.pin}`
    })
      .then((res) => {
        if (res?.data?.status?.isSuccess == true) {
          resetForm();
          addToast('Patient merged successfully.', { appearance: 'success' });
          setValue('');
          setValueTwo('');
          setPatientOne(0);
          setPatientTow(0);
          setResult([]);
          setResultTow([]);
          setLoading(false);
        } else if (res?.data?.status.errorMessage === 'Plz Enter Valid Pin') {
          addToast('Plz Enter Valid Pin.', { appearance: 'warning' });
          setPinPopUpShow(true);
          setLoading(false);
        } else {
          addToast('No record found.', { appearance: 'error' });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const showPinPopUp = () => {
    setPinPopUpShow(true);
    setShow(false);
  };
  // pin=123456
  return (
    <div>
      <Container className="  p-2">
        <Row>
          <Col lg={12}>
            {/* <span className="badge py-2 w-100" style={{ fontSize: '0.8rem', background: '#f87171', color: '#fff' }}>
              Functionality Pending
            </span> */}
          </Col>
        </Row>
        <b style={{ fontSize: '1.7rem', color: '#3f3f46' }}>Merge Patient</b>
        <Row className="mt-2">
          <Col md={4}>
            <AutoComplete
              position="center"
              style={{ width: '100%' }}
              id="searchBar"
              value={value}
              allowClear={true}
              onSearch={handleSearch}
              onSelect={onSelect}
              placeholder="Search Patient...(Enter min 3 character)"
              options={Options}
              onChange={setValue}
            // filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            />
          </Col>
          Move data into <span style={{marginLeft:"6px",display:"flex",fontSize:"25px"}}> {<BsArrowRight/>}</span>
          <Col md={4}>
            <AutoComplete
              position="center"
              style={{ width: '100%' }}
              id="searchBar"
              value={valueTwo}
              allowClear={true}
              onSearch={handleSearchTow}
              onSelect={onSelectTwo}
              placeholder="Search Patient...(Enter min 3 character)"
              options={optionsTwo}
              onChange={setValueTwo}
            // filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            />
          </Col>
          <Col md={4} style={{minWidth:"max-content",width:"0%",flex:"0"}}>
            <Button onClick={() => margePatient()} className="d-flex align-items-center">
              Merge Patient
            </Button>
          </Col>
        </Row>
        <div className="mt-5">
          <DeletePatient />
        </div>
      </Container>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <GoAlert style={{ color: '#ff8f39' }} className="mx-1" /> Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>Do you want to merge the selected patients? </b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => showPinPopUp()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={pinPopUpShow} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <GoAlert style={{ color: '#ff8f39' }} className="mx-1" /> Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitMarge}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="">
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>
                        PIN <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="m-0 p-2"
                        type="password"
                        name="pin"
                        placeholder="******"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pin}
                        error={errors.pin}
                        touched={touched.pin}
                        //   isInvalid={touched.pin && !!errors.pin}
                        //   isValid={touched.pin && !errors.pin}
                        required
                      />
                      {touched.pin && errors.pin && <small className="text-danger error_field">{errors.pin}</small>}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Col lg={12}>
                    <Button variant="primary" type="submit">
                      {margeLoading ? 'Loading...' : 'Submit'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeleteMergePatient;
