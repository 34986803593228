import axios from 'axios';
// import { Navigate, useNavigate } from 'react-router';
import { serverConfig } from '../config/server-config';

const appServerUrl = serverConfig.appServerUrl;

const LdcApi = (config) => {
  const token = localStorage.getItem('_code');
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (!error.response) {
        error.response = {
          data: 'INTERNAL SERVER ERROR',
          status: 500
        };
      }
      return Promise.reject(error);
    }
  );
  config.baseURL = appServerUrl;
  if (token != null) {
    if(config.url==='JobList/UpsertJobList'){
      config.headers={
        "SecurityCode": token,
        "Content-Type":"multipart/form-data"
      }
    }else{
      config.headers = {
        SecurityCode: token
      };
    }
  }
  return axios(config);
};

axios.interceptors.response.use(null, (error) => {
  const expectedError = error.response?.status === 401;
  // const expectedError =
  // error.response &&
  // error.response === 401 &&
  // error.response?.status >= 400 &&
  // error.response?.status < 500;
  if (expectedError) {

    setTimeout(() => {
      window.location.href = '/';
      localStorage.clear();
    }, 500);
  }

  return Promise.reject(expectedError);
});

export default LdcApi;
