import LdcApi from '../../LdcApis';
const user = JSON.parse(localStorage.getItem('user'));
const userInfo = JSON.parse(localStorage.getItem('user_info'));

// https://api-ldc.goldenbuzz.in/api/Doctor/GetDoctorsByDate?datetime=2022-12-23&doctorId=8
// Doctor/GetDoctorsByDate?datetime=${selectedDate}
// selectedDate

class DoctorModu {
  getAllDoctorByDate = (selectedDate, callback) => {
    LdcApi({
      method: 'GET',
      url: `Doctor/GetDoctorsByDate?datetime=${selectedDate}&doctorId=${
        (userInfo && userInfo?.roleID == 1) ||
        userInfo?.roleID == 2 ||
        userInfo?.roleID == 3 ||
        userInfo?.roleID == 5 ||
        userInfo?.roleID == 11 ||
        userInfo?.roleID == 13
          ? user.id
          : 0
      }`,
    })
      .then((response) => {
        if (response?.data) {
          console.log('response hit', response);
          callback({ status: 'success', data: response?.data });
        }
        // if (response) {
        //   callback({ status: 'success', data: response });
        // }
      })
      .catch((error) => {
        // localStorage.clear();
        // navigate('/');
        callback({ status: 'error' });
      });
  };
  getAllDoctor = (callback) => {
    LdcApi({
      method: 'GET',
      url: `Doctor/GetDoctorAvailablity`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  getChambers = (callback) => {
    LdcApi({
      method: 'GET',
      url: 'Chamber/GetChamberTimings?option=1',
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  addDoctor = (roleData, callback) => {
    LdcApi({
      method: 'POST',
      url: 'Doctor/AddDoctor',
      data: roleData,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  getAllAssistentDocter = (callback) => {
    LdcApi({
      method: 'GET',
      url: `User/GetUserByRoleAssistants`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  addChamber = (chamberData, callback) => {
    LdcApi({
      method: 'POST',
      url: 'Chamber/AddChamber',
      data: chamberData,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: error });
      });
  };

  getAllDoctors = (callback) => {
    LdcApi({
      method: 'GET',
      url: `Doctor/GetDoctor`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: error });
      });
  };
  getSearchedChambers = (doctorId, startDate, endDate, callback) => {
    LdcApi({
      method: 'GET',
      url: `Chamber/ViewRotaDetailsByDoctorIdAndDate?doctorId=${doctorId}&startDate=${startDate}&endDate=${endDate}`,
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: error });
      });
  };
}

export default new DoctorModu();
