import { AutoComplete } from 'antd';
import React, { useState } from 'react';
import Patients from '../../../modules/Patients';
import { Button, Col, Row, Modal, Form } from 'react-bootstrap';
import { GoAlert } from 'react-icons/go';
import LdcApi from '../../../LdcApis';
import { useToasts } from 'react-toast-notifications';
import { Formik } from 'formik';
import * as yup from 'yup';

const initialValues = {
  pin: ''
};
const validationSchema = yup.object().shape({
  pin: yup.string().max(6).min(6).required('PIN is required')
});
const DeletePatient = () => {
  const [result, setResult] = useState([]);
  const [res, setRes] = useState([]);
  const [show, setShow] = useState(false);
  const [patientOne, setPatientOne] = useState(0);
  const [margeLoading, setLoading] = useState(false);
  //pin
  const { addToast } = useToasts();
  const [empty, setEmpty] = useState('');
  const [pinError, setPinError] = useState(false);
  const [pinPopUpShow, setPinPopUpShow] = useState(false);
  const [value, setValue] = useState(null);

  const Options = result.map((el) => {
    return {
      key: el.id,
      label: `${el.fullName} (${el.mobile}) :: ${el.id}`,
      value: `${el.id}`
    };
  });

  const handleSearch = (value) => {
    setValue(value);
    setRes([]);
    if (value.length === '') {
      setResult([]);
    }
    if (value.length >= 3) {
      // allPatients();
      Patients.getExistPatients(value, (response) => {
        if (response?.status === 'success') {
          if (response?.data.length > 0) {
            setResult(response?.data);
          } else {
            setResult([]);
          }
        }
      });
    }else{
      setResult([]);
    }

  };

  const onSelect = async (data) => {
    const patientData = result.filter((item) => item?.id.toString() === data.toString());
    // await setValue(patientData[0].fullName);
    setValue(patientData[0].fullName + " :: " + data);
    onSubmit(data);
  };

  const onSubmit = (val, asd) => {
    const selItem = result.find((item) => item?.id.toString() === val.toString());
    setPatientOne(selItem.id);
  };
  const ShowModal = () => {
    if (patientOne === 0) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  const handleClose = () => {
    setShow(false);
    setPinPopUpShow(false);
  };
  const showPinPopUp = () => {
    setPinPopUpShow(true);
    setShow(false);
  };
  const submitMarge = (values, { resetForm }) => {
    setPinPopUpShow(false);
    setLoading(true);
    LdcApi({
      method: 'GET',
      url: `Patients/DeletePatient?id=${patientOne}&pin=${values.pin}`
    })
      .then((res) => {
        if (res?.data?.status?.isSuccess == true) {
          resetForm();
          addToast('Patient Deleted successfully ', { appearance: 'success' });
          setLoading(false);
          setValue('');
          setResult([]);
        } else if (res?.data?.status.errorMessage === 'Plz Enter Valid Pin') {
          addToast('Plz Enter Valid Pin', { appearance: 'warning' });
          setPinPopUpShow(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <b style={{ fontSize: '1.7rem', color: '#3f3f46' }}>Delete Patient</b>
      <Row className="mt-2">
        <Col md={4}>
          <AutoComplete
            position="center"
            style={{ width: '100%' }}
            //   style={{ width: '400px' }}
            id="searchBar"
            allowClear={true}
            value={value}
            // onMouseDown={allPatients}
            onSearch={handleSearch}
            onChange={(e, demo) => {
              setEmpty(e);
            }}
            onSelect={onSelect}
            placeholder="Search Patient...(Enter min 3 character)"
            options={Options}
          // filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          />
        </Col>
        <Col md={4}>
          <Button onClick={() => ShowModal()} variant="danger">
            Delete Patient
          </Button>
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <GoAlert style={{ color: '#ff8f39' }} className="mx-1" /> Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>Do you want to Delete the following patients? </b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => showPinPopUp()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={pinPopUpShow} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <GoAlert style={{ color: '#ff8f39' }} className="mx-1" /> Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitMarge}>
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="">
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Label>
                        PIN <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        className="m-0 p-2"
                        type="password"
                        name="pin"
                        placeholder="******"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pin}
                        error={errors.pin}
                        touched={touched.pin}
                        //   isInvalid={touched.pin && !!errors.pin}
                        //   isValid={touched.pin && !errors.pin}
                        required
                      />
                      {touched.pin && errors.pin && <small className="text-danger error_field">{errors.pin}</small>}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Col lg={12}>
                    <Button variant="primary" type="submit">
                      {margeLoading ? 'Loading...' : 'Submit'}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          {/* <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {margeLoading ? 'Loading...' : 'Submit'}
            </Button>
          </Form> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeletePatient;
