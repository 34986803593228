import React, { useMemo, useRef, useState } from 'react';
import ConsentFormsNavbar from '../../../components/common/ConsentFormsNavbar';
import ProfileBar from './ProfileBar';
import { Button, Modal, Container, Card, Form, Row, Col, Table } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router';
import classNames from 'classnames';
import { Checkbox, Radio } from 'antd';
import CanvasDraw from 'react-canvas-draw';
import { RadioGroup } from '@mui/material';
import LdcApi from '../../../LdcApis';
import { useToasts } from 'react-toast-notifications';
import { useEffect } from 'react';
import moment from 'moment';

const RoaccPackMaleMessage = () => {
  const [seriousMoodDisturbance, setSeriousMoodDisturbance] = useState(null);
  const [donateBlood, setDonateBlood] = useState(null);
  const [commonSideEffects, setCommonSideEffects] = useState(null);
  const [depressionOfMood, setdepressionOfMood] = useState(true);
  const [name, setName] = useState(null);
  // const [GMCNo, setGMCNo] = useState(null);
  const [doctorDate, setDoctorDate] = useState(null);
  // const [doctorSignature, setDoctorSignature] = useState('');
  const [patientName, setPatientName] = useState(null);
  const [patientDate, setPatientDate] = useState(null);
  const [patientSignature, setPatientSignature] = useState('');
  const [guardianName, setGuardianName] = useState(null);
  const [guardianDate, setGuardianDate] = useState(null);
  const [guardianSignature, setGuardianSignature] = useState('');
  const [schedulePatientName, setSchedulePatientName] = useState(null);
  const [schedulePatientDate, setSchedulePatientDate] = useState(null);
  const [schedulePatientSignature, setSchedulePatientSignature] = useState('');
  const [doctorInitiating, setDoctorInitiating] = useState(null);
  const [littleInterest, setlittleInterest] = useState(null);
  const [feelingDown, setFeelingDown] = useState(null);
  const [troubleFalling, setTroubleFalling] = useState(null);
  const [feelingTired, setFeelingTired] = useState(null);
  const [poorAppetite, setPoorAppetite] = useState(null);
  const [feelingBad, setFeelingBad] = useState(null);
  const [troubleConcentrating, setTroubleConcentrating] = useState(null);
  const [movingOrSpeaking, setMovingOrSpeaking] = useState(null);
  const [thoughts, setThoughts] = useState(null);
  const [difficult, setDiffcult] = useState(null);
  const [mouseDisable, setMouseDisable] = useState('auto');
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [totalScore, setTotalScore] = useState();

  const { addToast } = useToasts();

  let appointmentData = JSON.parse(localStorage.getItem('appointmentData'));

  const location = useLocation();

  const appointmentId = useParams();


  const [formData, setFromData] = useState({
    doctorName: location?.state?.[0]?.doctorName,
    doctorDate: moment(new Date()).format('DD/MM/YYYY'),
    patientName: location?.state?.[0]?.patientName,
    schedulePatientName: location?.state?.[0]?.patientName,
    patientDate: moment(new Date()).format('DD/MM/YYYY'),
    guardianDate: moment(new Date()).format('DD/MM/YYYY'),
    schedulePatientDate: moment(new Date()).format('DD/MM/YYYY'),
    // GMCNo: '',
    guardianName: '',
    doctorInitiating: location?.state?.[0]?.doctorName
  });

  const [brushColor, setBrushColor] = React.useState('#000');

  useEffect(() => {
    document.title = 'Isotretinoin Male Patient Consent Form - LDC';
    localStorage.setItem('confirmationCode', true);
  }, []);


  const sigPatientCanvas = useRef();
  // const sigDoctorCanvas = useRef();
  const sigGurdianCanvas = useRef();
  const sigSchedulePatientCanvas = useRef();

  const [validationError, setValidationError] = useState({
    seriousMoodDisturbance: false,
    donateBlood: false,
    commonSideEffects: false,
    depressionOfMood: false,
    doctorName: false,
    // GMCNo: false,
    // doctorSignature: false,
    doctorDate: false,
    patientName: false,
    patientDate: false,
    patientSignature: false,
    guardianName: false,
    guardianDate: false,
    guardianSignature: false,
    schedulePatientName: false,
    schedulePatientDate: false,
    schedulePatientSignature: false,
    doctorInitiating: false,
    littleInterest: false,
    feelingDown: false,
    troubleFalling: false,
    feelingTired: false,
    poorAppetite: false,
    feelingBad: false,
    troubleConcentrating: false,
    movingOrSpeaking: false,
    thoughts: false,
    difficult: false
  });

  const stat = useMemo(() => {
    const obj = {};
    [
      littleInterest,
      feelingDown,
      troubleFalling,
      feelingTired,
      poorAppetite,
      feelingBad,
      troubleConcentrating,
      movingOrSpeaking,
      thoughts
    ].forEach((item) => {
      if (item) {
        if (obj[item]) {
          obj[item] += 1;
        } else {
          obj[item] = 1;
        }
      }
    });
    let total = 0;
    Object.keys(obj).forEach((item) => {
      total += obj[item] || 0;
    });
    return {
      text: ['0', '1', '2', '3'].map((item) => obj[item] || 0).join(' + '),
      total
    };
  }, [
    littleInterest,
    feelingDown,
    troubleFalling,
    feelingTired,
    poorAppetite,
    feelingBad,
    troubleConcentrating,
    movingOrSpeaking,
    thoughts
  ]);

  useEffect(() => {
    setTotalScore(stat.total);
  }, [stat]);

  const navigate = useNavigate();

  const seriousMoodDisturbanceChange = (e) => {
    setValidationError({ ...validationError, seriousMoodDisturbance: false });
    setSeriousMoodDisturbance(JSON.parse(e));
  };

  const donateBloodChange = (e) => {
    setValidationError({ ...validationError, donateBlood: false });
    setDonateBlood(JSON.parse(e));
  };

  const commonSideEffectsChange = (e) => {
    setValidationError({ ...validationError, commonSideEffects: false });
    setCommonSideEffects(JSON.parse(e));
  };

  const depressionOfMoodChange = (e) => {
    setValidationError({ ...validationError, depressionOfMood: false });
    setdepressionOfMood('true');
  };


  const handleLittle = (e) => {
    setlittleInterest(e.target.value);
  };

  const handleFeelingDown = (e) => {
    setFeelingDown(e.target.value);
  };

  const handleTroubleFalling = (e) => {
    setTroubleFalling(e.target.value);
  };

  const handleFeelingTired = (e) => {
    setFeelingTired(e.target.value);
  };

  const handlePoorAppetite = (e) => {
    setPoorAppetite(e.target.value);
  };

  const handleFeelingBad = (e) => {
    setFeelingBad(e.target.value);
  };

  const handleTroubleConcentrating = (e) => {
    setTroubleConcentrating(e.target.value);
  };

  const handleMovingOrSpeaking = (e) => {
    setMovingOrSpeaking(e.target.value);
  };

  const handleSetThoughts = (e) => {
    setThoughts(e.target.value);
  };

  const handleProblem = (e) => {
    setDiffcult(e.target.value);
  };

  const handelOnchangeValue = (e) => {
    setFromData({ ...formData, [e.target.name]: e.target.value });
  };

  // signature  start************************************************

  const canvasRef = useRef(null);
  const canvasRefOne = useRef(null);
  const canvasRefTow = useRef(null);
  const canvasRefThree = useRef(null);

  const defaultProps = {
    loadTimeOffset: 5,
    lazyRadius: 0,
    brushRadius: 2,
    catenaryColor: '#0a0302',
    gridColor: 'rgba(150,150,150,0.17)',
    hideGrid: true,
    canvasHeight: 300,
    disabled: false,
    imgSrc: '',
    saveData: '',
    immediateLoading: false,
    hideInterface: false
  };
  // const getImgDoctorSignature = () => canvasRef.current.canvasContainer.children[1].toDataURL();
  const getImgPatientSignature = () => canvasRefOne.current.canvasContainer.children[1].toDataURL();
  const getImgGurdianSignature = () => canvasRefTow.current.canvasContainer.children[1].toDataURL();
  const getImgSchedulePatientSignature = () => canvasRefThree.current.canvasContainer.children[1].toDataURL();

  // const doctorSignatureCanvasChange = () => {
  //   const saveData = getImgDoctorSignature();
  //   setDoctorSignature(saveData);
  //   if (doctorSignature == '') {
  //     setValidationError({ ...validationError, doctorSignature: true });
  //   } else {
  //     setValidationError({ ...validationError, doctorSignature: false });
  //   }
  // };

  const patientSignatureCanvasChange = () => {
    const saveData = getImgPatientSignature();
    setPatientSignature(saveData);
    if (patientSignature == '') {
      setValidationError({ ...validationError, patientSignature: true });
    } else {
      setValidationError({ ...validationError, patientSignature: false });
    }
  };

  const gurdianSignatureCanvasChange = () => {
    const saveData = getImgGurdianSignature();
    setGuardianSignature(saveData);
    if (guardianSignature == '') {
      setValidationError({ ...validationError, guardianSignature: true });
    } else {
      setValidationError({ ...validationError, guardianSignature: false });
    }
  };

  const schedulePatientSignatureCanvasChange = () => {
    const saveData = getImgSchedulePatientSignature();
    setSchedulePatientSignature(saveData);
    if (schedulePatientSignature == '') {
      setValidationError({ ...validationError, schedulePatientSignature: true });
    } else {
      setValidationError({ ...validationError, schedulePatientSignature: false });
    }
  };

  // const doctorSignatureProps = {
  //   ...defaultProps,
  //   onChange: doctorSignatureCanvasChange,
  //   ref: canvasRef,
  //   className: classNames('canvasSignature'),
  //   brushColor,
  //   catenaryColor: brushColor
  // };

  const patientSignatureProps = {
    ...defaultProps,
    onChange: patientSignatureCanvasChange,
    ref: canvasRefOne,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor
  };

  const gurdianSignatureProps = {
    ...defaultProps,
    onChange: gurdianSignatureCanvasChange,
    ref: canvasRefTow,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor
  };

  const schedulePatientSignatureProps = {
    ...defaultProps,
    onChange: schedulePatientSignatureCanvasChange,
    ref: canvasRefThree,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor
  };

  // signature  end************************************************

  const handleSubmit = (e) => {
    e.preventDefault();
    setMouseDisable('none');
    setBtnSubmit(true);
    let fromValidationAllError = {
      seriousMoodDisturbance: false,
      donateBlood: false,
      commonSideEffects: false,
      depressionOfMood: false,
      doctorName: false,
      // GMCNo: false,
      // doctorSignature: false,
      doctorDate: false,
      patientName: false,
      patientDate: false,
      patientSignature: false,
      guardianName: false,
      guardianDate: false,
      guardianSignature: false,
      schedulePatientName: false,
      schedulePatientDate: false,
      schedulePatientSignature: false,
      doctorInitiating: false,
      littleInterest: false,
      feelingDown: false,
      troubleFalling: false,
      feelingTired: false,
      poorAppetite: false,
      feelingBad: false,
      troubleConcentrating: false,
      movingOrSpeaking: false,
      thoughts: false,
      difficult: false
    };
    let isValid = false;

    if (seriousMoodDisturbance == null) {
      fromValidationAllError.seriousMoodDisturbance = true;
      isValid = false;
    } else {
      isValid = true;
    }

    if (donateBlood == null) {
      fromValidationAllError.donateBlood = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (commonSideEffects == null) {
      fromValidationAllError.commonSideEffects = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (depressionOfMood == null) {
      fromValidationAllError.depressionOfMood = true;
      isValid = false;
    } else {
      isValid = true;
    }

    if (formData.doctorName === '') {
      fromValidationAllError.doctorName = true;
      isValid = false;
    } else {
      isValid = true;
    }
    // if (formData.GMCNo === '') {
    //   fromValidationAllError.GMCNo = true;
    //   isValid = false;
    // } else {
    //   isValid = true;
    // }
    // if (doctorSignature === '') {
    //   fromValidationAllError.doctorSignature = true;
    //   isValid = false;
    // } else {
    //   isValid = true;
    // }
    if (formData.doctorDate === '') {
      fromValidationAllError.doctorDate = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (formData.patientName === '') {
      fromValidationAllError.patientName = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (formData.patientDate === '') {
      fromValidationAllError.patientDate = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (patientSignature === '') {
      fromValidationAllError.patientSignature = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (formData.guardianName === '') {
      fromValidationAllError.guardianName = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (formData.guardianDate === '') {
      fromValidationAllError.guardianDate = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (guardianSignature === '') {
      fromValidationAllError.guardianSignature = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (formData.schedulePatientName === '') {
      fromValidationAllError.schedulePatientName = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (formData.schedulePatientDate === '') {
      fromValidationAllError.schedulePatientDate = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (schedulePatientSignature === '') {
      fromValidationAllError.schedulePatientSignature = true;
      isValid = false;
    } else {
      isValid = true;
    }

    if (formData.doctorInitiating === '') {
      fromValidationAllError.doctorInitiating = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (littleInterest == null) {
      fromValidationAllError.littleInterest = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (feelingDown == null) {
      fromValidationAllError.feelingDown = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (troubleFalling == null) {
      fromValidationAllError.troubleFalling = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (feelingTired == null) {
      fromValidationAllError.feelingTired = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (poorAppetite == null) {
      fromValidationAllError.poorAppetite = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (feelingBad == null) {
      fromValidationAllError.feelingBad = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (troubleConcentrating == null) {
      fromValidationAllError.troubleConcentrating = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (movingOrSpeaking == null) {
      fromValidationAllError.movingOrSpeaking = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (thoughts == null) {
      fromValidationAllError.thoughts = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (difficult == null) {
      fromValidationAllError.difficult = true;
      isValid = false;
    } else {
      isValid = true;
    }
    setValidationError(fromValidationAllError);
    const data = Object.values(fromValidationAllError).every((item) => item === false);
    if (data) {
      const newValues = {
        appointmentId: appointmentId?.aid,
        seriousMoodDisturbance: seriousMoodDisturbance,
        donateBlood: donateBlood,
        commonSideEffects: commonSideEffects,
        depressionOfMood: depressionOfMood,
        doctorName: formData.doctorName,
        // GMCNo: formData.GMCNo,
        // doctorSignature: doctorSignature,
        doctorDate: moment(new Date()).format(),
        patientName: formData.patientName,
        patientDate: moment(new Date()).format(),
        patientSignature: patientSignature,
        guardianName: formData.guardianName,
        guardianDate: moment(new Date()).format(),
        guardianSignature: guardianSignature,
        schedulePatientName: formData.patientName,
        schedulePatientDate: moment(new Date()).format(),
        schedulePatientSignature: schedulePatientSignature,
        doctorInitiating: formData.doctorInitiating,
        littleInterest: littleInterest,
        feelingDown: feelingDown,
        troubleFalling: troubleFalling,
        feelingTired: feelingTired,
        poorAppetite: poorAppetite,
        feelingBad: feelingBad,
        troubleConcentrating: troubleConcentrating,
        movingOrSpeaking: movingOrSpeaking,
        thoughts: thoughts,
        difficult: difficult,
        totalScore: totalScore
      };
      LdcApi({
        method: 'POST',
        url: 'HealthQuestionnaire/AddIsotretinoin',
        data: newValues
      })
        .then(async (res) => {
          if (res?.data?.status?.isSuccess === true) {
            await addToast('Male Patient Consent Form For Isotretinoin added successfully ', { appearance: 'success' });
            navigate('/thankyou');
            setMouseDisable('auto');
            setBtnSubmit(false);
            sigPatientCanvas.current.clear();
            // sigDoctorCanvas.current.clear();
            sigGurdianCanvas.current.clear();
            sigSchedulePatientCanvas.current.clear();
            setSeriousMoodDisturbance(null);
            setDonateBlood(null);
            setlittleInterest(null);
            setFeelingDown(null);
            setTroubleFalling(null);
            setFeelingTired(null);
            setPoorAppetite(null);
            setFeelingBad(null);
            setTroubleConcentrating(null);
            setMovingOrSpeaking(null);
            setThoughts(null);
            setDiffcult(null);
            setFromData({
              doctorName: location?.state?.[0]?.doctorName,
              doctorDate: moment(new Date()).format('DD/MM/YYYY'),
              patientName: location?.state?.[0]?.patientName,
              schedulePatientName: `${appointmentData?.firstName} ${appointmentData?.lastName}`,
              patientDate: moment(new Date()).format('DD/MM/YYYY'),
              guardianDate: moment(new Date()).format('DD/MM/YYYY'),
              schedulePatientDate: moment(new Date()).format('DD/MM/YYYY'),
              // GMCNo: '',
              guardianName: '',
              doctorInitiating: location?.state?.[0]?.doctorName
            });
          }
        })
        .catch((err) => {
          setMouseDisable('auto');
          setBtnSubmit(false);
        });
    } else {
      setBtnSubmit(false);
      setMouseDisable('auto');
      // addToast('All fields are required.', { appearance: 'warning' });
    }
  };

  //   const hideForms = () => {
  //     navigate('/confirmation');
  //   };

  return (
    <div>
      <div className="navbar_header_container br_c">
        <ConsentFormsNavbar />
      </div>
      {/* <ProfileBar /> */}
      <Container style={{ pointerEvents: { mouseDisable } }}>
        {/* <div className="d-flex my-4 justify-content-end">
          <Button onClick={() => hideForms()} variant="outline-primary mb-3">
            Go to diary
          </Button>
        </div> */}
        <Card className="p-3">
          <p className="text-center form_heading  mb-5 mt-4">Male Patient Consent Form For Isotretinoin</p>
          <p className="textHeadline">Male Patients To Complete</p>
          <p>I understand:</p>
          <div className="p-1">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group>
                <Row className=" justify-content-center">
                  <Col sm={11} className="p-2 mr-5">
                    <p>
                      1) Serious mood disturbance (depression) can be provoked by isotretinoin and I must contact my doctor and stop taking
                      isotretinoin if I experience depression, become withdrawn, have thoughts of self-harm, or I am feeling sad, anxious,
                      worthless, or hopeless.
                    </p>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={` yes_no_btn ${seriousMoodDisturbance !== null ? (seriousMoodDisturbance ? 'yes_Btn' : 'back') : null}`}
                      onClick={(e) => seriousMoodDisturbanceChange((e = 'true'))}
                    >
                      Yes
                    </div>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`   yes_no_btn ${seriousMoodDisturbance !== null ? (seriousMoodDisturbance ? 'back' : 'no_Btn') : null} `}
                      onClick={(e) => seriousMoodDisturbanceChange((e = 'false'))}
                    >
                      No
                    </div>
                  </Col>
                  <span className="text-danger">{validationError.seriousMoodDisturbance === true && 'Field is required.'}</span>

                  <Col sm={11} className="p-2 mr-5 ">
                    <p>2) I should not donate blood during isotretinoin treatment or for at least one month after treatment</p>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={` yes_no_btn ${donateBlood !== null ? (donateBlood ? 'yes_Btn' : 'back') : null}`}
                      onClick={(e) => donateBloodChange((e = 'true'))}
                    >
                      Yes
                    </div>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`   yes_no_btn ${donateBlood !== null ? (donateBlood ? 'back' : 'no_Btn') : null} `}
                      onClick={(e) => donateBloodChange((e = 'false'))}
                    >
                      No
                    </div>
                  </Col>
                  <span className="text-danger">{validationError.donateBlood === true && 'Field is required.'}</span>
                  <Col sm={11} className="p-2 mr-5 ">
                    <p>3) I have been informed about the common side effects and received my BAD fact sheet and schedule of care.</p>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={` yes_no_btn ${commonSideEffects !== null ? (commonSideEffects ? 'yes_Btn' : 'back') : null}`}
                      onClick={(e) => commonSideEffectsChange((e = 'true'))}
                    >
                      Yes
                    </div>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`   yes_no_btn ${commonSideEffects !== null ? (commonSideEffects ? 'back' : 'no_Btn') : null} `}
                      onClick={(e) => commonSideEffectsChange((e = 'false'))}
                    >
                      No
                    </div>
                  </Col>
                  <span className="text-danger">{validationError.commonSideEffects === true && 'Field is required.'}</span>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row className="justify-content-center">
                  <Col sm={12} className="p-2 mt-4">
                    <p className="textHeadline">Doctor To Complete</p>
                  </Col>
                  <Col sm={11} className="p-2 mr-5 ">
                    <p>1) I have explained to the patient that depression of mood can be provoked by isotretinoin</p>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={` yes_no_btn ${depressionOfMood !== null ? (depressionOfMood ? 'yes_Btn' : 'back') : null}`}
                      onClick={(e) => depressionOfMoodChange((e = 'true'))}
                    >
                      Yes
                    </div>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`   yes_no_btn ${depressionOfMood !== null ? (depressionOfMood ? 'back' : 'no_Btn') : null} `}
                      onClick={(e) => depressionOfMoodChange((e = 'false'))}
                    >
                      No
                    </div>
                  </Col>
                  <span className="text-danger">{validationError.depressionOfMood === true && 'Field is required.'}</span>
                </Row>
                <Row className="p-4">
                  <b>
                    Name : <span className="text-danger mr-2">*</span>
                  </b>

                  <div>
                    <input
                      className={`from_control  ${validationError.doctorName === true ? 'from_control_invalid' : ''} `}
                      type="text"
                      name="doctorName"
                      disabled
                      // placeholder="name"
                      onChange={(e) => {
                        handelOnchangeValue(e);
                        if (e.target.value.length > 0) {
                          setValidationError({ ...validationError, doctorName: false });
                        } else {
                          setValidationError({ ...validationError, doctorName: true });
                        }
                      }}
                      value={formData.doctorName}
                    />
                    <br />
                    <span className="text-danger">{validationError.doctorName === true && 'Field is required.'}</span>
                  </div>
                </Row>
                {/* <Row className="p-4">
                  <b>
                    GMC No: <span className="text-danger mr-2">*</span>
                  </b>

                  <div>
                    <input
                      className={`from_control  ${validationError.GMCNo === true ? 'from_control_invalid' : ''} `}
                      type="text"
                      name="GMCNo"
                      // placeholder="name"

                      onChange={(e) => {
                        handelOnchangeValue(e);
                        if (e.target.value.length > 0) {
                          setValidationError({ ...validationError, GMCNo: false });
                        } else {
                          setValidationError({ ...validationError, GMCNo: true });
                        }
                      }}
                      value={formData.GMCNo}
                    />
                    <br />
                    <span className="text-danger">{validationError.GMCNo === true && 'Field is required.'}</span>
                  </div>
                </Row> */}

                {/* <Row className="mx-4">
                  <Col className="mr-2">
                    <b>
                      Signature : <span className="text-danger">*</span>
                    </b>
                  </Col>
                  <Col sm={11} className="mt-2">
                    <div className="mt-4">
                      <div style={{ overflow: 'hidden' }}>
                        <CanvasDraw {...doctorSignatureProps} />
                        <span className="text-danger">{validationError.childSignature === true && 'Field is required.'}</span>
                        <br />
                        <Button
                          classNames="mt-2"
                          onClick={() => {
                            canvasRef.current.clear();
                            setPatientSignature('');
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                      <span className="text-danger">{validationError.doctorSignature === true && 'Field is required.'}</span>
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <div className="p-3">
                    <Form.Group className="p-0 d-flex align-items-center">
                      <Row className="p-2">
                        <Col className="mr-2">
                          <b>
                            Date: <span className="text-danger">*</span>
                          </b>
                        </Col>
                        <Col>
                          <div>
                            <input
                              className={`from_control  ${validationError.doctorDate === true ? 'from_control_invalid' : ''} `}
                              type="text"
                              name="doctorDate"
                              disabled
                              // placeholder="name"

                              onChange={(e) => {
                                handelOnchangeValue(e);
                                if (e.target.value.length > 0) {
                                  setValidationError({ ...validationError, doctorDate: false });
                                } else {
                                  setValidationError({ ...validationError, doctorDate: true });
                                }
                              }}
                              value={formData.doctorDate}
                            />
                            <br />
                            <span className="text-danger">{validationError.doctorDate === true && 'Field is required.'}</span>
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </Row>
              </Form.Group>
              <Form.Group className="justify-content-center align-items-center">
                <Table className="tableStyle" responsive striped bordered hover size="md">
                  <thead className="prescription_thead mb-3 " style={{ backgroundColor: 'gray' }}>
                    <tr>
                      <th style={{ width: '40%' }}>Patient</th>
                      <th style={{ width: '30%' }}>Parent or Guardian *Required if patient under 16 years old.</th>
                    </tr>
                  </thead>
                  <tbody style={{ width: '500px' }}>
                    <tr>
                      <td>
                        <p style={{ width: '100%', whiteSpace: 'pre-line' }}>
                          I understand the above information about the effects of isotretinoin and consent to the treatment.
                        </p>
                      </td>
                      <td>
                        <p style={{ width: '100%', whiteSpace: 'pre-line' }}>
                          I understand the above information about the effects of isotretinoin and as a parent consent to the treatment
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Row>
                  <Col>
                    <div className="mt-3 p-3">
                      <Form.Group className="mt-3  d-flex align-items-center">
                        <Row>
                          <Col className="mr-2 mt-2">
                            <b>
                              Name : <span className="text-danger">*</span>
                            </b>
                          </Col>
                          <Col>
                            <div>
                              <input
                                className={`from_control  ${validationError.patientName === true ? 'from_control_invalid' : ''} `}
                                type="text"
                                name="patientName"
                                disabled
                                // placeholder="name"

                                onChange={(e) => {
                                  handelOnchangeValue(e);
                                  if (e.target.value.length > 0) {
                                    setValidationError({ ...validationError, patientName: false });
                                  } else {
                                    setValidationError({ ...validationError, patientName: true });
                                  }
                                }}
                                value={formData.patientName}
                              />
                              <br />
                              <span className="text-danger">{validationError.patientName === true && 'Field is required.'}</span>
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </div>
                    <div className=" p-3">
                      <Form.Group className="p-0 d-flex align-items-center">
                        <Row>
                          <Col className="mr-2">
                            <b>
                              Date : <span className="text-danger">*</span>
                            </b>
                          </Col>
                          <Col>
                            <div>
                              <input
                                className={`from_control  ${validationError.patientDate === true ? 'from_control_invalid' : ''} `}
                                type="text"
                                name="patientDate"
                                disabled
                                // placeholder="name"

                                onChange={(e) => {
                                  handelOnchangeValue(e);
                                  if (e.target.value.length > 0) {
                                    setValidationError({ ...validationError, patientDate: false });
                                  } else {
                                    setValidationError({ ...validationError, patientDate: true });
                                  }
                                }}
                                value={formData.patientDate}
                              />
                              <br />
                              <span className="text-danger">{validationError.patientDate === true && 'Field is required.'}</span>
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </div>
                    <div className="mt-4">
                      <b>
                        Signature<span className="text-danger">*</span>
                      </b>
                      <Col sm={12} className="mt-2">
                        <div style={{ overflow: 'hidden' }}>
                          <CanvasDraw {...patientSignatureProps} />
                          {/* <span className="text-danger">{validationError.childSignature === true && 'Field is required.'}</span> */}
                          <br />
                          <Button
                            classNames="mt-2"
                            onClick={() => {
                              canvasRefOne.current.clear();
                              setPatientSignature('');
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                        <span className="text-danger">{validationError.patientSignature === true && 'Field is required.'}</span>
                      </Col>
                    </div>
                  </Col>
                  <Col>
                    <div className="mt-3 p-3">
                      <Form.Group className="mt-3  d-flex align-items-center">
                        <Row>
                          <Col className="mr-2 mt-2">
                            <b>
                              Name : <span className="text-danger">*</span>
                            </b>
                          </Col>
                          <Row className="ml-1">
                            <div>
                              <input
                                className={`from_control  ${validationError.guardianName === true ? 'from_control_invalid' : ''} `}
                                type="text"
                                name="guardianName"
                                // placeholder="name"

                                onChange={(e) => {
                                  handelOnchangeValue(e);
                                  if (e.target.value.length > 0) {
                                    setValidationError({ ...validationError, guardianName: false });
                                  } else {
                                    setValidationError({ ...validationError, guardianName: true });
                                  }
                                }}
                                value={formData.guardianName}
                              />
                              <br />
                            </div>
                            <span className="text-danger mt-3 m-2">{validationError.guardianName === true && 'Field is required.'}</span>
                          </Row>
                        </Row>
                      </Form.Group>
                    </div>
                    <div className=" p-3">
                      <Form.Group className="p-0 d-flex align-items-center">
                        <Row>
                          <Col className="mr-2">
                            <b>
                              Date : <span className="text-danger">*</span>
                            </b>
                          </Col>
                          <Col>
                            <div>
                              <input
                                className={`from_control  ${validationError.guardianDate === true ? 'from_control_invalid' : ''} `}
                                type="text"
                                name="guardianDate"
                                disabled
                                // placeholder="name"

                                onChange={(e) => {
                                  handelOnchangeValue(e);
                                  if (e.target.value.length > 0) {
                                    setValidationError({ ...validationError, guardianDate: false });
                                  } else {
                                    setValidationError({ ...validationError, guardianDate: true });
                                  }
                                }}
                                value={formData.guardianDate}
                              />
                              <br />
                              <span className="text-danger">{validationError.guardianDate === true && 'Field is required.'}</span>
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </div>
                    <div className="mt-4">
                      <b>
                        Signature<span className="text-danger">*</span>
                      </b>
                      <Col sm={12} className="mt-2">
                        <div style={{ overflow: 'hidden' }}>
                          <CanvasDraw {...gurdianSignatureProps} />
                          {/* <span className="text-danger">{validationError.childSignature === true && 'Field is required.'}</span> */}
                          <br />
                          <Button
                            classNames="mt-2"
                            onClick={() => {
                              canvasRefTow.current.clear();
                              setPatientSignature('');
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                        <span className="text-danger">{validationError.guardianSignature === true && 'Field is required.'}</span>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <p className="textHeadline">ISOTRETINOIN SCHEDULE</p>
                <Row>
                  <p className="p-5">
                    I understand that oral isotretinoin is a medication that needs careful monitoring for safety purposes. I have had the
                    risks, benefits and potential side effects linked to oral Isotretanoin explained to me, before I consented to treatment.
                    I have also been given the opportunity to ask any questions prior to starting treatment. I agree to adhere to any
                    schedule of care my clinic doctors advise is in my best interest. I have had the typical schedule of care below given to
                    me. I will contact the clinic if I have a problem. Clinic doctors reserve the right to stop prescribing oral
                    isotretinoin for me at any stage in the course, if I am unable to adhere to any recommendations to my schedule of care.
                  </p>
                </Row>
                <Col>
                  <div className="mt-3 p-3">
                    <Form.Group className="mt-3  d-flex align-items-center">
                      <Row>
                        <Col className="mr-2 mt-2">
                          <b>
                            Patient name: <span className="text-danger">*</span>
                          </b>
                        </Col>
                        <Col>
                          <div>
                            <input
                              className={`from_control  ${validationError.schedulePatientName === true ? 'from_control_invalid' : ''} `}
                              type="text"
                              name="schedulePatientName"
                              disabled
                              // placeholder="name"

                              onChange={(e) => {
                                handelOnchangeValue(e);
                                if (e.target.value.length > 0) {
                                  setValidationError({ ...validationError, schedulePatientName: false });
                                } else {
                                  setValidationError({ ...validationError, schedulePatientName: true });
                                }
                              }}
                              value={formData.patientName}
                            />
                            <br />
                            <span className="text-danger">{validationError.schedulePatientName === true && 'Field is required.'}</span>
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className=" p-3">
                    <Form.Group className="p-0 d-flex align-items-center">
                      <Row>
                        <Col className="mr-2">
                          <b>
                            Date : <span className="text-danger">*</span>
                          </b>
                        </Col>
                        <Col>
                          <div>
                            <input
                              className={`from_control  ${validationError.schedulePatientDate === true ? 'from_control_invalid' : ''} `}
                              type="text"
                              name="CarpalTunnelSurgery"
                              disabled
                              // placeholder="name"

                              onChange={(e) => {
                                handelOnchangeValue(e);
                                if (e.target.value.length > 0) {
                                  setValidationError({ ...validationError, schedulePatientDate: false });
                                } else {
                                  setValidationError({ ...validationError, schedulePatientDate: true });
                                }
                              }}
                              value={formData.schedulePatientDate}
                            />
                            <br />
                            <span className="text-danger">{validationError.schedulePatientDate === true && 'Field is required.'}</span>
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="mt-4 p-3">
                    <b>
                      Signature<span className="text-danger">*</span>
                    </b>
                    <Col sm={12} className="mt-2">
                      <div style={{ overflow: 'hidden' }}>
                        <CanvasDraw {...schedulePatientSignatureProps} />
                        {/* <span className="text-danger">{validationError.childSignature === true && 'Field is required.'}</span> */}
                        <br />
                        <Button
                          classNames="mt-2"
                          onClick={() => {
                            canvasRefThree.current.clear();
                            setPatientSignature('');
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                      <span className="text-danger">{validationError.schedulePatientSignature === true && 'Field is required.'}</span>
                    </Col>
                  </div>
                  <div className=" p-3">
                    <Form.Group className="p-0 d-flex align-items-center">
                      <Row>
                        <Col className="mr-2">
                          <b>
                            Doctor initiating: <span className="text-danger">*</span>
                          </b>
                        </Col>
                        <Col>
                          <div>
                            <input
                              className={`from_control  ${validationError.doctorInitiating === true ? 'from_control_invalid' : ''} `}
                              type="text"
                              name="doctorInitiating"
                              disabled
                              // placeholder="name"

                              onChange={(e) => {
                                handelOnchangeValue(e);
                                if (e.target.value.length > 0) {
                                  setValidationError({ ...validationError, doctorInitiating: false });
                                } else {
                                  setValidationError({ ...validationError, doctorInitiating: true });
                                }
                              }}
                              value={formData.doctorInitiating}
                            />
                            <br />
                            <span className="text-danger">{validationError.doctorInitiating === true && 'Field is required.'}</span>
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </Col>
              </Form.Group>
              <Form.Group>
                <p className="p-4" style={{ fontWeight: 'bold' }}>
                  Typical schedule care (can vary):
                </p>
                <Table responsive striped bordered hover size="md">
                  <thead className="prescription_thead mb-3 ">
                    <tr>
                      <th>Month</th>
                      <th>Consultation</th>
                      <th>Blood Test</th>
                      <th>Script</th>
                      <th>Pregnancy test(if needed)</th>
                      <th>Price*</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Serum bHCG</td>
                      <td>£195 (consult) +(£90 male or £145 female bloods)</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£175 (followup) + £90 (bloods)</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Yes</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£175 (followup)</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£90 (bloods) +£25 (script)</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Yes</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£175 (followup)</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£90 (bloods) + £25 (script)</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Yes</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£175 (followup)</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>-</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£25 (script)</td>
                    </tr>
                  </tbody>
                </Table>
                <span>*price correct at the time of publication but subject to change without notice.</span>
                <p className="p-2 mt-2 ml-4" style={{ fontWeight: 'bold' }}>
                  N.B.
                </p>
                <ul className="ml-5">
                  <li>
                    Blood tests are needed as oral isotretinoin can adversely affect different parts of your body like your liver,
                    cholesterol level or blood count
                  </li>
                  <li>
                    Consultations are an opportunity for your doctors to review your care and monitor your treatment safely. Reviews tend to
                    include your skin, mental health, any side effects you may be experiencing, answer questions you have and dosage
                    changes.
                  </li>
                  <li>Consultations can be conducted online over a video call or a face to face clinic attendance.</li>
                  <li>
                    If you decide to have your blood tests done externally please be aware you will have to be responsible in organising and
                    getting the correct test results to us in a timely manner to avoid delays or disruptions.
                  </li>
                </ul>
              </Form.Group>
              <Form.Group>
                <p className="textHeadline p-2" style={{ backgroundColor: 'blue' }}>
                  PATIENT HEALTH QUESTIONNAIRE-9 (PHQ-9)
                </p>
                <Table responsive hover size="md">
                  <thead className="prescription_thead mb-3 ">
                    <tr>
                      <th style={{ width: '25%', whiteSpace: 'pre-line' }}>
                        Over the last 2 weeks, how often have you been bothered by any of the following problems?(Use “✔” to indicate your
                        answer)
                      </th>
                      <th>Not at all</th>
                      <th>Several days</th>
                      <th style={{ width: '20%', whiteSpace: 'pre-line' }}>More than half the days</th>
                      <th>Nearly every day</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1. Little interest or pleasure in doing things</td>
                      <td>
                        <input type="radio" checked={littleInterest === '0'} value={0} name="data" onChange={handleLittle} />
                      </td>
                      <td>
                        <input type="radio" checked={littleInterest === '1'} value={1} name="data" onChange={handleLittle} />
                      </td>
                      <td>
                        <input type="radio" checked={littleInterest === '2'} value={2} name="data" onChange={handleLittle} />
                      </td>
                      <td>
                        <input type="radio" checked={littleInterest === '3'} value={3} name="data" onChange={handleLittle} />
                      </td>
                      <span className="text-danger">{validationError.littleInterest === true && 'Field is required.'}</span>
                    </tr>
                    <tr>
                      <td>2. Feeling down, depressed, or hopeless</td>
                      <td>
                        <input type="radio" checked={feelingDown === '0'} value={0} name="data1" onClick={handleFeelingDown} />
                      </td>
                      <td>
                        <input type="radio" checked={feelingDown === '1'} value={1} name="data1" onClick={handleFeelingDown} />
                      </td>
                      <td>
                        <input type="radio" checked={feelingDown === '2'} value={2} name="data1" onClick={handleFeelingDown} />
                      </td>
                      <td>
                        <input type="radio" checked={feelingDown === '3'} value={3} name="data1" onClick={handleFeelingDown} />
                      </td>
                      <span className="text-danger">{validationError.feelingDown === true && 'Field is required.'}</span>
                    </tr>
                    <tr>
                      <td>3. Trouble falling or staying asleep, or sleeping too much</td>
                      <td>
                        <input type="radio" checked={troubleFalling === '0'} value={0} name="data3" onClick={handleTroubleFalling} />
                      </td>
                      <td>
                        <input type="radio" checked={troubleFalling === '1'} value={1} name="data3" onClick={handleTroubleFalling} />
                      </td>
                      <td>
                        <input type="radio" checked={troubleFalling === '2'} value={2} name="data3" onClick={handleTroubleFalling} />
                      </td>
                      <td>
                        <input type="radio" checked={troubleFalling === '3'} value={3} name="data3" onClick={handleTroubleFalling} />
                      </td>
                      <span className="text-danger">{validationError.troubleFalling === true && 'Field is required.'}</span>
                    </tr>
                    <tr>
                      <td>4. Feeling tired or having little energy </td>
                      <td>
                        <input type="radio" checked={feelingTired === '0'} value={0} name="data4" onClick={handleFeelingTired} />
                      </td>
                      <td>
                        <input type="radio" checked={feelingTired === '1'} value={1} name="data4" onClick={handleFeelingTired} />
                      </td>
                      <td>
                        <input type="radio" checked={feelingTired === '2'} value={2} name="data4" onClick={handleFeelingTired} />
                      </td>
                      <td>
                        <input type="radio" checked={feelingTired === '3'} value={3} name="data4" onClick={handleFeelingTired} />
                      </td>
                      <span className="text-danger">{validationError.feelingTired === true && 'Field is required.'}</span>
                    </tr>
                    <tr>
                      <td>5. Poor appetite or overeating</td>
                      <td>
                        <input type="radio" checked={poorAppetite === '0'} value={0} name="data5" onClick={handlePoorAppetite} />
                      </td>
                      <td>
                        <input type="radio" checked={poorAppetite === '1'} value={1} name="data5" onClick={handlePoorAppetite} />
                      </td>
                      <td>
                        <input type="radio" checked={poorAppetite === '2'} value={2} name="data5" onClick={handlePoorAppetite} />
                      </td>
                      <td>
                        <input type="radio" checked={poorAppetite === '3'} value={3} name="data5" onClick={handlePoorAppetite} />
                      </td>
                      <span className="text-danger">{validationError.poorAppetite === true && 'Field is required.'}</span>
                    </tr>
                    <tr>
                      <td style={{ width: '25%', whiteSpace: 'pre-line' }}>
                        6. Feeling bad about yourself — or that you are a failure or have let yourself or your family down
                      </td>
                      <td>
                        <input type="radio" checked={feelingBad === '0'} value={0} name="data6" onClick={handleFeelingBad} />
                      </td>
                      <td>
                        <input type="radio" checked={feelingBad === '1'} value={1} name="data6" onClick={handleFeelingBad} />
                      </td>
                      <td>
                        <input type="radio" checked={feelingBad === '2'} value={2} name="data6" onClick={handleFeelingBad} />
                      </td>
                      <td>
                        <input type="radio" checked={feelingBad === '3'} value={3} name="data6" onClick={handleFeelingBad} />
                      </td>
                      <span className="text-danger">{validationError.feelingBad === true && 'Field is required.'}</span>
                    </tr>
                    <tr>
                      <td style={{ width: '25%', whiteSpace: 'pre-line' }}>
                        7. Trouble concentrating on things, such as reading the newspaper or watching television
                      </td>
                      <td>
                        <input
                          type="radio"
                          checked={troubleConcentrating === '0'}
                          value={0}
                          name="data7"
                          onClick={handleTroubleConcentrating}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          checked={troubleConcentrating === '1'}
                          value={1}
                          name="data7"
                          onClick={handleTroubleConcentrating}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          checked={troubleConcentrating === '2'}
                          value={2}
                          name="data7"
                          onClick={handleTroubleConcentrating}
                        />
                      </td>
                      <td>
                        <input
                          type="radio"
                          checked={troubleConcentrating === '3'}
                          value={3}
                          name="data7"
                          onClick={handleTroubleConcentrating}
                        />
                      </td>
                      <span className="text-danger">{validationError.troubleConcentrating === true && 'Field is required.'}</span>
                    </tr>
                    <tr>
                      <td style={{ width: '25%', whiteSpace: 'pre-line' }}>
                        8. Moving or speaking so slowly that other people could have noticed? Or the opposite — being so fidgety or restless
                        that you have been moving around a lot more than usual
                      </td>
                      <td>
                        <input type="radio" checked={movingOrSpeaking === '0'} value={0} name="data8" onClick={handleMovingOrSpeaking} />
                      </td>
                      <td>
                        <input type="radio" checked={movingOrSpeaking === '1'} value={1} name="data8" onClick={handleMovingOrSpeaking} />
                      </td>
                      <td>
                        <input type="radio" checked={movingOrSpeaking === '2'} value={2} name="data8" onClick={handleMovingOrSpeaking} />
                      </td>
                      <td>
                        <input type="radio" checked={movingOrSpeaking === '3'} value={3} name="data8" onClick={handleMovingOrSpeaking} />
                      </td>
                      <span className="text-danger">{validationError.movingOrSpeaking === true && 'Field is required.'}</span>
                    </tr>
                    <tr>
                      <td style={{ width: '25%', whiteSpace: 'pre-line' }}>
                        9. Thoughts that you would be better off dead or of hurting yourself in some way
                      </td>
                      <td>
                        <input type="radio" checked={thoughts === '0'} value={0} name="data9" onClick={handleSetThoughts} />
                      </td>
                      <td>
                        <input type="radio" checked={thoughts === '1'} value={1} name="data9" onClick={handleSetThoughts} />
                      </td>
                      <td>
                        <input type="radio" checked={thoughts === '2'} value={2} name="data9" onClick={handleSetThoughts} />
                      </td>
                      <td>
                        <input type="radio" checked={thoughts === '3'} value={3} name="data9" onClick={handleSetThoughts} />
                      </td>
                      <span className="text-danger">{validationError.thoughts === true && 'Field is required.'}</span>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Form.Group>
                <p style={{ marginLeft: '70%', fontWeight: 'bold', fontStyle: 'italic' }}>
                  FOR OFFICE CODING : <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{stat.text}</span>
                </p>
                <p style={{ marginLeft: '70%', fontWeight: 'bold', fontStyle: 'italic' }}>
                  = Total Score: <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{stat.total}</span>
                </p>
              </Form.Group>

              <hr className="mt-4" />
              <Form.Group>
                <p>
                  If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at
                  home, or get along with other people?
                </p>
                <Row className="p-4">
                  <Col>
                    <Row>
                      <p className="mr-2 mt-2">Not difficult at all</p>
                      <input
                        style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}
                        type="radio"
                        value={1}
                        checked={difficult === '1'}
                        name="data10"
                        onChange={handleProblem}
                      />
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <p className="mr-2 mt-2">Somewhat difficult</p>
                      <input type="radio" checked={difficult === '2'} value={2} name="data10" onChange={handleProblem} />
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <p className="mr-2 mt-2">Very difficult</p>
                      <input type="radio" checked={difficult === '3'} value={3} name="data10" onChange={handleProblem} />
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <p className="mr-2 mt-2">Extremely difficult</p>
                      <input type="radio" checked={difficult === '4'} value={4} name="data10" onChange={handleProblem} />
                    </Row>
                  </Col>
                  <span className="text-danger">{validationError.difficult === true && 'Field is required.'}</span>
                </Row>
              </Form.Group>
              <Form.Group>
                <p className="text-center form_heading  mb-5">Acne Skincare Routine</p>
                <h3>Formulation</h3>
                <p>
                  Acne is a condition that is influenced by many factors such as inflammation and the oil produced by your skin. Here at the
                  London Dermatology Clinic it's important for us to offer you a holistic package to maintain good skin care. Please
                  consider incorporating the following winning skin routines as recommended by our dermatologists.
                </p>
                <p style={{ textAlign: 'center', fontSize: '20px' }}>Items are available to purchase without a prescription:</p>
                <p>1. Eucerin DermoPurifyer Oil Control Cleansing Gel: used to wash face in the morning and evening.</p>
                <p>2. La Roche-Posay Effaclar H Moisturising Cream: apply in the morning and evening after washing your face.</p>
                <p>
                  3. La Roche-Posay Anthelios Ultra-Light Fluid Sun Cream SPF50+: apply in the morning to protect your skin from the sun.
                </p>
                <p>3. Eucerin Dry Skin Intensive Lip Balm: for relief from dry lips.</p>
                <h3>Alternatives</h3>
                <p>
                  If the above items are not available or in the unlikely event your skin struggles with them then you can consider trying
                  Cetaphil Gentle Skin Cleanser as your facial wash and Avène Cleanance Mattifying Emulsion as your moisturiser.
                </p>
                <h4 style={{ fontWeight: 'bold' }}>ISOTRETINOIN - MALE</h4>
                <p style={{ fontStyle: 'italic' }}>
                  Please note that this information leaflet is for men, there is a separate information leaflet for women.
                </p>
                <p style={{ fontSize: '18px' }}>What are the aims of this leaflet?</p>
                <p>
                  This leaflet has been written to help you understand more about isotretinoin. It tells you what it is, how it works, how
                  it is used to treat skin conditions, and where you can find out more about it
                </p>
                <p style={{ fontSize: '18px' }}>What is isotretinoin and how does it work?</p>
                <p>
                  Isotretinoin is a member of a group of drugs, closely related to vitamin A, called retinoids. Isotretinoin is the generic
                  name of a drug marketed by a number of companies such as Beacon and Ranbaxy, but the original brand name was Roaccutane,
                  marketed by Roche. It works in a variety of ways, targeting several of the factors that cause acne and other skin
                  conditions including production of sebum (an oily substance produced by the skin) and the bacteria that contribute to
                  acne.
                </p>
                <p style={{ fontSize: '18px' }}>What skin conditions are treated with isotretinoin?</p>
                <p>
                  Isotretinoin is licensed and most commonly used to treat severe acne, often where there is scarring and pigmentation. Your
                  dermatologist may occasionally use isotretinoin to treat other skin conditions such as hidradenitis suppurativa and
                  rosacea. In the United Kingdom isotretinoin may only be prescribed if you are under the care of a consultant
                  dermatologist.
                </p>
                <p style={{ fontSize: '18px' }}>Will isotretinoin cure my acne?</p>
                <p>
                  A large proportion of patients (about 90%) see a significant improvement in their acne with a single 16 to 24 week course
                  of isotretinoin, although during 4 Fitzroy Square, London W1T 5HQ Tel: 020 7383 0266 Fax: 020 7388 5263 e-mail:
                  admin@bad.org.uk Registered Charity No. 258474 the first few weeks of treatment the acne may worsen before it starts to
                  improve. A small number of patients continue to have milder (although improved) acne following isotretinoin that can be
                  controlled with conventional therapies, such as antibiotics. Others may relapse after stopping treatment with
                  isotretinoin, and occasionally, a prolonged or second course of treatment is required.
                </p>
                <p style={{ fontSize: '18px' }}>What dose should I take and for how long?</p>
                <p>
                  Your dermatologist will calculate the amount of isotretinoin you need according to your body weight and decide on an
                  appropriate starting dose. At future appointments the dose of isotretinoin may be changed depending on how well you are
                  tolerating and responding to the medication. Most patients take between 20mg and 80mg of isotretinoin each day, and a
                  course commonly lasts between 16 and 24 weeks. With doses in the lower part of this range, which are often better
                  tolerated, a course may last much longer than 24 weeks. Your acne may continue to improve for up to 8 weeks after
                  treatment.
                </p>
                <p style={{ fontSize: '18px' }}>How should I take isotretinoin?</p>
                <p>
                  As isotretinoin is best absorbed into the body with food containing some dietary fat, it should ideally be taken after a
                  meal or a snack with milk rather than on an empty stomach. The capsules need to be swallowed whole and cannot be crushed
                  or split open. Keep the capsules in a cool (5 to 25∞C) dark place away from children.
                </p>
                <p style={{ fontSize: '18px' }}>What are the common side effects of isotretinoin?</p>
                <p>
                  In general the side effects of isotretinoin are mild and settle later in the course of treatment. Dryness of the skin,
                  lips, eyes and throat is common. An increased risk of skin infections accompanies the skin becoming dry and cracked, and
                  nosebleeds may occur if the inside of the nose becomes very dry. Using a moisturiser and lip balm regularly will help to
                  prevent these symptoms. Dry eyes may interfere with the wearing of contact lenses. The skin may also peel and become
                  fragile, with wounds taking longer to heal. Whilst taking isotretinoin, and for six months afterwards, your skin will be
                  more delicate than usual; waxing, epilation, dermabrasion and laser treatment should be avoided. Shaving is normally
                  tolerated, but use of a moisturiser afterwards is advisable.
                </p>
                <p>
                  There is an increased risk of sunburn and you should use a sunscreen when appropriate. Muscles and joints may ache
                  especially after exercise.
                </p>
                <p>
                  Temporary hair thinning may occasionally occur. Isotretinoin can affect night vision and it should be used with care in
                  people whose job requires good night vision, such as airline pilots and drivers.
                </p>
                <p>
                  Increased fat levels in the blood, and mild liver inflammation, are common and not usually of clinical significance; these
                  will be monitored by blood tests during the course of treatment. If you have had problems with your liver or kidneys, or
                  suffer from high cholesterol or diabetes, you should discuss this with your doctor prior to starting the medication.
                </p>
                <p>
                  Some brands of isotretinoin contain soya (e.g. Beacon brand) and peanut oil (e.g. Roaccutane (Roche brand)), and you
                  should inform your doctor if you think you may have an allergy to these.
                </p>
                <p style={{ fontSize: '18px' }}>What are the rare side effects of isotretinoin?</p>
                <p>
                  A number of more serious side effects may occur although these are fortunately rare. There have been reports of
                  isotretinoin leading to mood change although the risk of this is small. Rates of depression in acne patients range from 1
                  to 11% in different trials, and are the same whether patients are taking antibiotics or isotretinoin. However, if you have
                  any prior history of depression, or other mental illness, please discuss this with your doctor before starting treatment.
                  If you or your relatives feel that your mood is changing whilst taking isotretinoin, please inform your doctor.
                </p>
                <p>
                  Rarely, inflammation of the liver or pancreas may be seen. Very rarely, increased pressure in the brain may present with
                  morning headaches and disturbance of vision.
                </p>
                <p>
                  If you do suffer from a side effect then stopping or reducing the dose of isotretinoin may resolve the problem. Please
                  talk to your doctor or nurse before making any changes to your medication.
                </p>
                <p style={{ fontSize: '18px' }}>May I drink alcohol whilst taking isotretinoin?</p>
                <p>
                  Ideally avoid alcohol completely, although small amounts of alcohol (up to 1 unit per day) are unlikely to cause any harm.
                </p>
                <p style={{ fontSize: '18px' }}>Can I take other medications at the same time as isotretinoin?</p>
                <p>
                  Most drugs can be taken safely with isotretinoin but some medications may interact. It is important that you tell your
                  doctor and pharmacist what you are currently taking before taking any new prescription or over-the-counter medications.
                </p>
                <p>Drugs that adversely interact with isotretinoin include:</p>
                <ul>
                  <li>Tetracycline antibiotics (only if taken at the same time as isotretinoin)</li>
                  <li>Methotrexate</li>
                </ul>
                <p>
                  This is not an exhaustive list and it is important that you always inform your doctor and pharmacist that you are taking
                  isotretinoin.
                </p>
                <p>Vitamin supplements containing vitamin A should be avoided during a course of isotretinoin.</p>
                <p style={{ fontSize: '18px' }}>Are they any other precautions whilst taking isotretinoin?</p>
                <p>
                  You must never share your tablets, especially with women. Do not donate blood whilst taking isotretinoin in case the blood
                  is given to a pregnant woman. If a pregnant woman is exposed to isotretinoin there is a high risk that the unborn baby
                  will be harmed, leading to severe and serious defects (such as abnormal appearance or mental handicap), or miscarriage.
                </p>
                <p>
                  There has been no known adverse effect on the pregnancy if a man taking isotretinoin fathers a child. However, as
                  isotretinoin is present in semen, it may be a sensible precaution to use a condom to avoid transmission of any of the drug
                  to females.
                </p>
                <p style={{ fontSize: '18px' }}>Where can I find out more about isotretinoin?</p>
                <p>
                  If you would like any further information about isotretinoin, or if you have any concerns about your treatment, you should
                  discuss this with your doctor or pharmacist. This information sheet does not list all the side effects this drug can
                  cause. For full details, please see the drug information leaflet that comes with your medicine. Your doctor will assess
                  your medical circumstances and draw your attention to side effects that may be relevant in your particular case.
                </p>
                <p style={{ fontStyle: 'italic' }}>Web links to detailed leaflets:</p>
                <p style={{ fontStyle: 'italic' }}>European Dermatology Forum Guidelines on Acne</p>
                <p style={{ fontWeight: 'bold' }}>
                  This leaflet aims to provide accurate information about the subject and is a consensus of the views held by
                  representatives of the British Association of Dermatologists: its contents, however, may occasionally differ from the
                  advice given to you by your doctor.
                </p>
                <p>
                  This leaflet has been assessed for readability by the British Association of Dermatologists’ Patient Information Lay
                  Review Panel
                </p>
                <p>BRITISH ASSOCIATION OF DERMATOLOGISTS</p>
                <p>PATIENT INFORMATION LEAFLET</p>
                <p>PRODUCED NOVEMBER 2011</p>
                <p>REVIEW DATE NOVEMBER 2014</p>
              </Form.Group>
              <div className="d-flex my-4    ">
                <button type="submit" disabled={btnSubmit} className="btn px-4 btn-outline-primary">
                  {btnSubmit ? 'Loading....' : 'Submit'}
                </button>
                {/* <CustomButtons type="submit " buttonDisabled={btnSubmit} variant="primary" className="outline- px-4" name="Save" /> */}
              </div>
            </Form>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default RoaccPackMaleMessage;
