import React, { useRef, useState } from 'react';
import ProfileBar from '../ProfileBar';
import { Button, Modal, Container, Card, Form, Row, Col, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import classNames from 'classnames';
import { useToasts } from 'react-toast-notifications';
import CanvasDraw from 'react-canvas-draw';
import { useEffect } from 'react';
import LdcApi from '../../../../LdcApis';
import { BsArrowLeft } from 'react-icons/bs';

const EditMethotrexate = () => {
  let patientDetailsData = JSON.parse(localStorage.getItem('patientDetailsData'));
  let userInfo = JSON.parse(localStorage.getItem('user_info'));

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mouseDisable, setMouseDisable] = useState('auto');

  const { id, viewId } = useParams();

  const navigate = useNavigate();
  const hideForms = () => {
    navigate('/confirmation');
  };

  useEffect(() => {
    setLoading(true);
    LdcApi({
      method: 'GET',
      url: `Methotrexate/GetViewMethotrexate?Id=${viewId}`
    })
      .then((response) => {
        if (response.data.length > 0) {
          setLoading(false);
          setRowData(response?.data[0]);
        } else {
          navigate('/diary');
        }
      })
      .catch((error) => {
        setLoading(false);
        navigate('/diary');
      });
  }, []);

  return (
    <div>
      <ProfileBar />
      <Container style={{ pointerEvents: { mouseDisable } }}>
        <div className="d-flex my-4 justify-content-end">
          <Button onClick={() => hideForms()} variant="outline-primary mb-3">
            Go to diary
          </Button>
        </div>
        <Card>
          <p className="text-center form_heading  mb-5">METHOTREXATE SCHEDULE</p>
          <p className="p-4">
            I understand that oral Methorexate is a medication that needs careful monitoring for safety purposes. I have had the risks,
            benefits and potential side effects linked to oral Methotrexate explained to me, before I consented to treatment. I have also
            been given the opportunity to ask any questions prior to starting treatment. I agree to adhere to any schedule of care my clinic
            doctors advise is in my best interest. I have had the typical schedule of care below given to me. I will contact the clinic if I
            have a problem. Clinic doctors reserve the right to stop prescribing oral Methotrexate for me at any stage in the course, if I
            am unable to adhere to any recommendations to my schedule of care.
          </p>
          <div className="p-1">
            <Form className="p-4">
              <Form.Group>
                <Row className="p-4">
                  <b>
                    Patient name : <span className="text-danger mr-2">*</span>
                  </b>

                  <div>
                    <input
                      className={`from_control  `}
                      type="text"
                      name="name"
                      disabled
                      // placeholder="name"
                      //   onChange={(e) => {
                      //     handelOnchangeValue(e);
                      //     if (e.target.value.length > 0) {
                      //       setValidationError({ ...validationError, patientName: false });
                      //     } else {
                      //       setValidationError({ ...validationError, patientName: true });
                      //     }
                      //   }}
                      value={rowData.patientName}
                    />
                    <br />
                  </div>
                </Row>
                <Row>
                  <b className="p-4">
                    Date: <span className="text-danger">*</span>
                  </b>

                  <div>
                    <input
                      className={`from_control `}
                      type="text"
                      name="doctorDate"
                      disabled
                      // placeholder="name"

                      //   onChange={(e) => {
                      //     handelOnchangeValue(e);
                      //     if (e.target.value.length > 0) {
                      //       setValidationError({ ...validationError, doctorDate: false });
                      //     } else {
                      //       setValidationError({ ...validationError, doctorDate: true });
                      //     }
                      //   }}
                      value={moment(rowData.date).format('DD/MM/YY')}
                    />
                    <br />
                  </div>
                </Row>
                <Row className="mx-2">
                  <b className="">
                    Signature : <span className="text-danger">*</span>
                  </b>

                  <Col sm={10} className="">
                    <div className="view_sigPadContainer p-2 my-3 ">
                      <img src={rowData?.patientSignature} className="signature" />
                    </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <b className="p-3">
                    Doctor initiating: <span className="text-danger">*</span>
                  </b>

                  <Col>
                    <div>
                      <input
                        className={`from_control `}
                        type="text"
                        name="doctorInitiating"
                        disabled
                        // placeholder="name"

                        // onChange={(e) => {
                        //   handelOnchangeValue(e);
                        //   if (e.target.value.length > 0) {
                        //     setValidationError({ ...validationError, doctorInitiating: false });
                        //   } else {
                        //     setValidationError({ ...validationError, doctorInitiating: true });
                        //   }
                        // }}
                        value={rowData.doctorInitiating}
                      />
                      <br />
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Row className="p-2">
                <p style={{ fontWeight: 'bold' }} className="mt-5">
                  Typical schedule care (can vary):{' '}
                </p>
              </Row>
              <Row className="p-2">
                <p style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Before starting treatment:</p>
              </Row>
              <Row>
                <p className="p-1 ml-4">1. Initial baseline blood tests: FBC, U&Es, LFTs, Type III procollagen (PIIINP).</p> <br />
              </Row>
              <Row>
                <p className="p-1 ml-4">2. Chest X-ray.</p>
              </Row>
              <Row>
                <p className="p-2">
                  <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>After starting treatment:</span> the following blood
                  tests are needed every 2 weeks until on a stable dose for 6 weeks, then once on stable dose, to be monitored monthly for 3
                  months, thereafter every 12 weeks;
                </p>
                <p className="p-4">
                  1. FBC (full blood count),
                  <br />
                  2. U&Es (urea and electrolytes including serum creatinine and eGFR)
                  <br />
                  3. LFTs (including ALT or AST and albumin)
                </p>
                <p className="p-2">
                  Initial bloods cost £250*. Each subsequent monitoring blood test costs £90* unless PIIINP is repeated in which case an
                  additional fee of £160 is payable. The above schedule frequency may change if abnormal results are found or after a
                  treatment dose change.
                </p>
                <p className="p-2" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                  Follow-up Consultations
                </p>
                <p className="p-2">
                  A review will be required by booking a follow-up consultation every 6 to 8 weeks and once the dose of the medication and
                  your skin condition is stable then it will be reduced to once every 4 months.
                </p>
                <p className="p-2">Each follow-up consultation costs £175*.</p> <br />
              </Row>
              <Row>
                <p style={{ fontStyle: 'italic' }} className="p-2">
                  (Side Effects section on next page)
                </p>
              </Row>
              <Row>
                <p className="p-2" style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                  Side Effects
                </p>
              </Row>
              <Row>
                <p className="p-2">The following is a list of some common side effects which can be expected.</p>
                <ul>
                  <li>Tiredness</li>
                  <li>Headaches</li>
                  <li>Hair loss</li>
                  <li>Diarrhoea</li>
                  <li>Other common side effects are loss of appetite, nausea, vomitings, stomach upset, and indigestion</li>
                </ul>
              </Row>
              <Row>
                <p className="p-2" style={{ fontStyle: 'italic' }}>
                  Serious side effects, albeit rare, are a reason to seek prompt medical review and include:
                </p>
                <ul>
                  <li>Jaundice - yellowing of the eyes and skin which can indicate a problem with the liver</li>
                  <li>Respiratory symptoms - feeling breathless or experiencing a persistent cough</li>
                  <li>Swelling of hands and feet or change in urinary frequency</li>
                  <li>Severe sore throat, easy bleeding or abnormal bruising - urgent blood test required</li>
                  <li>Persistent fever - signs of an infection or complication of methotrexate treatment</li>
                  <li>
                    Severe skin rash or blisters on your skin, mouth, eyes or genitals – these may be signs of Stevens-Johnson syndrome
                  </li>
                </ul>
              </Row>
              <Row>
                <p className="p-2" style={{ fontWeight: 'bold' }}>
                  N.B.{' '}
                </p>
                <ul>
                  <li>Blood tests are needed as oral Methotrexate can adversely affect your kidney and liver.</li>
                  <li>
                    Consultations are an opportunity for your doctors to review your care and monitor your treatment safely. Reviews tend to
                    include your skin, any side effects you may be experiencing, answer questions you have and dosage changes.
                  </li>
                  <li>Consultations can be conducted online over a video call or a face to face clinic attendance. </li>
                  <li>
                    If you decide to have your blood tests done externally please be aware you will have to be responsible in organising and
                    getting the correct test results to us in a timely manner to avoid delays or disruptions.
                  </li>
                </ul>
              </Row>
              <p style={{ fontStyle: 'italic' }}>*Price correct at the time of publication but subject to change without notice.</p>
            </Form>
          </div>
        </Card>
        {userInfo.roleID == 10 ? (
          <Button
            className="px-3"
            onClick={() => {
              localStorage.setItem('patientDetailsData', JSON.stringify({ ...patientDetailsData, tab: 0 }));

              navigate(`/profile/${id}`);
            }}
          >
            <BsArrowLeft /> Back
          </Button>
        ) : (
          <Button
            className="px-3"
            onClick={() => {
              localStorage.setItem('patientDetailsData', JSON.stringify({ ...patientDetailsData, tab: 7 }));

              navigate(`/profile/${id}`);
            }}
          >
            <BsArrowLeft /> Back
          </Button>
        )}
      </Container>
    </div>
  );
};

export default EditMethotrexate;
