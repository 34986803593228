import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
  Card,
  Table,
  Container,
  Modal,
} from 'react-bootstrap';
import QuestionTable from '../tables/QuestionTable';
import CustomButtons from '../ui-elements/Buttons/CustomButtons';
import CustomDangerButton from '../ui-elements/Buttons/CustomDangerButton';
import CustomInput from '../ui-elements/input/CustomInput';
import { Formik } from 'formik';
import * as yup from 'yup';

import { useLocation, useNavigate, useParams } from 'react-router';
import SignatureCanvas from 'react-signature-canvas';
import LdcApi from '../../LdcApis';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import ConsentFormsNavbar from '../../components/common/ConsentFormsNavbar';
import Patients from '../../modules/Patients';
import ProfileBar from '../pages/consentforms/ProfileBar';
import { Link } from 'react-router-dom';
import { isValid } from 'namor';

import CanvasDraw from 'react-canvas-draw';
import classNames from 'classnames';
import termsandcondition from './../../../src/assets/PDF/Terms_&_Conditions_(LDC).pdf';

export default function HealthQuestion() {
  const { id, appId } = useParams();

  const { addToast } = useToasts();
  const location = useLocation();

  useEffect(() => {
    // window.scrollTo(0, 0);
    document.title = 'Medical Questionnaire - LDC';
  });

  const navigate = useNavigate();
  const sigCanvas = useRef();
  const [hideForm, setHideForm] = useState(false);
  const [pin, setPin] = useState('');
  const [penColor, setPenColor] = useState('black');
  const [patientAptData, setPatientAptData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [signature, setSignature] = useState('');
  const [dateFormData, dateSetFormData] = useState({
    day: '',
    month: '',
    year: '',
  });
  const [dateValidation, dateSetValidation] = useState({
    day: '',
    month: '',
    year: '',
  });
  const [brushColor, setBrushColor] = React.useState('#000');
  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    addressLine: '',
    City: '',
    postCode: '',
    contactNo: '',
    email: '',
    GPNameAddress: '',
    gender: '',
    ifYesProvideDetails: '',
    ifSoPleaseListThem: '',
    allergiesList: '',
    listMedication: '',
    treatingYou: '',
  });
  let appointmentData = JSON.parse(localStorage.getItem('appointmentData'));

  const getProfileData = (patientdata) => {};

  useEffect(() => {
    if (location.state == null) {
      navigate(`/health-QuestionnaireOTP/${id}/appId/${appId}`);
    }
    setFormData({
      ...formData,
      firstName: location?.state?.firstName,
      lastName: location?.state?.lastName,
      dateOfBirth:
        location?.state?.dateOfBirth === null ||
        location?.state?.dateOfBirth === ''
          ? null
          : moment(location?.state?.dateOfBirth).format('YYYY-MM-DD'),
      addressLine: location?.state?.address,
      City: location?.state?.city,
      postCode: location?.state?.postCode,
      contactNo: location?.state?.mobile,
      email: location?.state?.email,
      GPNameAddress: location?.state?.homeDoctorName,
      gender: location?.state?.gender,
    });

    dateSetFormData({
      day:
        location?.state?.dateOfBirth === null
          ? ''
          : moment(location?.state?.dateOfBirth).format('DD'),
      month:
        location?.state?.dateOfBirth === null
          ? ''
          : moment(location?.state?.dateOfBirth).format('MM'),
      year:
        location?.state?.dateOfBirth === null
          ? ''
          : moment(location?.state?.dateOfBirth).format('YYYY'),
    });
  }, [id]);

  const initialValues = {
    ifYesProvideDetails: '',
    ifSoPleaseListThem: '',
    allergiesList: '',
    listMedication: '',
    treatingYou: '',
  };

  const validationSchema = yup.object().shape({
    ifYesProvideDetails: yup.string().optional('Field is optional.'),
    ifSoPleaseListThem: yup.string().optional('Field is optional.'),
    allergiesList: yup.string().optional('Field is optional.'),
    listMedication: yup.string().optional('Field is required.'),
    treatingYou: yup.string().optional('Field is required.'),
  });

  const [btnSubmit, setBtnSubmit] = useState(false);
  const [mouseDisable, setMouseDisable] = useState('auto');

  const [validationError, setValidationError] = useState({
    healthProblems: false,
    diabetesProblems: false,
    hivAids: false,
    hasChemotherapy: false,
    previousSurgeries: false,
    havePacemaker: false,
    clinicToContact: false,
    undersigned: false,
    // asdasd
    ifYesProvideDetails: false,
    ifSoPleaseListThem: false,
    allergiesList: false,
    listMedication: false,
    treatingYou: false,
    email: false,
    firstName: false,
    lastName: false,
    signature: false,
    gender: false,
    addressLine: false,
    dateOfBirth: false,
  });

  const [healthProblems, setHealthProblems] = useState(null);
  const healthProblemsChanges = (e) => {
    setHealthProblems(JSON.parse(e));
    setValidationError({ ...validationError, healthProblems: false });
  };

  const [diabetesProblems, setDiabetesProblems] = useState(null);
  const diabetesProblemsChanges = (e) => {
    setDiabetesProblems(JSON.parse(e));
    setValidationError({ ...validationError, diabetesProblems: false });
  };

  const [hivAids, setHivAids] = useState(null);
  const hivAidsChanges = (e) => {
    setHivAids(JSON.parse(e));
    setValidationError({ ...validationError, hivAids: false });
  };

  const [hasChemotherapy, setHasChemotherapy] = useState(null);
  const hasChemotherapyChanges = (e) => {
    setHasChemotherapy(JSON.parse(e));
    setValidationError({ ...validationError, hasChemotherapy: false });
  };

  const [previousSurgeries, setPreviousSurgeries] = useState(null);
  const previousSurgeriesChanges = (e) => {
    setPreviousSurgeries(JSON.parse(e));
    setValidationError({ ...validationError, previousSurgeries: false });
  };

  const [havePacemaker, setHavePacemaker] = useState(null);
  const havePacemakerChanges = (e) => {
    setHavePacemaker(JSON.parse(e));
    setValidationError({ ...validationError, havePacemaker: false });
  };

  const [clinicToContact, setClinicToContact] = useState(null);
  const clinicToContactChanges = (e) => {
    setClinicToContact(JSON.parse(e));
    setValidationError({ ...validationError, clinicToContact: false });
  };

  const [undersigned, setUndersigned] = useState(null);
  const undersignedChanges = (e) => {
    setUndersigned(JSON.parse(e));
    setValidationError({ ...validationError, undersigned: false });
  };
  const pinChange = (e) => {
    setPin(e.target.value);
  };

  const [validation, setValidation] = useState({
    gender: false,
    dateOfBirth: false,
  });
  const [healthProblemsValidaton, sethealthProblemsValidaton] = useState(false);

  const handelProfileChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === 'postCode'
          ? e.target.value.toUpperCase()
          : e.target.value,
    });
  };

  // signechure
  const canvasRef = useRef(null);

  const defaultProps = {
    loadTimeOffset: 5,
    lazyRadius: 0,
    brushRadius: 2,
    catenaryColor: '#0a0302',
    gridColor: 'rgba(150,150,150,0.17)',
    hideGrid: true,
    // canvasWidth: 1000,
    canvasHeight: 300,
    disabled: false,
    imgSrc: '',
    saveData: '',
    immediateLoading: false,
    hideInterface: false,
  };
  const getImg = () =>
    canvasRef.current.canvasContainer.children[1].toDataURL();
  const handleCanvasChange = () => {
    const saveData = getImg();
    setSignature(saveData);
    if (signature == '') {
      setValidationError({ ...validationError, signature: true });
    } else {
      setValidationError({ ...validationError, signature: false });
    }
  };
  const props = {
    ...defaultProps,
    onChange: handleCanvasChange,
    ref: canvasRef,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor,
  };

  const validDate = (dayInt, monthInt, yearInt) => {
    const currentYear = new Date().getFullYear();
    // if (!day || !month || !year) {
    //   return false;
    // }

    // Convert the inputs to integers
    // const dayInt = parseInt(day, 10);
    // const monthInt = parseInt(month, 10);
    // const yearInt = parseInt(year, 10);

    // Basic checks for valid numbers
    if (isNaN(dayInt) || isNaN(monthInt) || isNaN(yearInt)) {
      return false;
    }

    // Check if the year is valid (you can set your own year range here)
    if (yearInt < 1900 || yearInt > parseInt(currentYear, 10)) {
      return false;
    }

    // Check if the month is valid (between 1 and 12)
    if (monthInt < 1 || monthInt > 12) {
      return false;
    }

    // Check if the day is valid according to the month
    const daysInMonth = new Date(yearInt, monthInt, 0).getDate();
    if (dayInt < 1 || dayInt > daysInMonth) {
      return false;
    }
    return true;
  };

  const updateQuestion = (e) => {
    const dayInt = parseInt(dateFormData.day, 10);
    const monthInt = parseInt(dateFormData.month, 10);
    const yearInt = parseInt(dateFormData.year, 10);

    setBtnSubmit(true);
    e.preventDefault();
    let isValid = false;
    const formValidationAllError = {
      healthProblems: false,
      diabetesProblems: false,
      hivAids: false,

      hasChemotherapy: false,
      previousSurgeries: false,
      havePacemaker: false,
      clinicToContact: false,
      undersigned: false,
      // asdasd
      ifYesProvideDetails: false,
      ifSoPleaseListThem: false,
      allergiesList: false,
      listMedication: false,
      treatingYou: false,
      email: false,
      firstName: false,
      lastName: false,
      gender: false,
      addressLine: false,
      dateOfBirth: false,
      signature: false,
    };

    if (formData.gender === '' || formData.gender == null) {
      formValidationAllError.gender = true;
      isValid = false;
    } else {
      isValid = true;
    }

    if (formData.addressLine === '' || formData.addressLine == null) {
      formValidationAllError.addressLine = true;
      isValid = false;
    } else {
      isValid = true;
    }

    if (
      dateFormData.day === '' ||
      dateFormData.month == '' ||
      dateFormData.year === ''
    ) {
      formValidationAllError.dateOfBirth = true;
      isValid = false;
    } else if (!validDate(dayInt, monthInt, yearInt)) {
      addToast(`Date of Birth Year and Date is not correct  `, {
        appearance: 'warning',
      });
      formValidationAllError.dateOfBirth = true;
      isValid = false;
    } else {
      const dateOfBirth = `${dateFormData.year}-${dateFormData.month}-${dateFormData.day}`; //YYYY/MM/DD
      const da = new Date(dateOfBirth).toDateString();

      if (
        // new Date(dateOfBirth) === 'Invalid date' ||
        // dateValidation.year !== '' ||
        // da == undefined ||
        // da == 'Invalid Date' ||
        dateFormData.year === '0000'
      ) {
        formValidationAllError.dateOfBirth = true;
        isValid = false;
      } else {
        isValid = true;
      }
    }

    if (formData.firstName === '' || formData.firstName == null) {
      formValidationAllError.firstName = true;
      isValid = false;
    } else {
      isValid = true;
    }

    if (formData.lastName === '' || formData.lastName == null) {
      formValidationAllError.lastName = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (formData.email === '' || formData.email == null) {
      formValidationAllError.email = true;
      isValid = false;
    } else {
      isValid = true;
    }

    if (healthProblems == null) {
      formValidationAllError.healthProblems = true;
    } else if (healthProblems == true) {
      formValidationAllError.healthProblems = false;
      if (formData.ifYesProvideDetails === '') {
        formValidationAllError.ifYesProvideDetails = true;
      } else {
        formValidationAllError.ifYesProvideDetails = false;
      }
    } else {
      formValidationAllError.healthProblems = false;
    }

    if (diabetesProblems == null) {
      formValidationAllError.diabetesProblems = true;
    } else if (diabetesProblems == true) {
      formValidationAllError.diabetesProblems = false;
      if (formData.ifSoPleaseListThem === '') {
        formValidationAllError.ifSoPleaseListThem = true;
      } else {
        formValidationAllError.ifSoPleaseListThem = false;
      }
    } else {
      formValidationAllError.diabetesProblems = false;
    }

    // if (formData.allergiesList === '') {
    //   formValidationAllError.allergiesList = true;
    // } else {
    //   formValidationAllError.allergiesList = false;
    // }

    // if (formData.listMedication === '') {
    //   formValidationAllError.listMedication = true;
    // } else {
    //   formValidationAllError.listMedication = false;
    // }
    // if (formData.treatingYou === '') {
    //   formValidationAllError.treatingYou = true;
    // } else {
    //   formValidationAllError.treatingYou = false;
    // }

    if (hivAids == null) {
      formValidationAllError.hivAids = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (undersigned == null) {
      formValidationAllError.undersigned = true;
      isValid = false;
    } else {
      isValid = true;
    }

    if (clinicToContact == null) {
      formValidationAllError.clinicToContact = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (havePacemaker == null) {
      formValidationAllError.havePacemaker = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (previousSurgeries == null) {
      formValidationAllError.previousSurgeries = true;
      isValid = false;
    } else {
      isValid = true;
    }
    if (hasChemotherapy == null) {
      formValidationAllError.hasChemotherapy = true;
      isValid = false;
    } else {
      isValid = true;
    }

    if (signature === '') {
      formValidationAllError.signature = true;
      isValid = false;
    } else {
      isValid = true;
    }
    setValidationError(formValidationAllError);
    const data = Object.values(formValidationAllError).every(
      (item) => item === false
    );

    if (data) {
      const newValues = {
        patientId: id,
        appointmentId: appId,
        patientSignature: signature,
        pastProblemsDetails:
          healthProblems == true ? formData?.ifYesProvideDetails : '',
        medicalProblemsDetails:
          diabetesProblems == true ? formData?.ifSoPleaseListThem : '',
        allergies: formData?.allergiesList,
        medication: formData?.listMedication,
        anyThingElse: formData.treatingYou,
        pastProblems: healthProblems,
        medicalProblems: diabetesProblems,
        hiv: hivAids,
        chemotherapy: hasChemotherapy,
        scarring: previousSurgeries,
        pacemaker: havePacemaker,
        contactPermission: clinicToContact,
        informationCorrect: undersigned,
        date: moment(new Date()).format(),
        // patiens
        firstName: capitalizeWords(formData.firstName),
        lastName: capitalizeWords(formData.lastName),
        dateOfBirth: moment(
          new Date(
            dateFormData.year,
            parseInt(dateFormData.month, 10) - 1,
            dateFormData.day
          )
        ).format('YYYY-MM-DD'),
        address: capitalizeWords(
          formData.addressLine === null || formData.addressLine === ''
            ? ''
            : formData.addressLine
        ),
        city:
          formData.City === null || formData.City === ''
            ? ''
            : capitalizeWords(formData.City),
        email: formData.email,
        postCode: formData.postCode,
        mobile: formData.contactNo,
        homeDoctorName: capitalizeWords(
          formData.GPNameAddress === null || formData.GPNameAddress == ''
            ? ''
            : formData.GPNameAddress
        ),
        gender: formData.gender === '' ? null : formData.gender,
      };
      LdcApi({
        method: 'POST',
        url: 'HealthQuestionnaire/AddMedicalQuestionnaire?IsValid=true',
        data: newValues,
      })
        .then((res) => {
          console.log('newValues', newValues);
          if (res?.data?.status?.isSuccess === true) {
            addToast('Medical Questionnaire Form added successfully ', {
              appearance: 'success',
            });
            // addPatiendAPi();
            setBtnSubmit(false);
            setUndersigned(null);
            setClinicToContact(null);
            setHavePacemaker(null);
            setPreviousSurgeries(null);
            setHasChemotherapy(null);
            setHivAids(null);
            setDiabetesProblems(null);
            setHealthProblems(null);
            canvasRef.current.clear();
            localStorage.setItem('forPatients', false);
            navigate('/thankyou');
          }
        })
        .catch((err) => {
          //  navigate('/dary');
          setMouseDisable('auto');
          setBtnSubmit(false);
        });
    } else {
      addToast('fields are required.', { appearance: 'warning' });
      setBtnSubmit(false);
    }
  };

  const addPatiendAPi = () => {
    const newValues = {
      id: id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      dateOfBirth:
        formData.dateOfBirth === ''
          ? null
          : moment(new Date(formData.dateOfBirth)),
      address: formData.addressLine,
      city: formData.City,
      email: formData.email,
      postCode: formData.postCode,
      mobile: formData.contactNo,
      homeDoctorName: formData.GPNameAddress,
      gender: formData.gender === '' ? null : formData.gender,
    };
    Patients.addPatients(newValues, (response) => {});
  };

  const consentForm = () => {
    setShowModal(true);
    // navigate(`/consent-forms/${id}/form-1`);
  };
  const hideForms = () => {
    navigate('/confirmation');
    // localStorage.setItem('isLogin', false);
  };

  const [par, setPar] = useState({});
  const {
    firstName,
    lastName,
    dateOfBirth,
    addressLine,
    City,
    postCode,
    contactNo,
    email,
    GPNameAddress,
    gender,
  } = formData;
  return (
    <div>
      {/* navbar header  */}
      <div className='navbar_header_container  '>
        <ConsentFormsNavbar />
      </div>
      {/* <ProfileBar setPar={setPar} getProfileData={getProfileData} /> */}

      <Container className='' style={{ pointerEvents: { mouseDisable } }}>
        <div className='pt-2'>
          <div className='d-flex justify-content-start mt-5'>
            <p className=' question_date'>
              {moment(new Date()).format('DD/MM/YYYY')}
            </p>
          </div>
          <div className='d-flex justify-content-center'>
            <p className='text-center form_heading  mt-5'>
              Medical Questionnaire{' '}
            </p>
          </div>

          <p>
            Welcome to the clinic <br /> Please ensure you complete the
            questionnaire below to help us provide you with a safe service and
            the appropriate ongoing care. It's important we know your past
            medical history and medication to ensure we offer you the best
            treatment we can. It's also important for us to have your correct
            contact information to ensure we're able to liaise with you for the
            purpose of your healthcare.
            <br />
            Many thanks
          </p>
          <Card className='p-3'>
            <Form onSubmit={updateQuestion}>
              <Row className='my-4'>
                <Col lg={6} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      First Name <span className='text-danger'>*</span>{' '}
                    </Form.Label>
                    <Form.Control
                      style={{ textTransform: 'capitalize' }}
                      name='firstName'
                      type='text'
                      onChange={(e) => {
                        handelProfileChange(e);
                        if (e.target.value.length > 0) {
                          setValidationError({
                            ...validationError,
                            firstName: false,
                          });
                        } else {
                          setValidationError({
                            ...validationError,
                            firstName: true,
                          });
                        }
                      }}
                      value={firstName}
                    />
                    <small className='text-danger error_field'>
                      {validationError.firstName === true &&
                        'Field is required. '}
                    </small>
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      Last Name <span className='text-danger'>*</span>{' '}
                    </Form.Label>
                    <Form.Control
                      style={{ textTransform: 'capitalize' }}
                      name='lastName'
                      type='text'
                      onChange={(e) => {
                        handelProfileChange(e);
                        if (e.target.value.length > 0) {
                          setValidationError({
                            ...validationError,
                            lastName: false,
                          });
                        } else {
                          setValidationError({
                            ...validationError,
                            lastName: true,
                          });
                        }
                      }}
                      value={lastName}
                    />
                    <small className='text-danger error_field'>
                      {validationError.lastName === true &&
                        'Field is required. '}
                    </small>
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      Date Of Birth <span className='text-danger'>*</span>{' '}
                    </Form.Label>
                    <Row className=''>
                      <Col sm={4} className='pr-1 m-0'>
                        <Form.Control
                          onKeyUp={(e) => {
                            var test_value = e.target.value.replace(
                              /[^0-9]+/g,
                              ''
                            );
                            dateSetFormData({
                              ...dateFormData,
                              day: test_value,
                            });
                          }}
                          onChange={(e) => {
                            if (e.target.value !== '00')
                              dateSetFormData({
                                ...dateFormData,
                                day: e.target.value.substring(0, 2),
                              });
                            // if (e.target.value.length > 2) {
                            // } else {
                            //   if (e.target.value > 31) {
                            //     dateSetValidation({ ...dateValidation, year: 'Date is required' });
                            //   } else {
                            //     if (e.target.value.length === 2) {
                            //       dateSetValidation({ ...dateValidation, day: '' });
                            //     } else {
                            //       dateSetValidation({ ...dateValidation, day: 'Day Must be exactly 2 characters' });
                            //     }
                            //     dateSetFormData({ ...dateFormData, day: e.target.value });
                            //   }
                            // }
                          }}
                          name='day'
                          value={dateFormData.day}
                          placeholder='Date'
                          // maxLength={2}
                          type='text'
                        />
                      </Col>
                      <Col sm={3} className='p-0 m-0'>
                        <Form.Control
                          onKeyUp={(e) => {
                            var test_value = e.target.value.replace(
                              /[^0-9]+/g,
                              ''
                            );
                            dateSetFormData({
                              ...dateFormData,
                              month: test_value,
                            });
                          }}
                          onChange={(e) => {
                            if (e.target.value !== '00')
                              dateSetFormData({
                                ...dateFormData,
                                month: e.target.value.substring(0, 2),
                              });
                            // if (e.target.value.length > 2) {
                            //   dateSetValidation({ ...dateValidation, month: '' });
                            //   // setValidation({ ...validation, month: 'Must be exactly 2 characters' });
                            // } else {
                            //   if (e.target.value > 12 ) {
                            //     dateSetValidation({ ...dateValidation, year: 'Month is required' });
                            //   } else {
                            //     if (e.target.value.length == 2 ) {
                            //       dateSetValidation({ ...dateValidation, month: '' });
                            //     } else {
                            //       dateSetValidation({ ...dateValidation, month: 'Month Must be exactly 2 characters' });
                            //     }
                            //     dateSetFormData({ ...dateFormData, month: e.target.value });
                            //   }
                            // }
                          }}
                          name='month'
                          value={dateFormData.month}
                          placeholder='Month'
                          maxLength={2}
                          type='text'
                        />
                      </Col>
                      <Col sm={4} className='pl-1 m-0'>
                        <Form.Control
                          onKeyUp={(e) => {
                            var test_value = e.target.value.replace(
                              /[^0-9]+/g,
                              ''
                            );
                            dateSetFormData({
                              ...dateFormData,
                              year: test_value,
                            });
                          }}
                          onChange={(e) => {
                            if (
                              e.target.value !== '0000' &&
                              e.target.value !== '000' &&
                              e.target.value !== '00' &&
                              e.target.value !== '0'
                            )
                              dateSetFormData({
                                ...dateFormData,
                                year: e.target.value.substring(0, 4),
                              });
                            // if (e.target.value.length > 4) {
                            //   dateSetValidation({ ...dateValidation, year: '' });
                            // } else {
                            //   if (e.target.value.length == 4 ) {
                            //     dateSetValidation({ ...dateValidation, year: '' });
                            //   } else if (e.target.value.length == 0) {
                            //     dateSetValidation({ ...dateValidation, year: '' });
                            //   } else {
                            //     dateSetValidation({ ...dateValidation, year: 'Year Must be exactly 4 characters' });
                            //   }
                            //   dateSetFormData({ ...dateFormData, year: e.target.value });
                            // }
                          }}
                          name='year'
                          value={dateFormData.year}
                          placeholder='Year'
                          type='text'
                        />
                      </Col>
                    </Row>
                    {/* <Form.Control
                      name="dateOfBirth"
                      type="Date"
                      onChange={(e) => {
                        handelProfileChange(e);
                        if (e.target.value.length > 0) {
                          setValidationError({ ...validationError, dateOfBirth: false });
                        } else {
                          setValidationError({ ...validationError, dateOfBirth: true });
                        }
                      }}
                      value={dateOfBirth}
                    /> */}
                    <small className='text-danger error_field'>
                      {validationError.dateOfBirth === true &&
                        'Field is required. '}
                    </small>
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      Gender <span className='text-danger'></span>
                    </Form.Label>
                    <select
                      value={gender}
                      onChange={(e) => {
                        handelProfileChange(e);
                        if (e.target.value.length > 0) {
                          setValidationError({
                            ...validationError,
                            gender: false,
                          });
                        } else {
                          setValidationError({
                            ...validationError,
                            gender: true,
                          });
                        }
                      }}
                      className='form-control'
                      name='gender'
                      id='gender'
                    >
                      <option value=''>Choose</option>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                      <option value='Other'>Other</option>
                      <option value='Transmale(natal female)'>
                        Transmale (natal female)
                      </option>
                      <option value='Transfemale(natal male)'>
                        Transfemale (natal male)
                      </option>
                    </select>
                    <small className='text-danger error_field'>
                      {validationError.gender === true && 'Field is required. '}
                    </small>

                    {/* <Form.Control name="gender" type="text" onChange={(e) => handelProfileChange(e)} value={gender} /> */}
                    {/* {touched.homeGPDoctorName && errors.homeGPDoctorName && (
                          <small className="text-danger error_field">{errors.homeGPDoctorName}</small>
                        )} */}
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      Address <span className='text-danger'></span>{' '}
                    </Form.Label>
                    <Form.Control
                      style={{ textTransform: 'capitalize' }}
                      name='addressLine'
                      type='text'
                      onChange={(e) => {
                        handelProfileChange(e);
                        if (e.target.value.length > 0) {
                          setValidationError({
                            ...validationError,
                            addressLine: false,
                          });
                        } else {
                          setValidationError({
                            ...validationError,
                            addressLine: true,
                          });
                        }
                      }}
                      value={addressLine}
                    />
                    {/* {touched.addressLine1 && errors.addressLine1 && (
                          <small className="text-danger error_field">{errors.addressLine1}</small>
                        )} */}
                    <small className='text-danger error_field'>
                      {validationError.addressLine === true &&
                        'Field is required. '}
                    </small>
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      City <span className='text-danger'> </span>{' '}
                    </Form.Label>
                    <Form.Control
                      style={{ textTransform: 'capitalize' }}
                      name='City'
                      type='text'
                      onChange={(e) => handelProfileChange(e)}
                      value={City}
                    />
                    {/* {touched.city && errors.city && <small className="text-danger error_field">{errors.city}</small>} */}
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      Postcode <span className='text-danger'></span>{' '}
                    </Form.Label>
                    <Form.Control
                      name='postCode'
                      type='text'
                      onChange={(e) => handelProfileChange(e)}
                      value={postCode}
                    />
                    {/* {touched.postCode && errors.postCode && <small className="text-danger error_field">{errors.postCode}</small>} */}
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      Contact NO <span className='text-danger'></span>{' '}
                    </Form.Label>
                    <Form.Control
                      name='contactNo'
                      type='text'
                      onChange={(e) => handelProfileChange(e)}
                      value={contactNo}
                    />
                    {/* {touched.contactNo && errors.contactNo && <small className="text-danger error_field">{errors.contactNo}</small>} */}
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      Email <span className='text-danger'></span>{' '}
                    </Form.Label>
                    <Form.Control
                      name='email'
                      type='email'
                      onChange={(e) => {
                        handelProfileChange(e);
                        if (e.target.value.length > 0) {
                          setValidationError({
                            ...validationError,
                            email: false,
                          });
                        } else {
                          setValidationError({
                            ...validationError,
                            email: true,
                          });
                        }
                      }}
                      value={email}
                    />
                    <small className='text-danger error_field'>
                      {validationError.email === true && 'Field is required. '}
                    </small>
                    {/* {touched.email && errors.email && <small className="text-danger error_field">{errors.email}</small>} */}
                  </Form.Group>
                </Col>
                <Col lg={6} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      GP Name/Address <span className='text-danger'></span>{' '}
                    </Form.Label>
                    <Form.Control
                      style={{ textTransform: 'capitalize' }}
                      name='GPNameAddress'
                      type='text'
                      onChange={(e) => handelProfileChange(e)}
                      value={GPNameAddress}
                    />
                  </Form.Group>
                </Col>

                {/* <Col lg={6} sm={12}>
                      <Form.Group className="">
                        <Form.Label className="form_label">
                          Home GP/Doctor Address <span className="text-danger"></span>{' '}
                        </Form.Label>
                        <Form.Control
                          name="homeGPDoctorAddress"
                          type="text"
                          value={par?.homeDoctorAddress}
                          // // placeholder="First Name"
                          // onChange={handleChange}
                          // onBlur={handleBlur}
                          // value={values.homeGPDoctorAddress}
                          // error={errors.homeGPDoctorAddress}
                          // touched={touched.name}
                        />
                      </Form.Group>
                    </Col> */}
                {/* end all formik  */}
                {/*Start  yesNO btn  */}
                <Col lg={12} className='my-4'>
                  <label className='form_label' htmlFor=''>
                    Have you had any serious health problems in the past?{' '}
                    <span className='text-danger'>* </span>
                  </label>
                  <Row
                    className='mt-2   p-0 '
                    style={{
                      maxWidth: '1200px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  >
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${healthProblems !== null ? (healthProblems ? 'yes_Btn' : 'back') : null}`}
                        onClick={(e) => healthProblemsChanges((e = 'true'))}
                      >
                        YES
                      </div>
                    </Col>
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${healthProblems !== null ? (healthProblems ? 'back' : 'no_Btn') : null} `}
                        onClick={(e) => healthProblemsChanges((e = 'false'))}
                      >
                        NO
                      </div>
                    </Col>
                  </Row>
                  <small className='text-danger'>
                    {' '}
                    {validationError.healthProblems === true &&
                      'Field is required'}
                  </small>
                </Col>
                {/* end yesNO btn  */}
                {healthProblems ? (
                  <Col lg={12} sm={12}>
                    <Form.Group className=''>
                      <Form.Label className='form_label'>
                        If Yes provide details{' '}
                        <span className='text-danger'>*</span>{' '}
                      </Form.Label>
                      <Form.Control
                        rows={4}
                        as='textarea'
                        name='ifYesProvideDetails'
                        type='text'
                        // placeholder="First Name"
                        onChange={(e) => {
                          handelProfileChange(e);
                          if (e.target.value.length > 0) {
                            setValidationError({
                              ...validationError,
                              ifYesProvideDetails: false,
                            });
                          } else {
                            setValidationError({
                              ...validationError,
                              ifYesProvideDetails: true,
                            });
                          }
                        }}
                        value={formData.ifYesProvideDetails}

                        // touched={touched.name}
                      />
                      <small className='text-danger'>
                        {' '}
                        {validationError.ifYesProvideDetails === true &&
                          'Field is required'}
                      </small>
                    </Form.Group>
                  </Col>
                ) : null}

                {/*Start  yesNO btn  */}
                <Col lg={12} className='my-4'>
                  <label className='form_label' htmlFor=''>
                    Do you have any ongoing medical problems e.g. diabetes, high
                    blood pressure? <span className='text-danger'>* </span>
                  </label>
                  <Row
                    className='mt-2   p-0 '
                    style={{
                      maxWidth: '1200px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  >
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${diabetesProblems !== null ? (diabetesProblems ? 'yes_Btn' : 'back') : null}`}
                        onClick={(e) => diabetesProblemsChanges((e = 'true'))}
                      >
                        YES
                      </div>
                    </Col>
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${diabetesProblems !== null ? (diabetesProblems ? 'back' : 'no_Btn') : null} `}
                        onClick={(e) => diabetesProblemsChanges((e = 'false'))}
                      >
                        NO
                      </div>
                    </Col>
                  </Row>
                  <small className='text-danger'>
                    {' '}
                    {validationError.diabetesProblems === true &&
                      'Field is required'}
                  </small>
                </Col>
                {/* end yesNO btn  */}
                {diabetesProblems ? (
                  <Col lg={12} sm={12}>
                    <Form.Group className=''>
                      <Form.Label className='form_label'>
                        If so please list them{' '}
                        <span className='text-danger'>*</span>{' '}
                      </Form.Label>
                      <Form.Control
                        rows={4}
                        as='textarea'
                        name='ifSoPleaseListThem'
                        type='text'
                        // placeholder="First Name"
                        onChange={(e) => {
                          handelProfileChange(e);
                          if (e.target.value.length > 0) {
                            setValidationError({
                              ...validationError,
                              ifSoPleaseListThem: false,
                            });
                          } else {
                            setValidationError({
                              ...validationError,
                              ifSoPleaseListThem: true,
                            });
                          }
                        }}
                        value={formData.ifSoPleaseListThem}

                        // touched={touched.name}
                      />
                      <small className='text-danger'>
                        {' '}
                        {validationError.ifSoPleaseListThem === true &&
                          'Field is required'}
                      </small>
                    </Form.Group>
                  </Col>
                ) : null}

                <Col lg={12} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      Please list all allergies you are aware of fewsf. Please
                      also include any reactions to local anaesthetics, latex or
                      medications like antibiotics e.g. penicillin?{' '}
                      <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      rows={4}
                      as='textarea'
                      name='allergiesList'
                      type='text'
                      // placeholder="First Name"
                      onChange={(e) => {
                        handelProfileChange(e);
                        // if (e.target.value.length > 0) {
                        //   setValidationError({ ...validationError, allergiesList: false });
                        // } else {
                        //   setValidationError({ ...validationError, allergiesList: true });
                        // }
                      }}
                      value={formData.allergiesList}

                      // touched={touched.name}
                    />
                    {/* <small className="text-danger"> {validationError.allergiesList === true && 'Field is required'}</small> */}
                  </Form.Group>
                </Col>

                {/*Start  yesNO btn  */}
                <Col lg={12} className='my-4'>
                  <label className='form_label' htmlFor=''>
                    Have you been tested positive for HIV / AIDS, hepatitis or
                    another blood borne disease?
                    <span className='text-danger'>*</span>
                  </label>
                  <Row
                    className='mt-2   p-0 '
                    style={{
                      maxWidth: '1200px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  >
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${hivAids !== null ? (hivAids ? 'yes_Btn' : 'back') : null}`}
                        onClick={(e) => hivAidsChanges((e = 'true'))}
                      >
                        YES
                      </div>
                    </Col>
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${hivAids !== null ? (hivAids ? 'back' : 'no_Btn') : null} `}
                        onClick={(e) => hivAidsChanges((e = 'false'))}
                      >
                        NO
                      </div>
                    </Col>
                  </Row>
                  <small className='text-danger'>
                    {' '}
                    {validationError.hivAids === true && 'Field is required'}
                  </small>
                </Col>
                {/* end yesNO btn  */}
                {/*Start  yesNO btn  */}
                <Col lg={12} className='my-4'>
                  <label className='form_label' htmlFor=''>
                    Do you receive chemotherapy or any drugs that weaken your
                    immune system? <span className='text-danger'>*</span>
                  </label>
                  <Row
                    className='mt-2   p-0 '
                    style={{
                      maxWidth: '1200px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  >
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${hasChemotherapy !== null ? (hasChemotherapy ? 'yes_Btn' : 'back') : null}`}
                        onClick={(e) => hasChemotherapyChanges((e = 'true'))}
                      >
                        YES
                      </div>
                    </Col>
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${hasChemotherapy !== null ? (hasChemotherapy ? 'back' : 'no_Btn') : null} `}
                        onClick={(e) => hasChemotherapyChanges((e = 'false'))}
                      >
                        NO
                      </div>
                    </Col>
                  </Row>{' '}
                  <small className='text-danger'>
                    {' '}
                    {validationError.hasChemotherapy === true &&
                      'Field is required'}
                  </small>
                </Col>
                {/* end yesNO btn  */}
                {/*Start  yesNO btn  */}
                <Col lg={12} className='my-4'>
                  <label className='form_label' htmlFor=''>
                    Do you have any problems with scarring after previous
                    surgeries? <span className='text-danger'>*</span>
                  </label>
                  <Row
                    className='mt-2   p-0 '
                    style={{
                      maxWidth: '1200px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  >
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${previousSurgeries !== null ? (previousSurgeries ? 'yes_Btn' : 'back') : null}`}
                        onClick={(e) => previousSurgeriesChanges((e = 'true'))}
                      >
                        YES
                      </div>
                    </Col>
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${previousSurgeries !== null ? (previousSurgeries ? 'back' : 'no_Btn') : null} `}
                        onClick={(e) => previousSurgeriesChanges((e = 'false'))}
                      >
                        NO
                      </div>
                    </Col>
                  </Row>{' '}
                  <small className='text-danger'>
                    {' '}
                    {validationError.previousSurgeries === true &&
                      'Field is required'}
                  </small>
                </Col>
                {/* end yesNO btn  */}
                {/* {previousSurgeries ? ( */}
                <Col lg={12} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      Please list any medication, especially blood thinner e.g.
                      Warfarin/Aspirin, you take.{' '}
                      <span className='text-danger'>*</span>
                    </Form.Label>
                    <Form.Control
                      rows={4}
                      as='textarea'
                      name='listMedication'
                      type='text'
                      // placeholder="First Name"
                      onChange={(e) => {
                        handelProfileChange(e);
                        // if (e.target.value.length > 0) {
                        //   setValidationError({ ...validationError, listMedication: false });
                        // } else {
                        //   setValidationError({ ...validationError, listMedication: true });
                        // }
                      }}
                      value={formData.listMedication}

                      // touched={touched.name}
                    />
                    {/* <small className="text-danger"> {validationError.listMedication === true && 'Field is required'}</small> */}
                  </Form.Group>
                </Col>
                {/* ) : null} */}

                {/*Start  yesNO btn  */}
                <Col lg={12} className='my-4'>
                  <label className='form_label' htmlFor=''>
                    Do you have a pacemaker?{' '}
                    <span className='text-danger'>*</span>
                  </label>
                  <Row
                    className='mt-2   p-0 '
                    style={{
                      maxWidth: '1200px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  >
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${havePacemaker !== null ? (havePacemaker ? 'yes_Btn' : 'back') : null}`}
                        onClick={(e) => havePacemakerChanges((e = 'true'))}
                      >
                        YES
                      </div>
                    </Col>
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${havePacemaker !== null ? (havePacemaker ? 'back' : 'no_Btn') : null} `}
                        onClick={(e) => havePacemakerChanges((e = 'false'))}
                      >
                        NO
                      </div>
                    </Col>
                  </Row>{' '}
                  <small className='text-danger'>
                    {' '}
                    {validationError.havePacemaker === true &&
                      'Field is required'}
                  </small>
                </Col>
                {/* end yesNO btn  */}
                {/* {havePacemaker ? ( */}
                <Col lg={12} sm={12}>
                  <Form.Group className=''>
                    <Form.Label className='form_label'>
                      Please list anything else you think we should be aware of
                      when treating you. <span className='text-danger'></span>
                    </Form.Label>
                    <Form.Control
                      rows={4}
                      as='textarea'
                      name='treatingYou'
                      type='text'
                      // placeholder="treatingYou Name"

                      onChange={(e) => {
                        handelProfileChange(e);
                        // if (e.target.value.length > 0) {
                        //   setValidationError({ ...validationError, treatingYou: false });
                        // } else {
                        //   setValidationError({ ...validationError, treatingYou: true });
                        // }
                      }}
                      value={formData.treatingYou}

                      // touched={touched.name}
                    />
                    {/* <small className="text-danger"> {validationError.treatingYou === true && 'Field is required'}</small> */}
                  </Form.Group>
                </Col>
                {/* ) : null} */}

                <div className='p-4'>
                  <h6>PATIENT DECLARATION</h6>
                  <p>
                    We take our patients' privacy very seriously.
                    <br />
                    Please be aware the clinic will hold your mobile phone
                    number, home address and email address, where you have
                    provided this. We may use any of these forms of contact for
                    the purpose of your care only. For example, we may send you
                    a text message or emails with regard to your test results,
                    follow-up appointments or to request feedback. We may call
                    you to follow-up on you after a particular procedure or
                    treatment. We may use your postal address if you request for
                    us to post you a document or the doctor instructs us to post
                    a document to you.
                    <br />
                    Furthermore, we will never share your personal details with
                    any third party organisation without your permission. If you
                    have any concerns and need further details on how we use
                    your personal data, please speak to a member of our team,
                    and they will gladly provide you with our patient privacy
                    policy.
                  </p>
                </div>
                {/*Start  yesNO btn  */}
                <Col lg={12} className='my-4'>
                  <label className='form_label' htmlFor=''>
                    I give permission for the clinic to contact me (outside my
                    care) with regard to general skin healthcare advice in the
                    form of an email newsletter or services the clinic offers
                    that may be of interest to me.
                    <span className='text-danger'>*</span>
                  </label>
                  <Row
                    className='mt-2   p-0 '
                    style={{
                      maxWidth: '1200px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  >
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${clinicToContact !== null ? (clinicToContact ? 'yes_Btn' : 'back') : null}`}
                        onClick={(e) => clinicToContactChanges((e = 'true'))}
                      >
                        YES
                      </div>
                    </Col>
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${clinicToContact !== null ? (clinicToContact ? 'back' : 'no_Btn') : null} `}
                        onClick={(e) => clinicToContactChanges((e = 'false'))}
                      >
                        NO
                      </div>
                    </Col>
                  </Row>{' '}
                  <small className='text-danger'>
                    {' '}
                    {validationError.clinicToContact === true &&
                      'Field is required'}
                  </small>
                </Col>
                {/* end yesNO btn  */}

                {/*Start  yesNO btn  */}
                <Col lg={12} className='my-4'>
                  <label className='form_label' htmlFor=''>
                    I/we the undersigned, confirm that all the above information
                    is correct and that I have read, understood and accept the
                    clinic's terms and conditions{' '}
                    <a
                      style={{ textDecoration: 'underline', color: 'blue' }}
                      href={termsandcondition}
                      target='_blank'
                    >
                      (read here)
                    </a>
                    .<span className='text-danger'>*</span>
                  </label>
                  <Row
                    className='mt-2   p-0 '
                    style={{
                      maxWidth: '1200px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                  >
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${undersigned !== null ? (undersigned ? 'yes_Btn' : 'back') : null}`}
                        onClick={(e) => undersignedChanges((e = 'true'))}
                      >
                        YES
                      </div>
                    </Col>
                    <Col sm={2} className='p-0 mx-4'>
                      <div
                        className={` yes_no_btn ${undersigned !== null ? (undersigned ? 'back' : 'no_Btn') : null} `}
                        onClick={(e) => undersignedChanges((e = 'false'))}
                      >
                        NO
                      </div>
                    </Col>
                  </Row>{' '}
                  <small className='text-danger'>
                    {' '}
                    {validationError.undersigned === true &&
                      'Field is required'}
                  </small>
                </Col>

                {/* end yesNO btn  */}
                <Col sm={12} className='mt-2'>
                  <div style={{ overflow: 'hidden' }}>
                    <CanvasDraw {...props} />
                    <Button
                      classNames='mt-2'
                      onClick={() => {
                        canvasRef.current.clear();
                        setSignature('');
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <span className='text-danger'>
                    {validationError.signature === true && 'Field is required.'}
                  </span>
                </Col>
                {/* <small className="text-danger ml-3"> {validationError.URL === true && 'Field is required'}</small> */}

                <Col lg={6} sm={6}>
                  <Form.Group className='mt-4'>
                    <Form.Label className='form_label'>
                      Date <span className='text-danger'></span>{' '}
                    </Form.Label>
                    <Form.Control
                      name='ifSoPleaseListThem'
                      type='text'
                      disabled
                      value={moment(new Date()).format('DD/MM/YYYY')}
                      // touched={touched.name}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className='d-flex my-4    '>
                <button
                  type='submit'
                  disabled={btnSubmit}
                  className='btn px-4 btn-outline-primary'
                >
                  {btnSubmit ? 'Loading....' : 'Save'}
                </button>
                {/* <CustomDangerButton name="Cancel" cancelOnClick={() =>setEditModalShow(false)} variant="danger" className="px-4" /> */}
              </div>
            </Form>
          </Card>
        </div>
      </Container>
    </div>
  );
}
