import React from 'react';
import image from '../../assets/images/logo/image.png';
import insta from '../../assets/images/logo/sadasd.png';
import fasce from '../../assets/images/logo/faceboooks1.jpg';
import twitter from '../../assets/images/logo/icons8-twitter-30.png';
import facebookLite from '../../assets/images/logo/icons8-facebook-64.png';
import linedIn from '../../assets/images/logo/icons8-linkedin-2-30.png';

const TemplateLogo = () => {
  return (
    <div>
      <img src={image} alt="" />
      <img src={fasce} alt="" />
      <img src={insta} alt="" />
      <img src={facebookLite} alt="" />
      <img src={twitter} alt="" />
      <img src={linedIn} alt="" />
    </div>
  );
};

export default TemplateLogo;
