import React from 'react';
import { Form } from 'react-bootstrap';

const CustomSelect = ({ value, name, onChange, defaultValue, selectData, isDisabled = false, error = false, ...props }) => {
  return (
    <Form.Control
      style={{ borderColor: error ? 'red' : '' }}
      as="select"
      disabled={isDisabled}
      name={name}
      value={value}
      onChange={onChange}
      {...props}
    >
      <option value="">{defaultValue}</option>
      {name!=="selectDocument" ?selectData.map((item, idx) => {
        return (
          <option key={idx} value={item.id}>
            {`${item.name}`} {item?.role ? `(${item.role})` : ``}
          </option>
        );
      }):
      selectData.map((item) => {
        const date=item.createdOn.split("T")[0];
        const dateArray=date.split("-")
        return (
          <option key={item.id} value={item.id}>
            {item.description.split(" ").
            slice(0,3).join(" ")+` :: ${item.userName} :: ${dateArray[2]+"/"+dateArray[1]+"/"+dateArray[0]}`}
          </option>
        );
      })
      }
    </Form.Control>
  );
};

export default CustomSelect;

// active	true
// chamberDate	"2022-12-29"
// doctorId	10
// roomId	1
// timeEnd	"10:15"
// timeStart	"09:45"


// active	true
// chamberDate	"2022-12-28"
// doctorId	12
// roomId	2
// timeEnd	"10:00"
// timeStart	"09:00"