import { Link } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Row, Col, Card, Table, Button, Form, Modal } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import EditIcon from '@mui/icons-material/Edit';
import { Formik } from 'formik';
import * as yup from 'yup';

import moment from 'moment';
import { BiEditAlt } from 'react-icons/bi';
import { FaRegClone } from 'react-icons/fa';

//  ag grid
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Tooltip } from 'antd';
import LdcApi from '../../LdcApis';
import { useToasts } from 'react-toast-notifications';

export const handleTime = (dataD) => {
  let data = new Date(dataD);
  let hrs = data.getHours();
  let mins = data.getMinutes();
  if (hrs <= 9) hrs = '0' + hrs;
  if (mins < 10) mins = '0' + mins;
  const postTime = hrs + ':' + mins;
  return postTime;
};
const userInfo = JSON.parse(localStorage.getItem('user_info'));
const validationSchema = yup.object().shape({
  chamberDate: yup.string().required('  Date   is required'),
  roomId: yup.string().required('Room Name is required')
});

const ChamberTable = ({ chamberTableData, getChamberList, handleEditTableRow, name, role, roomList }) => {
  const { addToast } = useToasts();
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState();

  // ag grid ***********************************
  const [modify, setModify] = useState({});
  const [show, setShow] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleCloneTableRow = (params) => {
    setShow(true);
    setModify(params.data);
  };
  const [columnDefs] = useState([
    {
      headerName: '',
      width: 90,
      maxWidth: 120,
      filter: false,
      hide: (userInfo && userInfo?.roleID == 8) || userInfo?.roleID == 9 ? false : true,
      cellRenderer: function (params) {
        return (
          <div className="d-flex align-items-center mt-2 actions_ag_grid">
            {/* {params.data.role !== 'Assistant' && ( */}
            <Tooltip placement="left" title="Edit">
              <BiEditAlt
                className="mx-2 edit_btn"
                onClick={() => handleEditTableRow(params)}
                style={{
                  fontSize: '1.4rem',
                  cursor: 'pointer'
                }}
              />
            </Tooltip>
            <Tooltip placement="left" title="Clone">
              <FaRegClone
                className="mx-2 edit_btn"
                onClick={() => handleCloneTableRow(params)}
                style={{
                  fontSize: '1.4rem',
                  cursor: 'pointer'
                }}
              />
            </Tooltip>
            {/* )} */}
          </div>
        );
      }
    },
    {
      headerName: 'Doctor',
      field: 'doctor',
      width: 100,
      minWidth: 100,
      cellRenderer: function (params) {
        return (
          <p className="  cursors head_td " style={{ textTransform: 'capitalize' }}>
            {params.value}
          </p>
        );
      }
    },
    {
      headerName: 'Date',
      field: 'chamberDate',
      width: 100,
      maxWidth: 120,
      cellRenderer: function (params) {
        return <p className="ag_grid_caps cursors head_td ">{moment(new Date(params.value)).format('DD/MM/YYYY')}</p>;
      }
    },
    {
      headerName: 'Start Time',
      field: 'timeStart',
      width: 100,
      maxWidth: 100,
      cellRenderer: function (params) {
        return <p className="ag_grid_caps cursors head_td ">{params.value}</p>;
      }
    },
    {
      headerName: 'End Time',
      field: 'timeEnd',
      width: 100,
      maxWidth: 100,
      cellRenderer: function (params) {
        return <p className="ag_grid_caps cursors head_td ">{params.value}</p>;
      }
    },
    {
      headerName: 'Room',
      field: 'roomName',
      width: 100,
      maxWidth: 180,
      cellRenderer: function (params) {
        return <p className="ag_grid_caps cursors head_td ">{params.value}</p>;
      }
    },
    {
      headerName: 'Created By',
      field: 'userName',
      filter: false,
      sortable: false,
      floatingFilter: false,
      width: 100,
      minWidth: 100,
      cellRenderer: function (params) {
        return (
          <div className="">
            <span>
              {params.data.userName} at {moment(new Date(params.data.createdOn)).format('DD/MM/YYYY h:mm A')}
            </span>
          </div>
        );
      }
    },
    {
      headerName: 'Status',
      field: 'active',
      filter: false,
      sortable: false,
      floatingFilter: false,
      width: 60,
      maxWidth: 100,
      cellRenderer: function (params) {
        return (
          <div className="">
            <span className={`  ${params.data.active ? 'is_active' : 'in_active'}`}>{params.data.active ? 'Active' : 'Inactive'}</span>
          </div>
        );
      }
    }
  ]);

  const defaultColDef = {
    filter: false,
    sortable: true,
    floatingFilter: false,
    flex: 1,
    resizable: true,
    height: 100,
    minWidth: 100
  };
  const onGridReady = (params) => {
    setRowData(chamberTableData);
    setGridApi(params);
  };

  const cloneShift = (values) => {
    // url=`https://api-ldc.goldenbuzz.in/api/Chamber/CloneChamber?shiftid=1&chamberDate=2023-04-05&roomId=2` errorMessage
    setBtnLoading(true);
    LdcApi({
      method: 'GET',
      url: `Chamber/CloneChamber?shiftid=${values.id}&chamberDate=${values.chamberDate}&roomId=${values.roomId}&docterId=${values.doctorId}`
    })
      .then((res) => {
        if (res.data.status.isSuccess === true) {
          addToast('Clone Rota Added successfully.', { appearance: 'success' });
          setShow(false);
          setBtnLoading(false);
          getChamberList();
        } else if (res.data.status.errorMessage == `The doctor is already booked in the same room on the same date`) {
          addToast(`The doctor is already booked in the same room on the same date.`, { appearance: 'warning' });
          setBtnLoading(false);
        } else if (res.data.status.errorMessage == `The doctor is already booked on the same date but in a different room`) {
          addToast(`The Doctor is already booked on the selected date or room.`, { appearance: 'warning' });
          setBtnLoading(false);
        } else if (res.data.status.errorMessage == `The room is already booked by another doctor on the same date`) {
          addToast(`The room is already booked by another doctor on the same date.`, { appearance: 'warning' });
          setBtnLoading(false);
        } else {
          addToast(`Something is wrong.`, { appearance: 'error' });
          setBtnLoading(false);
        }
      })
      .catch((error) => {
        setShow(false);
        setBtnLoading(false);
      });
  };
  const handleClose = () => {
    setShow(false);
  };

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col>
          <Card className="p-3 ">
            <div className="ag-theme-alpine mt-2" style={{ height: '90vh', width: '100%' }}>
              <AgGridReact
                ref={gridRef}
                rowData={chamberTableData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                onGridReady={onGridReady}
                pagination={true}
                paginationPageSize={50}
                debounceVerticalScrollbar={true}
                // paginationAutoPageSize={true}
              />
            </div>
          </Card>
        </Col>
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              Clone Rota for <span style={{ textTransform: 'capitalize' }}>{modify?.doctor}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik initialValues={modify} validationSchema={validationSchema} onSubmit={cloneShift}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="my-4">
                    <Col lg={6}>
                      <Form.Group>
                        <Form.Label>
                          Date <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          className="m-0 p-2"
                          type="date"
                          name="chamberDate"
                          placeholder="Room Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.chamberDate}
                          error={errors.chamberDate}
                          touched={touched.chamberDate}
                          //   isInvalid={touched.date && !!errors.date}
                          //   isValid={touched.date && !errors.date}
                          required
                        />
                        {touched.chamberDate && errors.chamberDate && (
                          <small className="text-danger error_field">{errors.chamberDate}</small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Label>
                        Room <span className="text-danger">*</span>
                      </Form.Label>
                      <select
                        className="form-control"
                        aria-label="Default select example"
                        name="roomId"
                        type="number"
                        placeholder="Active"
                        display="Active"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.roomId}
                        error={errors.roomId}
                      >
                        {roomList.map((items, index) => (
                          <option key={index} value={items.id}>
                            {items.name}
                          </option>
                        ))}
                      </select>
                      {touched.roomId && errors.roomId && <small className="text-danger error_field">{errors.roomId}</small>}
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={6}>
                      <Button type="submit" className="ne_btn w-100 ne_btn_primary d-flex justify-content-center align-items-center">
                        {btnLoading ? 'Loading...' : 'Submit'}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </Row>
    </React.Fragment>
  );
};

export default ChamberTable;
