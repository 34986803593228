import React, { useRef, useState } from 'react';
import ProfileBar from '../ProfileBar';
import { Button, Modal, Container, Card, Form, Row, Col, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import classNames from 'classnames';
import { Checkbox, Radio } from 'antd';
import CanvasDraw from 'react-canvas-draw';
import { RadioGroup } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import { useEffect } from 'react';
import moment from 'moment';
import LdcApi from '../../../../LdcApis';
import { BsArrowLeft } from 'react-icons/bs';

const EditRoaccFemale = () => {
  const [seriousBirthDefects, setSeriousBirthDefects] = useState(null);
  const [twoForms, setUseTwoForms] = useState(null);
  const [seriousMoodDisturbance, setSeriousMoodDisturbance] = useState(null);
  const [donateBlood, setDonateBlood] = useState(null);
  const [commonSideEffects, setCommonSideEffects] = useState(null);
  const [depressionOfMood, setdepressionOfMood] = useState(null);
  const [doctorSignature, setDoctorSignature] = useState('');
  const [patientSignature, setPatientSignature] = useState('');
  const [guardianSignature, setGuardianSignature] = useState('');
  const [schedulePatientSignature, setSchedulePatientSignature] = useState(null);
  const [PregnancyPatientSignature, setPregnancyPatientSignature] = useState(null);
  const [PregnancyDoctorSignature, setPregnancyDoctorSignature] = useState(null);
  const [PregnancyGuardianSignature, setPregnancyGuardianSignature] = useState(null);
  const [littleInterest, setlittleInterest] = useState(null);
  const [feelingDown, setFeelingDown] = useState(null);
  const [troubleFalling, setTroubleFalling] = useState(null);
  const [feelingTired, setFeelingTired] = useState(null);
  const [poorAppetite, setPoorAppetite] = useState(null);
  const [feelingBad, setFeelingBad] = useState(null);
  const [troubleConcentrating, setTroubleConcentrating] = useState(null);
  const [movingOrSpeaking, setMovingOrSpeaking] = useState(null);
  const [thoughts, setThoughts] = useState(null);
  const [difficult, setDiffcult] = useState(null);
  const [mouseDisable, setMouseDisable] = useState('auto');
  const [btnSubmit, setBtnSubmit] = useState(false);
  const { addToast } = useToasts();
  let patientDetailsData = JSON.parse(localStorage.getItem('patientDetailsData'));
  let userInfo = JSON.parse(localStorage.getItem('user_info'));

  const { id, viewId } = useParams();

  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    document.title = 'View Isotretinoin Female Patient Consent Form - LDC';
  }, []);

  useEffect(() => {
    setLoading(true);
    LdcApi({
      method: 'GET',
      url: `Isotretinoin/GetViewIsotretinoin_FP?Id=${viewId}`
    })
      .then((response) => {
        if (response.data.length > 0) {
          setLoading(false);
          setRowData(response?.data[0]);
        } else {
          // navigate('/diary');
        }
      })
      .catch((error) => {
        setLoading(false);
        // navigate('/diary');
      });
  }, []);

  const navigate = useNavigate();

  const hideForms = () => {
    navigate('/confirmation');
  };

  useEffect(() => {
    setSeriousBirthDefects(rowData.seriousBirthDefects);
    setUseTwoForms(rowData.twoForms);
    setSeriousMoodDisturbance(rowData.seriousMoodDisturbance);
    setDonateBlood(rowData.donateBlood);
    setCommonSideEffects(rowData.commonSideEffects);
    setdepressionOfMood(rowData.depressionOfMood);
  }, [rowData]);

  return (
    <div>
      <ProfileBar />
      <Container>
        <div className="d-flex my-4 justify-content-end">
          <Button onClick={() => hideForms()} variant="outline-primary mb-3">
            Go to diary
          </Button>
        </div>
        <Card className="p-3">
          <p className="text-center form_heading  mb-5">Female Patient Consent Form For Isotretinoin</p>
          <p className="textHeadline">Female Patients To Complete</p>
          <p>I understand:</p>
          <div className="p-1">
            <Form>
              <Form.Group>
                <Row className=" justify-content-center">
                  <Col sm={11} className="p-2 mr-5">
                    <p>
                      1) Isotretinoin may cause serious birth defects and that I should not take isotretinoin if I am pregnant or
                      breastfeeding.
                    </p>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`yes_no_btn ${seriousBirthDefects !== null ? (seriousBirthDefects ? 'yes_Btn' : 'back') : null}`}
                      // onClick={(e) => seriousBirthDefectsChange((e = 'true'))}
                    >
                      Yes
                    </div>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`yes_no_btn ${seriousBirthDefects !== null ? (seriousBirthDefects ? 'back' : 'no_Btn') : null}`}
                      // onClick={(e) => seriousBirthDefectsChange((e = 'false'))}
                    >
                      No
                    </div>
                  </Col>
                  <Col sm={11} className="p-2 mr-5">
                    <p>
                      2) If I am sexually active, I should use two forms of appropriate contraception (eg. oral contraceptive pill and
                      condoms).
                      <ul>
                        <li>For at least one month before taking isotretinoin</li>
                        <li>While I am taking isotretinoin</li>
                        <li>One month after stopping treatment</li>
                      </ul>
                    </p>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`yes_no_btn ${twoForms !== null ? (twoForms ? 'yes_Btn' : 'back') : null}`}
                      // onClick={(e) => twoFormsChange((e = 'true'))}
                    >
                      Yes
                    </div>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`yes_no_btn  ${twoForms !== null ? (twoForms ? 'back' : 'no_Btn') : null}`}
                      // onClick={(e) => twoFormsChange((e = 'false'))}
                    >
                      No
                    </div>
                  </Col>
                  <Col sm={11} className="p-2 mr-5">
                    <p>
                      3) Serious mood disturbance (depression) can be provoked by isotretinoin and I must contact my doctor and stop taking
                      isotretinoin if I experience depression, become withdrawn, have thoughts of self-harm, or I am feeling sad, anxious,
                      worthless, or hopeless.
                    </p>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={` yes_no_btn ${seriousMoodDisturbance !== null ? (seriousMoodDisturbance ? 'yes_Btn' : 'back') : null}`}
                      // onClick={(e) => seriousMoodDisturbanceChange((e = 'true'))}
                    >
                      Yes
                    </div>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`yes_no_btn ${seriousMoodDisturbance !== null ? (seriousMoodDisturbance ? 'back' : 'no_Btn') : null} `}
                      // onClick={(e) => seriousMoodDisturbanceChange((e = 'false'))}
                    >
                      No
                    </div>
                  </Col>

                  <Col sm={11} className="p-2 mr-5 ">
                    <p>4) I should not donate blood during isotretinoin treatment or for at least one month after treatment</p>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={` yes_no_btn ${donateBlood !== null ? (donateBlood ? 'yes_Btn' : 'back') : null}`}
                      // onClick={(e) => donateBloodChange((e = 'true'))}
                    >
                      Yes
                    </div>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`   yes_no_btn ${donateBlood !== null ? (donateBlood ? 'back' : 'no_Btn') : null} `}
                      // onClick={(e) => donateBloodChange((e = 'false'))}
                    >
                      No
                    </div>
                  </Col>
                  <Col sm={11} className="p-2 mr-5 ">
                    <p>5) I have been informed about the common side effects and received my BAD fact sheet and schedule of care.</p>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={` yes_no_btn ${commonSideEffects !== null ? (commonSideEffects ? 'yes_Btn' : 'back') : null}`}
                      // onClick={(e) => commonSideEffectsChange((e = 'true'))}
                    >
                      Yes
                    </div>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`   yes_no_btn  ${commonSideEffects !== null ? (commonSideEffects ? 'back' : 'no_Btn') : null}`}
                      // onClick={(e) => commonSideEffectsChange((e = 'false'))}
                    >
                      No
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <Row className="justify-content-center">
                  <Col sm={12} className="p-2 mt-4">
                    <p className="textHeadline">Doctor To Complete</p>
                  </Col>
                  <Col sm={11} className="p-2 mr-5 ">
                    <p>1) I have explained to the patient that depression of mood can be provoked by isotretinoin</p>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={` yes_no_btn ${depressionOfMood !== null ? (depressionOfMood ? 'yes_Btn' : 'back') : null}`}
                      // onClick={(e) => depressionOfMoodChange((e = 'true'))}
                    >
                      Yes
                    </div>
                  </Col>
                  <Col sm={2} className="p-0 mx-4">
                    <div
                      className={`yes_no_btn ${depressionOfMood !== null ? (depressionOfMood ? 'back' : 'no_Btn') : null} `}
                      // onClick={(e) => depressionOfMoodChange((e = 'false'))}
                    >
                      No
                    </div>
                  </Col>
                </Row>
                <Row className="p-4">
                  <b>
                    Name : <span className="text-danger mr-2">*</span>
                  </b>

                  <div>
                    <input
                      className={`from_control`}
                      type="text"
                      name="name"
                      disabled
                      // placeholder="name"
                      // onChange={(e) => {
                      //   handelOnchangeValue(e);
                      //   if (e.target.value.length > 0) {
                      //     setValidationError({ ...validationError, name: false });
                      //   } else {
                      //     setValidationError({ ...validationError, name: true });
                      //   }
                      // }}
                      value={rowData.doctorName}
                    />
                    <br />
                  </div>
                </Row>
                <Row className="p-4">
                  <b>
                    GMC No: <span className="text-danger mr-2">*</span>
                  </b>

                  <div>
                    <input
                      className={`from_control `}
                      type="text"
                      name="GMCNo"
                      disabled
                      // placeholder="name"

                      // onChange={(e) => {
                      //   handelOnchangeValue(e);
                      //   if (e.target.value.length > 0) {
                      //     setValidationError({ ...validationError, GMCNo: false });
                      //   } else {
                      //     setValidationError({ ...validationError, GMCNo: true });
                      //   }
                      // }}
                      value={rowData.gmcNo}
                    />
                    <br />
                  </div>
                </Row>
                <Row className="mx-4">
                  <Col className="mr-2">
                    <b>
                      Signature : <span className="text-danger">*</span>
                    </b>
                  </Col>
                  <Col sm={11} className="mt-2">
                    <div className="view_sigPadContainer p-2 my-3 ">
                      <img src={rowData?.doctorSignature} className="signature" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="mt-3 p-3">
                    <Form.Group className="mt-3  p-0 d-flex align-items-center">
                      <Row>
                        <Col className="mr-2">
                          <b>
                            Date: <span className="text-danger">*</span>
                          </b>
                        </Col>
                        <Col>
                          <div>
                            <input
                              className={`from_control   `}
                              type="text"
                              name="doctorDate"
                              disabled
                              // placeholder="name"

                              // onChange={(e) => {
                              //   handelOnchangeValue(e);
                              //   if (e.target.value.length > 0) {
                              //     setValidationError({ ...validationError, doctorDate: false });
                              //   } else {
                              //     setValidationError({ ...validationError, doctorDate: true });
                              //   }
                              // }}
                              value={moment(rowData.doctorDate).format('DD/MM/YY')}
                            />
                            <br />
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </Row>
              </Form.Group>
              <Form.Group className="justify-content-center align-items-center">
                <Table className="tableStyle" responsive striped bordered hover size="md">
                  <thead className="prescription_thead mb-3 " style={{ backgroundColor: 'gray' }}>
                    <tr>
                      <th style={{ width: '40%' }}>Patient</th>
                      <th style={{ width: '30%' }}>Parent or Guardian *Required if patient under 16 years old.</th>
                    </tr>
                  </thead>
                  <tbody style={{ width: '500px' }}>
                    <tr>
                      <td>
                        <p style={{ width: '100%', whiteSpace: 'pre-line' }}>
                          I understand the above information about the effects of isotretinoin and consent to the treatment.
                        </p>
                      </td>
                      <td>
                        <p style={{ width: '100%', whiteSpace: 'pre-line' }}>
                          I understand the above information about the effects of isotretinoin and as a parent consent to the treatment
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Row>
                  <Col>
                    <div className="mt-3 p-3">
                      <Form.Group className="mt-3  d-flex align-items-center">
                        <Row>
                          <Col className="mr-2 mt-2">
                            <b>
                              Name : <span className="text-danger">*</span>
                            </b>
                          </Col>
                          <Col>
                            <div>
                              <input
                                className={`from_control  `}
                                type="text"
                                name="patientName"
                                disabled
                                // placeholder="name"

                                // onChange={(e) => {
                                //   handelOnchangeValue(e);
                                //   if (e.target.value.length > 0) {
                                //     setValidationError({ ...validationError, patientName: false });
                                //   } else {
                                //     setValidationError({ ...validationError, patientName: true });
                                //   }
                                // }}
                                value={rowData.patientName}
                              />
                              <br />
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </div>
                    <div className=" p-3">
                      <Form.Group className="p-0 d-flex align-items-center">
                        <Row>
                          <Col className="mr-2">
                            <b>
                              Date : <span className="text-danger">*</span>
                            </b>
                          </Col>
                          <Col>
                            <div>
                              <input
                                className={`from_control `}
                                type="text"
                                name="patientDate"
                                disabled
                                // placeholder="name"

                                // onChange={(e) => {
                                //   handelOnchangeValue(e);
                                //   if (e.target.value.length > 0) {
                                //     setValidationError({ ...validationError, patientDate: false });
                                //   } else {
                                //     setValidationError({ ...validationError, patientDate: true });
                                //   }
                                // }}
                                value={moment(rowData.patientDate).format('DD/MM/YY')}
                              />
                              <br />
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </div>
                    <div className="mt-4">
                      <b>
                        Signature<span className="text-danger">*</span>
                      </b>
                      <Col sm={12} className="mt-2">
                        <div className="view_sigPadContainer p-2 my-3 ">
                          <img src={rowData?.patientSignature} className="signature" />
                        </div>
                      </Col>
                    </div>
                  </Col>
                  <Col>
                    <div className="mt-3 p-3">
                      <Form.Group className="mt-3  d-flex align-items-center">
                        <Row>
                          <Col className="mr-2 mt-2">
                            <b>
                              Name : <span className="text-danger">*</span>
                            </b>
                          </Col>
                          <Col>
                            <div>
                              <input
                                className={`from_control `}
                                type="text"
                                name="guardianName"
                                disabled
                                // placeholder="name"

                                value={rowData.guardianName}
                              />
                              <br />
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </div>
                    <div className=" p-3">
                      <Form.Group className="p-0 d-flex align-items-center">
                        <Row>
                          <Col className="mr-2">
                            <b>
                              Date : <span className="text-danger">*</span>
                            </b>
                          </Col>
                          <Col>
                            <div>
                              <input
                                className={`from_control`}
                                type="text"
                                name="guardianDate"
                                disabled
                                // placeholder="name"

                                value={moment(rowData.guardianDate).format('DD/MM/YY')}
                              />
                              <br />
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </div>
                    <div className="mt-4">
                      <b>
                        Signature<span className="text-danger">*</span>
                      </b>
                      <Col sm={12} className="mt-2">
                        <div className="view_sigPadContainer p-2 my-3 ">
                          <img src={rowData?.guardianSignature} className="signature" />
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <p className="textHeadline">ISOTRETINOIN SCHEDULE</p>
                <Row>
                  <p className="p-5">
                    I understand that oral isotretinoin is a medication that needs careful monitoring for safety purposes. I have had the
                    risks, benefits and potential side effects linked to oral Isotretanoin explained to me, before I consented to treatment.
                    I have also been given the opportunity to ask any questions prior to starting treatment. I agree to adhere to any
                    schedule of care my clinic doctors advise is in my best interest. I have had the typical schedule of care below given to
                    me. I will contact the clinic if I have a problem. Clinic doctors reserve the right to stop prescribing oral
                    isotretinoin for me at any stage in the course, if I am unable to adhere to any recommendations to my schedule of care.
                  </p>
                </Row>
                <Col>
                  <div className="mt-3 p-3">
                    <Form.Group className="mt-3  d-flex align-items-center">
                      <Row>
                        <Col className="mr-2 mt-2">
                          <b>
                            Patient name: <span className="text-danger">*</span>
                          </b>
                        </Col>
                        <Col>
                          <div>
                            <input
                              className={`from_control`}
                              type="text"
                              name="schedulePatientName"
                              disabled
                              // placeholder="name"

                              // onChange={(e) => {
                              //   handelOnchangeValue(e);
                              //   if (e.target.value.length > 0) {
                              //     setValidationError({ ...validationError, schedulePatientName: false });
                              //   } else {
                              //     setValidationError({ ...validationError, schedulePatientName: true });
                              //   }
                              // }}
                              value={rowData.patientName}
                            />
                            <br />
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className=" p-3">
                    <Form.Group className="p-0 d-flex align-items-center">
                      <Row>
                        <Col className="mr-2">
                          <b>
                            Date : <span className="text-danger">*</span>
                          </b>
                        </Col>
                        <Col>
                          <div>
                            <input
                              className={`from_control `}
                              type="text"
                              name="CarpalTunnelSurgery"
                              disabled
                              // placeholder="name"

                              // onChange={(e) => {
                              //   handelOnchangeValue(e);
                              //   if (e.target.value.length > 0) {
                              //     setValidationError({ ...validationError, schedulePatientDate: false });
                              //   } else {
                              //     setValidationError({ ...validationError, schedulePatientDate: true });
                              //   }
                              // }}
                              value={moment(rowData.schedulePatientDate).format('DD/MM/YY')}
                            />
                            <br />
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                  <div className="mt-4 p-3">
                    <b>
                      Signature<span className="text-danger">*</span>
                    </b>
                    <Col sm={12} className="mt-2">
                      <div className="view_sigPadContainer p-2 my-3 ">
                        <img src={rowData?.schedulePatientSignature} className="signature" />
                      </div>
                    </Col>
                  </div>
                  <div className=" p-3">
                    <Form.Group className="p-0 d-flex align-items-center">
                      <Row>
                        <Col className="mr-2">
                          <b>
                            Doctor initiating: <span className="text-danger">*</span>
                          </b>
                        </Col>
                        <Col>
                          <div>
                            <input
                              className={`from_control  `}
                              type="text"
                              name="doctorInitiating"
                              disabled
                              // placeholder="name"

                              // onChange={(e) => {
                              //   handelOnchangeValue(e);
                              //   if (e.target.value.length > 0) {
                              //     setValidationError({ ...validationError, doctorInitiating: false });
                              //   } else {
                              //     setValidationError({ ...validationError, doctorInitiating: true });
                              //   }
                              // }}
                              value={rowData.doctorName}
                            />
                            <br />
                          </div>
                        </Col>
                      </Row>
                    </Form.Group>
                  </div>
                </Col>
              </Form.Group>
              <Form.Group>
                <p className="p-4" style={{ fontWeight: 'bold' }}>
                  Typical schedule care (can vary):
                </p>
                <Table responsive striped bordered hover size="md">
                  <thead className="prescription_thead mb-3 ">
                    <tr>
                      <th>Month</th>
                      <th>Consultation</th>
                      <th>Blood Test</th>
                      <th>Script</th>
                      <th>Pregnancy test(if needed)</th>
                      <th>Price*</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Serum bHCG</td>
                      <td>£195 (consult) +(£90 male or £145 female bloods)</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£175 (followup) + £90 (bloods)</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Yes</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£175 (followup)</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£90 (bloods) +£25 (script)</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Yes</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£175 (followup)</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£90 (bloods) + £25 (script)</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>Yes</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£175 (followup)</td>
                    </tr>
                    <tr>
                      <td>8</td>
                      <td>-</td>
                      <td>-</td>
                      <td>Yes</td>
                      <td>Free of charge</td>
                      <td>£25 (script)</td>
                    </tr>
                  </tbody>
                </Table>
                <span>*price correct at the time of publication but subject to change without notice.</span>
                <p className="p-2 mt-2 ml-4" style={{ fontWeight: 'bold' }}>
                  N.B.
                </p>
                <ul className="ml-5">
                  <li>
                    Blood tests are needed as oral isotretinoin can adversely affect different parts of your body like your liver,
                    cholesterol level or blood count
                  </li>
                  <li>
                    Consultations are an opportunity for your doctors to review your care and monitor your treatment safely. Reviews tend to
                    include your skin, mental health, any side effects you may be experiencing, answer questions you have and dosage
                    changes.
                  </li>
                  <li>Consultations can be conducted online over a video call or a face to face clinic attendance.</li>
                  <li>
                    If you decide to have your blood tests done externally please be aware you will have to be responsible in organising and
                    getting the correct test results to us in a timely manner to avoid delays or disruptions.
                  </li>
                </ul>
              </Form.Group>
              <Form.Group>
                <p className="textHeadline">
                  Consent Form to Opt Out of the Mandatory
                  <br />
                  Pregnancy Prevention Programme (PPP)
                </p>
                <p>
                  I have received the information about the risks of having an affected baby if I should become pregnant while taking
                  Isotretinoin.
                </p>
                <p>
                  I am aware that the risk to a pregnancy persists throughout the duration of the treatment with Isotretinoin and during the
                  month after finishing treatment.
                </p>
                <p>I believe that I am not pregnant at this moment.</p>
                <p>
                  I believe that I am not at risk of becoming pregnant during the course of treatment with Isotretinoin or in the month
                  following treatment.
                </p>
                <p>
                  I have discussed with the doctor the risks to a pregnancy during treatment with Isotretinoin and accept these risks if I
                  take Isotretinoin.
                </p>
                <p>
                  I am prepared to take Isotretinoin without taking/using contraception or the clinic performing pregnancy tests on me prior
                  to issuing a prescription.
                </p>
                <p>
                  If I become pregnant while taking Isotretinoin or in the month after treatment, I will inform my dermatologist promptly
                  and seek advice from The London Dermatology Clinic.
                </p>
                <Row>
                  <b className="mt-2 ml-3">
                    Patient Name : <span className="text-danger mr-2">*</span>
                  </b>

                  <div>
                    <input
                      className={`from_control`}
                      type="text"
                      name="pregnancyPatientName"
                      disabled
                      // placeholder="name"

                      // onChange={(e) => {
                      //   handelOnchangeValue(e);
                      //   if (e.target.value.length > 0) {
                      //     setValidationError({ ...validationError, pregnancyPatientName: false });
                      //   } else {
                      //     setValidationError({ ...validationError, pregnancyPatientName: true });
                      //   }
                      // }}
                      value={rowData.patientName}
                    />
                    <br />
                  </div>
                </Row>
                <Row>
                  <Col>
                    <b>
                      Signed: <span className="text-danger ">*</span>
                    </b>
                  </Col>
                  <Col sm={11} className="mt-2">
                    <div className="view_sigPadContainer p-2 my-3 ">
                      <img src={rowData?.pregnancyPatientSignature} className="signature" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <b className=" ml-3 p-3">
                    Date: <span className="text-danger">*</span>
                  </b>

                  <div>
                    <input
                      className={`from_control `}
                      type="text"
                      name="doctorDate"
                      disabled
                      // placeholder="name"

                      // onChange={(e) => {
                      //   handelOnchangeValue(e);
                      //   if (e.target.value.length > 0) {
                      //     setValidationError({ ...validationError, doctorDate: false });
                      //   } else {
                      //     setValidationError({ ...validationError, doctorDate: true });
                      //   }
                      // }}
                      value={moment(rowData.doctorDate).format('DD/MM/YY')}
                    />
                    <br />
                  </div>
                </Row>
                <Row>
                  <b className="mt-2 ml-4">
                    Parent/guardian : <span className="text-danger mr-2">*</span>
                  </b>

                  <div>
                    <input
                      className={`from_control  `}
                      type="text"
                      name="PragnancyGuardianName"
                      disabled
                      // placeholder="name"

                      // onChange={(e) => {
                      //   handelOnchangeValue(e);
                      //   if (e.target.value.length > 0) {
                      //     setValidationError({ ...validationError, PragnancyGuardianName: false });
                      //   } else {
                      //     setValidationError({ ...validationError, PragnancyGuardianName: true });
                      //   }
                      // }}
                      value={rowData.pregnancyGuardianName}
                    />
                    <br />
                  </div>
                </Row>
                <Row className="p-2">
                  <Col>
                    <b>
                      Signed: <span className="text-danger ">*</span>
                    </b>
                  </Col>
                  <Col sm={11} className="mt-2">
                    <div className="view_sigPadContainer p-2 my-3 ">
                      <img src={rowData?.pregnancyGuardianSignature} className="signature" />
                    </div>
                  </Col>
                </Row>
                <Row className="p-2">
                  <b className=" ml-3 p-3">
                    Date: <span className="text-danger">*</span>
                  </b>
                  <div>
                    <input
                      className={`from_control `}
                      type="text"
                      name="doctorDate"
                      disabled
                      // placeholder="name"

                      // onChange={(e) => {
                      //   handelOnchangeValue(e);
                      //   if (e.target.value.length > 0) {
                      //     setValidationError({ ...validationError, doctorDate: false });
                      //   } else {
                      //     setValidationError({ ...validationError, doctorDate: true });
                      //   }
                      // }}
                      value={moment(rowData.doctorDate).format('DD/MM/YY')}
                    />
                    <br />
                  </div>
                </Row>
                <Row className="p-4">
                  <b className="mt-2">
                    Doctor name: <span className="text-danger mr-2">*</span>
                  </b>

                  <div>
                    <input
                      className={`from_control`}
                      type="text"
                      name="doctorInitiating"
                      disabled
                      // placeholder="name"

                      // onChange={(e) => {
                      //   handelOnchangeValue(e);
                      //   if (e.target.value.length > 0) {
                      //     setValidationError({ ...validationError, doctorInitiating: false });
                      //   } else {
                      //     setValidationError({ ...validationError, doctorInitiating: true });
                      //   }
                      // }}
                      value={rowData.doctorName}
                    />
                    <br />
                  </div>
                </Row>
                <Row className="p-2">
                  <Col>
                    <b>
                      Doctor signature : <span className="text-danger ">*</span>
                    </b>
                  </Col>
                  <Col sm={11} className="mt-2">
                    <div className="view_sigPadContainer p-2 my-3 ">
                      <img src={rowData?.pregnancyDoctorSignature} className="signature" />
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <p className="textHeadline p-2" style={{ backgroundColor: 'blue' }}>
                  PATIENT HEALTH QUESTIONNAIRE-9 (PHQ-9)
                </p>
                <Table responsive hover size="md">
                  <thead className="prescription_thead mb-3 ">
                    <tr>
                      <th style={{ width: '25%', whiteSpace: 'pre-line' }}>
                        Over the last 2 weeks, how often have you been bothered by any of the following problems?(Use “✔” to indicate your
                        answer)
                      </th>
                      <th>Not at all</th>
                      <th>Several days</th>
                      <th style={{ width: '20%', whiteSpace: 'pre-line' }}>More than half the days</th>
                      <th>Nearly every day</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1. Little interest or pleasure in doing things</td>
                      <td>
                        {rowData.littleInterest == 0 ? (
                          <input type="radio" value={rowData.littleInterest} checked defaultValue={0} name="data" />
                        ) : (
                          <input type="radio" value={rowData.littleInterest} disabled defaultValue={0} name="data" />
                        )}
                      </td>
                      <td>
                        {rowData.littleInterest == 1 ? (
                          <input type="radio" value={rowData.littleInterest} checked defaultValue={1} name="data" />
                        ) : (
                          <input type="radio" value={rowData.littleInterest} disabled defaultValue={1} name="data" />
                        )}
                      </td>
                      <td>
                        {rowData.littleInterest == 2 ? (
                          <input type="radio" value={rowData.littleInterest} checked defaultValue={2} name="data" />
                        ) : (
                          <input type="radio" value={rowData.littleInterest} disabled defaultValue={2} name="data" />
                        )}
                      </td>
                      <td>
                        {rowData.littleInterest == 3 ? (
                          <input type="radio" value={rowData.littleInterest} checked defaultValue={3} name="data" />
                        ) : (
                          <input type="radio" value={rowData.littleInterest} disabled defaultValue={3} name="data" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>2. Feeling down, depressed, or hopeless</td>
                      <td>
                        {rowData.feelingDown == 0 ? (
                          <input type="radio" value={rowData.feelingDown} checked defaultValue={0} name="data1" />
                        ) : (
                          <input type="radio" value={rowData.feelingDown} disabled defaultValue={0} name="data1" />
                        )}
                      </td>
                      <td>
                        {rowData.feelingDown == 1 ? (
                          <input type="radio" value={rowData.feelingDown} checked defaultValue={1} name="data1" />
                        ) : (
                          <input type="radio" value={rowData.feelingDown} disabled defaultValue={1} name="data1" />
                        )}
                      </td>
                      <td>
                        {rowData.feelingDown == 2 ? (
                          <input type="radio" value={rowData.feelingDown} checked defaultValue={2} name="data1" />
                        ) : (
                          <input type="radio" value={rowData.feelingDown} disabled defaultValue={2} name="data1" />
                        )}
                      </td>
                      <td>
                        {rowData.feelingDown == 3 ? (
                          <input type="radio" value={rowData.feelingDown} checked defaultValue={3} name="data1" />
                        ) : (
                          <input type="radio" value={rowData.feelingDown} disabled defaultValue={3} name="data1" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>3. Trouble falling or staying asleep, or sleeping too much</td>
                      <td>
                        {rowData.troubleFalling == 0 ? (
                          <input type="radio" value={rowData.troubleFalling} checked defaultValue={0} name="data3" />
                        ) : (
                          <input type="radio" value={rowData.troubleFalling} disabled defaultValue={0} name="data3" />
                        )}
                      </td>
                      <td>
                        {rowData.troubleFalling == 1 ? (
                          <input type="radio" value={rowData.troubleFalling} checked defaultValue={1} name="data3" />
                        ) : (
                          <input type="radio" value={rowData.troubleFalling} disabled defaultValue={1} name="data3" />
                        )}
                      </td>
                      <td>
                        {rowData.troubleFalling == 2 ? (
                          <input type="radio" value={rowData.troubleFalling} checked defaultValue={2} name="data3" />
                        ) : (
                          <input type="radio" value={rowData.troubleFalling} disabled defaultValue={2} name="data3" />
                        )}
                      </td>
                      <td>
                        {rowData.troubleFalling == 3 ? (
                          <input type="radio" value={rowData.troubleFalling} checked defaultValue={3} name="data3" />
                        ) : (
                          <input type="radio" value={rowData.troubleFalling} disabled defaultValue={3} name="data3" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>4. Feeling tired or having little energy </td>
                      <td>
                        {rowData.feelingTired == 0 ? (
                          <input type="radio" value={rowData.feelingTired} checked defaultValue={0} name="data4" />
                        ) : (
                          <input type="radio" value={rowData.feelingTired} disabled defaultValue={0} name="data4" />
                        )}
                      </td>
                      <td>
                        {rowData.feelingTired == 1 ? (
                          <input type="radio" value={rowData.feelingTired} checked defaultValue={1} name="data4" />
                        ) : (
                          <input type="radio" value={rowData.feelingTired} disabled defaultValue={1} name="data4" />
                        )}
                      </td>
                      <td>
                        {rowData.feelingTired == 2 ? (
                          <input type="radio" value={rowData.feelingTired} checked defaultValue={2} name="data4" />
                        ) : (
                          <input type="radio" value={rowData.feelingTired} disabled defaultValue={2} name="data4" />
                        )}
                      </td>
                      <td>
                        {rowData.feelingTired == 3 ? (
                          <input type="radio" value={rowData.feelingTired} checked defaultValue={3} name="data4" />
                        ) : (
                          <input type="radio" value={rowData.feelingTired} disabled defaultValue={3} name="data4" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>5. Poor appetite or overeating</td>
                      <td>
                        {rowData.poorAppetite == 0 ? (
                          <input type="radio" checked value={rowData.poorAppetite} defaultValue={0} name="data5" />
                        ) : (
                          <input type="radio" value={rowData.poorAppetite} disabled defaultValue={0} name="data5" />
                        )}
                      </td>
                      <td>
                        {rowData.poorAppetite == 1 ? (
                          <input type="radio" checked value={rowData.poorAppetite} defaultValue={1} name="data5" />
                        ) : (
                          <input type="radio" value={rowData.poorAppetite} disabled defaultValue={1} name="data5" />
                        )}
                      </td>
                      <td>
                        {rowData.poorAppetite == 2 ? (
                          <input type="radio" checked value={rowData.poorAppetite} defaultValue={2} name="data5" />
                        ) : (
                          <input type="radio" value={rowData.poorAppetite} disabled defaultValue={2} name="data5" />
                        )}
                      </td>
                      <td>
                        {rowData.poorAppetite == 3 ? (
                          <input type="radio" checked value={rowData.poorAppetite} defaultValue={3} name="data5" />
                        ) : (
                          <input type="radio" value={rowData.poorAppetite} disabled defaultValue={3} name="data5" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '25%', whiteSpace: 'pre-line' }}>
                        6. Feeling bad about yourself — or that you are a failure or have let yourself or your family down
                      </td>
                      <td>
                        {rowData.feelingBad == 0 ? (
                          <input type="radio" value={rowData.feelingBad} checked defaultValue={0} name="data6" />
                        ) : (
                          <input type="radio" value={rowData.feelingBad} disabled defaultValue={0} name="data6" />
                        )}
                      </td>
                      <td>
                        {rowData.feelingBad == 1 ? (
                          <input type="radio" value={rowData.feelingBad} checked defaultValue={1} name="data6" />
                        ) : (
                          <input type="radio" value={rowData.feelingBad} disabled defaultValue={1} name="data6" />
                        )}
                      </td>
                      <td>
                        {rowData.feelingBad == 2 ? (
                          <input type="radio" value={rowData.feelingBad} checked defaultValue={2} name="data6" />
                        ) : (
                          <input type="radio" value={rowData.feelingBad} disabled defaultValue={2} name="data6" />
                        )}
                      </td>
                      <td>
                        {rowData.feelingBad == 3 ? (
                          <input type="radio" value={rowData.feelingBad} checked defaultValue={3} name="data6" />
                        ) : (
                          <input type="radio" value={rowData.feelingBad} disabled defaultValue={3} name="data6" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '25%', whiteSpace: 'pre-line' }}>
                        7. Trouble concentrating on things, such as reading the newspaper or watching television
                      </td>
                      <td>
                        {rowData.troubleConcentrating == 0 ? (
                          <input type="radio" value={rowData.troubleConcentrating} checked defaultValue={0} name="data7" />
                        ) : (
                          <input type="radio" value={rowData.troubleConcentrating} disabled defaultValue={0} name="data7" />
                        )}
                      </td>
                      <td>
                        {rowData.troubleConcentrating == 1 ? (
                          <input type="radio" value={rowData.troubleConcentrating} checked defaultValue={0} name="data7" />
                        ) : (
                          <input type="radio" value={rowData.troubleConcentrating} disabled defaultValue={0} name="data7" />
                        )}
                      </td>
                      <td>
                        {rowData.troubleConcentrating == 2 ? (
                          <input type="radio" value={rowData.troubleConcentrating} checked defaultValue={2} name="data7" />
                        ) : (
                          <input type="radio" value={rowData.troubleConcentrating} disabled defaultValue={2} name="data7" />
                        )}
                      </td>
                      <td>
                        {rowData.troubleConcentrating == 3 ? (
                          <input type="radio" value={rowData.troubleConcentrating} checked defaultValue={3} name="data7" />
                        ) : (
                          <input type="radio" value={rowData.troubleConcentrating} disabled defaultValue={3} name="data7" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '25%', whiteSpace: 'pre-line' }}>
                        8. Moving or speaking so slowly that other people could have noticed? Or the opposite — being so fidgety or restless
                        that you have been moving around a lot more than usual
                      </td>
                      <td>
                        {rowData.movingOrSpeaking == 0 ? (
                          <input type="radio" value={rowData.movingOrSpeaking} checked defaultValue={0} name="data8" />
                        ) : (
                          <input type="radio" value={rowData.movingOrSpeaking} disabled defaultValue={0} name="data8" />
                        )}
                      </td>
                      <td>
                        {rowData.movingOrSpeaking == 1 ? (
                          <input type="radio" value={rowData.movingOrSpeaking} checked defaultValue={1} name="data8" />
                        ) : (
                          <input type="radio" value={rowData.movingOrSpeaking} disabled defaultValue={1} name="data8" />
                        )}
                      </td>
                      <td>
                        {rowData.movingOrSpeaking == 2 ? (
                          <input type="radio" value={rowData.movingOrSpeaking} checked defaultValue={2} name="data8" />
                        ) : (
                          <input type="radio" value={rowData.movingOrSpeaking} disabled defaultValue={2} name="data8" />
                        )}
                      </td>
                      <td>
                        {rowData.movingOrSpeaking == 3 ? (
                          <input type="radio" value={rowData.movingOrSpeaking} checked defaultValue={3} name="data8" />
                        ) : (
                          <input type="radio" value={rowData.movingOrSpeaking} disabled defaultValue={3} name="data8" />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '25%', whiteSpace: 'pre-line' }}>
                        9. Thoughts that you would be better off dead or of hurting yourself in some way
                      </td>
                      <td>
                        {rowData.thoughts == 0 ? (
                          <input type="radio" value={rowData.thoughts} checked defaultValue={0} name="data9" />
                        ) : (
                          <input type="radio" value={rowData.thoughts} defaultValue={0} disabled name="data9" />
                        )}
                      </td>
                      <td>
                        {rowData.thoughts == 1 ? (
                          <input type="radio" value={rowData.thoughts} checked defaultValue={1} name="data9" />
                        ) : (
                          <input type="radio" value={rowData.thoughts} defaultValue={1} disabled name="data9" />
                        )}
                      </td>
                      <td>
                        {rowData.thoughts == 2 ? (
                          <input type="radio" value={rowData.thoughts} checked defaultValue={2} name="data9" />
                        ) : (
                          <input type="radio" value={rowData.thoughts} defaultValue={2} disabled name="data9" />
                        )}
                      </td>
                      <td>
                        {rowData.thoughts == 3 ? (
                          <input type="radio" value={rowData.thoughts} checked defaultValue={3} name="data9" />
                        ) : (
                          <input type="radio" value={rowData.thoughts} defaultValue={3} disabled name="data9" />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form.Group>
              <Form.Group>
                <p style={{ marginLeft: '70%', fontWeight: 'bold', fontStyle: 'italic' }}>
                  = Total Score: <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>{rowData.totalScore}</span>
                </p>
              </Form.Group>
              <hr className="mt-4" />
              <Form.Group>
                <p>
                  If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at
                  home, or get along with other people?
                </p>
                <Row>
                  <Col>
                    <Row>
                      <p className="mr-2 mt-2">Not difficult at all</p>
                      {rowData.difficult == 1 ? (
                        <input
                          style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}
                          type="radio"
                          value={1}
                          name="data10"
                          checked
                        />
                      ) : (
                        <input
                          style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}
                          type="radio"
                          value={1}
                          name="data10"
                          disabled
                        />
                      )}
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <p className="mr-2 mt-2">Somewhat difficult</p>
                      {rowData.difficult == 2 ? (
                        <input type="radio" value={2} checked name="data10" />
                      ) : (
                        <input type="radio" value={2} disabled name="data10" />
                      )}
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <p className="mr-2 mt-2">Very difficult</p>
                      {rowData.difficult == 3 ? (
                        <input type="radio" value={3} checked name="data10" />
                      ) : (
                        <input type="radio" value={3} disabled name="data10" />
                      )}
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <p className="mr-2 mt-2">Extremely difficult</p>
                      {rowData.difficult == 4 ? (
                        <input type="radio" value={4} checked name="data10" />
                      ) : (
                        <input type="radio" value={4} disabled name="data10" />
                      )}
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group>
                <p className="text-center form_heading  mb-5">Acne Skincare Routine</p>
                <h3>Formulation</h3>
                <p>
                  Acne is a condition that is influenced by many factors such as inflammation and the oil produced by your skin. Here at the
                  London Dermatology Clinic it's important for us to offer you a holistic package to maintain good skin care. Please
                  consider incorporating the following winning skin routines as recommended by our dermatologists.
                </p>
                <p style={{ textAlign: 'center', fontSize: '20px' }}>Items are available to purchase without a prescription:</p>
                <p>1. Eucerin DermoPurifyer Oil Control Cleansing Gel: used to wash face in the morning and evening.</p>
                <p>2. La Roche-Posay Effaclar H Moisturising Cream: apply in the morning and evening after washing your face.</p>
                <p>
                  3. La Roche-Posay Anthelios Ultra-Light Fluid Sun Cream SPF50+: apply in the morning to protect your skin from the sun.
                </p>
                <p>3. Eucerin Dry Skin Intensive Lip Balm: for relief from dry lips.</p>
                <h3>Alternatives</h3>
                <p>
                  If the above items are not available or in the unlikely event your skin struggles with them then you can consider trying
                  Cetaphil Gentle Skin Cleanser as your facial wash and Avène Cleanance Mattifying Emulsion as your moisturiser.
                </p>
                <h4 style={{ fontWeight: 'bold' }}>ISOTRETINOIN - FEMALE</h4>
                <p style={{ fontStyle: 'italic' }}>
                  Please note that this information leaflet is for women, there is a separate
                  information leaflet for men.
                </p>
                <p style={{ fontSize: '18px' }}>What are the aims of this leaflet?</p>
                <p>
                  This leaflet has been written to help you understand more about isotretinoin. It
                  tells you what it is, how it works, how it is used to treat skin conditions, and
                  where you can find out more about it.
                </p>
                <p style={{ fontSize: '18px' }}>What is isotretinoin and how does it work?</p>
                <p>
                  Isotretinoin is a member of a group of drugs, closely related to vitamin A,
                  called retinoids. Isotretinoin is the generic name of a drug marketed by a
                  number of companies such as Beacon and Ranbaxy, but the original brand
                  name was Roaccutane, marketed by Roche. It works in a variety of ways,
                  targeting several of the factors that cause acne and other skin conditions
                  including production of sebum (an oily substance produced by the skin) and
                  the bacteria that contribute to acne.
                </p>
                <p style={{ fontSize: '18px' }}>What skin conditions are treated with isotretinoin?</p>
                <p>
                  Isotretinoin is licensed and most commonly used to treat severe acne, often
                  where there is scarring and pigmentation. Your dermatologist may
                  occasionally use isotretinoin to treat other skin conditions such as <span class='male_form_underline'>hidradenitis</span>
                  <span> </span><span class='male_form_underline'>suppurativa</span> and <span class='male_form_underline'>rosacea</span>. In the United Kingdom isotretinoin may only be
                  prescribed if you are under the care of a consultant dermatologist.
                </p>
                <p style={{ fontSize: '18px' }}>Will isotretinoin cure my acne?</p>
                <p>
                  A large proportion of patients (about 90%) see a significant improvement in
                  their acne with a single 16 to 24 week course of isotretinoin, although during the first few weeks of treatment the acne may worsen before it starts to
                  improve.
                </p>
                <p>
                  A small number of patients continue to have milder (although improved) acne
                  following isotretinoin that can be controlled with conventional therapies, such
                  as antibiotics. Others may relapse after stopping treatment with isotretinoin,
                  and occasionally, a prolonged or second course of treatment is required.
                </p>
                <p style={{ fontSize: '18px' }}>What dose should I take and for how long?</p>
                <p>
                  Your dermatologist will calculate the amount of isotretinoin you need
                  according to your body weight and decide on an appropriate starting dose. At
                  future appointments the dose of isotretinoin may be changed depending on
                  how well you are tolerating and responding to the medication. Most patients
                  take between 20mg and 80mg of isotretinoin each day, and a course
                  commonly lasts between 16 and 24 weeks. With doses in the lower part of
                  this range, which are often better tolerated, a course may last much longer
                  than 24 weeks. Your acne may continue to improve for up to 8 weeks after
                  treatment.
                </p>
                <p style={{ fontSize: '18px' }}>How should I take isotretinoin?</p>
                <p>
                  As isotretinoin is best absorbed into the body with food containing some
                  dietary fat, it should ideally be taken after a meal or a snack with milk rather
                  than on an empty stomach. The capsules need to be swallowed whole and
                  cannot be crushed or split open. Keep the capsules in a cool (5 to 25∞C) dark
                  place away from children.
                </p>
                <p style={{ fontSize: '18px' }}>What are the common side effects of isotretinoin?</p>
                <p>
                  In general the side effects of isotretinoin are mild and settle later in the course
                  of treatment. Dryness of the skin, lips, eyes and throat is common. An
                  increased risk of skin infections accompanies the skin becoming dry and
                  cracked, and nosebleeds may occur if the inside of the nose becomes very
                  dry. Using a moisturiser and lip balm regularly will help to prevent these
                  symptoms. Dry eyes may interfere with the wearing of contact lenses. The
                  skin may also peel and become fragile, with wounds taking longer to heal.
                  Whilst taking isotretinoin, and for six months afterwards, your skin will be
                  more delicate than usual; waxing, epilation, dermabrasion and laser treatment
                  should be avoided.
                </p>
                <p>
                  There is an increased risk of sunburn and you should use a sunscreen when
                  appropriate. Muscles and joints may ache especially after exercise.
                </p>
                <p>
                  Temporary hair thinning may occasionally occur. Isotretinoin can affect night
                  vision and it should be used with care in people whose job requires good night
                  vision, such as airline pilots and drivers.
                </p>
                <p>
                  Increased fat levels in the blood, and mild liver inflammation, are common and
                  not usually of clinical significance; these will be monitored by blood tests
                  during the course of treatment. If you have had problems with your liver or
                  kidneys, or suffer from high cholesterol or diabetes, you should discuss this
                  with your doctor prior to starting the medication.
                </p>
                <p>
                  Some brands of isotretinoin contain soya (e.g. Beacon brand) and peanut oil
                  (e.g. Roaccutane (Roche brand)), and you should inform your doctor if you
                  think you may have an allergy to these.
                </p>
                <p style={{ fontSize: '18px' }}>What are the rare side effects of isotretinoin?</p>
                <p>
                  A number of more serious side effects may occur although these are
                  fortunately rare. There have been reports of isotretinoin leading to mood
                  change although the risk of this is small. Rates of depression in acne patients
                  range from 1 to 11% in different trials, and are the same whether patients are
                  taking antibiotics or isotretinoin. However, if you have any prior history of
                  depression, or other mental illness, please discuss this with your doctor
                  before starting treatment. If you or your relatives feel that your mood is
                  changing whilst taking isotretinoin, please inform your doctor.
                </p>
                <p>
                  Rarely, inflammation of the liver or pancreas may be seen. Very rarely,
                  increased pressure in the brain may present with morning headaches and
                  disturbance of vision.
                </p>
                <p>
                  If you do suffer from a side effect then stopping or reducing the dose of
                  isotretinoin may resolve the problem. Please talk to your doctor or nurse
                  before making any changes to your medication.
                </p>
                <p style={{ fontSize: '18px' }}>May I drink alcohol whilst taking isotretinoin?</p>
                <p>
                  Ideally avoid alcohol completely, although small amounts of alcohol (up to 1
                  unit per day) are unlikely to cause any harm.
                </p>
                <p style={{ fontSize: '18px' }}>Can I take other medications at the same time as isotretinoin?</p>
                <p>
                  Most drugs can be taken safely with isotretinoin but some medications may
                  interact. It is important that you tell your doctor and pharmacist what you are currently taking before taking any new prescription or over-the-counter
                  medications.
                </p>
                <p>Drugs that adversely interact with isotretinoin include:</p>
                <ul>
                  <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>Tetracycline antibiotics (only if taken at the same time as isotretinoin)</p></li>
                  <li style={{ marginBottom: '0px' }}><p>Methotrexate</p></li>
                </ul>
                <p>
                  This is not an exhaustive list and it is important that you always inform your
                  doctor and pharmacist that you are taking isotretinoin.
                </p>
                <p>Vitamin supplements containing vitamin A should be avoided during a course
                  of isotretinoin.</p>
                <p style={{ fontSize: '18px' }}>Are they any other precautions whilst taking isotretinoin?</p>
                <p>
                  You must never share your tablets, especially with other women. Do not
                  donate blood whilst taking isotretinoin in case the blood is given to a pregnant
                  woman.
                </p>
                <p style={{ fontSize: '18px' }}>Why is there concern about women taking isotretinoin and pregnancy?</p>
                <p>
                  If a pregnant woman is exposed to isotretinoin there is a high risk that the
                  unborn baby will be harmed, leading to severe and serious defects (such as
                  abnormal appearance or mental handicap) or miscarriage. For this reason:
                  <ul>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>Isotretinoin should not be taken during pregnancy.</p></li>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>You must not become pregnant whilst taking isotretinoin, or for at least
                      one month after stopping isotretinoin</p></li>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>You should not breast feed whilst taking isotretinoin, or for one month
                      afterwards.</p></li>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>If you do become pregnant, or suspect that you may be pregnant, you
                      must stop the medication immediately and contact your doctor, so you
                      may be referred to a specialist pregnancy abnormality clinic.</p></li>
                  </ul>
                </p>
                <p style={{ fontSize: '18px' }}>What is the Pregnancy Prevention Programme?</p>
                <p>All women considered to be at risk of conceiving will be entered into the
                  Pregnancy Prevention Programme whilst taking their course of isotretinoin, in
                  order to minimise the risk of pregnancy.
                  <ul>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>Before starting isotretinoin you must use at least one, and preferably
                      two, forms of adequate contraception for at least one month before starting treatment. It is necessary to discuss general aspects of
                      contraception with your doctor or nurse.</p>
                    </li>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>Usually one form of contraception will need to be a barrier method such
                      as condoms or a cap. The progesterone-only pill (‘The Minipill’) is not
                      considered effective contraception. Certain antibiotics (e.g. amoxicillin)
                      and herbal products (e.g. St John’s Wort) can interfere with hormonal
                      contraceptives (‘The Pill’) making them less effective.</p>
                    </li>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>You will be given your first prescription for isotretinoin after having a
                      negative pregnancy test at the clinic.</p>
                    </li>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>Ideally you should start treatment on day 2 or 3 of your menstrual
                      cycle.</p>
                    </li>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>Each month you will attend the clinic for a pregnancy test and you will
                      have a final pregnancy test 5 weeks after finishing treatment.</p>
                    </li>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>You will only be supplied with 30 days of treatment on each visit
                      following a negative pregnancy test.</p>
                    </li>
                    <li style={{ marginBottom: '0px' }}><p style={{ marginBottom: '0px' }}>You must collect your prescription for isotretinoin from the pharmacy
                      within 7 days of it being signed by your doctor.</p>
                    </li>
                  </ul>
                </p>
                <p style={{ fontSize: '18px' }}>Do all women have to enter the Pregnancy Prevention Programme?</p>
                <p>Women who are not sexually active, or who are unable to become pregnant
                  due to medical reasons, or who have been sterilised, may be excluded from
                  the Pregnancy Prevention Programme.</p>
                <p style={{ fontSize: '18px' }}>Will isotretinoin affect future pregnancies?</p>
                <p>Isotretinoin does not affect fertility. One month after finishing a course of
                  isotretinoin, you may get pregnant with no additional risk to the unborn baby.</p>
                <p style={{ fontSize: '18px' }}>Where can I find out more about isotretinoin?</p>
                <p>
                  If you would like any further information about isotretinoin, or if you have any
                  concerns about your treatment, you should discuss this with your doctor or
                  pharmacist. This information sheet does not list all the side effects this drug
                  can cause. For full details, please see the drug information leaflet that comes
                  with your medicine. Your doctor will assess your medical circumstances and
                  draw your attention to side effects that may be relevant in your particular
                  case.
                </p>
                <p style={{ fontStyle: 'italic' }}>Web links to detailed leaflets:</p>
                <p style={{ fontStyle: 'italic' }}>European Dermatology Forum Guidelines on Acne</p>
                <a href='http://www.euroderm.org/edf/index.php?option=com_content&view=article&id
=50&Itemid=56'>http://www.euroderm.org/edf/index.php?option=com_content&view=article&id
                  =50&Itemid=56</a>
                <p style={{ fontWeight: 'bold' }}>
                  This leaflet aims to provide accurate information about the subject and
                  is a consensus of the views held by representatives of the British
                  Association of Dermatologists: its contents, however, may occasionally
                  differ from the advice given to you by your doctor.
                </p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                  <p style={{ fontStyle: 'italic' }}>
                    This leaflet has been assessed for readability by the British Association of
                    Dermatologists’ Patient Information Lay Review Panel
                  </p>
                  <div style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p style={{ marginBottom: '0px' }}>BRITISH ASSOCIATION OF DERMATOLOGISTS</p>
                    <p style={{ marginBottom: '0px' }}>PATIENT INFORMATION LEAFLET</p>
                    <p style={{ marginBottom: '0px' }}>PRODUCED NOVEMBER 2011</p>
                    <p style={{ marginBottom: '0px' }}>REVIEW DATE NOVEMBER 2014</p>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
        </Card>
        {userInfo.roleID == 10 ? (
          <Button
            className="px-3"
            onClick={() => {
              localStorage.setItem('patientDetailsData', JSON.stringify({ ...patientDetailsData, tab: 0 }));

              navigate(`/profile/${id}`);
            }}
          >
            <BsArrowLeft /> Back
          </Button>
        ) : (
          <Button
            className="px-3"
            onClick={() => {
              localStorage.setItem('patientDetailsData', JSON.stringify({ ...patientDetailsData, tab: 7 }));

              navigate(`/profile/${id}`);
            }}
          >
            <BsArrowLeft /> Back
          </Button>
        )}
      </Container>
    </div>
  );
};

export default EditRoaccFemale;
