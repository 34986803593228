import React, { useEffect, useRef } from 'react';
import ConsentFormsNavbar from '../../../components/common/ConsentFormsNavbar';
import ProfileBar from './ProfileBar';
import { Button, Modal, Container, Card, Form, Row, Col, Table } from 'react-bootstrap';

import { useLocation, useNavigate, useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import classNames from 'classnames';
import LdcApi from '../../../LdcApis';
import moment from 'moment';

const SpironolactioneSchedule = () => {
    const location = useLocation(); 

    const appointmentId = useParams()
  
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const [brushColor, setBrushColor] = React.useState('#000');
    const [mouseDisable, setMouseDisable] = useState('auto');
    const [loading, setLoading] = useState(false);
    const [patientSignature, setPatientSignature] = useState('');
    const [btnSubmit, setBtnSubmit] = useState(false);
    const [formData, setFormData] = useState({
      patientName: location?.state?.[0]?.patientName,
      patientDate: moment(new Date()).format('DD/MM/YYYY'),
      doctorInitiating: location?.state?.[0]?.doctorName
    });

  useEffect(() => {
    document.title = 'Spironolactione Schedule Consent Form - LDC';
    localStorage.setItem('confirmationCode', true);
  }, []);
//   const hideForms = () => {
//     navigate('/confirmation');
//   };

  //   Signature *********************************👇
  const canvasRef = useRef(null);

  const defaultProps = {
    loadTimeOffset: 5,
    lazyRadius: 0,
    brushRadius: 2,
    catenaryColor: '#0a0302',
    gridColor: 'rgba(150,150,150,0.17)',
    hideGrid: true,
    // canvasWidth: 1000,
    canvasHeight: 300,
    disabled: false,
    imgSrc: '',
    saveData: '',
    immediateLoading: false,
    hideInterface: false
  };
  const getImgPatientSignature = () => canvasRef.current.canvasContainer.children[1].toDataURL();
  const handleCanvasChange = () => {
    const saveData = getImgPatientSignature();
    setPatientSignature(saveData);
    //  if (patientSignature == '') {
    //    setValidationError({ ...validationError, patientSignature: true });
    //  } else {
    //    setValidationError({ ...validationError, patientSignature: false });
    //  }
  };
  const patientSignatureProps = {
    ...defaultProps,
    onChange: handleCanvasChange,
    ref: canvasRef,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor
  };
  //   btn submit *********************************👇
  const [validationError, setValidationError] = useState({
    patientName: false,
    patientDate: false,
    doctorInitiating: false,
    signature: false
  });
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    let formValidationError = {
      patientName: false,
      patientDate: false,
      doctorInitiating: false,
      signature: false
    };
    if (formData.patientName.trim() == '') {
      formValidationError.patientName = true;
    } else {
      formValidationError.patientName = false;
    }
    if (formData.patientDate.trim() == '') {
      formValidationError.patientDate = true;
    } else {
      formValidationError.patientDate = false;
    }
    if (formData.doctorInitiating.trim() == '') {
      formValidationError.doctorInitiating = true;
    } else {
      formValidationError.doctorInitiating = false;
    }
    if (patientSignature === '') {
      formValidationError.signature = true;
    } else {
      formValidationError.signature = false;
    }
    setValidationError(formValidationError);
    const data = Object.values(formValidationError).every((item) => item === false);

    if (data) {
      const newValues = {
        patientName: formData.patientName,
        patientSignature: patientSignature,
        appointmentDate: moment(new Date()).format(),
        doctorName: formData.doctorInitiating,
        appointmentId: appointmentId?.aid
      };
      LdcApi({
        method: 'POST',
        url: 'HealthQuestionnaire/AddCiclosporinForm',
        data: newValues
      })
        .then(async(res) => {
          setLoading(false);
          if (res?.data?.status?.isSuccess === true) {
            addToast('Spironolactione Schedule Consent form added successfully ', { appearance: 'success' });
            navigate('/thankyou');
            canvasRef.current.clear();
            setFormData({
                patientName: location?.state?.fullName,
                patientDate: moment(new Date()).format('DD/MM/YYYY'),
                doctorInitiating:''
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          setMouseDisable('auto');
          setBtnSubmit(false);
        });
    } else {
        setBtnSubmit(false);
    }
  };
  const handelOnchnage = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className="navbar_header_container br_c">
        <ConsentFormsNavbar />
      </div>
      {/* <ProfileBar /> */}
      <Container
      // style={{ pointerEvents: { mouseDisable } }}
      >
        {/* go to diary  */}
        {/* <div className="d-flex my-4 justify-content-end">
          <Button onClick={() => hideForms()} variant="outline-primary mb-3">
            Go to diary
          </Button>
        </div> */}
        <Card className="p-3">
          <p className="text-center form_heading  mb-5">Spironolactione Schedule Consent Form</p>
          <div>
            <p>
              I understand that oral Spironolactone is a medication that needs careful monitoring for safety purposes. I have had the risks,
              benefits and potential side effects linked to oral Spironolactone explained to me, before I consented to treatment. I have
              also been given the opportunity to ask any questions prior to starting treatment. I agree to adhere to any schedule of care my
              clinic doctors advise is in my best interest. I have had the typical schedule of care below given to me. I will contact the
              clinic if I have a problem. Clinic doctors reserve the right to stop prescribing oral Spironolactone for me at any stage in
              the course, if I am unable to adhere to any recommendations to my schedule of care.{' '}
            </p>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Row className="d-flex justify-content-center">
                <Col sm={12} className=" ">
                  <div className="mt-3">
                    <Form.Group className="m-0 p-0">
                      <b className="mr-2  ">Patient name: </b>
                      <input
                        className={`from_control     `}
                        type="text"
                        disabled
                        value={formData.patientName}
                        // isInvalid={touched && !!error}
                        // isValid={touched && !error}
                        name="patientName"
                        onChange={(e) => handelOnchnage(e)}
                        //  value={formData.appointmentDate}
                      />

                      <span className="text-danger">{validationError.patientName === true && 'Field is required.'}</span>
                    </Form.Group>
                    <Form.Group className="m-0 p-0">
                      <b className="mr-2 ">Date: </b>
                      <input
                        className={`from_control     `}
                        value={formData.patientDate}
                        disabled
                        type="text"
                        name="patientDate"
                        onChange={(e) => handelOnchnage(e)}
                        //  value={formData.appointmentTime}
                      />
                      <span className="text-danger">{validationError.patientDate === true && 'Field is required.'}</span>
                    </Form.Group>
                  </div>
                  <div className="mt-4">
                    <b>
                      Signature<span className="text-danger">*</span>
                    </b>
                    <Col sm={12} className="mt-2">
                      <div style={{ overflow: 'hidden' }}>
                        <CanvasDraw {...patientSignatureProps} />
                        {/* <span className="text-danger">{validationError.childSignature === true && 'Field is required.'}</span> */}
                        <br />
                        <Button
                          classNames="mt-2"
                          onClick={() => {
                            canvasRef.current.clear();
                            setPatientSignature('');
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                      <span className="text-danger">{validationError.signature === true && 'Field is required.'}</span>
                    </Col>
                  </div>
                  <Form.Group className="m-0 p-0">
                    <b className="mr-2 ">Doctor initiating: </b>
                    <input
                      className={`from_control     `}
                      type="text"
                      disabled
                      name="doctorInitiating"
                      value={formData.doctorInitiating}
                      onChange={(e) => handelOnchnage(e)}
                      //  value={formData.appointmentTime}
                    />
                    <br />
                    <span className="text-danger">{validationError.doctorInitiating === true && 'Field is required.'}</span>
                  </Form.Group>
                  <div className="mt-5">
                    <b>Typical schedule care (can vary): </b>
                    <Row className="mt-2">
                      <Col lg={12} className="">
                        <div className="w-100 py-3 border-top border-right border-left text-center">BLOOD TESTS</div>
                      </Col>
                    </Row>
                    <Table responsive striped>
                      {/* <thead>
                        <tr>
                          <th> </th>
                          <th> </th>
                          <th> </th>
                        </tr>
                      </thead> */}
                      <tbody>
                        <tr>
                          <td>Before starting treatment:</td>
                          <td>1. Initial baseline blood test </td>
                          <td>(FBC, U&Es, LFTs) </td>
                          <td>£90</td>
                        </tr>
                        <tr>
                          <td>After starting treatment:</td>
                          <td>2. Repeat 1 month later</td>
                          <td>(U&Es)</td>
                          <td>£90</td>
                        </tr>

                        {/* sad  */}
                        <tr>
                          <td> </td>
                          <td>3. Repeat 1 month later </td>
                          <td>(U&Es)</td>
                          <td>£90</td>
                        </tr>
                        <tr>
                          <td> </td>
                          <td>4. Repeat 1 month later</td>
                          <td>(U&Es)</td>
                          <td>£90</td>
                        </tr>
                        <tr>
                          <td>(If results are stable) </td>
                          <td>5. Repeat every 3-months</td>
                          <td>(U&Es)</td>
                          <td>£90</td>
                        </tr>
                        <tr>
                          <td>(After 1-year) </td>
                          <td>6. Repeat every 6-months</td>
                          <td>(U&Es)</td>
                          <td>£90</td>
                        </tr>
                      </tbody>
                    </Table>
                    <p>The above schedule frequency may change if abnormal results are found or after a treatment dose change. </p>

                    <b className="" style={{ textDecoration: 'underline' }}>
                      Follow-up Consultations
                    </b>

                    <p>
                      A review will be required by booking a follow-up consultation every 6 to 8 weeks and once the dose of the medication
                      and your skin condition is stable then it will be reduced to once every 4 months.
                    </p>
                    <p>Each follow-up consultation costs £175*. Prescription requests cost £25* if outside a consultation.</p>
                  </div>

                  <b className="mt-5" style={{ textDecoration: 'underline' }}>
                    Side Effects
                  </b>
                  <p>The following is a list of some common side effects which can be expected.</p>
                  <ul>
                    <li> Breast tenderness or swelling</li>
                    <li> Headache</li>
                    <li>Nausea</li>
                    <li>Dry skin</li>
                    <li>Reduced libido</li>
                    <li>Menstrual irregularities</li>
                    <li>Increased urinary frequency (usually in the first month</li>
                    <li>Flare up of acne which affects around 5 in 100 people treated.</li>
                    <li>Increase in potassium level in the blood (less common under the age of 45yrs)</li>
                    <li>Reduced kidney function</li>
                    <li>Reduced sodium level in the blood</li>
                  </ul>

                  <b className="mt-5" style={{ textDecoration: 'underline' }}>
                    N.B.
                  </b>
                  <ul>
                    <li>
                      Blood tests are needed as oral Spironolactone can adversely affect your kidney function and result in increased levels
                      of potassium in the blood.
                    </li>
                    <li>
                      Consultations are an opportunity for your doctors to review your care and monitor your treatment safely. Reviews tend
                      to include your skin, any side effects you may be experiencing, answer questions you have and dosage changes.{' '}
                    </li>
                    <li> Consultations can be conducted online over a video call or a face to face clinic attendance. </li>
                    <li>
                      If you decide to have your blood tests done externally please be aware that you will have to be responsible for
                      organising and getting the correct test results to us in a timely manner to avoid delays or disruptions.{' '}
                    </li>
                  </ul>
                  <span>*Price correct at the time of publication but subject to change without notice.</span>
                  {/* end  😊 */}
                </Col>
              </Row>
              <div className="d-flex my-4    ">
                <button type="submit" disabled={btnSubmit} className="btn px-4 btn-outline-primary">
                  {btnSubmit ? 'Loading....' : 'Save'}
                </button>
                {/* <CustomButtons type="submit " buttonDisabled={btnSubmit} variant="primary" className="outline- px-4" name="Save" /> */}
              </div>
            </Form>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default SpironolactioneSchedule;
