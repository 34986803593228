import moment from 'moment';
import React, { useEffect, useState } from 'react';
import LdcApi from '../../../LdcApis';
import { Table } from 'react-bootstrap';
import { DatePicker, Empty, Skeleton } from 'antd';
import dayjs from 'dayjs';
const MonthlyEODTotal = () => {
  useEffect(() => {
    document.title = 'Monthly EOD Total- LDC';
  }, []);
  //   const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [finalDateRange, setFinalDateRange] = useState({ startDate: '', endDate: '' });

  const [DateRangesss, setDateRangess] = useState({ startDate: '', endDate: '' });
  const [loading, setLoading] = useState(null);
  const [eodMonthlyData, setEodMonthlyData] = useState([]);
  const [monthTotal, setMonthTotal] = useState(0);
  const { RangePicker } = DatePicker;
  const dateFormat = 'DD/MM/YYYY';
  const now = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 1);
  function formatDateToYYYYMMDD(date) {
    // Ensure the parameter is a Date object; if not, use the current date
    const currentDate = date instanceof Date ? date : new Date();
    // Extract year, month, and day
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    // Format the date
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  // Set the default value for the RangePicker
  const defaultValue = [dayjs(formatDateToYYYYMMDD(threeMonthsAgo)), dayjs(formatDateToYYYYMMDD(now))]; // RangePicker takes an array of two moment objects
  const [selectedRange, setSelectedRange] = useState(defaultValue);

  useEffect(() => {
    const monthTotals = eodMonthlyData.reduce((a, b) => (a === undefined ? 0 : a) + (b === undefined ? 0 : b.amount), 0);
    setMonthTotal(monthTotals);
  }, [eodMonthlyData]);

  const onChange = (date, dateString) => {
    setFinalDateRange({
      ...DateRangesss,
      startDate: dateString[0],
      endDate: dateString[1]
      // .split('-').reverse().join('-')
    });
  };

  useEffect(() => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setDateRangess({
      ...DateRangesss,
      startDate: moment(firstDay).format('YYYY/MM/DD'),
      endDate: moment(new Date()).format('YYYY/MM/DD')
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    let fistDate = finalDateRange.startDate.split('/').reverse().join('/');
    let endDate = finalDateRange.endDate.split('/').reverse().join('/');

    LdcApi({
      method: 'GET',
      url: `Invoice/GetDoctorByMonth?startdate=${finalDateRange.startDate === '' ? DateRangesss.startDate : fistDate}&enddate=${
        finalDateRange.startDate === '' ? DateRangesss.endDate : endDate
      }`
    })
      .then((res) => {
        if (res?.data.length !== 0) {
          setEodMonthlyData(res?.data);
          setLoading(false);
        } else {
          setLoading(null);
        }
      })
      .catch((err) => {
        setLoading(null);
      });
  }, [finalDateRange, DateRangesss]);

  return (
    <div>
      <div className="d-flex align-items-center my-4" style={{ color: 'gray' }}>
        <b className="mr-2">Date Range : </b>
        <RangePicker
          format={dateFormat}
          onChange={onChange}
          defaultValue={selectedRange}
        />
      </div>
      {loading === true ? (
        <>
          <Skeleton />
        </>
      ) : (
        <>
          {loading === null || false ? (
            <>
              <Empty />
            </>
          ) : (
            <>
              {' '}
              <Table responsive className="table table-bordered" id="bootstrap_table">
                <thead id="bootstrap_table_head">
                  <tr>
                    <th scope="col" style={{ minWidth: 200 }}>
                      Doctor Name
                    </th>
                    <th scope="col " style={{ minWidth: 200 }}>
                      <span id="eod_table_heading">Amount</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {eodMonthlyData.map((el, i) => (
                    <tr key={i}>
                      <td>
                        <div style={{ textTransform: 'capitalize' }}>{el.name}</div>
                      </td>
                      <td>
                        <div>{el.amount}</div>
                      </td>
                    </tr>
                  ))}

                  <tr className="border_top">
                    <td style={{ fontWeight: 'bold' }}>TOTAL</td>
                    <td style={{ fontWeight: 'bold' }}>£{monthTotal.toFixed(2)}</td>
                  </tr>
                </tbody>
              </Table>{' '}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MonthlyEODTotal;
