import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/user/logo.png';
import image from '../../assets/images/logo/logo_LDC.png';
const ConsentFormsNavbar = () => {
  return (
    <div className='py-2 Consent_forms_navbar  shadow'>
      <Container>
        <div className='b_brand  align-items-center' style={{ width: '380px' }}>
          <div className='mr-2 '>
            <img style={{ width: '60px', height: '50px' }} src={Logo} />
          </div>
          <div className='mt-2'>
            {/* <span className="b_brand_title">LDC</span> */}
            <img style={{ width: '100%', height: '50px' }} src={image} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ConsentFormsNavbar;
