import LdcApi from '../../LdcApis';

class Room {
  getAllRooms = (callback) => {
    LdcApi({
      method: 'GET',
      url: 'RoomMaster/GetRoomAvailbility'
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  getAllAssist = (callback) => {
    LdcApi({
      method: 'GET',
      url: 'Appointment/GetUserListByRoleId'
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  addRoom = (roleData, callback) => {
    LdcApi({
      method: 'POST',
      url: 'RoomMaster/AddRoomMaster',
      data: roleData
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
}

export default new Room();
