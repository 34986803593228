import React, { useState } from "react";
import { LineHeightOutlined } from "@ant-design/icons";
import { borderRadius, minHeight } from "@mui/system";
import axios from "axios";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import LdcApi from "../../LdcApis";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import { useEffect } from "react";

// const currentUser = {
//   roleID: 8,
//   role: 'admin', // or 'user'
// };

// Function to fetch existing password (replace with actual implementation)

const FormValidationExample = () => {
  const { addToast } = useToasts();

  const [formData, setFormData] = useState('');

  const [existingPin, setExistingPin] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(value);
  };

  const getPasword = () => {
    LdcApi({
      method: "GET",
      url: `SystemGeneratePin/GetSystemGeneratePin`,
    })
      .then((res) => {
        console.log("res.data.status", res.data.status.isSuccess);
        if (res.data.status.isSuccess === true) {
          setFormData(`${res?.data?.data?.pin}`);
          setExistingPin(`${res?.data?.data?.pin}`);
        } else {
          setFormData({ ...formData, password: "" });
        }
      })
      .catch((err) => {
        console.log("err", err);

        // addToast('There have some error.', {
        //   appearance: 'error'
        // })
      });
  };

  console.log("formData", formData);

  useEffect(() => {
    getPasword();
  }, []);

  console.log("existingPin", existingPin);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    if (formData.trim() === "") {
      validationErrors.password = "PIN is required";
    } else if (!/^\d+$/.test(formData?.trim())) {
      validationErrors.password = "PIN should contain only numbers";
    }

    if (formData?.trim() === existingPin) {
      validationErrors.password = "PIN already exists";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      console.log("call api");
      setIsSubmitting(true);
      LdcApi({
        method: "POST",
        url: `SystemGeneratePin/UpsertSystemGeneratePin`,
        data: { pin: formData?.trim() }, // pinasdasd
      })
        .then((res) => {
          setIsSubmitting(false);
          if (res.data.isSuccess) {
            addToast("PIN Modified Successfully ", {
              appearance: "success",
            });

            setFormData(formData);
            setExistingPin(formData);
          } else {
            addToast("Pin not modified", {
              appearance: "error",
            });
            setIsSubmitting(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsSubmitting(false);
          // addToast('There have some error.', {
          //   appearance: 'error'
          // })
        });
    } else {
      console.log("error");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div style={{ width: "90vw" }}>
      <div className="manageMain">
        <h1>Set Management PIN</h1>
      </div>
      <div className="formMain">
        <form
          onSubmit={handleSubmit}
          style={{ width: "85vw", margin: "0 auto" }}
        >
          <div className="infsys">
            <div style={{ marginBottom: "10px" }}>
              <label>
                <b>Set PIN</b>{" "}
                <span style={{ lineHeight: "0", color: "red" }}>*</span>
                <input
                  value={formData}
                  required
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="PIN"
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    lineHeight: "0",
                    marginRight: "10px",
                  }}
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    left: "15rem",
                    top: "47px",
                    cursor: "pointer",
                    lineHeight: "0",
                  }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
                <div style={{ color: "red", minHeight: "10px" }}>
                  <small >
                    {errors.password && <span>{errors.password}</span>}
                  </small>
                </div>
              </label>
            </div>
 
          </div>
          <div className="btn_div">
            <Button
 
              type="submit"
              style={{ position: "", marginLeft: "-10px", marginTop: ".7rem" }}
              variant="outline-primary"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </div>
         
        </form>
      </div>
    </div>
  );
};

export default FormValidationExample;
 