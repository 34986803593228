export const tdata = [
  {
    id: 1,
    docName: 'Maham Khan',
    docAva: 'DM'
  },
  {
    id: 2,
    docName: 'Stephanie Fenlon',
    docAva: 'SF'
  },
  {
    id: 3,
    docName: 'Arvind Mohan',
    docAva: 'AM'
  },
  {
    id: 4,
    docName: 'Isha Mohan',
    docAva: 'IM'
  },
  {
    id: 5,
    docName: 'Alok Das',
    docAva: 'AD'
  }
];

export const tdataInfo = (time) => {
  return [
    {
      colId: 1,
      title: '',
      isBookAppointment: false,
      isDelete: false,
      startTime: '',
      endTime: '',
      timeId: time,
      name: '',
      service: '',
      duration: '',
      notes: '',
      email: '',
      phone: '',
      isDelete: false,
      rowSpan: '',
      doctorId: null
    },
    {
      colId: 2,
      title: '',
      isBookAppointment: false,
      isDelete: false,
      startTime: '',
      endTime: '',
      timeId: time,
      name: '',
      service: '',
      duration: '',
      notes: '',
      email: '',
      phone: '',
      isDelete: false,
      rowSpan: '',
      doctorId: null
    },
    {
      colId: 3,
      title: '',
      isBookAppointment: false,
      isDelete: false,
      startTime: '',
      endTime: '',
      timeId: time,
      name: '',
      service: '',
      duration: '',
      notes: '',
      email: '',
      phone: '',
      isDelete: false,
      rowSpan: '',
      doctorId: null
    },
    {
      colId: 4,
      title: '',
      isBookAppointment: false,
      isDelete: false,
      startTime: '',
      endTime: '',
      timeId: time,
      name: '',
      service: '',
      duration: '',
      notes: '',
      email: '',
      phone: '',
      isDelete: false,
      rowSpan: '',
      doctorId: null
    },
    {
      colId: 5,
      title: '',
      isBookAppointment: false,
      isDelete: false,
      startTime: '',
      endTime: '',
      timeId: time,
      name: '',
      service: '',
      duration: '',
      notes: '',
      email: '',
      phone: '',
      isDelete: false,
      rowSpan: '',
      doctorId: null
    },
    {
      colId: 6,
      title: '',
      isBookAppointment: false,
      isDelete: false,
      startTime: '',
      endTime: '',
      timeId: time,
      name: '',
      service: '',
      duration: '',
      notes: '',
      email: '',
      phone: '',
      isDelete: false,
      rowSpan: '',
      doctorId: null
    },
    {
      colId: 8,
      title: '',
      isBookAppointment: false,
      isDelete: false,
      startTime: '',
      endTime: '',
      timeId: time,
      name: '',
      service: '',
      duration: '',
      notes: '',
      email: '',
      phone: '',
      isDelete: false,
      rowSpan: '',
      doctorId: null
    },
    {
      colId: 9,
      title: '',
      isBookAppointment: false,
      isDelete: false,
      startTime: '',
      endTime: '',
      timeId: time,
      name: '',
      service: '',
      duration: '',
      notes: '',
      email: '',
      phone: '',
      isDelete: false,
      rowSpan: '',
      doctorId: null
    }
  ];
};

export const tbodyList = [
  // {
  //   id: 0,
  //   timeId: '',
  //   info: tdataInfo('')
  // },
  {
    id: Math.random(),
    timeId: '09:00',
    info: tdataInfo('09:00')
  },
  {
    id: Math.random(),
    timeId: '09:15',
    info: tdataInfo('09:15')
  },
  {
    id: Math.random(),
    timeId: '09:30',
    info: tdataInfo('09:30')
  },
  {
    id: Math.random(),
    timeId: '09:45',
    info: tdataInfo('09:45')
  },
  {
    id: Math.random(),
    timeId: '10:00',
    info: tdataInfo('10:00')
  },
  {
    id: Math.random(),
    timeId: '10:15',
    info: tdataInfo('10:15')
  },
  {
    id: Math.random(),
    timeId: '10:30',
    info: tdataInfo('10:30')
  },
  {
    id: Math.random(),
    timeId: '10:45',
    info: tdataInfo('10:45')
  },
  {
    id: Math.random(),
    timeId: '11:00',
    info: tdataInfo('11:00')
  },
  {
    id: Math.random(),
    timeId: '11:15',
    info: tdataInfo('11:15')
  },
  {
    id: Math.random(),
    timeId: '11:30',
    info: tdataInfo('11:30')
  },
  {
    id: Math.random(),
    timeId: '11:45',
    info: tdataInfo('11:45')
  },
  {
    id: Math.random(),
    timeId: '12:00',
    info: tdataInfo('12:00')
  },
  {
    id: Math.random(),
    timeId: '12:15',
    info: tdataInfo('12:15')
  },
  {
    id: Math.random(),
    timeId: '12:30',
    info: tdataInfo('12:30')
  },
  {
    id: Math.random(),
    timeId: '12:45',
    info: tdataInfo('12:45')
  },
  {
    id: Math.random(),
    timeId: '13:00',
    info: tdataInfo('13:00')
  },
  {
    id: Math.random(),
    timeId: '13:15',
    info: tdataInfo('13:15')
  },
  {
    id: Math.random(),
    timeId: '13:30',
    info: tdataInfo('13:30')
  },
  {
    id: Math.random(),
    timeId: '13:45',
    info: tdataInfo('13:45')
  },
  {
    id: Math.random(),
    timeId: '14:00',
    info: tdataInfo('14:00')
  },
  {
    id: Math.random(),
    timeId: '14:15',
    info: tdataInfo('14:15')
  },
  {
    id: Math.random(),
    timeId: '14:30',
    info: tdataInfo('14:30')
  },
  {
    id: Math.random(),
    timeId: '14:45',
    info: tdataInfo('14:45')
  },
  {
    id: Math.random(),
    timeId: '15:00',
    info: tdataInfo('15:00')
  },
  {
    id: Math.random(),
    timeId: '15:15',
    info: tdataInfo('15:15')
  },
  {
    id: Math.random(),
    timeId: '15:30',
    info: tdataInfo('15:30')
  },
  {
    id: Math.random(),
    timeId: '15:45',
    info: tdataInfo('15:45')
  },
  {
    id: Math.random(),
    timeId: '16:00',
    info: tdataInfo('16:00')
  },
  {
    id: Math.random(),
    timeId: '16:15',
    info: tdataInfo('16:15')
  },
  {
    id: Math.random(),
    timeId: '16:30',
    info: tdataInfo('16:30')
  },
  {
    id: Math.random(),
    timeId: '16:45',
    info: tdataInfo('16:45')
  },
  {
    id: Math.random(),
    timeId: '17:00',
    info: tdataInfo('17:00')
  },
  {
    id: Math.random(),
    timeId: '17:15',
    info: tdataInfo('17:15')
  },
  {
    id: Math.random(),
    timeId: '17:30',
    info: tdataInfo('17:30')
  },
  {
    id: Math.random(),
    timeId: '17:45',
    info: tdataInfo('17:45')
  },
  {
    id: Math.random(),
    timeId: '18:00',
    info: tdataInfo('18:00')
  },
  {
    id: Math.random(),
    timeId: '18:15',
    info: tdataInfo('18:15')
  },
  {
    id: Math.random(),
    timeId: '18:30',
    info: tdataInfo('18:30')
  },
  {
    id: Math.random(),
    timeId: '18:45',
    info: tdataInfo('18:45')
  },
  {
    id: Math.random(),
    timeId: '19:00',
    info: tdataInfo('19:00')
  },
  {
    id: Math.random(),
    timeId: '19:15',
    info: tdataInfo('19:15')
  },
  {
    id: Math.random(),
    timeId: '19:30',
    info: tdataInfo('19:30')
  },
  {
    id: Math.random(),
    timeId: '19:45',
    info: tdataInfo('19:45')
  },
  {
    id: Math.random(),
    timeId: '20:00',
    info: tdataInfo('20:00')
  },
  {
    id: Math.random(),
    timeId: '20:15',
    info: tdataInfo('20:15')
  },
  {
    id: Math.random(),
    timeId: '20:30',
    info: tdataInfo('20:30')
  },
  {
    id: Math.random(),
    timeId: '20:45',
    info: tdataInfo('20:45')
  },
  {
    id: Math.random(),
    timeId: '21:00',
    info: tdataInfo('21:00')
  }

  // {
  //   id: Math.random(),
  //   timeId: '21:15',
  //   info: tdataInfo('21:15')
  // },
  // {
  //   id: Math.random(),
  //   timeId: '21:30',
  //   info: tdataInfo('21:30')
  // },
  // {
  //   id: Math.random(),
  //   timeId: '21:45',
  //   info: tdataInfo('21:45')
  // },
  // {
  //   id: Math.random(),
  //   timeId: '22:00',
  //   info: tdataInfo('22:00')
  // }
];

export const timesArr = [
  // '08:00',
  // '08:15',
  // '08:30',
  // '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00'
  // '21:15',
  // '21:30',
  // '21:45',
  // '22:00'
];

export const optionsCursorTrueWithMargin = {
  followCursor: true,
  shiftX: -300,
  shiftY: -250
};
export const initialState = {
  cvv: '',
  cardNumber: '',
  expiryDate: '',
  tableId: '',
  cellId: '',
  activeColumnId: '',
  duration: '',
  deposit: '',
  firstName: '',
  lastName: '',
  mobile: '',
  email: '',
  provisional: false,
  contactMethod: '',
  serviceName: '',
  serviceColor: '',
  serviceId: '',
  room: '',
  startTime: '',
  endTime: '',
  start: '',
  duration: '',
  with: '',
  notes: '',
  internalMemo: '',
  date: '',
  status: '',
  patientId: 0,
  smsConfirmation: true,
  emailConfirmation: true,
  servicesCat: '',
  insurance: null,
  roomName: '',
  isReview:null,
};
export const aptError = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  serviceName: '',
  servicesCat: '',
  activeColumnId: ''
};
