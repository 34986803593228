import React, { useState } from 'react';
import { Row, Col, Button, Alert, Form } from 'react-bootstrap';

import { Formik } from 'formik';
import useAuth from '../../../hooks/useAuth';
import useScriptRef from '../../../hooks/useScriptRef';
import { useNavigate } from 'react-router-dom';
import { singIn } from '../../../redux/reducer/AuthSlice';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import LdcApi from '../../../LdcApis';
import { useToasts } from 'react-toast-notifications';
import { useEffect } from 'react';

const initialValues = {
  email: '',
  password: ''
};

const validationSchema = yup.object().shape({
  email: yup.string().email().required('Email is required.'),
  password: yup
    .string()
    .oneOf([yup.ref('password'), null])
    .min(8, 'Password must be at least 8 characters.')
    .required('Password is required.')
});

const FirebaseLogin = ({ className, ...rest }) => {
  const { addToast } = useToasts();
  const disPatch = useDispatch();
  const scriptedRef = useScriptRef();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { firebaseEmailPasswordSignIn, firebaseGoogleSignIn } = useAuth();

  const loginSubmit = async (values) => {
    setIsSubmitting(true);
    const { email, password } = values;

    localStorage.setItem('user_data', JSON.stringify(values));
    await LdcApi({
      method: 'GET',
      url: `Account/SignIn?Email=${email}&password=${password}`
    })
      .then((res) => {
        if (res.data.status.isSuccess === true) {
          setIsSubmitting(false);
          localStorage.setItem('user', JSON.stringify(res.data.data));
          navigate('/otp');
        } else {
          addToast('Invalid email or password.`', { appearance: 'error' });
        }

        // alert(res.data.otp);
      })
      .catch((error) => {
        addToast('Invalid email or password.`', { appearance: 'error' });
      });

    // await LdcApi({
    //   method: 'GET',
    //   url: `User/SendOPT?username=${username}&password=${password}`
    // })
    //   .then((res) => {
    //     alert(res.data);
    //     // navigate('/otp');
    //     // localStorage.setItem('user', JSON.stringify(values));
    //   })
    //   .catch((error) => {
    //     // addToast('Invalid username or password.`', { appearance: 'error' });
    //   });

    //  disPatch(singIn(username, password));
    // navigate('/otp');
    // await firebaseEmailPasswordSignIn(values);
  };
  return (
    <React.Fragment>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={loginSubmit}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit} autocomplete="off">
            <Row className="my-4">
              <Col lg={12} sm={12}>
                <Form.Group className="">
                  <Form.Control
                    name="email"
                    type="text"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={errors.email}
                    touched={touched.email}
                  />
                  {touched.email && errors.email && <small className="text-danger error_field">{errors.email}</small>}
                </Form.Group>
              </Col>
              <Col lg={12} sm={12}>
                <Form.Group className="">
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={errors.password}
                    // touched={touched.password}
                  />
                  {touched.password && errors.password && <small className="text-danger error_field">{errors.password}</small>}
                </Form.Group>
              </Col>
            </Row>
            {window.location.href.match('londondermatologyclinic') ? (
              <></>
            ) : (
              <Button
                className="btn-block"
                color="primary"
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="primary"
                // onClick={() => navigate('/otp')}
              >
                {/* //https://vc.londondermatologyclinic.com/ */}
                {isSubmitting ? 'Logging...' : ' Sign In'}
              </Button>
            )}
          </Form>
        )}
      </Formik>

      {/* <Formik
        initialValues={{
          email: 'demo@gmail.com',
          password: '123456',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await firebaseEmailPasswordSignIn(values.email, values.password);

            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(true);
            }
          } catch (err) {
            console.error(err);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit} className={className} {...rest}>
            <div className="form-group mb-3">
              <input
                className="form-control"
                error={touched.email && errors.email}
                label="Email Address / Username"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
              />
              {touched.email && errors.email && <small className="text-danger form-text">{errors.email}</small>}
            </div>
            <div className="form-group mb-4">
              <input
                className="form-control"
                error={touched.password && errors.password}
                label="Password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
              />
              {touched.password && errors.password && <small className="text-danger form-text">{errors.password}</small>}
            </div>

            {errors.submit && (
              <Col sm={12}>
                <Alert variant="danger">{errors.submit}</Alert>
              </Col>
            )}

            <div className="custom-control custom-checkbox  text-left mb-4 mt-2">
              <input type="checkbox" className="custom-control-input" id="customCheck1" />
              <label className="custom-control-label" htmlFor="customCheck1">
                Save credentials.
              </label>
            </div>

            <Row>
              <Col mt={2}>
                <Button
                  className="btn-block"
                  color="primary"
                  // disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="primary"
                  onClick={() => navigate('/otp')}
                >
                  Sign In
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik> */}

      {/* <Row>
        <Col sm={12}>
          <h5 className="my-3"> OR </h5>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Button onClick={googleHandler} variant="danger">
            <i className="fa fa-lock" /> Sign in with Google
          </Button>
        </Col>
      </Row> */}

      <hr />
    </React.Fragment>
  );
};

export default FirebaseLogin;
