import React, { useState, useEffect } from 'react';
import ConsentFormsNavbar from '../../components/common/ConsentFormsNavbar';

import { AiFillLock, AiFillUnlock } from 'react-icons/ai';
import { BsPhone } from 'react-icons/bs';
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { RiCloseFill } from 'react-icons/ri';
import LdcApi from '../../LdcApis';
import { useNavigate, useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';

const initialValues = {
  pin: ''
};
const validationSchema = yup.object().shape({
  pin: yup
    .string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(4, 'Must be exactly 4 digits')
    .max(4, 'Must be exactly 4 digits')
    .required('Field is required.')
});
// const steps = ['Requested', 'Received', 'Sent'];

const HealthQuestionnaireOTP = () => {
  const [GetLoading, setGetLoading] = useState(false);

  const { id, appId } = useParams();
  const { addToast } = useToasts();
  let forPatients = JSON.parse(localStorage.getItem('forPatients'));

  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'OTP  - LDC';
  }, []);
  const nextBtn = (values) => {
    setGetLoading(true);
    LdcApi({
      method: 'GET',
      url: `HealthQuestionnaire/ValidatePhoneNumber?PatientId=${id}&Mobile=${values.pin}`
    })
      .then((res) => {
        // // navigate(`/health-QuestionnaireOTP-verification/${id}/${values.pin}/appId/${appId}`);
        setGetLoading(false);
        if (res.data.status.isSuccess == true) {
          // alert(res.data.data);
          navigate(`/health-QuestionnaireOTP-verification/${id}/appId/${appId}`, { state: { ph: values.pin } });
        } else {
          addToast(`Four digits Number invalid `, {
            appearance: 'error'
          });
        }
      })
      .catch((error) => {
        setGetLoading(false);
      });
  };

  return (
    <div className="pt-5">
      <ConsentFormsNavbar />
      <Container>
        <div className="pt-5" style={{ maxWidth: '450px', display: 'flex', margin: 'auto' }}>
          <div className="mt-5 ">
            <h4 className="text-center">London Dermatology Clinic Secure Person Verification</h4>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={nextBtn}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form
                  className="mt-4"
                  style={{ maxWidth: '300px', display: 'flex', margin: 'auto', flexDirection: 'column' }}
                  onSubmit={handleSubmit}
                >
                  <div>
                    <p>Let's make sure it's really you. Enter the last four digits of your mobile phone number.</p>
                    <Form.Group>
                      <b>Enter the last 4 digits</b>
                      <Form.Control
                        className="mt-1"
                        type="text"
                        name="pin"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.pin}
                        error={errors.pin}
                      />
                      {touched.pin && errors.pin && <small className="text-danger error_field">{errors.pin}</small>}
                    </Form.Group>
                  </div>
                  <Button type="submit" className="d-block">
                    {GetLoading ? 'Loading...' : 'Next'}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HealthQuestionnaireOTP;
