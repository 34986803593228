import { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from 'react-bootstrap';

import ConsentFormsNavbar from '../../../components/common/ConsentFormsNavbar';
import ProfileBar from './ProfileBar';
import { useNavigate, useLocation, useParams } from 'react-router';

import classNames from 'classnames';
import CanvasDraw from 'react-canvas-draw';
import { useToasts } from 'react-toast-notifications';
import { Toast } from 'react-bootstrap';

import LdcApi from '../../../LdcApis';
import moment from 'moment';

const defaultData = {
  id: 0,
  appointmentId: 0,
  date: new Date(),
  patientSignatureFirst: '',
  doctorInitiating: '',
  myPrescriber: {
    explained: false,
    acknowledges: false,
  },
  startingIsotretinoin: {
    explained: false,
    acknowledges: false,
  },
  lossOfTouchWithReality: {
    explained: false,
    acknowledges: false,
  },
  mentalHealthAssessment: {
    explained: false,
    acknowledges: false,
  },
  bloodTestBeforeTreatment: {
    explained: false,
    acknowledges: false,
  },
  treatmentForMonitoring: {
    explained: false,
    acknowledges: false,
  },
  donateBlood: {
    explained: false,
    acknowledges: false,
  },
  isotretinoinCapsules: {
    explained: false,
    acknowledges: false,
  },
  contactMyPrescriber: {
    explained: false,
    acknowledges: false,
  },
  stopIsotretinoin: {
    explained: false,
    acknowledges: false,
  },
  stopTreatmentFromSection: {
    explained: false,
    acknowledges: false,
  },
  childbearingPotential: null,
  duringPregnancy: {
    explained: false,
    acknowledges: false,
  },
  contraception1A: {
    explained: false,
    acknowledges: false,
  },
  contraception1B: {
    explained: false,
    acknowledges: false,
  },
  contraception2: {
    explained: false,
    acknowledges: false,
  },
  gettingPregnant: {
    explained: false,
    acknowledges: false,
  },
  hormonalContraception: {
    explained: false,
    acknowledges: false,
  },
  byThePrescriber: {
    explained: false,
    acknowledges: false,
  },
  firstMonthAfterTheLastDose: {
    explained: false,
    acknowledges: false,
  },
  medicalRecords: {
    explained: false,
    acknowledges: false,
  },
  emergencyIUDFitted: {
    explained: false,
    acknowledges: false,
  },
  monthAfterStopping: {
    explained: false,
    acknowledges: false,
  },
  pregnantDespite: {
    explained: false,
    acknowledges: false,
  },
  possibleRisksOfIsotretinoin: null,
  patientName: '',
  patientSignature: '',
  patientSignatureDate: new Date(),
  nameOfParentOrGuardian: '',
  parentOrGuardianSignature: '',
  guardianSignatureDate: new Date(),
  patientsChildbearingPotentialA: false,
  patientsChildbearingPotentialB: false,
  patientsChildbearingPotentialC: false,
  patientSignatureTwo: '',
  pregnancyPreventionProgrammeStatusNo: false,
  pregnancyPreventionProgrammeStatusA: false,
  pregnancyPreventionProgrammeStatusB: false,
  pregnancyPreventionProgrammeStatusC: false,
  leadPrescriber: '',
  roleAndUnique: '',
  leadPrescriberSignature: '',
  leadPrescriberSignatureDate: new Date(),
  healthcareProfessional: '',
  roleAndPlaceOfWork: '',
  dateAgreed: new Date(),
  mdtDecision: '',
  contactDetailsOfDermatology: '',
};

// console.log('data', data)

const IsotretinoinConsentMessage = () => {
  const navigate = useNavigate();
  const { pid, aid } = useParams();
  const { addToast } = useToasts();
  const location = useLocation();
  let appointmentData = JSON.parse(localStorage.getItem('appointmentData'));
  const [patientDetails, setPatientDetails] = useState(null);
  const [mouseDisable, setMouseDisable] = useState('auto');
  const [loading, setLoading] = useState(false);
  const [brushColor, setBrushColor] = useState('#000');
  const [patientSignature, setPatientSignature] = useState('');
  const [formData, setFormData] = useState({
    ...defaultData,
    doctorInitiating: location?.state?.[0]?.doctorName,
    patientName: location?.state?.[0]?.patientName,
  });

  const [formBasicData, setFromBasicData] = useState({
    doctorName: location?.state?.[0]?.doctorName,
    doctorDate: moment(new Date()).format('DD/MM/YYYY'),
    patientName: location?.state?.[0]?.patientName,
    schedulePatientName: location?.state?.[0]?.patientName,
    patientDate: moment(new Date()).format('DD/MM/YYYY'),
    guardianDate: moment(new Date()).format('DD/MM/YYYY'),
    schedulePatientDate: moment(new Date()).format('DD/MM/YYYY'),
    // GMCNo: '',
    guardianName: '',
    pregnancyGuardianName: '',
    doctorInitiating: location?.state?.[0]?.doctorName,
  });
  const [formErrors, setFormErrors] = useState({});
  const [validationError, setValidationError] = useState({
    // patientSignature: false,
  });

  console.log('formBasicData', formBasicData);
  console.log('id,appId');
  const hideForms = () => {
    navigate('/confirmation');
  };
  useEffect(() => {
    document.title = 'Isotretinoin Consent Form - LDC';
    localStorage.setItem('confirmationCode', true);
  }, []);

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handelOnChangeField = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handelSlelectOne = (e) => {
    const { name } = e.target;

    setFormData({
      ...formData,
      patientsChildbearingPotentialA: name === 'patientsChildbearingPotentialA',
      patientsChildbearingPotentialB: name === 'patientsChildbearingPotentialB',
      patientsChildbearingPotentialC: name === 'patientsChildbearingPotentialC',
    });
  };

  const handleSelectFour = (e) => {
    const { name } = e.target;

    setFormData({
      ...formData,
      pregnancyPreventionProgrammeStatusNo:
        name === 'pregnancyPreventionProgrammeStatusNo',
      pregnancyPreventionProgrammeStatusA:
        name === 'pregnancyPreventionProgrammeStatusA',
      pregnancyPreventionProgrammeStatusB:
        name === 'pregnancyPreventionProgrammeStatusB',
      pregnancyPreventionProgrammeStatusC:
        name === 'pregnancyPreventionProgrammeStatusC',
    });
  };

  const handleChangeMultipleCheckbox = (xname, bool, value) => {
    setFormData((prevData) => {
      const updatedSection = { ...prevData[xname] };

      if (value === 'Prescriber Explained') {
        updatedSection.explained = bool;
      } else if (value === 'Patient Acknowledges') {
        updatedSection.acknowledges = bool;
      }

      return {
        ...prevData,
        [xname]: updatedSection,
      };
    });
  };

  const validateSignForm = () => {
    const errors = {};

    if (!formData.patientSignature) {
      errors.patientSignature = true;
    }

    if (!formData.patientSignatureFirst) {
      errors.patientSignatureFirst = true;
    }

    if (!formData.parentOrGuardianSignature) {
      errors.parentOrGuardianSignature = true;
    }

    if (!formData.patientSignatureTwo) {
      errors.patientSignatureTwo = true;
    }

    if (!formData.leadPrescriberSignature) {
      errors.leadPrescriberSignature = true;
    }

    setValidationError(errors);

    return Object.keys(errors).length === 0;
  };

  const validateForm = () => {
    const errors = {};

    if (formData?.doctorInitiating.trim() == '') {
      errors.doctorInitiating = 'Doctor Initiating field is required.';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateSignForm()) {
      setLoading(true);
      setMouseDisable('none');
      LdcApi({
        method: 'POST',
        url: 'IsotretanoinConsentForm/UpsertIsotretanoinConsentForm',
        data: { ...formData, appointmentId: aid },
      })
        .then((res) => {
          console.log('res', res);
          setLoading(false);
          setMouseDisable('auto');

          if (res?.data?.status?.isSuccess === true) {
            // clientSignatureCanvas.current.clear();
            addToast('Isotretinoin Consent Form added successfully ', {
              appearance: 'success',
            });
            setFormData(defaultData);
            navigate('/confirmation');
          } else {
            addToast('An error occurred. Please try again.', {
              appearance: 'error',
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          setMouseDisable('auto');
          addToast('An error occurred. Please try again.', {
            appearance: 'error',
          });
        });
    }

    // console.log('formData', formData);
  };

  const defaultProps = {
    loadTimeOffset: 5,
    lazyRadius: 0,
    brushRadius: 2,
    catenaryColor: '#0a0302',
    gridColor: 'rgba(150,150,150,0.17)',
    hideGrid: true,
    // canvasWidth: 1000,
    canvasHeight: 300,
    disabled: false,
    imgSrc: '',
    saveData: '',
    immediateLoading: false,
    hideInterface: false,
  };
  // SignatureCanvas 1
  const patientSignatureCanvas = useRef(null);

  const getImgHandlePatientSignature = () =>
    patientSignatureCanvas.current.canvasContainer.children[1].toDataURL();

  const handlePatientSignature = () => {
    const saveData = getImgHandlePatientSignature();
    setFormData({
      ...formData,
      patientSignature: saveData,
    });
  };
  const patientSignatureProps = {
    ...defaultProps,
    onChange: handlePatientSignature,
    ref: patientSignatureCanvas,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor,
  };

  // SignatureCanvas 2

  const patient2ndSignatureCanvas = useRef(null);
  const getImgHandlePatient2ndSignature = () =>
    patient2ndSignatureCanvas.current.canvasContainer.children[1].toDataURL();
  const handlePatient2ndSignature = () => {
    const saveData = getImgHandlePatient2ndSignature();
    setFormData({
      ...formData,
      patientSignatureFirst: saveData,
    });
  };
  const patient2ndSignatureProps = {
    ...defaultProps,
    onChange: handlePatient2ndSignature,
    ref: patient2ndSignatureCanvas,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor,
  };

  // SignatureCanvas 3 ***********************************************************************************************************************
  const PGuardianSignatureCanvas = useRef(null);
  const getImgHandlePGuardianSignature = () =>
    PGuardianSignatureCanvas.current.canvasContainer.children[1].toDataURL();
  const PGuardianSignature = () => {
    const saveData = getImgHandlePGuardianSignature();
    setFormData({
      ...formData,
      parentOrGuardianSignature: saveData,
    });
  };
  const PGuardianSignatureProps = {
    ...defaultProps,
    onChange: PGuardianSignature,
    ref: PGuardianSignatureCanvas,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor,
  };

  // // SignatureCanvas 4 ***********************************************************************************************************************
  const Patient3rdSignatureCanvas = useRef(null);
  const getImgHandlPatient3rdSignature = () =>
    Patient3rdSignatureCanvas.current.canvasContainer.children[1].toDataURL();

  const Patient3rdSignature = () => {
    const saveData = getImgHandlPatient3rdSignature();
    setFormData({
      ...formData,
      patientSignatureTwo: saveData,
    });
  };

  const Patient3rdSignatureProps = {
    ...defaultProps,
    onChange: Patient3rdSignature,
    ref: Patient3rdSignatureCanvas,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor,
  };

  // // SignatureCanvas 5 ***********************************************************************************************************************
  const LeadSignatureCanvas = useRef(null);
  const getImgLeadSignature = () =>
    LeadSignatureCanvas.current.canvasContainer.children[1].toDataURL();

  const LeadSignature = () => {
    const saveData = getImgLeadSignature();
    setFormData({
      ...formData,
      leadPrescriberSignature: saveData,
    });
  };

  const LeadSignatureProps = {
    ...defaultProps,
    onChange: LeadSignature,
    ref: LeadSignatureCanvas,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor,
  };
  //   useEffect(() => {
  //     setFormData({ ...formData, patientName: patientDetails?.fullName });
  //   }, [patientDetails]);

  // const handleChangeMultipleCheckbox = (xname, bool, value) => {

  //   // if (bool) {
  //   //   setFormData((prev) => ({ ...prev, [name]: [...prev[name], value] }));
  //   // } else {
  //   //   const arr = formData[name].filter((item) => {
  //   //     return item !== value;
  //   //   });
  //   //   setFormData((prev) => ({ ...prev, [name]: arr }));
  //   // }
  // };

  console.log('formData', formData);
  return (
    <>
      <div>
        <div className='navbar_header_container  '>
          <ConsentFormsNavbar />
        </div>
        {/* <ProfileBar setPatientDetails={setPatientDetails} /> */}
        <Container style={{ pointerEvents: { mouseDisable } }}>
          <div className='d-flex my-4 justify-content-end'></div>
          <Card className='p-3'>
            <p className='text-center form_heading  mb-5'>
              Isotretinoin Consent Form
            </p>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <p className='textHeadline'>Information for PATIENTS</p>
              <div style={{ padding: '0px 20px', marginBottom: '20px' }}>
                <p>
                  All medicines have benefits and risks. Isotretinoin works well
                  to treat severe acne, but it can cause side effects. For
                  example, your acne might get worse before it gets better. your
                  lips can get quite dry. you can get sunburnt more easily, even
                  if you do not normally burn. Some side effects may continue
                  even after stopping isotretinoin. We do not know how often
                  this happens, or for how long those side effects can last.
                </p>
                <p>
                  Isotretinoin can seriously harm an unborn baby. This is why
                  patients <span style={{ fontWeight: 'bold' }}>must not</span>{' '}
                  become pregnant during treatment with isotretinoin and for 1
                  month after isotretinoin is stopped. Patients of childbearing
                  potential (anyone who may be able to get pregnant) must enter
                  the Pregnancy Prevention Programme.
                </p>
                <p>
                  This{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    Isotretinoin Acknowledgement of Risk
                  </span>{' '}
                  Form is to make sure you know about the side effects and
                  possible risks which have been associated with isotretinoin.
                  If you are under 18 years old, then this form is also to
                  record that a second approved named healthcare professional
                  (HCP) has agreed that isotretinoin is a suitable medicine for
                  you to take.
                </p>
                <p>
                  Your prescriber will go through this form with you. Please
                  read each part of it carefully. You need to agree to all
                  applicable points to receive isotretinoin. You will receive a
                  copy of your completed form before starting isotretinoin
                  treatment - please keep the copy safe.
                </p>
              </div>
              <p className='textHeadline'>
                Information for the Lead Prescriber (the prescriber initiating
                isotretinoin treatment)
              </p>
              <div style={{ padding: '0px 20px', marginBottom: '20px' }}>
                <p>
                  The Lead Prescriber must complete this form for all patients
                  treated with isotretinoin. The Lead Prescriber and patient
                  (and usually a parent or guardian if under 18 years old)
                  should go through the form together. Be aware of safeguarding
                  concerns when talking to under 18s. The patient must be given
                  a copy of the completed form. This form is divided into 3
                  sections:
                </p>
                <ol>
                  <li className='mb-2' style={{ fontSize: '14px' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Isotretinoin risks
                    </span>
                    : a checklist of the risks of isotretinoin, including
                    possible risks to mental health and sexual function.
                  </li>
                  <li className='mb-2' style={{ fontSize: '14px' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Pregnancy Prevention Programme
                    </span>
                    : all patients of childbearing potential must enter the
                    Pregnancy Prevention Programme in order to be fully informed
                    of the risks to an unborn baby and to prevent harm to an
                    unborn baby from exposure to isotretinoin.
                  </li>
                  <li className='mb-2' style={{ fontSize: '14px' }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Acknowledgement of risk
                    </span>{' '}
                    and agreement of a second approved named healthcare
                    professional1 (if applicable):
                    <ul>
                      <li className='mb-2' style={{ fontSize: '14px' }}>
                        <span style={{ fontWeight: 'bold' }}>All patients</span>{' '}
                        (and usually a parent or guardian if under 18 years old)
                        must sign to confirm they are aware of the risks of
                        isotretinoin.
                      </li>
                      <li className='mb-2' style={{ fontSize: '14px' }}>
                        The Lead Prescriber must sign to confirm they have
                        explained the risks to the patient.
                      </li>
                      <li className='mb-2' style={{ fontSize: '14px' }}>
                        In patients under 18 years old, the Lead Prescriber must
                        also document the agreement of the second approved named
                        healthcare professional that isotretinoin is the most
                        appropriate treatment option.
                      </li>
                    </ul>
                  </li>
                </ol>
                <p>
                  <span style={{ fontWeight: 'bold' }}>WARNING</span>: Use of
                  isotretinoin for indications not listed in the Summary of
                  Product Characteristics is outside the licence. Prescribers
                  are reminded of the General Medical Council’s guidance in good
                  practice in prescribing and managing medicines and devices and
                  also Decision Making and Consent.
                </p>
              </div>
              <Form.Group>
                <p
                  className='textHeadline p-2'
                  style={{ backgroundColor: 'blue' }}
                >
                  1. Isotretinoin Risks
                </p>
                <p style={{ fontWeight: 'bold' }}>
                  The Lead Prescriber and patient must go through every point in
                  this checklist and tick once completed. All patients must
                  complete this section.
                </p>
                <table
                  className=' table-md table-striped table-bordered table-hover'
                  style={{ fontSize: '14px' }}
                >
                  <thead
                    className='prescription_thead mb-5 '
                    style={{ borderBottom: '1px solid #dee2e6' }}
                  >
                    <tr>
                      <th
                        style={{
                          width: '65%',
                          whiteSpace: 'pre-line',
                          padding: '10px',
                        }}
                      ></th>
                      <th style={{ width: '15%', padding: '10px' }}>
                        Prescriber explained
                      </th>
                      <th style={{ width: '20%', padding: '10px' }}>
                        Patient acknowledges
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I have discussed my treatment options for acne with my
                        prescriber.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'myPrescriber',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData.myPrescriber?.explained}
                          // checked={formData?.myPrescriber?.includes(
                          //   'Prescriber Explained'
                          // )}
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'myPrescriber',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData.myPrescriber?.acknowledges}
                          // checked={formData?.myPrescriber?.includes(
                          //   'Patient Acknowledges'
                          // )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I have read the relevant patient information on
                        isotretinoin (British Association of Dermatologists
                        ‘Isotretinoin Patient Guide’ and/or Medicines for
                        Children ‘Oral isotretinoin Guide for young people’).I
                        understand there are a range of possible side effects
                        associated with taking isotretinoin. I understand that
                        some side effects may continue after treatment. I agree
                        to read the patient information leaflet that comes with
                        the medicine before starting isotretinoin.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'startingIsotretinoin',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          // checked={formData?.startingIsotretinoin?.includes(
                          //   'Prescriber Explained'
                          // )}
                          checked={formData?.startingIsotretinoin.explained}
                        />
                      </td>
                      <td
                        style={{ textAlign: 'center', padding: '10px' }}

                        // checked={formData?.startingIsotretinoin?.includes(
                        //   'Patient Acknowledges'
                        // )}
                      >
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'startingIsotretinoin',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData?.startingIsotretinoin.acknowledges}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I understand that isotretinoin may be linked with
                        possible mental health and sexual function side effects.
                        Possible mental health side effects include low mood,
                        depression, anxiety, agitation, aggression, self-harm,
                        suicidal thoughts/attempts, psychosis (loss of touch
                        with reality).{' '}
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'lossOfTouchWithReality',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData?.lossOfTouchWithReality.explained}
                          // checked={formData?.lossOfTouchWithReality?.includes(
                          //   'Prescriber Explained'
                          // )}
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'lossOfTouchWithReality',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={
                            formData?.lossOfTouchWithReality.acknowledges
                          }
                          // checked={formData?.lossOfTouchWithReality?.includes(
                          //   'Patient Acknowledges'
                          // )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I have completed a mental health assessment before
                        treatment. I have been directed towards appropriate
                        mental health support if there are concerns.{' '}
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'mentalHealthAssessment',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData?.mentalHealthAssessment.explained}
                          // checked={formData?.mentalHealthAssessment?.includes(
                          //   'Prescriber Explained'
                          // )}
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'mentalHealthAssessment',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={
                            formData?.mentalHealthAssessment.acknowledges
                          }
                          // checked={formData?.mentalHealthAssessment?.includes(
                          //   'Patient Acknowledges'
                          // )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I have had blood tests for my liver and blood fat levels
                        before treatment.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'bloodTestBeforeTreatment',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData?.bloodTestBeforeTreatment.explained}
                          // checked={formData?.mentalHealthAssessment?.includes(
                          //   'Prescriber Explained'
                          // )}
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'bloodTestBeforeTreatment',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={
                            formData?.bloodTestBeforeTreatment.acknowledges
                          }
                          // checked={formData?.mentalHealthAssessment?.includes(
                          //   'Patient Acknowledges'
                          // )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I agree to attend regular clinic appointments during my
                        treatment for monitoring.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'treatmentForMonitoring',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          type='checkbox'
                          className='new_checkbox'
                          checked={formData?.treatmentForMonitoring.explained}
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'treatmentForMonitoring',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          type='checkbox'
                          className='new_checkbox'
                          checked={
                            formData?.treatmentForMonitoring.acknowledges
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I understand I must not donate blood during treatment
                        with isotretinoin and for 1 month afterwards.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'donateBlood',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          type='checkbox'
                          className='new_checkbox'
                          checked={formData?.donateBlood.explained}
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'donateBlood',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          type='checkbox'
                          className='new_checkbox'
                          checked={formData?.donateBlood.acknowledges}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I will not share my isotretinoin capsules with anyone
                        else.{' '}
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'isotretinoinCapsules',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          type='checkbox'
                          className='new_checkbox'
                          checked={formData?.isotretinoinCapsules.explained}
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'isotretinoinCapsules',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          type='checkbox'
                          className='new_checkbox'
                          checked={formData?.isotretinoinCapsules.acknowledges}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I will inform my family and/or friends that I am taking
                        isotretinoin. I will tell them about possible side
                        effects to look out for. I will ask them to tell me to
                        contact my prescriber or someone in their team if
                        needed.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'contactMyPrescriber',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData?.contactMyPrescriber.explained}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'contactMyPrescriber',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData?.contactMyPrescriber.acknowledges}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        If I have thoughts of harming myself or if there are
                        serious concerns about my mental health I will stop
                        taking isotretinoin and immediately seek medical help
                        (see end of document for contact details).
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'stopIsotretinoin',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData?.stopIsotretinoin.explained}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'stopIsotretinoin',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData?.stopIsotretinoin.acknowledges}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I have been given information on how to get in contact
                        with my prescriber or someone in their team. I will
                        contact them if I have concerns about the side effects
                        of isotretinoin or if I stop treatment (see section 3.3
                        of this form).
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'stopTreatmentFromSection',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          type='checkbox'
                          className='new_checkbox'
                          checked={formData?.stopTreatmentFromSection.explained}
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'stopTreatmentFromSection',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={
                            formData?.stopTreatmentFromSection.acknowledges
                          }
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form.Group>
              <Form.Group>
                <p
                  className='textHeadline p-2'
                  style={{ backgroundColor: 'blue' }}
                >
                  2. Pregnancy Prevention Programme
                </p>
                {/* <div style={{display:'flex',padding:'10px 0px',alignItems:'center'}}>
                                     <p style={{ fontWeight: 'bold',marginBottom:'0px', }}>Does the patient have childbearing potential?</p>
                                     <div style={{ display: 'flex', gap: '20px', width: '50%', justifyContent: 'center' }}>
                                         <div
                                             className={`yes_no_btn`}
                                             style={{ padding: '5px 30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
 
                                         >
                                             YES
                                         </div>
                                         <div
                                             className={`yes_no_btn`}
                                             style={{ padding: '5px 30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
 
                                         >
                                             NO
                                         </div>
                                     </div>
                                 </div> */}
                {/* <Row
                   className="mt-2"
                   style={{
                     justifyContent: "space-between",
                     margin: "auto",
                     alignItems: "center",
                     fontSize: "14px",
                     padding: "10px 0px",
                   }}
                 >
                   <div style={{ width: "50%", fontWeight: "bold" }}>
                     Does the patient have childbearing potential?
                   </div>
                   <div
                     style={{
                       display: "flex",
                       gap: "20px",
                       width: "50%",
                       justifyContent: "center",
                     }}
                   >
                     <div
                       className={`yes_no_btn`}
                       style={{
                         padding: "5px 30px",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center",
                       }}
                     >
                       YES
                     </div>
                     <div
                       className={`yes_no_btn`}
                       style={{
                         padding: "5px 30px",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center",
                       }}
                     >
                       NO
                     </div>
                   </div>
                 </Row> */}
                <Row
                  className='mt-2 px-4 '
                  style={{
                    justifyContent: 'space-between',
                    margin: 'auto',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>
                    Does the patient have childbearing potential?
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      width: '200px',
                      justifyContent: 'center',
                      marginRight: '90px',
                    }}
                  >
                    <div
                      className={`yes_no_btn ${formData?.childbearingPotential === true ? 'yes_Btn' : 'back'}`}
                      style={{
                        padding: '5px 30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={(e) =>
                        handleChange('childbearingPotential', true)
                      }
                    >
                      YES
                    </div>
                    <div
                      className={`yes_no_btn ${formData?.childbearingPotential === false ? 'no_Btn' : 'back'}`}
                      style={{
                        padding: '5px 30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={(e) =>
                        handleChange('childbearingPotential', false)
                      }
                    >
                      NO
                    </div>
                  </div>
                </Row>

                <p style={{ fontWeight: 'bold', marginBottom: '0px' }}>
                  A person has childbearing potential if they have a uterus and
                  at least one ovary unless they:
                </p>
                <p style={{ padding: '0px 10px', marginBottom: '0px' }}>
                  a Have undergone surgical sterilisation (tubal ligation),
                  confirmed by a healthcare professional.
                  <br />b Are post-menopausal, confirmed by a healthcare
                  professional.
                </p>
                <p style={{ fontWeight: 'bold' }}>
                  If ‘No’ go to section 3: Acknowledgment of risk
                </p>
                <p style={{ fontWeight: 'bold' }}>
                  All patients with childbearing potential (anyone who may be
                  able to get pregnant) must be entered into the Pregnancy
                  Prevention Programme.
                </p>
                <table
                  className=' table-md table-striped table-bordered table-hover'
                  style={{ fontSize: '14px' }}
                >
                  <thead
                    className='prescription_thead mb-5 '
                    style={{ borderBottom: '1px solid #dee2e6' }}
                  >
                    <tr>
                      <th
                        style={{
                          width: '65%',
                          whiteSpace: 'pre-line',
                          padding: '10px',
                        }}
                      ></th>
                      <th style={{ width: '15%', padding: '10px' }}>
                        Prescriber explained
                      </th>
                      <th style={{ width: '20%', padding: '10px' }}>
                        Patient acknowledges
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I understand that isotretinoin can seriously harm an
                        unborn baby and increases the risk of miscarriage when
                        taken during pregnancy. I know that I must not get
                        pregnant whilst taking isotretinoin and for 1 month
                        after stopping treatment.{' '}
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          name='myPrescriber'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'duringPregnancy',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData.duringPregnancy.explained}
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          type='checkbox'
                          name='myPrescriber'
                          className='new_checkbox'
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'duringPregnancy',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData.duringPregnancy.acknowledges}
                        />
                      </td>
                    </tr>
                    <tr
                      className='textHeadline p-2 not_hover_paragraph_heading'
                      style={{ backgroundColor: 'blue', textAlign: 'left' }}
                    >
                      <td colSpan={3} style={{ paddingLeft: '10px' }}>
                        <p>
                          Use of contraception - complete i) OR ii) as applies
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        <p>
                          i) I have been using contraception for the last 4
                          weeks. I agree to pregnancy testing during treatment.
                          I understand and agree to use the following
                          contraception during treatment and for 1 month
                          afterwards (either a or b):
                        </p>
                        <div
                          style={{ paddingLeft: '20px', marginBottom: '10px' }}
                        >
                          <ol type='a'>
                            <li style={{ paddingLeft: '15px' }}>
                              A hormonal contraceptive pill or contraceptive
                              injection plus a barrier method (i.e. a condom,
                              female condom, vaginal cap).
                            </li>
                            <li style={{ paddingLeft: '15px' }}>
                              The coil (IUD), intra-uterine system (IUS), or
                              contraceptive implant (highly effective
                              user-independent forms of contraception) which
                              have been in place for at least 4 weeks.
                            </li>
                          </ol>
                        </div>
                        <p>
                          ii) The prescriber and I agree I do not need to use
                          contraception because there is expected to be{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            no risk of pregnancy
                          </span>
                          during treatment and for 1 month after treatment. If
                          my situation changes, I will let my prescriber know
                          and take/use appropriate contraception to avoid
                          pregnancy.{' '}
                          <span style={{ fontWeight: 'bold' }}>
                            Prescriber to document here the agreed reason that
                            no contraception is needed. Go to ‘Unprotected Sex
                            or Pregnancy’ section.
                          </span>
                        </p>
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <div
                          style={{
                            marginTop: '50px',
                            marginBottom: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '84px',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <input
                            onChange={(e) =>
                              handleChangeMultipleCheckbox(
                                'contraception1A',
                                e.target.checked,
                                'Prescriber Explained'
                              )
                            }
                            checked={formData.contraception1A.explained}
                            type='checkbox'
                            className='new_checkbox'
                          />
                          <input
                            onChange={(e) =>
                              handleChangeMultipleCheckbox(
                                'contraception1B',
                                e.target.checked,
                                'Prescriber Explained'
                              )
                            }
                            checked={formData.contraception1B.explained}
                            type='checkbox'
                            className='new_checkbox'
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '84px',
                          }}
                        >
                          <input
                            onChange={(e) =>
                              handleChangeMultipleCheckbox(
                                'contraception2',
                                e.target.checked,
                                'Prescriber Explained'
                              )
                            }
                            checked={formData.contraception2.explained}
                            type='checkbox'
                            className='new_checkbox'
                          />
                        </div>
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <div
                          style={{
                            marginTop: '50px',
                            marginBottom: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '84px',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                          }}
                        >
                          <input
                            onChange={(e) =>
                              handleChangeMultipleCheckbox(
                                'contraception1A',
                                e.target.checked,
                                'Patient Acknowledges'
                              )
                            }
                            checked={formData.contraception1A.acknowledges}
                            type='checkbox'
                            className='new_checkbox'
                          />
                          <input
                            onChange={(e) =>
                              handleChangeMultipleCheckbox(
                                'contraception1B',
                                e.target.checked,
                                'Patient Acknowledges'
                              )
                            }
                            checked={formData.contraception1B.acknowledges}
                            type='checkbox'
                            className='new_checkbox'
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '84px',
                          }}
                        >
                          <input
                            onChange={(e) =>
                              handleChangeMultipleCheckbox(
                                'contraception2',
                                e.target.checked,
                                'Patient Acknowledges'
                              )
                            }
                            checked={formData.contraception2.acknowledges}
                            type='checkbox'
                            className='new_checkbox'
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I am aware that any contraception can fail. I know there
                        is a very small chance of getting pregnant even if I am
                        on contraception.{' '}
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'gettingPregnant',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData.gettingPregnant.explained}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'gettingPregnant',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData.gettingPregnant.acknowledges}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        Hormonal contraception can be less effective in some
                        situations. I understand I may need to use extra
                        contraception if:
                        <ul style={{ marginLeft: '15px' }}>
                          <li>
                            I am starting new medications, including antibiotics
                            or herbal preparations such as St John’s Wort
                          </li>
                          <li>I have diarrhoea and vomiting</li>
                          <li>I have missed taking my contraception</li>
                        </ul>
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'hormonalContraception',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData.hormonalContraception.explained}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'hormonalContraception',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData.hormonalContraception.acknowledges}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I understand the first prescription for isotretinoin can
                        only be given after I have had one negative pregnancy
                        test checked by the prescriber.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'byThePrescriber',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData.byThePrescriber.explained}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'byThePrescriber',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData.byThePrescriber.acknowledges}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I understand I need a pregnancy test 1 month after
                        stopping treatment because the risks to an unborn baby
                        last for 1 month after the last dose.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'firstMonthAfterTheLastDose',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={
                            formData.firstMonthAfterTheLastDose.explained
                          }
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'firstMonthAfterTheLastDose',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={
                            formData.firstMonthAfterTheLastDose.acknowledges
                          }
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        The contraceptive methods and pregnancy test results
                        have been recorded in my medical records.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'medicalRecords',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData.medicalRecords.explained}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'medicalRecords',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData.medicalRecords.acknowledges}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I understand I will need to seek medical advice as soon
                        as possible if I have unprotected sex with someone who
                        can make me pregnant. I will take emergency
                        contraception (morning-after pill or have an emergency
                        IUD fitted).{' '}
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'emergencyIUDFitted',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData.emergencyIUDFitted.explained}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'emergencyIUDFitted',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData.emergencyIUDFitted.acknowledges}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        I will stop my isotretinoin immediately, inform my
                        dermatology team and seek medical advice if I miss my
                        period, become pregnant, or suspect that I have become
                        pregnant. This applies during isotretinoin treatment and
                        for 1 month after stopping.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'monthAfterStopping',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData.monthAfterStopping.explained}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'monthAfterStopping',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData.monthAfterStopping.acknowledges}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px' }}>
                        If I get pregnant despite the above advice, I understand
                        I will need to seek medical advice as soon as possible.
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'pregnantDespite',
                              e.target.checked,
                              'Prescriber Explained'
                            )
                          }
                          checked={formData.pregnantDespite.explained}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                      <td style={{ textAlign: 'center', padding: '10px' }}>
                        <input
                          onChange={(e) =>
                            handleChangeMultipleCheckbox(
                              'pregnantDespite',
                              e.target.checked,
                              'Patient Acknowledges'
                            )
                          }
                          checked={formData.pregnantDespite.acknowledges}
                          type='checkbox'
                          className='new_checkbox'
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Form.Group>
              <Form.Group>
                <p
                  className='textHeadline p-2'
                  style={{ backgroundColor: 'blue' }}
                >
                  3. Acknowledgment of risk
                </p>
                <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  3.1 Patients
                </p>
                <div style={{ paddingLeft: '10px' }}>
                  <p className='px-4' style={{ fontWeight: 'bold' }}>
                    All patients
                  </p>
                  <p className='px-4'>
                    The patient (and if applicable their parent or guardian )
                    must sign to confirm that they understand the possible risks
                    of isotretinoin.
                  </p>
                  <Row
                    className='mt-2 px-4 '
                    style={{
                      justifyContent: 'space-between',
                      margin: 'auto',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ width: '50%', fontWeight: 'bold' }}>
                      I confirm I understand the possible risks of isotretinoin.
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        gap: '20px',
                        width: '200px',
                        justifyContent: 'center',
                        marginRight: '90px',
                      }}
                    >
                      <div
                        className={`yes_no_btn ${formData?.possibleRisksOfIsotretinoin === true ? 'yes_Btn' : 'back'}`}
                        style={{
                          padding: '5px 30px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={(e) =>
                          handleChange('possibleRisksOfIsotretinoin', true)
                        }
                      >
                        YES
                      </div>
                      <div
                        className={`yes_no_btn ${formData?.possibleRisksOfIsotretinoin === false ? 'no_Btn' : 'back'}`}
                        style={{
                          padding: '5px 30px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={(e) =>
                          handleChange('possibleRisksOfIsotretinoin', false)
                        }
                      >
                        NO
                      </div>
                    </div>
                  </Row>
                  <div>
                    <Row
                      className='px-4'
                      style={{
                        justifyContent: 'space-between',
                        margin: 'auto',
                        fontSize: '14px',
                      }}
                    >
                      <Form.Group className='mt-4' style={{ width: '20%' }}>
                        <Form.Label className='form_label'>
                          Name of Patient
                        </Form.Label>
                        <Form.Control
                          disabled
                          value={formData.patientName}
                          name='clientName'
                          type='text'
                        />
                      </Form.Group>
                    </Row>

                    <div className='mt-2 px-2'>
                      <Col sm={12} className='mt-2'>
                        <b>Signature of patient: </b>{' '}
                        <span style={{ color: 'red' }}>*</span>
                        <div className='mt-2' style={{ overflow: 'hidden' }}>
                          <CanvasDraw {...patient2ndSignatureProps} />
                          {validationError.patientSignatureFirst && (
                            <span className='text-danger'>
                              Please fill in the signature.
                            </span>
                          )}
                          {/* <span className="text-danger">{validationError.patientSignature === true && 'Field is required.'}</span> */}
                          <br />
                          <Button
                            classNames='mt-2'
                            onClick={() => {
                              console.log('hi');
                              patient2ndSignatureCanvas.current.clear();
                              setFormData({
                                ...formData,
                                patientSignatureFirst: '',
                              });
                              // setValidationError((prev) => ({
                              //   ...prev,
                              //   patientSignatureFirst: true,
                              // }));
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </Col>
                    </div>

                    <Row
                      className='px-4'
                      style={{
                        justifyContent: 'space-between',
                        margin: 'auto',
                        fontSize: '14px',
                      }}
                    >
                      <Form.Group className='mt-4' style={{ width: '20%' }}>
                        <Form.Label className='form_label'>
                          Date Of Signature
                        </Form.Label>
                        <Form.Control
                          disabled={true}
                          type='text'
                          name='patientSignatureDate'
                          value={moment(formData.patientSignatureDate).format(
                            'DD/MM/YYYY'
                          )}
                        />
                      </Form.Group>
                    </Row>
                  </div>
                  <div>
                    <Row
                      className='px-4'
                      style={{
                        justifyContent: 'space-between',
                        margin: 'auto',
                        fontSize: '14px',
                      }}
                    >
                      <Form.Group className='' style={{ width: '20%' }}>
                        <Form.Label
                          className='form_label'
                          style={{ width: 'max-content' }}
                        >
                          Name of parent or guardian (if applicable):
                        </Form.Label>
                        <Form.Control
                          name='nameOfParentOrGuardian'
                          type='text'
                          value={formData?.nameOfParentOrGuardian}
                          onChange={(e) => {
                            handelOnChangeField(e);
                          }}
                        />
                      </Form.Group>
                    </Row>

                    <div className='mt-2 px-2'>
                      <Col sm={12} className='mt-2'>
                        <b>Signature of parent or guardian (if applicable):</b>
                        <span style={{ color: 'red' }}>*</span>
                        <div className='mt-2' style={{ overflow: 'hidden' }}>
                          <CanvasDraw {...PGuardianSignatureProps} />
                          {validationError.parentOrGuardianSignature && (
                            <span className='text-danger'>
                              Please fill in the signature.
                            </span>
                          )}
                          {/* <span className="text-danger">{validationError.patientSignature === true && 'Field is required.'}</span> */}
                          <br />
                          <Button
                            classNames='mt-2'
                            onClick={() => {
                              PGuardianSignatureCanvas.current.clear();
                              setFormData({
                                ...formData,
                                parentOrGuardianSignature: '',
                              });
                              // setValidationError((prev) => ({
                              //   ...prev,
                              //   parentOrGuardianSignature: true,
                              // }));
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </Col>
                    </div>

                    <Row
                      className='px-4'
                      style={{
                        justifyContent: 'space-between',
                        margin: 'auto',
                        fontSize: '14px',
                      }}
                    >
                      <Form.Group className='mt-4' style={{ width: '20%' }}>
                        <Form.Label className='form_label'>
                          Date Of Signature
                        </Form.Label>
                        <Form.Control
                          name='guardianSignatureDate'
                          disabled
                          value={moment(formData?.guardianSignatureDate).format(
                            'DD/MM/YYYY'
                          )}
                          type='text'
                        />
                      </Form.Group>
                    </Row>
                  </div>
                  <div className='px-4'>
                    <p style={{ fontWeight: 'bold' }}>
                      Patients with childbearing potential (tick A, B or C as
                      appropriate).
                    </p>
                    <div style={{ paddingLeft: '10px' }}>
                      <div
                        style={{ width: '100%', display: 'flex', gap: '10px' }}
                      >
                        <div>
                          <input
                            name='patientsChildbearingPotentialA'
                            checked={formData?.patientsChildbearingPotentialA}
                            onChange={(e) => handelSlelectOne(e)}
                            type='checkbox'
                            className='new_checkbox'
                          />
                        </div>
                        <div>
                          <p>
                            <span style={{ fontWeight: 'bold' }}>
                              A - I confirm
                            </span>
                            I do not require contraception because there is no
                            risk of pregnancy during treatment and for 1 month
                            after treatment. I do not require pregnancy testing.
                            I will let my prescriber know if my situation
                            changes.
                          </p>
                        </div>
                      </div>
                      <div
                        style={{ width: '100%', display: 'flex', gap: '10px' }}
                      >
                        <div>
                          <input
                            name='patientsChildbearingPotentialB'
                            checked={formData?.patientsChildbearingPotentialB}
                            onChange={(e) => handelSlelectOne(e)}
                            type='checkbox'
                            className='new_checkbox'
                          />
                        </div>
                        <div>
                          <p>
                            <span style={{ fontWeight: 'bold' }}>
                              B - I confirm
                            </span>{' '}
                            I have been using the contraceptive implant or have
                            had a coil (IUD) or intra-uterine system (IUS) for
                            at least 4 weeks. I agree to pregnancy testing at
                            follow-up appointments. I may choose to do monthly
                            pregnancy tests at home because no contraception is
                            100% effective. I will let my prescriber know if my
                            situation changes.
                          </p>
                        </div>
                      </div>
                      <div
                        style={{ width: '100%', display: 'flex', gap: '10px' }}
                      >
                        <div>
                          <input
                            checked={formData?.patientsChildbearingPotentialC}
                            name='patientsChildbearingPotentialC'
                            onChange={(e) => handelSlelectOne(e)}
                            type='checkbox'
                            className='new_checkbox'
                          />
                        </div>
                        <div>
                          <p>
                            <span style={{ fontWeight: 'bold' }}>
                              C - I confirm
                            </span>{' '}
                            I have been using a hormonal contraceptive pill or
                            contraceptive injection{' '}
                            <span style={{ fontWeight: 'bold' }}>plus</span> I
                            agree to use a barrier method (i.e. a condom, female
                            condom, vaginal cap). I agree to pregnancy testing
                            every 30 days during treatment. My prescriptions
                            will be for 30 days. Prescriptions will need to be
                            collected within 7 days.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='mt-2'>
                      <Col style={{ paddingLeft: '0px' }} className='mt-2'>
                        <b>Signature of patient:</b>
                        <span style={{ color: 'red' }}>*</span>
                        <div className='mt-2' style={{ overflow: 'hidden' }}>
                          <CanvasDraw {...Patient3rdSignatureProps} />
                          {validationError.patientSignatureTwo && (
                            <span className='text-danger'>
                              Please fill in the signature.
                            </span>
                          )}

                          {/* <span className="text-danger">{validationError.patientSignature === true && 'Field is required.'}</span> */}
                          <br />
                          <Button
                            classNames='mt-2'
                            onClick={() => {
                              Patient3rdSignatureCanvas.current.clear();
                              setFormData({
                                ...formData,
                                patientSignatureTwo: '',
                              });
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </Col>
                    </div>

                    <Row
                      className=''
                      style={{
                        justifyContent: 'space-between',
                        margin: 'auto',
                        fontSize: '14px',
                      }}
                    >
                      <Form.Group className='mt-4' style={{ width: '20%' }}>
                        <Form.Label className='form_label'>
                          Date Of Signature
                        </Form.Label>
                        <Form.Control
                          disabled
                          value={moment(formData?.patientSignatureDate).format(
                            'DD/MM/YYYY'
                          )}
                          name='patientSignatureDate'
                          type='text'
                        />
                      </Form.Group>
                    </Row>
                  </div>
                </div>
                <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  3.2 Lead Prescriber
                </p>
                <div style={{ paddingLeft: '10px' }}>
                  <p className='px-4'>
                    <span style={{ fontWeight: 'bold' }}>I confirm </span>that
                    the possible risks of isotretinoin have been explained to
                    the patient.{' '}
                  </p>
                  <p className='px-4'>
                    <span style={{ fontWeight: 'bold' }}>
                      Pregnancy Prevention Programme status: (tick ‘Not
                      applicable’, A, B or C, as appropriate)
                    </span>
                    . For patients in groups ‘Not applicable’, A and B, once
                    stable on isotretinoin (after the first 1-3 months) the
                    prescription may be for longer than 30 days (up to 12
                    weeks).
                  </p>
                </div>
                <div
                  style={{
                    padding: '0px 34px',
                    display: 'flex',
                    fontSize: '14px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div>Not applicable (no childbearing potential)</div>
                    <div>
                      <input
                        name='pregnancyPreventionProgrammeStatusNo'
                        checked={formData?.pregnancyPreventionProgrammeStatusNo}
                        onChange={(e) => handleSelectFour(e)}
                        type='checkbox'
                        className='new_checkbox'
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div>Group A</div>
                    <div>
                      <input
                        type='checkbox'
                        className='new_checkbox'
                        checked={formData?.pregnancyPreventionProgrammeStatusA}
                        name='pregnancyPreventionProgrammeStatusA'
                        onChange={(e) => handleSelectFour(e)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div>Group B</div>
                    <div>
                      <input
                        type='checkbox'
                        className='new_checkbox'
                        checked={formData?.pregnancyPreventionProgrammeStatusB}
                        name='pregnancyPreventionProgrammeStatusB'
                        onChange={(e) => handleSelectFour(e)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div>Group C</div>
                    <div>
                      <input
                        name='pregnancyPreventionProgrammeStatusC'
                        checked={formData?.pregnancyPreventionProgrammeStatusC}
                        onChange={(e) => handleSelectFour(e)}
                        type='checkbox'
                        className='new_checkbox'
                      />
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: '10px' }}>
                  <Row
                    className='px-4'
                    style={{ gap: '70px', margin: 'auto', fontSize: '14px' }}
                  >
                    <Form.Group className='mt-4' style={{ width: '22%' }}>
                      <Form.Label className='form_label'>
                        Name of Lead Prescriber:
                      </Form.Label>
                      <Form.Control
                        name='leadPrescriber'
                        value={formData?.leadPrescriber}
                        onChange={(e) => handelOnChangeField(e)}
                        type='text'
                      />
                    </Form.Group>
                    <Form.Group className='mt-4' style={{ width: '22%' }}>
                      <Form.Label className='form_label'>
                        Role and unique identifier:
                      </Form.Label>
                      <Form.Control
                        name='roleAndUnique'
                        value={formData?.roleAndUnique}
                        onChange={(e) => handelOnChangeField(e)}
                        type='text'
                      />
                    </Form.Group>
                  </Row>

                  <Row
                    className='px-4'
                    style={{
                      justifyContent: 'space-between',
                      margin: 'auto',
                      fontSize: '14px',
                    }}
                  >
                    <Col style={{ paddingLeft: '0px' }} className='mt-2'>
                      <b>Signature of Lead Prescriber:</b>
                      <div className='mt-2' style={{ overflow: 'hidden' }}>
                        <CanvasDraw {...LeadSignatureProps} />
                        {validationError.leadPrescriberSignature && (
                          <span className='text-danger'>
                            Please fill in the signature.
                          </span>
                        )}
                        {/* <span className="text-danger">{validationError.patientSignature === true && 'Field is required.'}</span> */}
                        <br />
                        <Button
                          classNames='mt-2'
                          onClick={() => {
                            LeadSignatureCanvas.current.clear();
                            setFormData({
                              ...formData,
                              leadPrescriberSignature: '',
                            });
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    className='px-4'
                    style={{
                      justifyContent: 'space-between',
                      margin: 'auto',
                      fontSize: '14px',
                    }}
                  >
                    <Form.Group className='mt-4' style={{ width: '22%' }}>
                      <Form.Label className='form_label'>
                        Date of signature:
                      </Form.Label>
                      <Form.Control
                        disabled
                        name='leadPrescriberSignatureDate'
                        value={moment(
                          formData?.leadPrescriberSignatureDate
                        ).format('DD/MM/YYYY')}
                        type='text'
                      />
                    </Form.Group>
                  </Row>
                </div>
                <p style={{ fontSize: '16px' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    3.3 Agreement of second approved named healthcare
                    professional for patients under 18 years old
                  </span>
                  (to be completed by lead prescriber prior to initiation of
                  treatment):
                </p>
                <div style={{ paddingLeft: '10px' }}>
                  <p className='px-4'>
                    A second approved healthcare professional or
                    multi-disciplinary team agrees that treatment with
                    isotretinoin is appropriate (i.e. the patient has severe
                    acne for which other standard treatments have been
                    sufficiently tried and were ineffective).
                  </p>
                  <Row
                    className='px-4'
                    style={{
                      justifyContent: 'space-between',
                      margin: 'auto',
                      fontSize: '14px',
                    }}
                  >
                    <Form.Group className='mt-4' style={{ width: '100%' }}>
                      <Form.Label className='form_label'>
                        Name of second approved healthcare professional:
                      </Form.Label>
                      <Form.Control
                        value={formData?.healthcareProfessional}
                        name='healthcareProfessional'
                        onChange={(e) => handelOnChangeField(e)}
                        type='text'
                        style={{ width: '20%' }}
                      />
                    </Form.Group>
                  </Row>
                  <Row
                    className='px-4'
                    style={{
                      justifyContent: 'space-between',
                      margin: 'auto',
                      fontSize: '14px',
                    }}
                  >
                    <Form.Group className='mt-4' style={{ width: '100%' }}>
                      <Form.Label className='form_label'>
                        Role and place of work:
                      </Form.Label>
                      <Form.Control
                        name='roleAndPlaceOfWork'
                        value={formData?.roleAndPlaceOfWork}
                        onChange={(e) => handelOnChangeField(e)}
                        type='text'
                        style={{ width: '20%' }}
                      />
                    </Form.Group>
                  </Row>
                  <Row
                    className='px-4'
                    style={{
                      justifyContent: 'space-between',
                      margin: 'auto',
                      fontSize: '14px',
                    }}
                  >
                    <Form.Group className='mt-4' style={{ width: '100%' }}>
                      <Form.Label className='form_label'>
                        Date agreed:
                      </Form.Label>
                      <Form.Control
                        name='dateAgreed'
                        type='text'
                        disabled
                        value={moment(formData?.dateAgreed).format(
                          'DD/MM/YYYY'
                        )}
                        style={{ width: '20%' }}
                      />
                    </Form.Group>
                  </Row>
                  <Row
                    className='px-4'
                    style={{
                      justifyContent: 'space-between',
                      margin: 'auto',
                      fontSize: '14px',
                    }}
                  >
                    <Form.Group className='mt-4' style={{ width: '100%' }}>
                      <Form.Label className='form_label'>
                        If MDT decision, name of lead MDT clinician and date of
                        meeting:
                      </Form.Label>
                      <Form.Control
                        name='mdtDecision'
                        value={formData?.mdtDecision}
                        onChange={(e) => handelOnChangeField(e)}
                        type='text'
                        style={{ width: '20%' }}
                      />
                    </Form.Group>
                  </Row>
                  <p className='px-4'>
                    Once completed, a copy of this form should be given to the
                    patient or their parent(s) or guardian(s) and this form
                    should be stored in their medical notes and shared with all
                    healthcare professionals if needed.
                  </p>
                  <Form.Group className='px-4' style={{ marginBottom: '5px' }}>
                    <label style={{ fontWeight: 'bold' }}>
                      Contact details of dermatology team for all patients
                    </label>
                    <textarea
                      name='contactDetailsOfDermatology'
                      value={formData?.contactDetailsOfDermatology}
                      onChange={(e) => handelOnChangeField(e)}
                      cols='60'
                      rows='3'
                      className='form-control'
                    />
                  </Form.Group>
                  <p className='px-4'>
                    See below for advice on mental health contacts.
                  </p>
                  <div className='px-4'>
                    <p
                      style={{
                        color: 'red',
                        fontWeight: 'bold',
                        marginBottom: '0px',
                      }}
                    >
                      Remember:
                    </p>
                    <p style={{ fontWeight: 'bold' }}>
                      Talk to your dermatology team or GP about your treatment
                      or if you have any concerns. You should stop taking
                      isotretinoin and contact your dermatology team for further
                      advice if you have serious concerns about your mental
                      health or thoughts of harming yourself or other serious
                      side effects.{' '}
                    </p>
                    <p>
                      If you are in mental health crisis or feel suicidal during
                      treatment, contact your local mental health crisis team,
                      or NHS on 111 for support. Alternatively, you can call the
                      Samaritans to talk about anything that is upsetting you,
                      24 hours a day, 365 days a year. You can call 116 123
                      (free from any phone).
                    </p>
                    <p>
                      If you have seriously harmed yourself or feel that you may
                      be about to harm yourself, call 999 for an ambulance or go
                      straight to A&E.
                    </p>
                    <p>
                      ▼This medicine is subject to additional monitoring. This
                      will allow quick identification of new safety information.
                      You can help by reporting any side effects that you may
                      experience.
                    </p>
                    <p>
                      Pregnancies occurring during treatment and within 1 month
                      following discontinuation of treatment should be reported
                      to the MHRA and the company named in the patient
                      information leaflet who will follow up with you to record
                      the pregnancy outcome.
                    </p>
                    <p>
                      Report any suspected adverse reactions. Adverse events
                      should be reported to the MHRA, and the company listed in
                      the patient’s package information leaflet. Reporting forms
                      and information can be found at www.mhra.gov.uk/yellowcard
                      or search for MHRA Yellow Card in the Google Play or Apple
                      App Store.
                    </p>
                  </div>
                  <Form.Group>
                    <p className='textHeadline'>ISOTRETINOIN SCHEDULE</p>
                    <Row>
                      <p className='pt-1 pb-1 pl-5 pr-5'>
                        I understand that oral isotretinoin is a medication that
                        needs careful monitoring for safety purposes. I have had
                        the risks, benefits and potential side effects linked to
                        oral Isotretanoin explained to me, before I consented to
                        treatment. I have also been given the opportunity to ask
                        any questions prior to starting treatment. I agree to
                        adhere to any schedule of care my clinic doctors advise
                        is in my best interest. I have had the typical schedule
                        of care below given to me. I will contact the clinic if
                        I have a problem. Clinic doctors reserve the right to
                        stop prescribing oral isotretinoin for me at any stage
                        in the course, if I am unable to adhere to any
                        recommendations to my schedule of care.
                      </p>
                    </Row>
                    <Col>
                      <div className='mt- pl-2 ml-4'>
                        <Form.Group className='mt-3  d-flex align-items-center'>
                          <Row>
                            <Col className='mr-2 mt-2 '>
                              <b>
                                Patient name:{' '}
                                <span className='text-danger'>*</span>
                              </b>
                            </Col>
                            <Col>
                              <div>
                                <input
                                  className={`from_control    `}
                                  type='text'
                                  name='schedulePatientName'
                                  disabled
                                  // placeholder="name"
                                  value={formData?.patientName}
                                />
                                <br />
                                <span className='text-danger'>
                                  {/* {validationError.schedulePatientName === true &&
                                 'Field is required.'} */}
                                </span>
                              </div>
                            </Col>
                          </Row>

                          <Row className='' style={{ marginLeft: '12rem' }}>
                            <Col
                              className='mr-2 mt-2'
                              style={{ textAlign: 'center' }}
                            >
                              <b>
                                Date : <span className='text-danger'>*</span>
                              </b>
                            </Col>
                            <Col>
                              <div>
                                <input
                                  className={`from_control    `}
                                  type='text'
                                  name='CarpalTunnelSurgery'
                                  disabled
                                  // placeholder="name"
                                  value={moment(formData?.date).format(
                                    'DD/MM/YYYY'
                                  )}
                                />
                                <br />
                                <span className='text-danger'>
                                  {/* {validationError.schedulePatientDate === true &&
                                 'Field is required.'} */}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>

                      <div className='mt-2 px-2'>
                        <Col sm={12} className='mt-2'>
                          <b>Signature of patient: </b>
                          <span style={{ color: 'red' }}>*</span>
                          <div className='mt-2' style={{ overflow: 'hidden' }}>
                            <CanvasDraw {...patientSignatureProps} />
                            {validationError.patientSignature && (
                              <span className='text-danger'>
                                Please fill in the signature.
                              </span>
                            )}
                            {/* <span className="text-danger">{validationError.patientSignature === true && 'Field is required.'}</span> */}
                            <br />
                            <Button
                              classNames='mt-2'
                              onClick={() => {
                                console.log('hi');
                                patientSignatureCanvas.current.clear();
                                setFormData({
                                  ...formData,
                                  patientSignature: '',
                                });
                                setValidationError((prev) => ({
                                  ...prev,
                                  patientSignature: true,
                                }));
                              }}
                            >
                              Clear
                            </Button>
                          </div>
                        </Col>
                      </div>
                      <div className=' p-3'>
                        <Form.Group className='p-0 d-flex align-items-center'>
                          <Row>
                            <Col className='mr-2 mt-2'>
                              <b>
                                Doctor initiating:{' '}
                                <span className='text-danger'>*</span>
                              </b>
                            </Col>
                            <Col>
                              <div>
                                <input
                                  className={`from_control   `}
                                  type='text'
                                  name='doctorInitiating'
                                  // placeholder="name"
                                  required
                                  onChange={(e) => {
                                    handelOnChangeField(e);
                                  }}
                                  value={formData?.doctorInitiating}
                                />
                                <br />
                                {console.log('formErrors', formErrors)}
                                {formErrors?.doctorInitiating && (
                                  <span className='text-danger'>
                                    {formErrors?.doctorInitiating}
                                  </span>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </Form.Group>
                      </div>
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <p className='p-4' style={{ fontWeight: 'bold' }}>
                      Typical schedule care (can vary):
                    </p>
                    <Table responsive striped bordered hover size='md'>
                      <thead className='prescription_thead mb-3 '>
                        <tr>
                          <th>Month</th>
                          <th>Consultation</th>
                          <th>Blood Test</th>
                          <th>Script</th>
                          <th>Pregnancy test(if needed)</th>
                          <th>Price*</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Yes</td>
                          <td>Yes</td>
                          <td>Yes</td>
                          <td>Serum bHCG</td>
                          <td>
                            £195 (consult) +(£90 male or £145 female bloods)
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Yes</td>
                          <td>Yes</td>
                          <td>Yes</td>
                          <td>Free of charge</td>
                          <td>£175 (followup) + £90 (bloods)</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Yes</td>
                          <td>-</td>
                          <td>Yes</td>
                          <td>Free of charge</td>
                          <td>£175 (followup)</td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>-</td>
                          <td>Yes</td>
                          <td>Yes</td>
                          <td>Free of charge</td>
                          <td>£90 (bloods) +£25 (script)</td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Yes</td>
                          <td>-</td>
                          <td>Yes</td>
                          <td>Free of charge</td>
                          <td>£175 (followup)</td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>-</td>
                          <td>Yes</td>
                          <td>Yes</td>
                          <td>Free of charge</td>
                          <td>£90 (bloods) + £25 (script)</td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Yes</td>
                          <td>-</td>
                          <td>Yes</td>
                          <td>Free of charge</td>
                          <td>£175 (followup)</td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>-</td>
                          <td>-</td>
                          <td>Yes</td>
                          <td>Free of charge</td>
                          <td>£25 (script)</td>
                        </tr>
                      </tbody>
                    </Table>
                    <span>
                      *price correct at the time of publication but subject to
                      change without notice.
                    </span>
                    <p className='p-2 mt-2 ml-4' style={{ fontWeight: 'bold' }}>
                      N.B.
                    </p>
                    <ul className='ml-5'>
                      <li>
                        Blood tests are needed as oral isotretinoin can
                        adversely affect different parts of your body like your
                        liver, cholesterol level or blood count
                      </li>
                      <li>
                        Consultations are an opportunity for your doctors to
                        review your care and monitor your treatment safely.
                        Reviews tend to include your skin, mental health, any
                        side effects you may be experiencing, answer questions
                        you have and dosage changes.
                      </li>
                      <li>
                        Consultations can be conducted online over a video call
                        or a face to face clinic attendance.
                      </li>
                      <li>
                        If you decide to have your blood tests done externally
                        please be aware you will have to be responsible in
                        organising and getting the correct test results to us in
                        a timely manner to avoid delays or disruptions.
                      </li>
                    </ul>
                  </Form.Group>
                </div>
              </Form.Group>

              <div className='mt-2'>
                {/* Display the first validation message, if any */}
                {validationError.patientSignature && (
                  <span className='text-danger d-block'>
                    Please fill in the patient signature.
                  </span>
                )}

                {validationError.patientSignatureFirst &&
                  !validationError.patientSignature && (
                    <span className='text-danger d-block'>
                      Please fill in the second patient signature.
                    </span>
                  )}

                {validationError.parentOrGuardianSignature &&
                  !validationError.patientSignature &&
                  !validationError.patientSignatureFirst && (
                    <span className='text-danger d-block'>
                      Please fill in the parent or guardian signature.
                    </span>
                  )}

                {validationError.patientSignatureTwo &&
                  !validationError.patientSignature &&
                  !validationError.patientSignatureFirst &&
                  !validationError.parentOrGuardianSignature && (
                    <span className='text-danger d-block'>
                      Please fill in the third patient signature.
                    </span>
                  )}

                {validationError.leadPrescriberSignature &&
                  !validationError.patientSignature &&
                  !validationError.patientSignatureFirst &&
                  !validationError.parentOrGuardianSignature &&
                  !validationError.patientSignatureTwo && (
                    <span className='text-danger d-block'>
                      Please fill in the lead prescriber signature.
                    </span>
                  )}

                {/* Button */}
                {loading ? (
                  <Button>Saving....</Button>
                ) : (
                  <Button type='submit'>Save</Button>
                )}
              </div>

              {/* {loading ? (
                 <Button>Saving....</Button>
               ) : (
                 <Button type='submit'>Save</Button>
               )} */}
            </Form>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default IsotretinoinConsentMessage;
