import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { GoAlert } from 'react-icons/go';

const ChamberCatchupBlocks = (props) => {

  const { catchupBlocks, onChamberSubmit } = props;

  const [messageShowModal, setMessageShowModal] = useState(false);

  const formsClose = () => {
    setMessageShowModal(false);
  };

  useEffect(() => {
    if (catchupBlocks) {
      setMessageShowModal(true);
    }
  }, [catchupBlocks]);


  return (
    <>
      {catchupBlocks ? (
        <Modal show={messageShowModal} centered size="md" onHide={() => formsClose()}>
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              <GoAlert style={{ color: '#ff8f39' }} className="mx-1" /> Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>This clinic date has slots still appearing on the diary. These may be blocked or catch up slots. Are you sure you wish to cancel this shift?</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button className="d-flex justify-content-end" onClick={onChamberSubmit}>
              YES
            </Button>
            <Button variant="danger" className="d-flex justify-content-end danger" onClick={() => setMessageShowModal(false)}>
              NO
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export default ChamberCatchupBlocks;
