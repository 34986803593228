import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Card, Table } from 'react-bootstrap';

function Skelton() {
  return (
    <div style={{ marginTop: '1rem' }}>
      <Card>
        <Card.Body>
          <Table responsive>
            <Stack spacing={1}>
              {[...Array(6)].map((elementInArray, index) => (
                <Skeleton key={index} variant="rectangular" width="100%" height={60} sx={{ bgcolor: '#e2e8f0' }} />
              ))}
            </Stack>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Skelton;
