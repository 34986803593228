import { useEffect, useRef, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';

import ProfileBar from '../ProfileBar';
import {
  Container,
  Button,
  Card,
  Form,
  Row,
  Col,
  Table,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';

import CanvasDraw from 'react-canvas-draw';
import classNames from 'classnames';
import moment from 'moment';
import LdcApi from '../../../../LdcApis';
import { Toast } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import session from 'redux-persist/lib/storage/session';
// import { use } from "video.js/dist/types/tech/middleware";

const sessions = Array.from({ length: 19 }, (_, i) => i + 2);
const EditDermaluxDailyConsentForm = () => {
  const { addToast } = useToasts();
  let userInfo = JSON.parse(localStorage.getItem('user_info'));

  let patientDetailsData = JSON.parse(
    localStorage.getItem('patientDetailsData')
  );
  let appointmentData = JSON.parse(localStorage.getItem('appointmentData'));

  const { id, viewId } = useParams();
  const [loading, setLoading] = useState(false);

  const [mouseDisable, setMouseDisable] = useState('auto');
  const [clientSignature, setClientSignature] = useState('');
  const [practitionerSignature, setPractitionerSignature] = useState('');
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [notes, setNotes] = useState(''); // Define the notes state
  const [programme, setProgramme] = useState();

  //const [sessions, setSession] = useState();
  const [selectedSession, setSelectedSession] = useState('');
  const [brushColor, setBrushColor] = useState('#000');
  const [date, setDate] = useState();
  const [formData, setFormData] = useState({
    // doctorDate: moment(new Date()).format("DD/MM/YYYY"),

    date: '',
    programme: '',
    session: '',
    notes: '',
    clientSignature: '',
    practitionerSignature: '',
  });

  useEffect(() => {
    document.title = 'Dermalux Daily Consent Form - LDC';
    // localStorage.setItem("confirmationCode", true);
    setLoading(true);
    LdcApi({
      method: 'GET',
      url: `DermaluxDailyConsentForm/GetDermaluxDailyConsentFormData?id=${viewId}`,
    })
      .then((response) => {
        console.log('res--->', response);
        console.log('response-->', response.data.status.isSuccess);
        // if(response.data.length > 0){
        if (response.data.status.isSuccess === true) {
          console.log('response----->', response.data.data);

          setLoading(false);
          setFormData(response?.data?.data);
          //   setRowData(response?.data?.data);
        }

        //   }
        else {
          //   navigate('/diary');
        }
      })
      .catch((error) => {
        setLoading(false);
        navigate('/diary');
      });
  }, []);

  const navigate = useNavigate();

  const hideForms = () => {
    navigate('/confirmation');
  };
  const handleProgrammeChange = (e) => {
    setProgramme(e.target.value);
  };

  const handleSessionChange = (e) => {
    setSelectedSession(e.target.value);
  };

  const [validationError, setValidationError] = useState({
    hasCoronavirus: false,
    authorisedRepresentative: false,
    treatmentProcedure: false,
    unansweredQuestions: false,
    problemsAfterTheSurgery: false,
    missedDiagnosisSuchAsCancer: false,
    patientSignature: false,
    childSignature: false,
    doctorSignature: false,
    NameOfDoctor: false,
    NameOfTreatment: false,
    nameOfPatient: false,
  });

  /*   Signature Start   */

  const clientSignatureCanvas = useRef(null);
  const practitionerSignatureCanvas = useRef(null);

  const defaultProps = {
    loadTimeOffset: 5,
    lazyRadius: 0,
    brushRadius: 2,
    catenaryColor: '#0a0302',
    gridColor: 'rgba(150,150,150,0.17)',
    hideGrid: true,
    // canvasWidth: 1000,
    canvasHeight: 150,
    disabled: false,
    imgSrc: '',
    saveData: '',
    immediateLoading: false,
    hideInterface: false,
  };

  const getImgClientSignature = () =>
    clientSignatureCanvas.current.canvasContainer.children[1].toDataURL();
  const getImgPractitionerSignature = () =>
    practitionerSignatureCanvas.current.canvasContainer.children[1].toDataURL();

  const handleClientSignature = () => {
    const saveData = getImgClientSignature();
    setClientSignature(saveData);
    if (clientSignature === '') {
      setValidationError({ ...validationError, clientSignature: true });
    } else {
      setValidationError({ ...validationError, clientSignature: false });
    }
  };

  const handlePractitionerSignature = () => {
    const saveData = getImgPractitionerSignature();
    setPractitionerSignature(saveData);
    if (practitionerSignature === '') {
      setValidationError({ ...validationError, practitionerSignature: true });
    } else {
      setValidationError({ ...validationError, practitionerSignature: false });
    }
  };

  const clientSignatureProps = {
    ...defaultProps,
    onChange: handleClientSignature,
    ref: clientSignatureCanvas,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor,
  };

  const practitionerSignatureProps = {
    ...defaultProps,
    onChange: handlePractitionerSignature,
    ref: practitionerSignatureCanvas,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData)
  };

  /*  Signature End  */
  return (
    <>
      <div>
        <ProfileBar />
        <Container style={{ pointerEvents: { mouseDisable } }}>
          <div className='d-flex my-4 justify-content-end'>
            <Button onClick={() => hideForms()} variant='outline-primary mb-3'>
              Go to diary
            </Button>
          </div>
          <Card className='p-3'>
            <p className='text-center form_heading  mb-5'>
              Dermalux Daily Consent Form
            </p>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Row className='d-flex justify-content-center'>
                <Col sm={12} className=' '>
                  <Form.Group className='justify-content-center align-items-center'>
                    <Table
                      className='tableStyle'
                      responsive
                      striped
                      bordered
                      hover
                      size='md'
                    >
                      <thead
                        className='prescription_thead mb-3 '
                        style={{ backgroundColor: 'gray' }}
                      >
                        <tr>
                          <th style={{ width: '13%' }}>Date</th>
                          <th style={{ width: '10%' }}>Session</th>
                          <th style={{ width: '24%' }}>Programme</th>
                          <th style={{ width: '20%' }}>Client Signature</th>
                          <th style={{ width: '18%' }}>
                            Practitioner Signature
                          </th>
                          <th style={{ width: '50%' }}>Notes</th>
                        </tr>
                      </thead>
                      <tbody style={{ width: '500px' }}>
                        <tr>
                          <td>
                            <Form.Control
                              name='clientName'
                              type='text'
                              // value={formData.clientName}
                              // onChange={(e) => handleChange('clientName', e.target.value)}
                              //style={{ width: '100%', marginTop: '-10px' }}
                              style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #495057',
                                borderRadius: '0px',
                                outline: 'none',
                              }}
                              disabled
                              value={moment(date).format('DD/MM/YYYY')}
                            />
                          </td>
                          <td>
                            <Form.Control
                              name='clientName'
                              type='text'
                              // value={formData.clientName}
                              // onChange={(e) => handleChange('clientName', e.target.value)}
                              //style={{ width: '100%', marginTop: '-10px' }}
                              style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #495057',
                                borderRadius: '0px',
                                outline: 'none',
                              }}
                              disabled
                              value={formData?.session}
                            />
                          </td>
                          <td>
                            <Form.Control
                              name='clientName'
                              type='text'
                              // value={formData.clientName}
                              // onChange={(e) => handleChange('clientName', e.target.value)}
                              //style={{ width: '100%', marginTop: '-10px' }}
                              style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #495057',
                                borderRadius: '0px',
                                outline: 'none',
                              }}
                              disabled
                              value={formData?.programme}
                            />
                          </td>
                          <td>
                            <div className='mt-' style={{ overflow: 'hidden' }}>
                              <div
                                className='view_sigPadContainer p-2   '
                                style={{ height: '150px' }}
                              >
                                <img
                                  src={formData?.clientSignature}
                                  className='signature'
                                  style={{ height: '100%' }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='mt-' style={{ overflow: 'hidden' }}>
                              <div
                                className='view_sigPadContainer p-2 '
                                style={{ height: '150px' }}
                              >
                                <img
                                  src={formData?.practitionerSignature}
                                  className='signature'
                                  style={{ height: '100%' }}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <textarea
                              name='notes'
                              type='text'
                              className='form-control'
                              value={formData?.notes}
                              onChange={(e) => setNotes(e.target.value)} // Update notes on change
                              placeholder='Enter notes here'
                              // value={formData.clientName}
                              // onChange={(e) => handleChange('clientName', e.target.value)}
                              //style={{ width: '100%', marginTop: '-10px' }}
                              style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                borderBottom: '1px solid #495057',
                                borderRadius: '0px',
                                height: '100px',
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Form.Group>
                  {userInfo.roleID == 10 ? (
                    <Button
                      className='px-3'
                      onClick={() => {
                        localStorage.setItem(
                          'patientDetailsData',
                          JSON.stringify({ ...patientDetailsData, tab: 0 })
                        );

                        navigate(`/profile/${id}`);
                      }}
                    >
                      <BsArrowLeft /> Back
                    </Button>
                  ) : (
                    <Button
                      className='px-3'
                      onClick={() => {
                        localStorage.setItem(
                          'patientDetailsData',
                          JSON.stringify({ ...patientDetailsData, tab: 7 })
                        );

                        navigate(`/profile/${id}`);
                      }}
                    >
                      <BsArrowLeft /> Back
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default EditDermaluxDailyConsentForm;
