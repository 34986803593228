import React from 'react';
import { Space, Spin } from 'antd';
const Spins = () => {
  return (
    <div style={{ height: '100vh' }} className="d-flex justify-content-center align-items-center m-auto">
      <Spin size="large" />
    </div>
  );
};

export default Spins;
