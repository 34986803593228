// CiclosporinSchedule
import React, { useEffect, useRef } from 'react';
import ConsentFormsNavbar from '../../../components/common/ConsentFormsNavbar';
import ProfileBar from './ProfileBar';
import {
  Button,
  Modal,
  Container,
  Card,
  Form,
  Row,
  Col,
  Table,
} from 'react-bootstrap';

import { useNavigate, useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import classNames from 'classnames';
import LdcApi from '../../../LdcApis';
import moment from 'moment';

const CiclosporinSchedule = () => {
  let appointmentData = JSON.parse(localStorage.getItem('appointmentData'));
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [brushColor, setBrushColor] = React.useState('#000');
  const [mouseDisable, setMouseDisable] = useState('auto');
  const [loading, setLoading] = useState(false);
  const [patientSignature, setPatientSignature] = useState('');
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [formData, setFormData] = useState({
    patientName: `${appointmentData?.firstName} ${appointmentData?.lastName}`,
    patientDate: moment(new Date()).format('DD/MM/YYYY'),
    doctorInitiating: `${appointmentData?.doctorName == null || appointmentData?.doctorName == '' ? '' : appointmentData?.doctorName}`,
  });

  useEffect(() => {
    document.title = 'Ciclosporin Schedule Consent Form - LDC';
    localStorage.setItem('confirmationCode', true);
  }, []);
  const hideForms = () => {
    navigate('/confirmation');
  };

  //   Signature *********************************👇
  const canvasRef = useRef(null);

  const defaultProps = {
    loadTimeOffset: 5,
    lazyRadius: 0,
    brushRadius: 2,
    catenaryColor: '#0a0302',
    gridColor: 'rgba(150,150,150,0.17)',
    hideGrid: true,
    // canvasWidth: 1000,
    canvasHeight: 300,
    disabled: false,
    imgSrc: '',
    saveData: '',
    immediateLoading: false,
    hideInterface: false,
  };
  const getImgPatientSignature = () =>
    canvasRef.current.canvasContainer.children[1].toDataURL();
  const handleCanvasChange = () => {
    const saveData = getImgPatientSignature();
    setPatientSignature(saveData);
    //  if (patientSignature == '') {
    //    setValidationError({ ...validationError, patientSignature: true });
    //  } else {
    //    setValidationError({ ...validationError, patientSignature: false });
    //  }
  };
  const patientSignatureProps = {
    ...defaultProps,
    onChange: handleCanvasChange,
    ref: canvasRef,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor,
  };
  //   btn submit *********************************👇
  const [validationError, setValidationError] = useState({
    patientName: false,
    patientDate: false,
    doctorInitiating: false,
    signature: false,
  });
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    let formValidationError = {
      patientName: false,
      patientDate: false,
      doctorInitiating: false,
      signature: false,
    };
    if (formData.patientName.trim() == '') {
      formValidationError.patientName = true;
    } else {
      formValidationError.patientName = false;
    }
    if (formData.patientDate.trim() == '') {
      formValidationError.patientDate = true;
    } else {
      formValidationError.patientDate = false;
    }
    if (formData.doctorInitiating.trim() == '') {
      formValidationError.doctorInitiating = true;
    } else {
      formValidationError.doctorInitiating = false;
    }
    if (patientSignature === '') {
      formValidationError.signature = true;
    } else {
      formValidationError.signature = false;
    }
    setValidationError(formValidationError);
    const data = Object.values(formValidationError).every(
      (item) => item === false
    );

    if (data) {
      const newValues = {
        patientName: formData.patientName,
        patientSignature: patientSignature,
        appointmentDate: moment(new Date()).format(),
        doctorName: formData.doctorInitiating,
        appointmentId: appointmentData?.id,
      };
      LdcApi({
        method: 'POST',
        url: 'Ciclosporin/AddCiclosporinForm',
        data: newValues,
      })
        .then((res) => {
          setLoading(false);
          if (res?.data?.status?.isSuccess === true) {
            addToast('Ciclosporin Schedule Consent form added successfully ', {
              appearance: 'success',
            });
            // setMouseDisable('auto');
            // setBtnSubmit(false);
            canvasRef.current.clear();

            navigate('/confirmation');
            setFormData({
              patientName: `${appointmentData?.firstName} ${appointmentData?.lastName}`,
              patientDate: moment(new Date()).format('DD/MM/YYYY'),
              doctorInitiating: `${
                appointmentData?.doctorName == null ||
                appointmentData?.doctorName == ''
                  ? ''
                  : appointmentData?.doctorName
              }`,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          navigate('/dary');
          setMouseDisable('auto');
          setBtnSubmit(false);
        });
    } else {
      setBtnSubmit(false);
    }
  };
  const handelOnchnage = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className='navbar_header_container br_c'>
        <ConsentFormsNavbar />
      </div>
      <ProfileBar />
      <Container
      // style={{ pointerEvents: { mouseDisable } }}
      >
        {/* go to diary  */}
        <div className='d-flex my-4 justify-content-end'>
          <Button onClick={() => hideForms()} variant='outline-primary mb-3'>
            Go to diary
          </Button>
        </div>
        <Card className='p-3'>
          <p className='text-center form_heading  mb-5'>
            Ciclosporin Schedule Consent Form
          </p>
          <div>
            <p>
              I understand that oral Ciclosporin is a medication that needs
              careful monitoring for safety purposes. I have had the risks,
              benefits and potential side effects linked to oral Ciclosporin
              explained to me, before I consented to treatment. I have also been
              given the opportunity to ask any questions prior to starting
              treatment. I agree to adhere to any schedule of care my clinic
              doctors advise is in my best interest. I have had the typical
              schedule of care below given to me. I will contact the clinic if I
              have a problem. Clinic doctors reserve the right to stop
              prescribing oral Ciclosporin for me at any stage in the course, if
              I am unable to adhere to any recommendations to my schedule of
              care.
            </p>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Row className='d-flex justify-content-center'>
                <Col sm={12} className=' '>
                  <div className='mt-3'>
                    <Form.Group className='m-0 p-0'>
                      <b className='mr-2  '>Patient name: </b>
                      <input
                        className={`from_control     `}
                        type='text'
                        disabled
                        value={formData.patientName}
                        // isInvalid={touched && !!error}
                        // isValid={touched && !error}
                        name='patientName'
                        onChange={(e) => handelOnchnage(e)}
                        //  value={formData.appointmentDate}
                      />

                      <span className='text-danger'>
                        {validationError.patientName === true &&
                          'Field is required.'}
                      </span>
                    </Form.Group>
                    <Form.Group className='m-0 p-0'>
                      <b className='mr-2 '>Date: </b>
                      <input
                        className={`from_control     `}
                        value={formData.patientDate}
                        disabled
                        type='text'
                        name='patientDate'
                        onChange={(e) => handelOnchnage(e)}
                        //  value={formData.appointmentTime}
                      />
                      <span className='text-danger'>
                        {validationError.patientDate === true &&
                          'Field is required.'}
                      </span>
                    </Form.Group>
                  </div>
                  <div className='mt-4'>
                    <b>
                      Signature<span className='text-danger'>*</span>
                    </b>
                    <Col sm={12} className='mt-2'>
                      <div style={{ overflow: 'hidden' }}>
                        <CanvasDraw {...patientSignatureProps} />
                        {/* <span className="text-danger">{validationError.childSignature === true && 'Field is required.'}</span> */}
                        <br />
                        <Button
                          classNames='mt-2'
                          onClick={() => {
                            canvasRef.current.clear();
                            setPatientSignature('');
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                      <span className='text-danger'>
                        {validationError.signature === true &&
                          'Field is required.'}
                      </span>
                    </Col>
                  </div>
                  <Form.Group className='m-0 p-0'>
                    <b className='mr-2 '>Doctor initiating: </b>
                    <input
                      className={`from_control     `}
                      type='text'
                      disabled
                      name='doctorInitiating'
                      value={formData.doctorInitiating}
                      onChange={(e) => handelOnchnage(e)}
                      //  value={formData.appointmentTime}
                    />
                    <br />
                    <span className='text-danger'>
                      {validationError.doctorInitiating === true &&
                        'Field is required.'}
                    </span>
                  </Form.Group>
                  <div className='mt-5'>
                    <b>Typical schedule care (can vary): </b>

                    <div className='mt-5 ml-3'>
                      <b className='' style={{ textDecoration: 'underline' }}>
                        Before starting treatment:
                      </b>
                      <ol>
                        <li>
                          Initial baseline blood test; FBC, U&Es, LFTs, fasting
                          lipids, HbA1c, Hepatitis B + C serology, HIV test,
                          Varicella Zoster Virus serology and T-SPOT TB.
                        </li>
                        <li> Blood pressure.</li>
                      </ol>
                    </div>
                    <div className='mt-5 ml-3'>
                      <b className='' style={{ textDecoration: 'underline' }}>
                        After starting treatment:
                      </b>
                      <ol className=''>
                        <li>
                          Blood tests for FBC, U&Es, LFTs, and blood pressure
                          <b> 2-weekly </b> for the first <b> 6 weeks</b> , then
                          monthly thereafter if stable.
                        </li>
                        <li>
                          Blood test - fasting lipids at <b> 1 month</b> and
                          then yearly if remains on treatment.
                        </li>
                        <li>
                          Blood test - HbA1c after <b>3 months</b>.
                        </li>
                      </ol>
                    </div>
                    <p className='mt-2'>
                      Initial bloods cost £510*. Each subsequent monitoring
                      blood test costs £90*. The above schedule frequency may
                      change if abnormal results are found or after a treatment
                      dose change.
                    </p>

                    <b className='mt-3' style={{ textDecoration: 'underline' }}>
                      Follow-up Consultations
                    </b>
                    <p className='mt-2'>
                      A review will be required by booking a follow-up
                      consultation every 6 to 8 weeks and once the dose of the
                      medication and your skin condition is stable then it will
                      be reduced to once every 4 months.
                    </p>
                    <p className='mt-2'>
                      Each follow-up consultation costs £175*.
                    </p>
                  </div>

                  <b className='mt-5' style={{ textDecoration: 'underline' }}>
                    Side Effects
                  </b>
                  <p>
                    The following is a list of some common side effects which
                    can be expected.
                  </p>
                  <ul className='ml-3'>
                    <li> Increased susceptibility to infection</li>
                    <li> High blood pressure</li>
                    <li> Increased hair growth</li>
                    <li> Swollen or inflamed gums</li>
                    <li> Numbness or tingling of the hands or feet</li>
                    <li>
                      {' '}
                      Change in blood electrolytes (potassium and magnesium
                      levels)
                    </li>
                    <li>
                      Other common side effects are tremors, restlessness,
                      stomach upset, nausea, cramps, diarrhea, headache, and
                      changes in blood sugar.
                    </li>
                  </ul>

                  <b className='mt-5' style={{ textDecoration: 'underline' }}>
                    N.B.
                  </b>
                  <ul className='ml-3'>
                    <li>
                      {' '}
                      Blood tests are needed as oral Ciclosporin can adversely
                      affect your kidney and liver.
                    </li>
                    <li>
                      Consultations are an opportunity for your doctors to
                      review your care and monitor your treatment safely.
                      Reviews tend to include your skin, any side effects you
                      may be experiencing, answer questions you have and dosage
                      changes.
                    </li>
                    <li>
                      {' '}
                      Consultations can be conducted online over a video call or
                      a face to face clinic attendance.{' '}
                    </li>
                    <li>
                      If you decide to have your blood tests done externally
                      please be aware you will have to be responsible in
                      organising and getting the correct test results to us in a
                      timely manner to avoid delays or disruptions.
                    </li>
                  </ul>
                  <span>
                    *Price correct at the time of publication but subject to
                    change without notice.
                  </span>
                  {/* end  😊 */}
                  <br />
                  <br />
                </Col>
              </Row>
              <div className='d-flex my-4    '>
                <button
                  type='submit'
                  disabled={btnSubmit}
                  className='btn px-4 btn-outline-primary'
                >
                  {btnSubmit ? 'Loading....' : 'Save'}
                </button>
                {/* <CustomButtons type="submit " buttonDisabled={btnSubmit} variant="primary" className="outline- px-4" name="Save" /> */}
              </div>
            </Form>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default CiclosporinSchedule;
