import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Container, Table } from 'react-bootstrap'; // ag grid
import { DatePicker, Empty, Skeleton } from 'antd';
import LdcApi from '../../../LdcApis';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import dayjs from 'dayjs';
const ConversionTracker = () => {
  const [finalDateRange, setFinalDateRange] = useState({ startDate: '', endDate: '' });
  const [eodMonthlyData, setEodMonthlyData] = useState([]);
  const [sortAccending, setSortAccending] = useState(false);
  const [sortData, setSortData] = useState([]);

  const { RangePicker } = DatePicker;
  const [DateRangesss, setDateRangess] = useState({ startDate: '', endDate: '' });
  const [monthTotals, setmonthTotals] = useState(0);
  const [monthTotalsConverted, setmonthTotalsConverted] = useState(0);
  // const [monthTotalsConverPercentage, setmonthTotalsConverPercentage] = useState(0);
  const [loading, setLoading] = useState(null);
  const dateFormat = 'DD/MM/YYYY';
  const now = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 1);
  function formatDateToYYYYMMDD(date) {
    // Ensure the parameter is a Date object; if not, use the current date
    const currentDate = date instanceof Date ? date : new Date();
    // Extract year, month, and day
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0');
    // Format the date
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  // Set the default value for the RangePicker
  const defaultValue = [dayjs(formatDateToYYYYMMDD(threeMonthsAgo)), dayjs(formatDateToYYYYMMDD(now))]; // RangePicker takes an array of two moment objects
  // State to hold the selected date range
  const [selectedRange, setSelectedRange] = useState(defaultValue);
  const onChange = (date, dateString) => {
    setFinalDateRange({
      ...DateRangesss,
      startDate: dateString[0],
      endDate: dateString[1]
    });
  };
  useEffect(() => {
    document.title = 'Conversion Tracker- LDC';
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    setDateRangess({
      ...DateRangesss,
      startDate: moment(firstDay).format('YYYY/MM/DD'),
      endDate: moment(new Date()).format('YYYY/MM/DD')
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    let fistDate = finalDateRange.startDate.split('/').reverse().join('/');
    let endDate = finalDateRange.endDate.split('/').reverse().join('/');

    LdcApi({
      method: 'GET',
      //https://api-ldc.goldenbuzz.in/api/LeadManagement/GetLeadConversion?startdate=2023-05-22&enddate=2023-05-23
      url: `LeadManagement/GetLeadConversion?startdate=${finalDateRange.startDate === '' ? DateRangesss.startDate : fistDate}&enddate=${
        finalDateRange.startDate === '' ? DateRangesss.endDate : endDate
      }`
    })
      .then((res) => {
        if (res?.data.length !== 0) {
          setEodMonthlyData(res?.data);
          setLoading(false);
        } else {
          setLoading(null);
        }
      })
      .catch((err) => {
        setLoading(null);
      });
  }, [finalDateRange, DateRangesss]);


  useEffect(() => {
    const monthTotals = eodMonthlyData.reduce((a, b) => (a === undefined ? 0 : a) + (b === undefined ? 0 : b.handled), 0);
    setmonthTotals(monthTotals);
  }, [eodMonthlyData]);

  useEffect(() => {
    const monthTotalsConverted = eodMonthlyData.reduce((a, b) => (a === undefined ? 0 : a) + (b === undefined ? 0 : b.converted), 0);
    setmonthTotalsConverted(monthTotalsConverted);
  }, [eodMonthlyData]);

  // useEffect(() => {
  //   const monthTotalsConverPercentage = eodMonthlyData.reduce(
  //     (a, b) => (a === undefined ? 0 : a) + (b === undefined ? 0 : b.converPercentage),
  //     0
  //   );
  //   setmonthTotalsConverPercentage(monthTotalsConverPercentage);
  // }, [eodMonthlyData]);

  const sortLead = () => {
    // const sortData = eodMonthlyData.reverse();
    eodMonthlyData.sort((a, b) => {
      let name1 = a.userName.toLowerCase(),
        name2 = b.userName.toLowerCase();
      if (sortAccending) {
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
      } else {
        if (name1 < name2) return 1;
        if (name1 > name2) return -1;
      }
      return 0;
    });
    setSortData(eodMonthlyData);
  };
  useEffect(() => {
    setEodMonthlyData(sortData);
  }, [sortData]);

  return (
    <>
      <Container>
        <div>
          <div className="d-flex align-items-center my-4" style={{ color: 'gray' }}>
            <b className="mr-2">Date Range : </b>
            <RangePicker
              format={dateFormat}
              onChange={onChange}
              defaultValue={selectedRange}
            />
          </div>
          {loading === true ? (
            <>
              <Skeleton />
            </>
          ) : (
            <>
              {loading === null || false ? (
                <>
                  <Empty />
                </>
              ) : (
                <>
                  {' '}
                  <Table responsive className="table table-bordered" id="bootstrap_table">
                    <thead id="bootstrap_table_head">
                      <tr>
                        <th
                          scope="col"
                          style={{ minWidth: 200, cursor: 'pointer' }}
                          onClick={() => {
                            setSortAccending(!sortAccending);
                            sortLead();
                          }}
                        >
                          Lead Owner {sortAccending ? <BsArrowDown /> : <BsArrowUp />}
                        </th>
                        <th scope="col " style={{ minWidth: 200 }}>
                          <span id="eod_table_heading">Handled</span>
                        </th>
                        <th scope="col" style={{ minWidth: 200 }}>
                          Converted
                        </th>
                        <th scope="col " style={{ minWidth: 200 }}>
                          <span id="eod_table_heading">Conversion %</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {eodMonthlyData.map((el, i) => (
                        <tr key={i}>
                          <td>
                            <div style={{ textTransform: 'capitalize' }}>{el.userName}</div>
                          </td>
                          <td>
                            <div>{el.handled}</div>
                          </td>
                          <td>
                            <div style={{ textTransform: 'capitalize' }}>{el.converted}</div>
                          </td>
                          <td>
                            <div>{el.converPercentage == 100 ? el.converPercentage : el.converPercentage.toFixed(2)}</div>
                            {/* <div>{((el.converted / el.handled) * 100).toFixed(2)}</div> */}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <b>TOTAL</b>
                        </td>
                        <td>
                          <b>{monthTotals}</b>
                        </td>
                        <td>
                          <b>{monthTotalsConverted}</b>
                        </td>
                        <td>
                          <b>{((monthTotalsConverted / monthTotals) * 100).toFixed(2)}</b>
                        </td>
                      </tr>
                    </tbody>
                  </Table>{' '}
                </>
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default ConversionTracker;
