import React, { useEffect, useState } from 'react';
import { Card, Button, Alert } from 'react-bootstrap';
import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';

import Breadcrumb from '../../../layouts/AdminLayout/Breadcrumb';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import FirebaseLogin from './Login';
import OtpVerification from './OtpVerification';

const Auth = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = location.pathname === '/otp' ? 'OTP - LDC' : 'Login - LDC';
  });
  const [isOtp, setIsOtp] = useState(false);

  useEffect(() => {
    const isLogin = JSON.parse(localStorage.getItem('isLogin'));
    if (isLogin === true) {
      navigate('/diary');
    } else {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/otp') {
      setIsOtp(true);
    } else {
      setIsOtp(false);
    }
  }, [location]);

  return (
    <React.Fragment>
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <Card className="borderless ">
            <Card.Body>
              <div className="mb-4 text-center">
                <i className="feather icon-unlock auth-icon" />
              </div>
              {isOtp ? <OtpVerification /> : <FirebaseLogin />}
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Auth;
