export const validate = (name, value) => {

  switch (name) {
    case 'firstName':
      if (!value || value.trim() === '') {
        return 'First Name is Required';
      } else {
        return '';
      }
    case 'lastName':
      if (!value || value.trim() === '') {
        return 'Last Name is Required';
      } else {
        return '';
      }
    case 'endTime':
      if (!value || value.trim() === '') {
        return 'End Time is Required';
      } else {
        return '';
      }
    // case 'with':
    //   if (!value || value === '' || value === 'Select') {
    //     return 'With is Required';
    //   } else {
    //     return '';
    //   }
    case 'serviceName':
      if (!value || value === '' || value === 'Select') {
        return 'Service is Required';
      } else {
        return '';
      }
    // case 'room':
    //   if (!value || value === '' || value === 'Select') {
    //     return 'Room is Required';
    //   } else {
    //     return '';
    //   }
    case 'email':
      if (!value) {
        return 'Email is Required';
      } else if (
        !value.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        return 'Enter a valid email address';
      } else {
        return '';
      }
    case 'mobile':
      if (!value || value.trim() === '') {
        return 'Mobile Number is Required';
      } else if (value.length > 12 || value.length < 10) {
        return 'Mobile Number is Not Valid';
      } else {
        return '';
      }
    case 'insurance':
      if (value === null || value=== '') {
        return 'Insurance is Required';
      } else {
        return '';
      }
    case 'servicesCat':
      if (value == '') {
        return 'Services Category is Required';
      } else {
        return '';
      }
    case 'activeColumnId':
      if (value == '') {
        return 'Room is Required';
      } else {
        return '';
      }
    default: {
      return '';
    }
  }
};

export const chanelValidate = (name, value) => {
  switch (name) {
    case 'timeStart':
      if (!value || value.trim() === '') {
        return 'Field is required.';
      } else {
        return '';
      }

    case 'timeEnd':
      if (!value || value.trim() === '') {
        return 'Field is required';
      } else {
        return '';
      }

    case 'startDate':
      if (!value || value.trim() === '') {
        return 'Field is required';
      } else {
        return '';
      }
    case 'chamberDate':
      if (!value || value.trim() === '') {
        return 'Field is required';
      } else {
        return '';
      }
    // case 'endDate':
    //   if (!value || value.trim() === '') {
    //     return 'End Date is Required';
    //   } else {
    //     return '';
    //   }
    // case 'clinicName':
    //   if (!value || value.trim() === '') {
    //     return 'Clinic Name is Required';
    //   } else {
    //     return '';
    //   }
    case 'doctorId':
      if (!value || value === '') {
        return 'Field is Required';
      } else {
        return '';
      }
    case 'roomName':
      if (!value || value === '') {
        return 'Field is Required';
      } else {
        return '';
      }
    // case 'dayOfWeek':
    //   if (!value || value.trim() === '') {
    //     return 'Week is Required';
    //   } else {
    //     return '';
    //   }
    // case 'status':
    //   if (!value || value.trim() === '') {
    //     return 'Status is Required';
    //   } else {
    //     return '';
    //   }

    default: {
      return '';
    }
  }
};

export const validateStartTime = (startTime, endTime) => {
  let isValid = false;
  if (endTime !== '') {
    if (startTime >= endTime) {
      isValid = true;
    } else {
      isValid = false;
    }
  }
  return isValid;
};
export const validateEndTime = (endTime, startTime) => {
  let isValid = false;
  if (startTime !== '') {
    if (endTime <= startTime) {
      isValid = true;
    } else {
      isValid = false;
    }
  }
  return isValid;
};
