import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { defaultLogin } from '../../services.js';

const initialState = {
  isLogin: false,
  isError: false,
  message: '',
  oTP: '',
  userName: '',
  isSignUp: false,
  isFetching: false
};

export const singIn = createAsyncThunk('auth/singIn', async (userName, password, { rejectWithValue }) => {
  try {
    const data = await defaultLogin(userName, password);
    return data;
  } catch (error) {
    return rejectWithValue(error.response?.data);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isLogin = false;
      state.isFetching = false;
      state.message = '';
      state.oTP = '';
      state.isSignUp = false;
      return state;
    }
  },
  extraReducers: {
    [singIn.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isLogin = true;
      state.oTP = payload;
      state.message = 'Success';
      return state;
    },
    [singIn.pending]: (state) => {
      state.isFetching = true;
      state.message = '';
      state.oTP = '';
      state.isError = false;
      return state;
    },
    [singIn.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.oTP = '';
      state.isLogin = false;
      state.message = payload;
      return state;
    }
  }
});

const { reducer } = authSlice;
export default reducer;
