import { Snackbar, SnackbarContent } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
// import CloseIcon from '@mui/icons-material/Close';

const ReminderPopup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const intervalRef= useRef();
    // Function to handle user clicking the "Done" button
    const handleDone = () => {
        // Close the pop-up
        setShowPopup(false);
        // You may want to store in local storage or a state variable
        // that the reminder has been completed for the day
        localStorage.setItem('reminderCompleted', true);
        console.log(intervalRef.current)
        clearInterval(intervalRef.current)
    };

    // Function to handle user clicking the "Snooze" button
    const handleSnooze = () => {
        // Close the pop-up
        setShowPopup(false);
        localStorage.setItem('reminderCompleted', true);
        // Set a timer to show the pop-up again after 5 minutes
        setTimeout(() => {
            setShowPopup(true);
            if(JSON.parse(localStorage.getItem('reminderCompleted')))
            localStorage.removeItem('reminderCompleted')
        }, 5 * 60 * 1000); // 5 minutes in milliseconds
    };

    // Function to check if it's time to show the reminder
    const checkReminderTime = () => {
        const now = new Date();
        const dayOfWeek = now.getDay(); // 0 for Sunday, 1 for Monday, etc.
        const hour = now.getHours();
        const minute = now.getMinutes();
        console.log(hour,minute)
        // Check if it's the correct time to show the reminder
        if (hour >= 19 && minute >= 45 && dayOfWeek >= 1 && dayOfWeek <= 6 && !JSON.parse(localStorage.getItem('reminderCompleted'))) {
            console.log("Hello1")
            setShowPopup(true);
        }else if(hour>=17 && minute >=45 && dayOfWeek===0 && !JSON.parse(localStorage.getItem('reminderCompleted'))){
            console.log("Hello1")
            setShowPopup(true);
        }else if(hour<19){
            if(JSON.parse(localStorage.getItem('reminderCompleted')))
            localStorage.removeItem('reminderCompleted')
        }
    };

    // Use useEffect to check the reminder time when the component mounts
    useEffect(() => {
        const interval = setInterval(checkReminderTime, 1000); // Check every minute
        intervalRef.current=interval;
        return () => clearInterval(interval);
    }, []);
    
    const action = (
        <React.Fragment>
          <Button
            style={{ border: '1px solid white', color: 'white', padding: '5px',marginRight:'10px' }}
            size="small"
            onClick={() => handleDone()}
          >
            Done
          </Button>
          <Button
            style={{ border: '1px solid white', color: 'white', padding: '5px' }}
            size="small"
            onClick={() => handleSnooze()}
          >
            Snooze for 5 min
          </Button>
          {/* <IconButton size="small" aria-label="close" color="success" onClick={onSnackBarClose}>
            <CloseIcon style={{ color: 'white' }} fontSize="small" />
          </IconButton> */}
        </React.Fragment>
      );
    return (<>
        {/* <div>
            {showPopup && (
                <div className="popup">
                    <p>REMINDER: Send doctors an email for their shift tomorrow</p>
                    <button onClick={handleDone}>Done</button>
                    <button onClick={handleSnooze}>Snooze for 5 min</button>
                </div>
            )}
        </div> */}
        <Snackbar
            sx={{
                '& .SnackbarItem-variantSuccess': {
                    backgroundColor: 'success'
                },
                '& .SnackbarItem-variantError': {
                    backgroundColor: 'success'
                },
                '& .SnackbarItem-variantWarning': {
                    backgroundColor: 'success'
                },
                '& .SnackbarItem-variantInfo': {
                    backgroundColor: 'success'
                }
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={showPopup}
            // onClose={onSnackBarClose}
        >
            <SnackbarContent
                style={{
                    backgroundColor: '#3f4d67'
                }}
                action={action}
                message={<span id="client-snackbar">REMINDER: Send doctors an email for their shift tomorrow AND post a message in BT chat.</span>}
            />
        </Snackbar>
    </>

    );
};

export default ReminderPopup;
