import LdcApi from '../../LdcApis';

class Question {
  getAllQuestion = (callback) => {
    LdcApi({
      method: 'GET',
      url: 'PatientQuestionnaire/GetPatientQuestionnaire'
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  QuestionTypeMasterById = (id,patId, callback) => {
    LdcApi({
      method: 'GET',
      url: `TreatmentNoteTemplate/GetTreatmentNoteTemplateQuestionsByTemplateID?templateID=${id}&patId=${patId}`
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: error });
      });
  };
  QuestionTypeMaster = (callback) => {
    LdcApi({
      method: 'GET',
      url: `QuestionTypeMaster/GetQuestionTypeMaster`
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  AddTreatmentNotes = (questionData, callback) => {
    LdcApi({
      method: 'POST',
      url: 'TreatmentNotes/UpsertTreatmentNotes',
      // url: 'PatientQuestionnaire/UpsertPatientQuestionnaire',

      data: questionData
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  addQuestion = (questionData, callback) => {
    LdcApi({
      method: 'POST',
      url: 'PatientQuestionnaire/UpsertPatientQuestionnaire',
      data: questionData
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  getQuestionByPatientId = (patientId, callback) => {
    LdcApi({
      method: 'GET',
      url: `PatientQuestionnaire/GetQuestionnairesByPatientId?patientId=${patientId}`
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };

  getQuestionTemplateNotes = (callback) => {
    LdcApi({
      method: 'GET',
      url: 'TreatmentNoteTemplate/GetTreatmentNoteTemplate'
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  getChildTemplateQuestions = (id, callback) => {
    LdcApi({
      method: 'GET',
      url: `QuestionTypeMaster/GetChildInformation?parentQuestionID=${id}`
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  getTreatmentQuestionEdit = (id, callback) => {
    LdcApi({
      method: 'GET',
      url: `TreatmentNotes/GetTreatmentNoteAnswerbyId?TreatmentNoteId=${id}`
    })
      .then((response) => {
        if (response?.data) {
          callback({ status: 'success', data: response?.data });
        }
      })
      .catch((error) => {
        callback({ status: 'error' });
      });
  };
  
}


export default new Question();
