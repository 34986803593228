import React from 'react';
import { Button } from 'react-bootstrap';

function CustomDangerButton({ name, cancelOnClick, variant, disabled = false }) {
  return (
    <div>
      <Button disabled={disabled} onClick={cancelOnClick} variant={'outline-' + variant}>
        {name}
      </Button>
    </div>
  );
}

export default CustomDangerButton;
