import React, { useContext } from 'react';
import { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import MuiSnackBar from '../../../../../../components/MuiSnackbar/MuiSnackBar';
import { ConfigContext } from '../../../../../../contexts/ConfigContext';
import * as actionType from '../../../../../../store/actions';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Tabs } from 'antd';
import { values } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import LdcApi from '../../../../../../LdcApis';
import { useNavigate } from 'react-router';

const initialValues = {
  oldPassword: '',
  password: '',
  confirmPassword: ''
};

const validationSchema = yup.object().shape({
  oldPassword: yup.string().min(6).required('Old password is required.'),
  password: yup
    .string()
    .min(8)
    .max(16)
    .when('oldPassword', (oldPassword, field) => (oldPassword ? field.required() : field))
    .required('Password is required.'),
  confirmPassword: yup
    .string()
    .when('password', (password, field) => (password ? field.required().oneOf([yup.ref('password')]) : field))
    .required('Confirm Password is required.')
});

const initialValuesPin = {
  oldPin: '',
  pin: '',
  confirmPin: ''
};

const validationSchemaPin = yup.object().shape({
  oldPin: yup.string().min(6).required('Old PIN is required.'),
  pin: yup
    .string()
    .min(6)
    .max(6)
    .when('oldPin', (oldPin, field) => (oldPin ? field.required() : field))
    .required('PIN is required.'),
  confirmPin: yup
    .string()
    .when('pin', (pin, field) => (pin ? field.required().oneOf([yup.ref('pin')]) : field))
    .required('Confirm PIN is required.')
});

function ChangePassword({ passwordOpen, saveData }) {
  const navigate = useNavigate();
  const users = JSON.parse(localStorage.getItem('user'));
  const configContext = useContext(ConfigContext);
  const { addToast } = useToasts();
  const { isChangePasswordOpen } = configContext.state;
  const { dispatch } = configContext;

  const [ldcSnackBar, setLdcSnackBar] = useState({ open: false });
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    dispatch({
      type: actionType.PASSWORD_CHANGE,
      payload: false
    });
  };

  const closeSnackBar = () => {
    const ldcSnackbarClose = {
      open: false
    };
    setLdcSnackBar(ldcSnackbarClose);
  };
  const openLdcSnackBar = (message, type) => {
    const openLdcSnackBarObj = {
      open: true,
      mesage: message,
      type: type
    };
    setLdcSnackBar(openLdcSnackBarObj);
  };

  const updatePassword = async (values, { resetForm }) => {
    setLoading(true);

    const newValues = {
      id: users?.id,
      currentPassword: values.oldPassword,
      newPassword: values.password,
      type: users?.userType
    };
    await LdcApi({
      method: 'POST',
      url: 'User/UpdateProfile',
      data: newValues
    })
      .then((res) => {
        if (res?.data?.status?.isSuccess === true) {
          setLoading(false);
          resetForm();
          addToast('Password Update Successfully`', { appearance: 'success' });
          const ldcSnackbarClose = {
            open: false
          };
          setLdcSnackBar(ldcSnackbarClose);
          setTimeout(() => {
            addToast('Please login with your new password & PIN.', { appearance: 'success', autoDismiss: 3000 });
            localStorage.removeItem('isLogin');
            localStorage.removeItem('_code');
            localStorage.removeItem('user');
            localStorage.clear();
            navigate('/');
          }, 500);
        } else if (res?.data?.status?.errorMessage === `The user's current password does not match`) {
          setLoading(false);
          addToast(`user's Old Password  does not match`, { appearance: 'error' });
        } else if (res?.data?.status?.errorMessage === `The doctor's current password does not match`) {
          setLoading(false);
          addToast(`Doctor's Old Password  does not match`, { appearance: 'error' });
        } else {
          setLoading(false);
          addToast('Error occurred While Updating Password', { appearance: 'error' });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast('Error occurred while updating Password ', { appearance: 'error' });
      });
  };

  const updatePin = async (values, { resetForm }) => {
    setLoading(true);

    const newValues = {
      id: users?.id,
      oldPin: values.oldPin,
      newPin: values.pin,
      type: users?.userType
    };
    await LdcApi({
      method: 'POST',
      url: 'User/UpdateProfile',
      data: newValues
    })
      .then((res) => {
        if (res?.data?.status?.isSuccess === true) {
          setLoading(false);
          resetForm();
          addToast('PIN Updated Successfully`', { appearance: 'success' });
          const ldcSnackbarClose = {
            open: false
          };
          setLdcSnackBar(ldcSnackbarClose);
          setTimeout(() => {
            addToast('Please login with your new password & PIN.', { appearance: 'success', autoDismiss: 3000 });
            localStorage.removeItem('isLogin');
            localStorage.removeItem('_code');
            localStorage.removeItem('user');
            localStorage.clear();
            navigate('/');
          }, 500);
        } else if (res?.data?.status?.errorMessage === `The user's old pin does not match`) {
          setLoading(false);
          addToast(`User's old pin does not match`, { appearance: 'error' });
        } else if (res?.data?.status?.errorMessage === `The doctor's old pin does not match`) {
          setLoading(false);
          addToast(`Doctor's  old pin does not match`, { appearance: 'error' });
        } else {
          setLoading(false);
          addToast('Error occurred While Updating PIN', { appearance: 'error' });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast('Error occurred while updating PIN ', { appearance: 'error' });
      });
  };

  return (
    <Modal show={isChangePasswordOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> Change Password/PIN</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Change Password " key="Tab 1">
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={updatePassword}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="my-4">
                    <Col lg={12} sm={12}>
                      <Form.Group className="">
                        <Form.Label>
                          Old Password <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Control
                          name="oldPassword"
                          type="password"
                          placeholder="Old Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.oldPassword}
                          error={errors.oldPassword}
                          autocomplete="new-password"
                          // touched={touched.name}
                        />
                        {touched.oldPassword && errors.oldPassword && (
                          <small className="text-danger error_field">{errors.oldPassword}</small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={12} sm={12}>
                      <Form.Group className="">
                        <Form.Label>
                          Password <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Control
                          name="password"
                          type="password"
                          placeholder="Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          error={errors.password}
                          autocomplete="new-password"
                          // touched={touched.name}
                        />
                        {touched.password && errors.password && <small className="text-danger error_field">{errors.password}</small>}
                      </Form.Group>
                    </Col>

                    <Col lg={12} sm={12}>
                      <Form.Group className="">
                        <Form.Label>
                          Confirm Password <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Control
                          name="confirmPassword"
                          type="password"
                          placeholder="Confirm Password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          error={errors.confirmPassword}
                          autocomplete="new-password"
                          // touched={touched.name}
                        />
                        {touched.confirmPassword && errors.confirmPassword && (
                          <small className="text-danger error_field">{errors.confirmPassword}</small>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex my-4    ">
                    <Button variant="primary" disabled={loading} className="px-4" type="submit">
                      {loading ? 'Loading...' : 'Submit'}
                    </Button>
                    {/* <button name="Cancel" onClick={}={() => setEditModalShow(false)} variant="danger" className="px-4" /> */}
                  </div>
                </Form>
              )}
            </Formik>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Change PIN" key="Tab 2" className="pb-2">
            <Formik initialValues={initialValuesPin} validationSchema={validationSchemaPin} onSubmit={updatePin}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="my-4">
                    <Col lg={12} sm={12}>
                      <Form.Group className="">
                        <Form.Label>
                          Old PIN <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Control
                          name="oldPin"
                          type="password"
                          placeholder="Old PIN"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.oldPin}
                          error={errors.oldPin}
                          autocomplete="new-password"
                          // touched={touched.name}
                        />
                        {touched.oldPin && errors.oldPin && <small className="text-danger error_field">{errors.oldPin}</small>}
                      </Form.Group>
                    </Col>
                    <Col lg={12} sm={12}>
                      <Form.Group className="">
                        <Form.Label>
                          PIN <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Control
                          name="pin"
                          type="password"
                          placeholder="PIN"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.pin}
                          error={errors.pin}
                          autocomplete="new-password"
                          // touched={touched.name}
                        />
                        {touched.pin && errors.pin && <small className="text-danger error_field">{errors.pin}</small>}
                      </Form.Group>
                    </Col>

                    <Col lg={12} sm={12}>
                      <Form.Group className="">
                        <Form.Label>
                          Confirm PIN <span className="text-danger">*</span>{' '}
                        </Form.Label>
                        <Form.Control
                          name="confirmPin"
                          type="password"
                          placeholder="Confirm PIN"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPin}
                          error={errors.confirmPin}
                          autocomplete="new-password"
                          // touched={touched.name}
                        />
                        {touched.confirmPin && errors.confirmPin && <small className="text-danger error_field">{errors.confirmPin}</small>}
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex my-4  ">
                    <Button variant="primary" disabled={loading} className="px-4" type="submit">
                      {loading ? 'Loading...' : 'Submit'}
                    </Button>
                    {/* <button name="Cancel" onClick={}={() => setEditModalShow(false)} variant="danger" className="px-4" /> */}
                  </div>
                </Form>
              )}
            </Formik>
          </Tabs.TabPane>
        </Tabs>
      </Modal.Body>

      {/* <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => submitData()}>
          Save
        </Button>
      </Modal.Footer> */}

      {ldcSnackBar.open ? <MuiSnackBar ldcSnackBar={ldcSnackBar} onClose={closeSnackBar} /> : null}
    </Modal>
  );
}

export default ChangePassword;
