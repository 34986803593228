import React from 'react';
import { useEffect } from 'react';
import ChamberForm from '../../forms/ChamberForm';

export default function Chamber() {
  useEffect(() => {
    document.title = 'Rota - LDC';
  });
  return (
    <div>
      <ChamberForm />
    </div>
  );
}
