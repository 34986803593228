import React from 'react';
import ConsentFormsNavbar from '../../components/common/ConsentFormsNavbar';
import { Container } from 'react-bootstrap';

const ThankYou = () => {
  return (
    <div>
      <div className="pt-5">
        <ConsentFormsNavbar />
        <Container>
          <div className="pt-5 px-1" style={{ maxWidth: '650px', display: 'flex', margin: 'auto' }}>
            <div>
              <p className="text-center my-5 " style={{ fontSize: '1.4rem', fontWeight: '800', color: '#000' }}>
                Thank you for completing this form. You may close this page now.
              </p>
              <div className="py-4" style={{ fontSize: '1.1rem' }}>
                <b>London Dermatology Clinic</b>
                <br />
                <b>
                  T:{' '}
                  <span>
                    <a href="tel:020 7112 4924" style={{ textDecoration: 'none' }}>
                      020 7112 4924
                    </a>
                  </span>
                </b>
                <br />
                <b>
                  E:{' '}
                  <span>
                    <a
                      target="_blank"
                      href="mailto:info@londondermatologyclinic.com"
                      style={{ textDecoration: 'none', color: 'rgb(7, 7, 123)' }}
                    >
                      info@londondermatologyclinic.com
                    </a>
                  </span>
                </b>
                <br />
                <b>
                  W:{' '}
                  <span>
                    <a
                      target="_blank"
                      href="https://londondermatologyclinic.com/"
                      style={{ textDecoration: 'none', color: 'rgb(7, 7, 123)' }}
                    >
                      www.londondermatologyclinic.com
                    </a>
                  </span>
                </b>
                <br />
              </div>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <a
                  href="https://www.facebook.com/DermatologyLondonClinic/"
                  style={{ marginRight: '1px', marginTop: '5px' }}
                  target="_blank"
                >
                  <img style={{ width: '36px' }} src="https://ldc.goldenbuzz.in/static/media/faceboooks1.62c878d1.jpg" alt="facebook" />
                </a>

                <a href="https://www.instagram.com/dermatologylondonclinic/" target="_blank">
                  <img src="https://ldc.goldenbuzz.in/static/media/sadasd.72d1dd5c.png" style={{ width: '45px' }} alt="instagram" />
                </a>
              </div> */}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ThankYou;
