import moment from 'moment';
import React, { useEffect, useState } from 'react';
import LdcApi from '../../../LdcApis';
import { Table } from 'react-bootstrap';
import { DatePicker, Empty, Skeleton } from 'antd';
import 'moment-timezone';
import dayjs from 'dayjs';
const DoctorHours = () => {
    useEffect(() => {
        document.title = 'Doctor Hours - LDC';
    }, []);
    function formatDateToYYYYMMDD(date) {
        // Ensure the parameter is a Date object; if not, use the current date
        const currentDate = date instanceof Date ? date : new Date();
        // Extract year, month, and day
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = currentDate.getDate().toString().padStart(2, '0');
        // Format the date
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    // Get the current date and time in the UK time zone
    // const now = moment().tz('Europe/London');

    // Calculate 1 month ago from the current date
    // const threeMonthsAgo = now.clone().subtract(1, 'months');

    const now = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 1);

    // Set the default value for the RangePicker
    const defaultValue = [dayjs(formatDateToYYYYMMDD(threeMonthsAgo)), dayjs(formatDateToYYYYMMDD(now))]; // RangePicker takes an array of two moment objects

    // State to hold the selected date range
    const [selectedRange, setSelectedRange] = useState(defaultValue);
    //   const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [finalDateRange, setFinalDateRange] = useState({ startDate: '', endDate: '' });

    const [DateRangesss, setDateRangess] = useState({ startDate: '', endDate: '' });
    const [loading, setLoading] = useState(false);
    const [eodMonthlyData, setEodMonthlyData] = useState([]);
    const [monthTotal, setMonthTotal] = useState(0);
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD/MM/YYYY';
    const [allDoctor, setAllDoctor] = useState([]);
    const [selectDoctorForFilter, setSelectDoctorForFilter] = useState("");
    useEffect(() => {
        LdcApi({
            method: 'GET',
            url: `Doctor/GetDoctorDetail`
        })
            .then((response) => {
                setAllDoctor(response.data)
            })
            .catch((error) => {
            });
    }, [])
    function formatDate(dateObj) {
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 to get the correct month (0-indexed)
        const day = dateObj.getDate().toString().padStart(2, '0');
        const year = dateObj.getFullYear();

        return `${month}-${year}-${day}`;
    }
    useEffect(() => {
        if (selectedRange) {
            const ukStartDate = selectedRange[0][`$d`];
            const ukEndDate = selectedRange[1][`$d`];
            const startDate = formatDate(ukStartDate);
            const endDate = formatDate(ukEndDate)
            getDoctorHourData(startDate, endDate, selectDoctorForFilter);
        }
    }, [selectDoctorForFilter, selectedRange])
    // total count data
    useEffect(() => {
        const totalMinutes = eodMonthlyData.reduce((accumulator, currentValue) => {
            const [hours,, minutes,] = currentValue.totalHours.split(' ');
            const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
            return accumulator + totalMinutes;
        }, 0);
        const totalHours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;
        setMonthTotal(`${totalHours} hours ${remainingMinutes!==0 ? remainingMinutes+` minutes`: ``} `);
    }, [eodMonthlyData]);

    const onChange = (date, dateString) => {
        setFinalDateRange({
            ...DateRangesss,
            startDate: dateString[0],
            endDate: dateString[1]
            // .split('-').reverse().join('-')
        });
    };

    // useEffect(() => {
    //     var date = new Date();
    //     var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    //     setDateRangess({
    //         ...DateRangesss,
    //         startDate: moment(firstDay).format('YYYY/MM/DD'),
    //         endDate: moment(new Date()).format('YYYY/MM/DD')
    //     });
    // }, []);

    const handleSelectDoctor = (e) => {
        setSelectDoctorForFilter(e.target.value)
    }
    const handleDateChange = (dates) => {
        setSelectedRange(dates);
    };
    const getDoctorHourData = (startDate, endDate, selectDoctorForFilter) => {
        if (selectDoctorForFilter) {
            setLoading(true);
            LdcApi({
                method: 'GET',
                url: `Chamber/GetChamberDetailsByDoctor?doctorId=${selectDoctorForFilter}&starttime=${startDate}&endtime=${endDate}`
            })
                .then((response) => {
                    setEodMonthlyData(response.data)
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                });
        }else{
            setEodMonthlyData([])
        }
    }
    return (
        <div>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="d-flex align-items-center my-4" style={{ color: 'gray' }}>
                    <b className="mr-2">Date Range : </b>
                    {/* <RangePicker
                        format={dateFormat}
                        onChange={onChange}
                        defaultValue={[
                            moment(moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY/MM/DD')),
                            moment(moment(new Date()).format('YYYY/MM/DD'))
                        ]}
                    /> */}
                    <RangePicker value={selectedRange} onChange={handleDateChange} format="DD-MM-YYYY" />
                </div>
                <select onChange={handleSelectDoctor} value={selectDoctorForFilter} className='form-control doctor-slot-select'
                    style={{ width: "max-content", height: "40px",paddingTop:'0px',paddingBottom:'0px' }}>
                    <option value="">Search for Doctor</option>
                    {allDoctor.map((item) => {
                        return (
                            <option value={item.id}>{item.name}</option>
                        )
                    })}
                </select>
            </div>


            {loading ? (
                <>
                    <Skeleton />
                </>
            ) :
                <>
                    {eodMonthlyData.length > 0 ? <Table responsive className="table table-bordered" id="bootstrap_table">
                        <thead id="bootstrap_table_head">
                            <tr>
                                <th scope="col" style={{ minWidth: 200 }}>
                                    Date
                                </th>
                                <th scope="col" style={{ minWidth: 200 }}>
                                    Start Time
                                </th>
                                <th scope="col" style={{ minWidth: 200 }}>
                                    End Time
                                </th>
                                <th scope="col " style={{ minWidth: 200 }}>
                                    <span id="eod_table_heading">Total Hour</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {eodMonthlyData.map((el, i) => {
                                const originalDate = new Date(el.chamberDate);
                                // Get the components of the date
                                const day = originalDate.getDate();
                                const month = originalDate.getMonth() + 1; // Months are zero-based, so add 1
                                const year = originalDate.getFullYear();
                                // Create the formatted date string
                                const formattedDateString = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
                                return (<tr key={i}>
                                    <td>
                                        <div>{formattedDateString}</div>
                                    </td>
                                    <td>
                                        <div>{el.timeStart}</div>
                                    </td>
                                    <td>
                                        <div>{el.timeEnd}</div>
                                    </td>
                                    <td>
                                        <div>{el.totalHours}</div>
                                    </td>
                                </tr>)
                            })}

                            <tr className="border_top">
                                <td colspan={3} style={{ fontWeight: 'bold' }}>TOTAL</td>
                                <td style={{ fontWeight: 'bold' }}>{monthTotal}</td>
                            </tr>
                        </tbody>
                    </Table> : <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        {selectDoctorForFilter?<p>No records to show</p>:<h4 style={{ opacity: '0.7' }}>Select a doctor first</h4>}
                    </div>
                    }
                </>
            }
        </div>
    );
};

export default DoctorHours;
