import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { timesArr } from '../../../data/aptData';
import './Input.scss';

const getTimesArray = () => {
  var times = [],
    periods = ['AM', 'PM'],
    hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    prop = null,
    hour = null,
    min = null;

  for (prop in periods) {
    for (hour in hours) {
      for (min = 0; min < 60; min += 15) {
        if (hours[hour].toString().length === 1) {
          times.push(`0${hours[hour]}` + ':' + ('0' + min).slice(-2));
        } else {
          times.push(hours[hour] + ':' + ('0' + min).slice(-2));
        }
      }
    }
  }

  return times;
};

const TimePicker = ({ data, onTimeSelect, timeInput }) => {
  return (
    <div className="ne_dropdown_menu">
      <ul className="ne_dropdown_list p-0">
        {data.map((time, idx) => {
          return (
            <li className={`ne-scope`} key={idx} id={`time-${time}`} onClick={() => onTimeSelect(time)}>
              <a className={`ne_dropdown_item px-3 py-3 ${timeInput === time && 'selTime'}`}>
                <span className="ne-binding">{time}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const CustomTime = ({

  error = false,
  value,
  isDisabled,
  isListOpen,
  setIsListOpen,
  onTime,
  isDisabledTime,
  block,
  activeDotor,
  filedNotRed,
  showEditMode
}) => {
  const users = JSON.parse(localStorage.getItem('user_info'))

  const [endDateTime, setEndDateTime] = useState(timesArr);
  const scrollToDiv = (idToScroll) => {
    var element = document.getElementById(idToScroll);
    if (element) {
      element?.scrollIntoView({
        behavior: 'smooth',
        block: block //can take start, end
      });
    }
    return '';
  };

  const docFilt = endDateTime.filter((item) => {
    return item >= activeDotor?.timeStart && item <= activeDotor?.timeEnd;
  });

  useEffect(() => {
    if (isListOpen) {
      scrollToDiv(`time-${value}`);
    }
  }, [isListOpen]);
  const onHandleDisableTime = () => {
    let newTimeArr = endDateTime.indexOf(isDisabledTime?.toString());

    let newTimeArr2 = endDateTime.slice(newTimeArr + 1);

    setEndDateTime(newTimeArr2);
    setTimeout(() => {
      setIsListOpen(!isDisabled ? !isListOpen : false);
    }, 10);
  };
  return (
    <Form.Group controlId="controlInput1" className="custom_input_container">
      <div onClick={() => onHandleDisableTime()}>
        <Form.Control style={{ borderColor: error ? 'red' : '' }} type="text" placeholder="--:--" value={value}
          disabled={users?.roleID === 10 && showEditMode ? true : false}
        />
        <AiOutlineClockCircle className="custom_input_clock" />
      </div>

      {isListOpen && !isDisabled && (
        <div className="custom_time">
          <TimePicker data={!filedNotRed ? docFilt : endDateTime} onTimeSelect={onTime} timeInput={value} />
        </div>
      )}
    </Form.Group>
  );
};

export default CustomTime;
