import React from 'react';
import Form from 'react-bootstrap/Form';

const CustomInput = ({ error = false, placeholder, type, value, name, isDisabled, onChange, ...props }) => {
  return (
    <div className="">
      <Form.Control
        style={{ borderColor: error ? 'red' : '' }}
        disabled={isDisabled}
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        id={name}
        onChange={onChange}
        autoComplete={'' + Math.random()}
        {...props}
      />
    </div>
  );
};

export default CustomInput;
