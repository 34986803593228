import React from 'react';
import { Button } from 'react-bootstrap';
const CustomButtons = ({ buttonDisabled, variant, className, name, type, onClick, ...props }) => {
  return (
    <div>
      <Button disabled={buttonDisabled} type={type} variant={'outline-' + variant} className={className} onClick={onClick} {...props}>
        {props.children}
       {name}
      </Button>
    </div>
  );
};

export default CustomButtons;
