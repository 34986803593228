import React, { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import ConsentFormsNavbar from '../../../components/common/ConsentFormsNavbar';
import ProfileBar from './ProfileBar';
import './constant-form.scss';
import SignatureCanvas from 'react-signature-canvas';
import useScreenSize, { BreakPoint } from 'use-screen-size';
import CanvasDraw from 'react-canvas-draw';
import classNames from 'classnames';
import LdcApi from '../../../LdcApis';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

const afterSclerotherapy = [
  { id: 1, title: 'Stinging ', body: 'at the time of treatment which settles within minutes.' },
  { id: 1, title: 'Bruising', body: 'around the treated area which disappears within 2 weeks.' },
  {
    id: 1,
    title: 'Trapped blood',
    body: '-appears as dark tender lumps in the treated veins. This is expected  and can be drained within the first few weeks. If untreated this will improve within 6  months.'
  },
  { id: 1, title: 'Dizziness ', body: '- may be experienced during the ultrasound examination and rarely with  treatment.' },
  { id: 1, title: 'Leg aching', body: 'in days following treatment is usually mild and can be treated with  ibuprofen and walking.' },
  {
    id: 1,
    title: 'Urticaria ',
    body: '- localised itching and swelling of the skin may occur during the procedure  and resolves within 30 minutes.'
  },
  {
    id: 1,
    title: 'Skin blisters',
    body: '- may develop due to the rubbing of the silicone band of the compression stockings against the thigh and can be avoided by changing the position  of the band daily.'
  },
  {
    id: 1,
    title: 'Phlebitis ',
    body: '- inflammation of the treated vein which may be related to the trapped  blood. Usually resolves within days.'
  },
  {
    id: 1,
    title: 'Darkening of the treated veins',
    body: '(1:10 – 1:100) shortly after treatment. Often improves within weeks but may take up to a year.'
  },
  {
    id: 1,
    title: 'Matting ',
    body: '(1:10 – 1:100) – appearance of new fine spider veins in the treated areas.  They often disappear without further treatment within 3-6 months. Very rarely  matting is permanent.'
  },
  {
    id: 1,
    title: 'Migraine/visual disturbances',
    body: ' (1:100) – more common if you have a history of  migraines, usually resolves within 20 minutes.'
  }
];
const RareSideEffects = [
  { title: 'Motor nerve damage paralysis', body: '(1:10,000) – permanent pigmentation / scarring /  keloid.' },
  { title: 'Dermatitis', body: ' inflammation/skin reaction – permanent pigmentation / scarring / keloid.' },
  { title: 'Chest tightness', body: '(1:10 000) – can be due to vein spasm, usually resolves within 20  minutes.' },
  { title: 'Hair growth', body: ' – may occur even months after treatments and is localised and  temporary.' },
  {
    title: 'Skin ulcers',
    body: '(1:1000) – small and painful, can occur weeks after treatment. Will heal  but may occasionally leave a scar.'
  },
  { title: 'Allergic reaction', body: '– can range from mild to serious and life threatening.' },
  {
    title: 'Deep Vein Thrombosis',
    body: '(DVT) (1:1000 – 1:10 000) – a clot in a deep vein (different to  the treated vein). Higher risk with prior history of DVT. A potentially dangerous  problem that can lead to the clot travelling to the lungs. Risk can be minimised by  wearing your compression stockings after treatment and walking daily as directed.'
  },
  { title: 'Sensory nerve damage', body: '(1:1000 – 1:10 000) – may be temporary or permanent.  Usually improves within a few months.' },
  {
    title: 'Intra-arterial injection',
    body: ' (>1:10 000) – inadvertent injection into an artery which may  result in skin and muscle damage.'
  },
  {
    title: 'Stroke ',
    body: '(0.01%) - a temporary or permanent loss of body functions following an abrupt interruption of blood flow to the brain. A potentially dangerous condition that can be life threatening. '
  },
  {
    title: 'Recurrence ',
    body: '- it is possible for the veins to recur and the need for further treatment may be necessary.'
  }
];
const FoamSclerotherapy = () => {
  let appointmentData = JSON.parse(localStorage.getItem('appointmentData'));
  const size = useScreenSize();
  const { addToast } = useToasts();
  const { id } = useParams();
  const navigate = useNavigate();
  const sigCanvas = useRef();
  const sigCanvasOne = useRef();
  const [penColor, setPenColor] = useState('black');
  const [mouseDisable, setMouseDisable] = useState('auto');
  const [submitBtn, setBtnSubmit] = useState(false);
  const [brushColor, setBrushColor] = React.useState('#000');

  const [loading, setLoading] = useState(false);
  const [PatientSignatureShow, setPatientSignature] = useState(false);
  const [patientSignatureImges, setPatientSignatureImages] = useState('');
  const [doctorSignature, setDoctorSignature] = useState('');

  useEffect(() => {
    localStorage.setItem('confirmationCode', true);
    document.title = 'Foam Sclerotherapy Consent Form - LDC';
  }, []);

  const [formData, setFromData] = useState({
    patientName: `${appointmentData?.firstName} ${appointmentData?.lastName}`,
    patientDate: moment(new Date()).format('DD/MM/YYYY'),
    doctorDate: moment(new Date()).format('DD/MM/YYYY'),
    doctorName: `${appointmentData?.doctorName == null || appointmentData?.doctorName == '' ? '' : appointmentData?.doctorName}`
  });

  const handelOnChange = (e) => {
    setFromData({ ...formData, [e.target.name]: e.target.value });
  };

  const [validationError, setValidationError] = useState({
    patientName: false,
    patientDate: false,
    doctorDate: false,
    doctorName: false,
    doctorSignature: false,
    patientSignatureImges: false
  });

  const handelSubmit = (e) => {
    setBtnSubmit(true);
    e.preventDefault();
    let formValidationError = {
      patientName: false,
      patientDate: false,
      doctorDate: false,
      doctorName: false,
      doctorSignature: false,
      patientSignatureImges: false
    };
    if (formData.doctorDate === '') {
      formValidationError.doctorDate = true;
    } else {
      formValidationError.doctorDate = false;
    }
    if (formData.doctorName === '') {
      formValidationError.doctorName = true;
    } else {
      formValidationError.doctorName = false;
    }
    if (formData.patientDate === '') {
      formValidationError.patientDate = true;
    } else {
      formValidationError.patientDate = false;
    }
    if (formData.patientName === '') {
      formValidationError.patientName = true;
    } else {
      formValidationError.patientName = false;
    }

    if (patientSignatureImges === '') {
      formValidationError.patientSignatureImges = true;
    } else {
      formValidationError.patientSignatureImges = false;
    }
    if (doctorSignature === '') {
      formValidationError.doctorSignature = true;
    } else {
      formValidationError.doctorSignature = false;
    }
    setValidationError(formValidationError);

    const data = Object.values(formValidationError).every((item) => item === false);

    if (data) {
      const newValues = {
        appointmentId: appointmentData?.id,
        patientName: formData.patientName,
        patientSignature: patientSignatureImges,
        patientDate: moment(new Date()).format(),
        doctorSignature: doctorSignature,
        doctorDate: moment(new Date()).format(),
        doctorName: formData.doctorDate
      };
      LdcApi({
        method: 'POST',
        url: 'PatientConsentForm_Sclerotherapy/UpsertFoamSclerotherapy',
        data: newValues
      })
        .then((res) => {
          if (res?.data?.status?.isSuccess === true) {
            setBtnSubmit(false);
            canvasRef.current.clear();
            navigate('/confirmation');
            canvasRefTwo.current.clear();
            formData({
              patientName: '',
              patientDate: moment(new Date()).format('DD/MM/YYYY'),
              doctorDate: moment(new Date()).format('DD/MM/YYYY'),
              doctorName: ''
            });
            addToast('General Surgery Consent form added successfully ', { appearance: 'success' });
          } else {
            setBtnSubmit(false);
          }
        })
        .catch((err) => {
          setBtnSubmit(false);
        });
    } else {
      setBtnSubmit(false);
    }
  };
  const hideForms = () => {
    navigate('/confirmation');
  };

  // new SignaturePad
  const canvasRef = useRef(null);
  const canvasRefTwo = useRef(null);

  const defaultProps = {
    loadTimeOffset: 5,
    lazyRadius: 0,
    brushRadius: 2,
    catenaryColor: '#0a0302',
    gridColor: 'rgba(150,150,150,0.17)',
    hideGrid: true,
    // canvasWidth: 1000,
    canvasHeight: 300,
    disabled: false,
    imgSrc: '',
    saveData: '',
    immediateLoading: false,
    hideInterface: false
  };

  const getImg = () => canvasRef.current.canvasContainer.children[1].toDataURL();
  const getImgTwo = () => canvasRefTwo.current.canvasContainer.children[1].toDataURL();

  const handleCanvasChange = () => {
    const saveData = getImg();
    setPatientSignatureImages(saveData);
    if (patientSignatureImges == '') {
      setValidationError({ ...validationError, patientSignatureImges: true });
    } else {
      setValidationError({ ...validationError, patientSignatureImges: false });
    }
  };

  const handleCanvasChangetwo = () => {
    const saveData = getImgTwo();
    setDoctorSignature(saveData);
    if (doctorSignature == '') {
      setValidationError({ ...validationError, doctorSignature: true });
    } else {
      setValidationError({ ...validationError, doctorSignature: false });
    }
  };

  const props = {
    ...defaultProps,
    onChange: handleCanvasChange,
    ref: canvasRef,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor
  };

  const propstwo = {
    ...defaultProps,
    onChange: handleCanvasChangetwo,
    ref: canvasRefTwo,
    className: classNames('canvasSignature'),
    brushColor,
    catenaryColor: brushColor
  };

  return (
    <div>
      <div className="navbar_header_container br_c">
        <ConsentFormsNavbar />
      </div>
      <ProfileBar />
      <Container style={{ pointerEvents: { mouseDisable } }}>
        {/* go to diary  */}
        <div className="d-flex my-4 justify-content-end">
          <Button onClick={() => hideForms()} variant="outline-primary mb-3">
            Go to diary
          </Button>
        </div>
        <Card className="p-4">
          <p className="text-center form_heading  mb-5">Foam Sclerotherapy Consent Form</p>

          <Form onSubmit={(e) => handelSubmit(e)}>
            <p>
              This form is designed to provide you with the information you need to make an informed decision on whether to undergo
              sclerotherapy. Please ask any questions prior to signing.{' '}
            </p>
            <div className="mt-2">
              <b>Purpose of Vein treatment: </b>
              <p>
                By signing I hereby authorise the doctor and/or associates to extract, ablate, inject or interrupt diseased veins for the
                purpose of attempting to improve the symptomology and/or appearance of the legs.{' '}
              </p>
            </div>
            <div className="mt-2">
              <b>Alternatives treatments</b>
              <p>
                I understand that alternative treatment for varicose veins exist, including conservative treatments (elastic stockings)
                surgical stripping, vein ablation and vein litigation.
              </p>
            </div>
            <div className="mt-2">
              <b>Outcomes from treatment</b>
              <p>
                By signing I understand that the practice of medicine and surgery is not an exact science, and, therefore reputable
                practitioners cannot guarantee results. No guarantee or assurance has been given by anyone, as to the results that may be
                obtained.
              </p>
            </div>

            <div className="mt-5">
              <b>Common side effects during / after sclerotherapy:</b>
              <ul>
                {afterSclerotherapy.map((el, index) => (
                  <li key={index} className=" align-items-center my-1">
                    <b className="mr-1">{el.title}</b>
                    {el.body}
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-5">
              <b>Rare side effects during / after sclerotherapy:</b>
              <ul>
                {RareSideEffects.map((el, index) => (
                  <li key={index} className=" align-items-center my-1">
                    <b className="mr-1">{el.title}</b>
                    {el.body}
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-5">
              <b>Informed Treatment Consent:</b>
              <ul>
                <b>Patient </b>
                <p className=" align-items-center my-1">
                  ➢ I have read this consent form and have adequately been informed of the proposed sclerotherapy procedure and the risks of
                  treatment.
                </p>
                <p className=" align-items-center my-1">
                  ➢ Alternative methods of treatment and the risks/eventualities of not treating my condition have been discussed.
                </p>{' '}
                <p className=" align-items-center my-1">
                  ➢ I acknowledge that adequate time was provided for any questions to be answered. I understand that the results (improved
                  or success to satisfaction or failure) cannot be guaranteed and depend on various aspects individual to each patient.
                  There is a risk of recurrence of symptoms despite treatment. I hereby authorise the doctor to perform any treatment that
                  may be deemed necessary, should he/she encounter an unhealthy or unforeseen condition during the course of the procedure.
                </p>
              </ul>
            </div>
            <div>
              <Form.Group className="m-0 p-0 d-flex align-items-center">
                <b className="mr-2  ">
                  Patient Name: <span className="text-danger">*</span>{' '}
                </b>
                <div className="">
                  <input
                    className={`from_control    `}
                    type="text"
                    name="patientName"
                    onChange={(e) => {
                      handelOnChange(e);
                      if (e.target.value.length > 0) {
                        setValidationError({ ...validationError, patientName: false });
                      } else {
                        setValidationError({ ...validationError, patientName: true });
                      }
                    }}
                    value={formData.patientName}
                  // placeholder="name"
                  />
                  <br /> <span className="text-danger">{validationError.patientName === true && 'Field is required.'}</span>
                </div>
              </Form.Group>

              <Row className="pl-3">
                <b className="mr-2  ">
                  Patient Signature: <span className="text-danger">*</span>
                </b>
                <Col sm={12} className="mt-2">
                  <div style={{ overflow: 'hidden' }}>
                    <CanvasDraw {...props} />
                    <span className="text-danger">{validationError.patientSignatureImges === true && 'Field is required.'}</span>
                    <br />
                    <Button
                      classNames="mt-2"
                      onClick={() => {
                        canvasRef.current.clear();
                        setPatientSignatureImages('');
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </Col>
                {/* <span className="text-danger  ml-3">{validationError.URL === true && 'Field is required.'}</span> */}
              </Row>

              <Form.Group className="m-0 p-0 d-flex align-items-center  ">
                <b className="mr-2">
                  Date: <span className="text-danger">*</span>
                </b>
                <div>
                  <input
                    disabled
                    className={`from_control    `}
                    type="text"
                    name="patientDate"
                    onChange={(e) => {
                      handelOnChange(e);
                      if (e.target.value.length > 0) {
                        setValidationError({ ...validationError, patientDate: false });
                      } else {
                        setValidationError({ ...validationError, patientDate: true });
                      }
                    }}
                    value={formData.patientDate}
                  // placeholder="name"
                  />
                  <br />
                  <span className="text-danger">{validationError.patientDate === true && 'Field is required.'}</span>
                </div>
              </Form.Group>
            </div>

            <ul>
              <b>Doctor </b>
              <p className=" align-items-center my-1">
                ➢ I declare that I have personally explained the nature of the patient’s condition, the need for treatment, the procedure to
                be performed and the risks and alternatives outlined in this consent form.
              </p>
              <p className=" align-items-center my-1">
                ➢I have given the patient named above the opportunity to ask questions which I believe I have answered to the patient’s
                satisfaction.
              </p>{' '}
            </ul>
            <div>
              <Form.Group className="m-0 p-0 d-flex align-items-center">
                <b className="mr-2  ">
                  Doctor Name: <span className="text-danger">*</span>
                </b>

                <div>
                  <input
                    className={`from_control    `}
                    type="text"
                    name="doctorName"
                    onChange={(e) => {
                      handelOnChange(e);
                      if (e.target.value.length > 0) {
                        setValidationError({ ...validationError, doctorName: false });
                      } else {
                        setValidationError({ ...validationError, doctorName: true });
                      }
                    }}
                    value={formData.doctorName}

                  // placeholder="name"
                  />
                  <br />
                  <span className="text-danger">{validationError.doctorName === true && 'Field is required.'}</span>
                </div>
              </Form.Group>
              <Form.Group className="m-0 p-0 d-flex align-items-center">
                <b className="mr-2">
                  Date: <span className="text-danger">*</span>
                </b>
                <div>
                  <input
                    className={`from_control     `}
                    type="text"
                    disabled
                    name="doctorDate"
                    onChange={(e) => {
                      handelOnChange(e);
                      if (e.target.value.length > 0) {
                        setValidationError({ ...validationError, doctorDate: false });
                      } else {
                        setValidationError({ ...validationError, doctorDate: true });
                      }
                    }}
                    value={formData.doctorDate}
                  // placeholder="name"
                  />
                  <br />
                  <span className="text-danger">{validationError.doctorDate === true && 'Field is required.'}</span>
                </div>
              </Form.Group>

              <Row className="pl-3">
                <b className="mr-2  ">
                  Doctor Signature: <span className="text-danger">*</span>
                </b>
                <Col sm={12} className="mt-2">
                  <div style={{ overflow: 'hidden' }}>
                    <CanvasDraw {...propstwo} />
                    <span className="text-danger">{validationError.doctorSignature === true && 'Field is required.'}</span>
                    <br />
                    <Button
                      classNames="mt-2"
                      onClick={() => {
                        canvasRefTwo.current.clear();
                        setDoctorSignature('');
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                </Col>
                {/* <span className="text-danger  ml-3">{validationError.URL === true && 'Field is required.'}</span> */}

                <Col sm={12} className="mt-2"></Col>
              </Row>
            </div>
            <div className="d-flex my-4    ">
              <button type="submit" disabled={submitBtn} className="btn px-4 btn-outline-primary">
                {submitBtn ? 'Loading....' : 'Save'}
              </button>
              {/* <CustomButtons type="submit " buttonDisabled={submitBtn} variant="primary" className="outline- px-4" name="Save" /> */}
            </div>
          </Form>
        </Card>
      </Container>
    </div>
  );
};

export default FoamSclerotherapy;
